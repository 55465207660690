
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Player from './components/Player';
import Search from './components/Search';

import './style.scss';

class Detection extends React.Component {

    render() {
        return (
            <div className='Detection-Container'>
                <Switch>
                    <Route path='/web/detection/player'>
                        <Player />
                    </Route>
                    <Route path='/web/detection/search'>
                        <Search />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default Detection;