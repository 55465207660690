
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { createUseStyles } from 'react-jss';

import { backgroundClass } from '../../index';

export const BottomDrawer = ({ background, children, isOpen })=> {
    const classes = useStyles();
    
    return (
        <div className={clsx({
            [classes.Container]: true,
            [classes.SlideInBottom]: (isOpen),
            [classes.SlideOutBottom]: (!isOpen)})}>
            <div className={clsx({
                [classes.Wrapper]: true,
                [classes.Clear]: (background===backgroundClass.CLEAR),
                [classes.Opaque]: (background===backgroundClass.OPAQUE),
                [classes.Transparent]: (background===backgroundClass.TRANSPARENT)})}>
                {children}
            </div>
        </div>
    );
};

const useStyles = createUseStyles({
    '@keyframes slideInBottom': {
        from: { display: 'none', bottom: '-100%', opacity: '0' },
        to: { display: 'block', bottom: '0', opacity: '1' }
    },
    '@keyframes slideOutBottom': {
        from: { display: 'block', bottom: '0', opacity: '1' },
        to: { display: 'none', bottom: '-100%', opacity: '0' }
    },
    Container: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        zIndex: '99',
    },
    Wrapper: {
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        width: '100%'
    },
    Clear: {
        background: 0
    },
    Opaque: {
        background: '#000'
    },
    Transparent: {
        background: '#00000060'
    },
    SlideInBottom: {
        display: 'block',
        bottom: '0',

        animationName: '$slideInBottom',
        animationDuration: '1s'
    },
    SlideOutBottom: {
        bottom: '-100%',
        
        animationName: '$slideOutBottom',
        animationDuration: '1s'
    }
}, { name: 'BottomDrawer' });

BottomDrawer.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool,
};