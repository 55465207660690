/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import config from '../../constants/config';

// used for making the prop types of this component
import PropTypes from "prop-types";

@withRouter
class Footer extends React.Component {
  render() {
    let currentPath = this.props.location.pathname;
    let _path = (currentPath.indexOf('/web')>-1)?'/web/support':'/home/support';
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <div className="copyright">
            {new Date().getFullYear()} © Tysfllo <br/><p className='versionNo'>{config.version}</p>
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
