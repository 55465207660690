import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Program from './Program';
import moment from 'moment';

class ChannelItem extends React.PureComponent {

  isProgramActive(program) {
    const {currentTime} = this.props;
    const start = moment(program.start);
    const end = moment(program.end);
    const current = moment(currentTime);

    return current.isBetween(start, end, null, '[)');
  }

  renderPrograms() {
    const {channel, onProgramSelected} = this.props;
    const programs = channel.schedules;

    return programs.map((program, index) => {
      const isActive = this.isProgramActive(program);
      return <Program program={program} channel={channel}
        isActive={isActive} 
        key={index}
        onProgramSelected={onProgramSelected}/>;
    });
  }

  /*
  renderPrograms() {
      const { channel, onProgramSelected } = this.props;

      const generateStubs = ()=> {
          const today = dayjs().startOf('day');
          return new Array(HOURS_IN_A_DAY*2).fill(null).map((item, index)=> ({
              start: today.add((index*30), 'minute').format(),
              end: today.add(((index*30)+30), 'minute').format()
          }));
      };

      const renderProgram = (program, index)=> {
          const isActive = this.isProgramActive(program);
          return (
              <Program key={index}
                  isActive={isActive}
                  program={program}
                  onProgramSelected={()=> { onProgramSelected(channel, program); }} />
          );
      };

      const programs = ((channel.schedules && channel.schedules.length) ? channel.schedules : generateStubs());
      return programs.map(renderProgram);
  }
  */

  render() {
    const {channel, onProgramSelected, currentTime} = this.props;
    const programElements = this.renderPrograms();

    if(programElements && programElements.length > 0){
      return (
        <>
        {/* {(programElements && programElements.length > 0)? */}
          <div className={(programElements && programElements.length > 0)?'timetable__body-row':'timetable__body-row empty'}>
            {programElements}
          </div>
          {/* :null} */}
        </>
      );
    } else {
      const _program = {start: new Date(currentTime)};
      return (
        <>
          <div 
            className={(programElements && programElements.length > 0)?'timetable__body-row':'timetable__body-row empty'}
            onClick={()=> { onProgramSelected(channel, _program) }}>
          </div>
        </>
      );
    }
  }
}

ChannelItem.propTypes = {
  channel: PropTypes.object,
  currentTime: PropTypes.string,
  onProgramSelected: PropTypes.func
};

export default ChannelItem
