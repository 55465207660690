import socket from './connector';
/**
 * Wrapping the Store with SIO and calling supplied Subscribe Action.
 * Your supplied {storeObj} should contain a Subscribe(type, data) action to call on SIO message recieved.
 * Subscribe action: 
 * - type: CREATE, UPDATE, DELETE
 * - entity: TENANT, USER ...
 * - data: Payload data
 * 
 * @param {Object} storeObj The mobx Store Obj
 * @param {String} modelName The model the store is listening for messages, ALL or MODELNAME
 * @param {Object} socket Socket Instance connected
 */
const setupSIOStore = (storeObj, modelName) => {
  // Create the one instance & Global
  let inst = new storeObj();
  let instName = inst.constructor.name;
  var newStore = (window[`${instName}`] = inst);

  if(newStore && newStore.Subscribe && typeof newStore.Subscribe === 'function'){
    if(socket && socket.on){ // valid socket
      // console.log(`--- [SIOStore] (${instName}) Channel: ${modelName} Start Listening...`);
      socket.on('MESSAGE', (payload) =>{
        try{
          if(payload && payload.model && payload.action && payload.data){
            // console.log(`--- [SIOStore] (${instName}) Channel: ${modelName}`, payload);
            // let _type = payload.channel.split('_');
            if(modelName.toUpperCase() === 'ALL' || payload.model.toUpperCase() === modelName.toUpperCase()){
              newStore.Subscribe(payload.action, payload.model, payload.data);
            }
          }
        }catch(ex){
          try{
            socket.off('MESSAGE');
            // console.log(`--- [SIOStore] (${instName}) Channel: ${modelName} Stopped Listening...`);
          }catch(e){} // Don't care
        }
      });
    }
  } else {
    // console.log(`#-# Store: ${instName} has been SIO wrapped, but has no Subscribe(type, data) Action, did you forget to add it?`);
  }

  return newStore;
}

export default setupSIOStore;