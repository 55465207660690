
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import dayjs from 'dayjs';

export const Events = ({ currentTime, media, events, onEventClicked })=> {

    const renderEvent = (event, index)=> {
        let categoryStyle = {};
        let type = '';
        let description = '';
        let duration = '';
        const start = dayjs(event.detect_timeUTC);
        const end = dayjs(event.detect_timeUTC).add(event.duration);
        let isCurrentEvent = false;
        if (start.unix() <= currentTime.unix() && end.unix() >= currentTime.unix()) {
            isCurrentEvent = true;
        }
        if (event.type !== 'EVENT_TAG') {
            if (event.detect_type === 'music') {
                if (event.genres === 'jingle') {
                    categoryStyle = { background: '#cc00ff', border: '1px solid #800080' };
                    type = '[jingle]';
                } else {
                    categoryStyle = { background: '#735CF4', border: '1px solid #0000ff' };
                    type = '[music]';
                    description = `${event.artists} - ${event.title}`;
                }
                duration = `[${parseInt(Math.ceil(event.duration))} sec]`;
            } else if (event.detect_type === 'advert') {
                categoryStyle = { background: '#5CF487', border: '1px solid #008000' };
                type = '[advert]';
                description = `${event.flightcode}`;
                duration = `[${parseInt(Math.ceil(event.duration/1000))} sec]`;
            } else {
                type = '[unknown]';
            }
        } else {
            event.detect_timelocal = dayjs(event.start_time*1000).add(2, 'hour');
            event.detect_timeUTC = event.start_time*1000;
            
            duration = `[${parseInt(Math.ceil(dayjs(event.end_time*1000).diff(dayjs(event.start_time*1000), 'second')))} sec]`;
            categoryStyle = { background: '#FFF30F', border: '1px solid #bfb000' };
            description = event.title;
            type = '[tag]';
        }

        const navigateToEvent = (startTime)=> { onEventClicked(startTime); };

        return (
            <li className={clsx({ 'Scrubber-Navigation-Events-List-Item-Container': true, 'selected': isCurrentEvent })} key={index}>
                <div className='Scrubber-Navigation-Events-List-Item-Wrapper' onClick={()=> { navigateToEvent(dayjs(event.detect_timelocal).subtract(2, 'hour')); }}>
                    <div className='Scrubber-Navigation-Events-List-Item-Timestamp'>
                        {dayjs(event.detect_timeUTC).format('HH:mm:ss')}
                    </div>
                    <div className='Scrubber-Navigation-Events-List-Item-Color-Code' style={categoryStyle} />
                    <div className='Scrubber-Navigation-Events-List-Item-Description'>
                        <span>{type}</span>
                        <span>{description}</span>
                        <span>{duration}</span>
                    </div>
                </div>
            </li>
        );
    };

    let filteredEvents = [];
    if (media && media.readyTimestamp) {
        filteredEvents = events.filter((event)=> {
            if (event.type !== 'EVENT_TAG') {
                if (dayjs(event.detect_timeUTC).unix() >= media.readyTimestamp.subtract(2, 'minute').unix()) {
                    if (dayjs(event.detect_timeUTC).unix() <= media.readyTimestamp.subtract(2, 'minute').clone().add(media.duration, 'second').unix()) {
                        return true;
                    }
                }
            } else {
                if (event.start_time >= media.readyTimestamp.subtract(2, 'minute').unix()) {
                    if (event.end_time <= media.readyTimestamp.subtract(2, 'minute').clone().add(media.duration, 'second').unix()) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    return (
        <div className='Scrubber-Navigation-Events-Container'>
            <ul className='Scrubber-Navigation-Events-List'>
                {filteredEvents.map(renderEvent)}
            </ul>
        </div>
    );
};

Events.propTypes = {
    onCurrentTimeChanged: PropTypes.func,
};