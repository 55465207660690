
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { HLSPlayer, ResizePanel } from '../../../../components';

import { Navigation } from './components';

import './style.scss';

export const MainPanel = (props)=> {
    const { media, selectedChannel, onHLSReady } = props;
    const containerRef = useRef();
    const mediaPlayerRef = useRef();

    const handleHLSReady = (hls) => {
        if(hls && onHLSReady && typeof onHLSReady === 'function'){
            onHLSReady(hls);
        }
    }

    return (
        <div className='ScrubberDep-Main-Panel-Container' ref={containerRef}>
            <ResizePanel
                left={
                    <HLSPlayer controls
                        autoPlay={media.autoPlay}
                        innerRef={mediaPlayerRef}
                        style={{ margin: 'auto 0', width: '100%' }}
                        source={media.source}
                        media={media}
                        selectedChannel={selectedChannel}
                        onHLSReady={handleHLSReady} />
                }
                leftStyle={{ flex: 7, margin: '0', width: '70%', maxWidth: '70%', minWidth: '730px', padding: '0 8px 0 0' }} // 1080px
                minWidth={420}
                right={<Navigation {...props} />}
                rightStyle={{ height: '100%', flex: 5, margin: '0 auto', maxWidth: '50%', minWidth: '550px', padding: '0 0 0 8px' }} // 300px
                onResize={()=> {}} />
        </div>
    );
};

MainPanel.propTypes = {
    currentTime: PropTypes.object,
    events: PropTypes.array,
    exportRecord: PropTypes.object,
    media: PropTypes.object,
    selectedChannel: PropTypes.object,
    tags: PropTypes.array,
    user: PropTypes.object,
    onClearExport: PropTypes.func,
    onClearEvent: PropTypes.func,
    onExport: PropTypes.func,
    onHLSReady: PropTypes.func,
    onExportChoice: PropTypes.func,
    onPersistExport: PropTypes.func,
    onPersistEventTag: PropTypes.func,
    onExportClicked: PropTypes.func,
    onRefreshExports: PropTypes.func,
    onEventClicked: PropTypes.func
};