
import { observable, action, reaction } from 'mobx';

import utils from './utils';

import commonStore from './commonStore';
import loadingStore from './loadingStore';

const defaultData = {
    isLoading: false,
}

class DataStore {
    @observable isLoading = false;

    constructor() {
        reaction(()=> (this.isLoading), ()=> {
            loadingStore.setLoading(this.isLoading);
        });
    }

    /**
     * Retrieves data from cache or locally stored.
     * @param {string} key - The key used to access the data.
     * @returns 
     */
    fetchCachedData(key) {
        const hasSessionItems = utils.hasSessionItem(key);
        if ((!this[key] || !this[key].length) && hasSessionItems) { // Return Session Data.
            return this.getSessionData(key);
        } else if (this[key] && this[key].length) { // Return Store Data.
            return this[key];
        }
        return [];  // Return Empty Array.
    }
    
    /**
     * Removes local data for the provided key.
     * @param {string} key - The key used to access the data.
     * @returns {boolean}
     */
    clearLocalData(key) {
        return utils.removeLocalItem(key);
    }

    /**
     * Returns the local data for the provided key.
     * @param {string} key - The key used to access the data.
     * @returns {object}
     */
    getLocalData(key) {
        if (utils.hasLocalItem(key)) {
            return utils.getLocalItem(key);
        }
    }

    /**
     * Checks whether local data exists for the provided key.
     * @param {string} key - The key used to access the data.
     * @returns {boolean}
     */
    hasLocalData(key) {
        return utils.hasLocalItem(key);
    }

    /**
     * Initializes the browsers' LocalStorage for the provided key.
     * @param {string} key - The key used to access the data.
     * @param {object?} data - The data to store.
     */
    initializeLocalCache(key, data={}) {
        const hasLocalData = this.hasLocalData(key);
        if (hasLocalData) {
            this.clearLocalData(key);
        }
        this.setLocalData(key, data);
    }

    /**
     * Stores retrieved data in the browsers' LocalStorage.
     * @param {string} key - The key used to access the data.
     * @param {object} data - The data to store.
     */
    setLocalData(key, data) {
        utils.setLocalItem(key, data);
    }
    
    /**
     * Removes session data for the provided key.
     * @param {string} key - The key used to access the data.
     * @returns {boolean}
     */
    clearSessionData(key) {
        return utils.removeSessionItem(key);
    }

    /**
     * Checks whether session data exists for the provided key.
     * @param {string} key - The key used to access the data.
     * @returns {boolean}
     */
    hasSessionData(key) {
        return utils.hasSessionItem(key);
    }

    /**
     * Initializes the browsers' SessionStorage for the provided key.
     * @param {string} key - The key used to access the data.
     * @param {object?} data - The data to store.
     */
    initializeSessionCache(key, data={}) {
        const hasLocalData = this.hasSessionData(key);
        if (hasLocalData) {
            this.clearSessionData(key);
        }
        this.setSessionData(key, data);
    }

    /**
     * Stores retrieved data in the browsers' SessionStorage.
     * @param {string} key - The key used to access the data.
     * @param {object} data - The data to store.
     */
    setSessionData(key, data) {
        utils.setSessionItem(key, data);
    }

    /**
     * Sets a loading-key to the desired state.
     * @param {string} key - The key of the loading-state to set.
     * @param {boolean?} value - The value of the loading-state to set.
     */
    @action setLoadingState(key, value, force=false) {
        this.loadingState[key] = value;
        console.log(`Loading: ${key} [${value}]`);
        this.setIsLoading(value, force);
    }

    /**
     * Sets whether this store is currently loading data.
     * @param {boolean} isLoading - The loading state to set.
     * @param {boolean?} force - Should present state be forcefully overwritten.
     */
     @action setIsLoading(isLoading, force=false) {
        const setIsLoading = (loading)=> {
            this.isLoading = loading;
        };
        if (force || (this.isLoading !== isLoading)) {
            setIsLoading(isLoading);
        }
    }

    /**
     * Invalidates the loading-state.
     */
    @action clearLoading() {
        let shouldClear = true;
        for (const key of Object.keys(this.loadingState)) {
            if (this.loadingState[key] !== false) {
                shouldClear = false;
            }
        }
        if (shouldClear) {
            loadingStore.setLoadingForce(false);
        }
    }

    /**
     * Wraps an ajax call in a try-catch block and responds with neat errors if needed,
     * else, returns the data.
     * @param {function} callback - The ajax call to execute.
     * @param {object?} params - The parameters to apply to the ajax call. 
     * @param {string?} callee - The function-trace executing the call, e.g. `[ChannelStore::getChannels]`.
     * @returns {object|array}
     */
    @action async ajaxCall(callback, params=[], callee='[undefined]') {
        try {
            const response = await callback.call(this, ...params);
            if (response.message) {
                throw new Error(response.message)
            }
            return response;
        } catch(err) {
            console.error(callee, err);
            //commonStore.addErrorMsg(err);
        }
    }

    /**
     * Restores this store to the initial-state (defaultData).
     */
    @action clearStore() {
        Object.keys(defaultData).forEach(key => {
            this[key] = defaultData[key];
        });
    }
}

export default DataStore;