
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

import './style.scss';

export const Events = ({ events=[], media={}, selectedChannel })=> {
    const containerRef = useRef();
    
    let timelineEvents = [];
    let containerWidth = 0;

    if (containerRef && containerRef.current) {
        containerWidth = containerRef.current.clientWidth;
    }

    const styleEvent = (event)=> {
        let background;
        let border;
        let zIndex;
        if (event.type !== 'EVENT_TAG') {
            if (event.detect_type === 'music') {
                if (event.genres === 'jingle') {
                    background = '#cc00ff';
                    border = '1px solid #800080';
                    zIndex = '1';
                } else {
                    background = '#735cf4';
                    border = '1px solid #0000ff';
                    zIndex = '0';
                }
            } else if (event.detect_type === 'advert') {
                background = '#5CF487';
                border = '1px solid #008000';
                zIndex = '1';
            }
        } else {
            event.flightcode = event.title;
            event.detect_timelocal = event.start_time*1000;
            event.detect_timeUTC = event.start_time*1000;
            event.duration = dayjs(event.end_time*1000).diff(dayjs(event.start_time*1000), 'second');

            background = '#FFF30F';
            border = '1px solid #bfb000';
            zIndex = '1';
        }
        event.background = background;
        event.border = border;
        event.zIndex = zIndex;
    };

    if (media.readyTimestamp && containerWidth) {
        const startTime = media.readyTimestamp.subtract(2, 'minute');
        const endTime = startTime.clone().add(media.duration, 'second');
        const filteredEvents = events.slice().filter((event)=> (
            (dayjs(event.detect_timeUTC).unix() >= startTime.unix()) &&
            (dayjs(event.detect_timeUTC).unix() <= endTime.unix())
        ));
        filteredEvents.forEach((event)=> {
            const start = dayjs(event.detect_timeUTC);
            let potentialWidth;
            if (event.type !== 'EVENT_TAG') {
                if (!selectedChannel.audioOnly) {
                    const end = start.add(event.duration, 'second');
                    const duration = ((end.diff(start, 'second'))/media.duration);
                    potentialWidth = ((duration/1000)*containerWidth);
                } else {
                    const end = start.add(event.duration, 'second');
                    const duration = ((end.diff(start, 'second'))/media.duration);
                    potentialWidth = ((duration)*containerWidth);
                }
            } else {
                const duration = event.duration/media.duration;
                potentialWidth = (duration*containerWidth);
            }
            event.width = ((potentialWidth !== Infinity) ? potentialWidth : 0);
            event.left = (((start.diff(startTime)/1000)/media.duration)*100)+'%';
            styleEvent(event);
        });
        timelineEvents = filteredEvents;
    }

    const renderEvent = (event, index)=> {
        const style = { background: event.background, border: event.border, left: event.left, width: event.width, zIndex: event.zIndex };
        return (
            <div className='Scrubber-Panel-Timeline-Event' key={index} style={style}>
                <p>{event.flightcode}</p>
                <p>{event.detect_timelocal}</p>
            </div>
        );
    };

    return (
        <div className='Scrubber-Panel-Timeline-Events-Container' ref={containerRef}>
            <div className='Scrubber-Panel-Timeline-Events-Wrapper'>
                {timelineEvents.map(renderEvent)}
            </div>
        </div>
    );
};

Events.propTypes = {
    currentTime: PropTypes.object,
    events: PropTypes.array,
    media: PropTypes.object,
    selectedChannel: PropTypes.object
};