import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import googleAnalytics from '../../services/googleAnalytics';

import { WebNavbar, Footer, Loading } from '@components';

import getRoutes from '../../routes/routes_default';
import './Default.scss';

class Default extends React.Component {
    constructor(props) {
        super(props);

        this.routes = [];
    }

    componentWillMount() {
        this.routes = getRoutes(false);
    }

    componentDidMount() {
        document.documentElement.classList.remove('nav-open');
    }
    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === '/web' && prop.identifier !== 'login') {
                if (prop.hidden) {
                    return null;
                }
            }
            if (prop.views) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout && prop.layout === '/auth') {
                return (
                    <Route key={key}
                        path={prop.path}
                        component={prop.component} />
                );
            } else if (prop.layout === '/web') {
                return (
                    <Route key={key}
                        path={prop.path}
                        component={prop.component} />
                );
            }
            return (
                <Redirect to='/web/login' />
            );
        });
    };
    getActiveRoute = routes => {
        let activeRoute = 'Default';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return { name: collapseActiveRoute, hide: (!!collapseActiveRoute.hideTitle) };
                }
            } else {
                if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return { name: routes[i].name, hide: (!!routes[i].hideTitle) };
                }
            }
        }
        return { name: activeRoute, hide: (!!activeRoute.hideTitle) };
    };
    getFullPageName = routes => {
        const view = this.getActiveRoute(routes);
        googleAnalytics.sendGAPageView(view.name.toLowerCase);
        switch (view) {
            case 'Home':
                return 'home-page';
            case 'Login':
                return 'login-page';
            case 'Register':
                return 'register-page';
            // case 'Lock Screen':
            //   return 'lock-page';
            default:
                return 'login-page';
        }
    };

    render() {
        const activeRoute = this.getActiveRoute(this.routes);

        return (
            <>
                <Loading />
                <WebNavbar brandText={(!!activeRoute.hide) ? '' : activeRoute.name + ' Page'} />
                <div className='wrapper wrapper-full-page' ref='fullPages'>
                    <div className={'full-page ' + this.getFullPageName(this.routes)}>
                        <Switch>{this.getRoutes(this.routes)}</Switch>
                        <Footer fluid />
                    </div>
                </div>
            </>
        );
    }
}

export default Default;
