import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'mobx-react';
import stores from './stores';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './components/complex/theme-default';

import App from './App';

//Socket
import IOProvider from './serviceWrapper/SocketIO/IOProvider';
import socket from './serviceWrapper/SocketIO/connector';

import "./assets/css/nucleo-icons.scss";
import "./assets/scss/black-react.scss?v=1.0.0";
import "./assets/scss/main.scss";
import "react-notification-alert/dist/animate.css";

// Utils
import './utils';

// For easier debugging ;)
window._____APP_STATE_____ = stores;

ReactDOM.render(
  <IOProvider socket={socket}> 
    <Provider {...stores}>
      <MuiThemeProvider theme={theme}>
        <App/>
      </MuiThemeProvider>
    </Provider>
  </IOProvider>,
  document.getElementById("root")
);
