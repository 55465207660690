
import { observable, action } from 'mobx';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import config from '../constants/config';

import DataStore from './base';
import authStore from './authStore';
import channelStore from './channelStore';
import scrubberPlayerStore from './scrubberPlayerStore';

import prepareModel from './prepareModel';

import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);
dayjs.extend(utc);

const dataKeys = { EPG: 'epg', EVENTS: 'events', EXPORTS: 'exports', TAGS: 'tags' };
const defaultData = {
    channels: [],
    currentTime: null,
    events: [],
    epg: [],
    exports: [],
    selectedChannel: null,
    tags: [],

    exportStarted: false,
    event: {},
    export: {},

    loadingState: {
        epgLoading: false,
        eventsLoading: false,
        exportsLoading: false,
    }
};

class ScrubberStore extends DataStore {
    @observable channels = [];
    @observable currentTime = null;
    @observable events = [];
    @observable epg = [];
    @observable exports = [];
    @observable selectedChannel = {};
    @observable tags = [];
    
    @observable exportStarted = false;
    @observable event = null;
    @observable export = null;

    @observable loadingState = {
        epgLoading: false,
        eventsLoading: false,
        exportsLoading: false,
    }

    @action async fetchEvents(channelId, refresh=true) {
        try {
            if (!refresh) {
                this.events = this.fetchCachedData(dataKeys.EVENTS);
            } else {
                const callee = '[ScrubberStore::fetchEvents]';
                const getChannelEvents = async ()=> {
                    try {
                        const start = this.currentTime.clone().startOf('day').toDate().toISOString();
                        return await this.ajaxCall(agentAJAX.Events.getByChannelUTC, [true, channelId, start], callee);
                    } catch(err) {
                        return [];
                    }
                };
                const getChannelEventTags = async ()=> {
                    try {
                        const start = this.currentTime.clone().startOf('day').toDate().toISOString();
                        return await this.ajaxCall(agentAJAX.EventTags.getByChannelUTC, [channelId, start], callee);
                    } catch(err) {
                        return [];
                    }
                };
                this.setLoadingState(dataKeys.EVENTS, true);
                this.events = []
                const resultSet = await Promise.all([getChannelEvents(), getChannelEventTags()]);
                resultSet.forEach((set)=> {
                    for (const entry of set) {
                        this.events.push(entry);
                    }
                });
                this.setLoadingState(dataKeys.EVENTS, false, true);
            }
        } catch(err) {
            console.log(err);
        }
    }

    @action async fetchEPG(channelDetectName, refresh = true) {
        const getUniqueListBy = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];

        const removeDupEpg = (epgArr) => getUniqueListBy(epgArr, 'key');
        try {
            if (!refresh) {
                this.epg = removeDupEpg(this.fetchCachedData(dataKeys.EPG));
            } else {
                const callee = '[ScrubberStore::fetchEPG]';
                const offset = dayjs().utcOffset();
                const start = this.currentTime.clone().startOf('day').toDate().toISOString();
                const end = this.currentTime.clone().endOf('day').add(offset, 'minute').toDate().toISOString();
                const getChannelEPGData = async ()=> {
                    return await this.ajaxCall(agentAJAX.Epg.getAllByEPGId, [true, channelDetectName, start, end], callee);
                };
                this.setLoadingState(dataKeys.EPG, true);
                const epgData = await getChannelEPGData();
                this.epg = removeDupEpg(epgData);
                this.setLoadingState(dataKeys.EPG, false, true);
            }
        } catch(err) {
            console.log(err);
        }
    }

    @action async fetchExports(channelId, refresh=true) {
        try {
            if (!refresh) {
                this.exports = this.fetchCachedData(dataKeys.EXPORTS);
            } else {
                const callee = '[ScrubberStore::fetchExports]';
                const getChannelExports = async ()=> {
                    return await this.ajaxCall(agentAJAX.Exports.getByChannel, [true, channelId], callee);
                };
                this.setLoadingState(dataKeys.EXPORTS, true);

                const currentDateStart = this.currentTime.clone().startOf('day');
                const currentDateEnd = this.currentTime.clone().endOf('day');
                this.exports = await getChannelExports();
                this.exports = this.exports.filter((record)=> (
                    (dayjs(new Date(record.start_time*1000)).unix() >= currentDateStart.unix()) &&
                    (dayjs(new Date(record.end_time*1000)).unix() <= currentDateEnd.unix())
                ));
                this.setLoadingState(dataKeys.EXPORTS, false, true);
            }
        } catch(err) {
            console.log(err);
        }
    }

    @action async persistExport() {
        try {
            const preparedModel = prepareModel.prepareExport(this.export, channelStore.channels);
            this.export = {};
            return await agentAJAX.Exports.add(preparedModel);
        } catch(err) {
            console.log(err);
        }
    }

    @action async persistEventTag() {
        try {
            const preparedModel = prepareModel.prepareEventTag(this.event, channelStore.channels);
            this.event = {};
            return await agentAJAX.EventTags.add(preparedModel);
        } catch(err) {
            console.log(err);
        }
    }

    @action async fetchTags(refresh=true) {
        try {
            if (!refresh) {
                this.tags = this.fetchCachedData(dataKeys.TAGS);
            } else {
                const callee = '[ScrubberStore::fetchTags]';
                const getAllTags = async ()=> {
                    return await this.ajaxCall(agentAJAX.Tags.getAll, [true], callee);
                };
                this.setLoadingState(dataKeys.TAGS, true);
                this.tags = [];
                const user = await authStore.getUser();
                const tags = await getAllTags(user.id);
                for (const tag of tags) {
                    // TODO: Filter out user tags...
                    this.tags.push(tag);
                }
                this.setLoadingState(dataKeys.TAGS, false, true);
            }
        } catch(err) {
            console.log(err);
        }
    }

    @action async downloadExport(record) {
        if (this.selectedChannel) {
            let channel = null;
            if (record.channelName) {
                channel = record.channelName;
            } else if (record.channel_name) {
                channel = record.channel_name;
            } else if (record.channel) {
                const target = this.channels.find((channel)=> (channel.id === record.channel));
                channel = (target) ? target.name : null;
            }
            if (!channel) {
                channel = this.selectedChannel.name;
            }

            let date;
            if (record.start_time) {
                date = dayjs(new Date(record.start_time*1000).toISOString().substring(0, 10)).format('DD-MM-YYYY');
            } else {
                date = dayjs('1970-01-01').format('DD-MM-YYYY');
            }
            const times = `${dayjs(new Date(record.start_time*1000)).format('HH:mm:ss')} - ${dayjs(new Date(record.end_time*1000)).format('HH:mm:ss')}`;

            let endpoint = `${config.serverURL}/${record.url}`;

            window.URL = window.URL || window.webkitURL;
            
            const xhr = new XMLHttpRequest();
            const link = document.createElement('a');
            xhr.open('GET', endpoint, true);
            xhr.responseType = 'blob';
            xhr.onload = function () {
                const file = new Blob([xhr.response], { type : 'application/octet-stream' });
                link.href = window.URL.createObjectURL(file);
                link.download = (`${channel}_${date}_${times}.mp4`).toLocaleLowerCase().replace(/ /g, '_');
                link.click();
            };
            xhr.send();
        }
    }

    @action async getChannels() {
        this.channels = await channelStore.getChannels(true, true);
        this.channels = this.channels.sort((a, b)=> ((a.order === b.order) ? 0 : ((a.order > b.order) ? 1 : -1)));
        return this.channels;
    }

    @action async getEvents(channelId, refresh=true) {
        return await this.fetchEvents(channelId);
    }

    @action async getEPG(epgId) {
        return await this.fetchEPG(epgId);
    }

    @action async getExports(channelId) {
        return await this.fetchExports(channelId);
    }

    @action async getTags() {
        return await this.fetchTags();
    }

    @action setCurrentTime(newDateTime, reset=true) {
        if (!this.currentTime) {
            this.currentTime = newDateTime;
        } else if ((newDateTime) && (typeof newDateTime.unix === 'function') && (this.currentTime.unix() !== newDateTime.unix())) {
            this.currentTime = newDateTime;
        } else {
            return;
        }
        if (reset) {
            scrubberPlayerStore.resetPlayer();
        }
    }

    @action setSelectedChannel(newChannel) {
        if (!this.selectedChannel) {
            this.selectedChannel = newChannel;
        } else if ((newChannel && newChannel.id) && (this.selectedChannel.id !== newChannel.id)) {
            this.selectedChannel = newChannel;
        }
    }

    @action setExportStarted(started) {
        if (this.exportStarted !== started) {
            this.exportStarted = started;
        }
    }

    @action setExport(exportData) {
        this.export = exportData;
    }

    @action setEvent(eventData) {
        this.event = eventData;
    }
}

// const scrubberStore = SIOStore(ScrubberStore, 'ALL');
const scrubberStore = new ScrubberStore();
export default scrubberStore;