import { observable, action, reaction, toJS } from 'mobx';
// decorate, configure, computed, 
import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

// Data Entity
import { ChannelEntity, ChannelGroupEntity } from '../entities';

import DataStore from './base';
import utils from './utils';

import loadingStore from './loadingStore';
import prepareModel from './prepareModel';
import commonStore from './commonStore';
import config from '../constants/config';
import authStore from './authStore';
// import contentStore from './contentStore';

// Set Strict to ensure data is updated in Alias actions when using async/promises.
//configure({ enforceActions: 'strict' });

const blankData = {
    channelList: {
        removable: false,
        addable: true,
        data: []
    },
    vodList: {
        removable: false,
        addable: true,
        data: []
    },
    contentList: {
        removable: false,
        addable: true,
        data: []
    },
};

const defaultData = {
    originalData: {},
    channels: null,
    channelGroups: null,
    contentTabs: null,
    channelBuilder: {
        key: null,
        selectedChannel: null,
        selectedChannelIdx: -1,
        prevVisible: true,
        channels: [],
        contentInfoModal: false,
        contentInfo: null,
        contentCategories: [],
        contentTabs: null,
        selectedContent: null,
        selectedVOD: null,
        vodList: [],
        showVodListModal: false,
        viewCatBrowser: true,
        contentExpActiveTab: 0,
        windowSize: { width: 0, height: 0 },
        columnsAll: blankData, // {channelList, vodList, contentList} - blankData
        recordings: {}
    },
    channelManagement: {
        entity: null,
        entityEdit: null,
        data: null,
        editViewData: null,
        initView: null
    },
    channelGroupManagement: {
        entity: null,
        entityEdit: null,
        data: null,
        editViewData: null,
        initView: null
    },
    posterChannelManagement: {
        entity: null,
        entityEdit: null,
        data: null,
        editViewData: null,
        initView: null
    },
    channelBrowserId: new Date().getTime(),
    channelBrowser: {
        currentLocation: '/',
        fileSystem: {}
    },
    editChannel: null,
    selectedChannel: null
};

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

class ChannelStore extends DataStore {
    @observable originalData = {};
    @observable channels = null;
    @observable storages = null;
    @observable channelGroups = null;
    @observable contentTabs = null;

    @observable channelBuilder = {
        key: null,
        selectedChannel: null,
        selectedChannelIdx: -1,
        prevVisible: true,
        channels: [],
        contentInfoModal: false,
        contentInfo: null,
        contentCategories: [],
        contentTabs: null,
        selectedContent: null,
        selectedVOD: null,
        vodList: [],
        showVodListModal: false,
        viewCatBrowser: true,
        contentExpActiveTab: 0,
        windowSize: { width: 0, height: 0 },
        columnsAll: blankData, // {channelList, vodList, contentList} - blankData
        recordings: {}
    }

    @observable channelManagement = {
        entity: null,
        data: null,
        editViewData: null,
        initView: null
    }
    @observable channelGroupManagement = {
        entity: null,
        entityEdit: null,
        data: null,
        editViewData: null,
        initView: null
    }

    @observable posterChannelManagement = {
        entity: null,
        data: null,
        editViewData: null,
        initView: null
    }

    @observable channelBrowserId = new Date().getTime();
    @observable channelBrowser = {
        currentLocation: '/',
        fileSystem: {}
    }

    //Channel Builder Process
    @observable editChannel = null;

    // Generic Store Data
    @observable selectedChannel = null;

    constructor() {
        super();

        const getLocalStorageChannels = ()=> {
            const channels = utils.getLocalItem('channels')
            if (channels && channels.length) {
                try {
                    return JSON.parse(channels);
                } catch(err) {
                    if (err.type === 'SyntaxError') { return channels; }
                }
            }
            return [];
        };

        this.channels = getLocalStorageChannels();
        reaction(()=> (this.channels), (channels)=> {
            for (const channel of channels) {
                channel.order = (!channel.order || channel.order <= 0) ? 9999 : channel.order;
            }
            channels = channels.slice().sort((a, b)=> ((a.order === b.order) ? 0 : ((a.order > b.order) ? 1 : -1)));
            this.initializeLocalCache('channels', channels);
        });
    }

    @action setAndFilter(type, obj) {
        if (!this.originalData[type]) { // Save Originals
            this.originalData[type] = obj;
        }
        return obj;
    }

    @action originalDelete(type, cId) {
        if (this.originalData && this.originalData[type]) {
            //Remove local Original
            let _newArr = this.originalData[type].slice();
            _newArr = _newArr.filter(f => f._id !== cId);
            this.originalData[type] = _newArr;
        }
    }

    @action setChannelBuilder(data, callback) {
        Object.keys(data).forEach((key)=> {
            this.channelBuilder[key] = data[key];
        });
        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    @action setChannelBrowser(data, callback) {
        Object.keys(data).forEach((key)=> {
            this.channelBrowser[key] = data[key];
        });
        this.channelBrowserId = new Date().getTime();
        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    /**
     * Retrieves a channel to edit from the cache.
     * @returns {object}
     */
    @action getEditChannel() {
        if (!this.editChannel) {
            const items = window.localStorage.getItem('editChannelItems');
            this.editChannel = (items) ? JSON.parse(items) : null;
        }
        return toJS(this.editChannel);
    }

    /**
     * Stores a channel to edit to the cache.
     * @param {object} channel - The Channel to store in cache.
     */
    @action saveEditChannel(channel) {
        const entry = JSON.stringify(channel);
        window.localStorage.setItem('editChannelItems', entry);
        this.editChannel = channel;
    }

    /**
     * Removes a channel to edit from the cache.
     */
    @action removeEditChannel() {
        this.editChannel = null;
        window.localStorage.removeItem('editChannelItems');
    }

    /**
     * Stores a channel-group to edit to the cache.
     * @param {object} channelGroup - The Channel-Group to store in cache.
     */
    @action saveEditChannelGroup(channelGroup) {
        const entry = JSON.stringify(channelGroup);
        window.localStorage.setItem('editChannelGroupItems', entry);
        this.editChannelGroup = channelGroup;
    }

    /**
     * Removes a channel-group to edit from the cache.
     */
    @action removeEditChannelGroup() {
        this.editChannelGroup = null;
        window.localStorage.removeItem('editChannelGroupItems');
    }

    @action setChannelManagement(data, callback) {
        Object.keys(data).forEach((key)=> {
            this.channelManagement[key] = data[key];
        });
        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    @action setChannelGroupManagement(data, callback) {
        Object.keys(data).forEach((key)=> {
            this.channelGroupManagement[key] = data[key]
        });
        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    @action setPosterChannelManagement(data, callback) {
        Object.keys(data).forEach((key)=> {
            this.posterChannelManagement[key] = data[key];
        });
        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    //########################### Channel ###########################

    /**
     * Gets the PosterChannelEntity of a channel.
     * @returns {Promise<object>}
     */
    @action async getPosterChannelEntity() {
        const channelEntity = toJS(await this.getChannelEntity());
        if (channelEntity && channelEntity.groups) {
            channelEntity.groups.forEach((group)=> {
                group.fields = group.fields.map((field)=> {
                    if (field.indexOf('isPoster') !== -1) {
                        return 'posterImageId';
                    }
                    return field;
                });
            });
        }
        return channelEntity;
    }

    /**
     * Gets the ChannelEntity.
     * @returns {Promise<object>}
     */
    @action async getChannelEntity() {
        const entity = ChannelEntity;
        if (entity && entity.fields) {
            const targetField = entity.fields.find((field)=> (field.id === 'channelgroups'));
            if (targetField && targetField.list) {
                const channelGroups = await this.getChannelGroups(true);
                targetField.list = channelGroups.map((channelGroup)=> ({ id: channelGroup._id, text: channelGroup.name }));
            }

            // Add Parent Options
            const parentChannelField = entity.fields.find((field)=> (field.id === 'parentChannelId'));
            if (parentChannelField && parentChannelField.list) {
                let channels = await this.getChannels(true);
                channels = channels.filter((f) => !(f.parentChannelId !== undefined && f.parentChannelId.length > 0));
                channels = channels.slice().sort((a, b)=> ((a.order === b.order) ? 0 : ((a.order > b.order) ? 1 : -1)));
                parentChannelField.list = channels.map((channel)=> ({ id: channel._id, text: channel.name }));
            }

            // Add storage options
            const hotStorageField = entity.fields.find((field)=> (field.id === 'hotStorage'));
            const coldStorageField = entity.fields.find((field)=> (field.id === 'coldStorages'));

            const storages = await this.getStorageOptions(true);
            if (hotStorageField && hotStorageField.list) {
                hotStorageField.list = storages.map((itm)=> ({ id: itm._id, text: itm.name }));
            }
            if (coldStorageField && coldStorageField.list) {
                coldStorageField.list = storages.map((itm)=> ({ id: itm._id, text: itm.name }));
            }
        }
        return entity;
    }

    /**
     * Gets the ChannelGroupEntity.
     * @returns {object}
     */
    @action getChannelGroupEntity() {
        return ChannelGroupEntity;
    }

    @action fixBase64(base64Str, width, height) {
        return new Promise((res, rej) => {
            // Create and initialize two canvas
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var canvasCopy = document.createElement("canvas");
            var copyContext = canvasCopy.getContext("2d");

            // Create original image
            var img = new Image();
            img.src = base64Str;

            // Determine new ratio based on max size
            var ratio = 1;
            if (img.width > width)
                ratio = width / img.width;
            else if (img.height > height)
                ratio = height / img.height;

            // Draw original image in second canvas
            canvasCopy.width = img.width;
            canvasCopy.height = img.height;
            copyContext.drawImage(img, 0, 0);

            // Copy and resize second canvas to first canvas
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;
            ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

            res(canvas.toDataURL());
        });
    }

    /**
     * Create or Update a Channel.
     * @param {object} newChannel - The data to persist to the DB.
     * @returns {Promise<object>}
     */
    @action async addUpdateChannel(newChannel) {
        const data = JSON.parse(JSON.stringify(newChannel)); // Clone Data...

        this.setIsLoading(true);

        // TODO: Not sure if this does anything at this point... :|
        const promiseArrayTags = [];
        const tags = await Promise.all(promiseArrayTags);
        const tagIds = tags.map((tag)=> (tag.id)).filter((tagId)=> (tagId));
        
        const preparedChannel = prepareModel.prepareChannel(data);
        console.log('[SAVE]', preparedChannel);

        // Fix the Channel Image.
        if ((preparedChannel.image && preparedChannel.image.base64Data) && preparedChannel.image.base64Data.indexOf('base64') > -1) {
            let base64Data = preparedChannel.image.base64Data || null;
            if (base64Data && base64Data.length) {
                const size = config.channelImgSize;
                base64Data = await this.fixBase64(base64Data, size, size);
                preparedChannel.image = base64Data;
            }
        }

        let response = null;
        try {
            if (data && data._id) { // Edit
                response = await agentAJAX.Channels.edit(data._id, preparedChannel);
                if (response && response._id) {
                    console.log('[EDIT CHANNEL]', response);
                }
            } else {    // Create
                response = await agentAJAX.Channels.add(preparedChannel);
                if (response && response._id) {
                    console.log('[CREATE CHANNEL]', response);
                }
            }
        } catch(err) {
            console.error('[addUpdateChannel]', err);
            commonStore.addErrorMsg(err);
        } finally {
            this.removeEditChannel();
            await this.getChannels(true, true);    // Refresh Channels from Server.
            let editChannel = {};
            if (this.editChannel && (this.editChannel._id || this.editChannel.id)) {
                editChannel = this.channels.find((channel)=> ((channel._id || channel.id)) === (this.editChannel._id || this.editChannel.id));
                this.saveEditChannel(editChannel);
            }
            this.setIsLoading(false);
            return editChannel;
        }
    }

    /**
     * Add a new channel to the DB.
     * @param {object} data
     */
    @action addUpdateChannelDep(_data) {
        let data = JSON.parse(JSON.stringify(_data)); //Clone
        let newChannel = data;

        loadingStore.setLoading(true);

        return new Promise((res, rej) => {
            //## Add new Tags
            let promiseArrayTags = [];

            Promise.all(promiseArrayTags).then((tags) => {
                let tagIds = [];
                if (tags) {
                    tags.forEach(tag => {
                        if (tag && tag._id) {
                            tagIds.push(tag._id);
                        }
                    });
                }
                return tagIds;
            }).then(async () => {
                // Prep & Clean
                newChannel = prepareModel.prepareChannel(newChannel);

                return newChannel;
            }).then(async (channel) => {
                // Add/Insert

                console.log('[SAVE]', channel);

                // Fix img Data
                if (channel.image && channel.image.base64Data && channel.image.base64Data.indexOf('base64') > -1) {
                    let base64Data = channel.image.base64Data || null;
                    if (base64Data && base64Data.length > 0) {
                        let _size = config.channelImgSize;
                        base64Data = await this.fixBase64(base64Data, _size, _size);
                        channel.image = base64Data; // Set base64 data to save
                    }
                }

                if (data && data._id) {

                    // Edit
                    let ret = await agentAJAX.Channels.edit(data._id, channel).then((ret) => {
                        if (ret && ret._id) {
                            console.log('[Edit Channel]', ret);
                        }
                    }).catch((err) => {
                        console.error('[addUpdateChannel]', err);
                        commonStore.addErrorMsg(err);
                    });

                    return ret;
                } else {
                    // Insert
                    let ret = await agentAJAX.Channels.add(channel).then((ret) => {
                        if (ret && ret._id) {
                            console.log('[New Channel]', ret);
                            channel._id = ret._id;
                        }
                    }).catch((err) => {
                        console.error('[addUpdateChannel]', err);
                        commonStore.addErrorMsg(err);
                    });

                    return ret;
                }
            }).finally((ret) => {
                // Refresh From Server
                this.removeEditChannel();
                let that = this;
                //setTimeout(() => {
                res(that.getChannels(true, true).then(() => {
                    // re-set Current Channel
                    if (that.editChannel && (that.editChannel.id || that.editChannel._id)) {
                        let _editC = that.channels.filter(f => (f.id || f._id) === (that.editChannel.id || that.editChannel._id));
                        if (_editC.length > 0) {
                            this.saveEditChannel(_editC[0]);
                        }
                    }
                    loadingStore.setLoadingForce(false);
                    return that.channels;
                }));
                //}, 250);
            });
        });
    }

    /**
     * Add a new channel-group to the DB.
     * @param {Object} data - The object to persist.
     */
    @action addUpdateChannelGroup(data) {
        let newChannelGroup = { ...data };
        return new Promise((res, rej) => {
            // Channels...
            const channelPromises = [];
            if (data && data.channels && data.channels.length) {
                data.channels.forEach((channel) => {
                    channelPromises.push(this.getChannel(channel.id));
                });
            }
            Promise.all(channelPromises).then((result) => {
                const channels = [];
                if (result) {
                    result.forEach((channel) => {
                        if (channel && channel._id) {
                            channels.push(channel._id);
                        }
                    });
                }
                return channels;
            }).then((channels) => {
                newChannelGroup.channels = channels;

                // Users
                const userPromises = [];
                if (data && data.users && data.users.length) {
                    data.users.forEach((user) => {
                        // TODO
                        //userPromises.push(authStore.getUser(user.id));
                    });
                }
                Promise.all(userPromises).then((result) => {
                    const users = [];
                    if (result) {
                        result.forEach((user) => {
                            if (user && user._id) {
                                users.push(user._id);
                            }
                        });
                    }
                    return users;
                }).then((users) => {
                    newChannelGroup.users = users;
                    return prepareModel.prepareChannelGroup(newChannelGroup);
                }).then((result) => {
                    if (data && data._id) {
                        // Edit
                        return agentAJAX.ChannelGroups.edit(data._id, result).catch((err) => {
                            commonStore.addErrorMsg(err);
                        });
                    } else {
                        // Insert
                        return agentAJAX.ChannelGroups.add(result).then((ret) => {
                            if (ret && ret._id) {
                                result._id = ret._id;
                            }
                        }).catch((err) => {
                            commonStore.addErrorMsg(err);
                        });
                    }
                }).finally(() => {
                    // Refresh From Server
                    this.removeEditChannelGroup();
                    let that = this;
                    setTimeout(() => {
                        res(that.getChannelGroups(true).then(() => {
                            // re-set Current Channel
                            if (that.editChannelGroup && (that.editChannelGroup.id || that.editChannelGroup._id)) {
                                const editObjects = that.channelGroups.filter((channelGroup) => (channelGroup.id || channelGroup._id) === (that.editChannelGroup.id || that.editChannelGroup._id));
                                if (editObjects.length) {
                                    that.saveEditChannelGroup(editObjects[0]);
                                }
                            }
                            return that.channelGroups;
                        }));
                    }, 1000);
                });
            })
        });
    }

    cleanImageData(img) {
        if (typeof img === 'object' && img._id) {
            return img._id
        } else {
            return img;
        }
    }

    //# Delete Item
    @action deleteChannel(cId) {
        console.log(cId);
        //Remove local
        let newChannelsArr = this.channels.slice();
        newChannelsArr = newChannelsArr.filter(f => f._id !== cId);
        //this.channels = this.setAndFilter('channels', newChannelsArr, this);
        this.channels = newChannelsArr;

        this.originalDelete('channels', cId);

        return new Promise((res, rej) => {
            //Delete Server
            agentAJAX.Channels.delete(cId)
                .then((ret) => {
                    if (ret && ret.responseCode !== 0 && ret.responseMessage) {
                        commonStore.addErrorMsg(ret.responseMessage);
                    }
                    res(ret);
                }).catch((err) => {
                    console.error('[deleteChannel]', err);
                    commonStore.addErrorMsg(err);
                    rej(err);
                });
        });
    }

    //# Delete Item
    @action deleteChannelGroup(cId) {
        this.originalDelete('channelGroups', cId);

        return new Promise((res, rej) => {
            //Delete Server
            agentAJAX.ChannelGroups.delete(cId)
                .then((ret) => {
                    if (ret && ret.responseCode !== 0 && ret.responseMessage) {
                        commonStore.addErrorMsg(ret.responseMessage);
                    }
                    res(ret);
                }).catch((err) => {
                    console.error('[deleteChannelGroups]', err);
                    commonStore.addErrorMsg(err);
                    rej(err);
                });
        });
    }

    // Get Channel Recordings.
    @action fetchRecordings(channelId) {
        if (channelId && this.channelBuilder && this.channelBuilder.recordings) {
            if (!this.channelBuilder.recordings[channelId]) {
                this.channelBuilder.recordings[channelId] = [];
            }
            agentAJAX.Channel.recordings(channelId).then((recordings) => {
                this.channelBuilder.recordings[channelId] = recordings;
            }).catch((err) => { console.log(err); })
        }
    }

    @action downloadRecording(fileName) {
        agentAJAX.Recordings.get(this.channelBuilder.selectedChannel.id, fileName);
    }

    @action deleteChannelMany(ids) {
        return new Promise((res, rej) => {
            let delPromiseArr = [];
            ids.forEach(id => {
                delPromiseArr.push(this.deleteChannel(id));
            });

            Promise.all(delPromiseArr).then(() => {
                res('Done');
            }).catch((err) => {
                rej(err);
            });
        });
    }

    @action deleteChannelGroupsMany(ids) {
        return new Promise((res, rej) => {
            let delPromiseArr = [];
            ids.forEach(id => {
                delPromiseArr.push(this.deleteChannelGroup(id));
            });

            Promise.all(delPromiseArr).then(() => {
                res('Done');
            }).catch((err) => {
                rej(err);
            });
        });
    }

    //# Get Channel
    @action getChannel(id, stop = false) {
        return new Promise((res, rej) => {
            if (this.channels) {
                let retArr = toJS(this.channels).filter(f => f._id === id);
                if (retArr && retArr.length > 0) {
                    res(retArr[0]);
                } else if (!stop) {
                    // Try to find on server
                    res(this.getChannels(true).finally(() => res(this.getChannel(id, true))));
                } else {
                    res(null);
                }
            } else {
                // Populate if it's empty
                this.getChannels(true).finally(() => res(this.getChannel(id)));
            }
        });
    }

    /**
     * Retrieves channels based on the current-user.
     * @param {boolean?} refresh - Should data forcefully be retrieved from the DB. 
     * @param {boolean?} noLoad - Should a Loading-Overlay be visible. 
     * @returns {Promise<Array<object>>}
     */
    @action async getChannels(refresh=true, noLoad=false) {
        if (this.channels && !refresh) {
            this.setIsLoading(false);
            this.channels = this.channels.slice().sort(utils.orderChannels);
            return this.channels;
        }
        const imageURL = `${config.epgImageURL}`;

        // try{
        //     // Get all Storage
        //     const storages = await agentAJAX.Storage.getAll(noLoad);
        //     if(storages && storages.length){
        //         this.storages = storages;
        //     }
        // } catch(err) {
        //     console.error('Storage fetch ERROR', err);
        // }

        this.channels = [];
        try {
            const isAdmin = await authStore.isAdmin();
            if (isAdmin) {  // Get ALL Channels
                const response = await agentAJAX.Channels.getAll(noLoad);
                if (response && response.length) {
                    this.channels = response;
                    this.channels.forEach((channel)=> { // Fix Images - serve from API.
                        if (channel.image && channel.image.length) {
                            channel.image = `${imageURL}${channel.image}`;
                        }
                        channel.order = (!channel.order || channel.order <= 0) ? 9999 : channel.order;
                    });
                }
                
                return this.channels;
            } else {    // Get Channels by Channel-Group.
                this.channels = [];

                const userId = (authStore.user._id || authStore.user.id);
                const response = await agentAJAX.ChannelGroups.getByUserId(userId);
                if (response && response.length) {
                    let channelIds = [];
                    response.forEach((channelGroup)=> {
                        channelIds = channelIds.concat(channelGroup.channels);
                    });
                    channelIds = [...new Set(channelIds)];  // Remove duplicates.
                    const channelPromises = [];
                    for (const channelId of channelIds) {
                        const channel = agentAJAX.Channels.get(channelId);
                        if (channel) {
                            channelPromises.push(channel);
                        }
                    }
                    this.channels = await Promise.all(channelPromises);
                    
                    this.channels.forEach((channel)=> { // Fix Images - serve from API.
                        if (channel.image && channel.image.length) {
                            channel.image = `${imageURL}${channel.image}`;
                        }
                        channel.order = (!channel.order || channel.order <= 0) ? 9999 : channel.order;
                    });
                    this.channels = this.channels.slice().sort(utils.orderChannels);
                    this.channelBuilder.key = new Date().getTime(); // Render Key
                }
            }
        } catch(err) {
            console.log(err);
        } finally {
            this.setIsLoading(false);
            this.channels = this.channels.slice().sort(utils.orderChannels);
            return this.channels;
        }
    }

    /**
     * Fetch single ChannelGroup based on provided ID.
     * @param {String} id - The ID of the ChannelGroup to fetch.
     * @param {Boolean} server - Indicates the record should be looked up server-side.
     */
    @action getChannelGroup(id, server = false) {
        return new Promise((res, rej) => {
            const resultArray = toJS(this.channelGroups).filter((channelGroup) => (channelGroup._id === id));
            if (this.channelGroups) {
                if (resultArray && resultArray.length) {
                    res(resultArray[0]);
                } else if (!server) {
                    res(this.getChannelGroups(true).finally(() => res(this.getChannelGroup(id, true))));
                } else {
                    res(null);
                }
            } else {
                this.getChannelGroups().finally(() => (this.getChannelGroup(id)))
            }
        });
    }

    /**
     * Fetch All ChannelGroups.
     * @param {Boolean} refresh - Refresh the data.
     * @param {Boolean} noload - Sets whether the UI becomes modal.
     */
    @action getChannelGroups(refresh, noload = false) {
        return new Promise((res, rej) => {
            if (this.channelGroups && !refresh) {
                res(this.channelGroups);
            } else {
                let channelGroups = [];
                agentAJAX.ChannelGroups.getAll(noload).then((ret) => {
                    if (ret && ret.length) {
                        channelGroups = ret;
                    }
                    channelGroups = channelGroups.slice().sort(utils.orderChannelGroups);
                    this.channelsGroups = channelGroups;
                    this.channelGroups = this.setAndFilter('channelGroups', channelGroups, this);
                    this.channelGroups.key = new Date().getTime(); // Render Key
                    loadingStore.setLoadingForce(false);
                    res(this.channelGroups);
                }).catch((err) => rej(err));
            }
        });
    }

    /**
     * Fetch All Storages.
     * @param {Boolean} refresh - Refresh the data.
     * @param {Boolean} noload - Sets whether the UI becomes modal.
     */
     @action getStorageOptions(refresh, noload = false) {
        return new Promise((res, rej) => {
            if (this.storages && !refresh) {
                res(this.storages);
            } else {
                let storages = [];
                agentAJAX.Storage.getAll(noload).then((ret) => {
                    if (ret && ret.length) {
                        storages = ret;
                    }
                    
                    this.storages = storages;
                    this.storages = this.setAndFilter('storages', storages, this);
                    loadingStore.setLoadingForce(false);
                    res(this.storages);
                }).catch((err) => rej(err));
            }
        });
    }

    /**
     * Retrieves the selected channel for this store.
     * If no selected channel exists, sets the value equal to the first
     * channel in the list of channels.
     * @returns Promise<object>
     */
    @action async getSelectedChannel() {
        if (this.selectedChannel && (this.selectedChannel._id || this.selectedChannel.id)) {
            return this.selectedChannel;
        }
        try {
            if (this.channels && this.channels.length) {
                this.setSelectedChannel(this.channels[0])
            } else {
                const channels = await this.getChannels(true, true);
                this.setSelectedChannel(channels[0]);
            }
        } catch(err) {
            console.error('[getSelectedChannel]', err);
            commonStore.addErrorMsg(err);
        } finally {
            return this.selectedChannel;
        }
    }

    /**
     * Sets the selected channel for this store.
     * @param {object} newChannel - The channel which is now selected.
     */
    @action setSelectedChannel(newChannel) {
        if (JSON.stringify(newChannel) !== JSON.stringify(this.selectedChannel)) {
            this.selectedChannel = newChannel;
        }
    }
}

// export default SIOStore(ChannelStore, 'CHANNEL');
export default new ChannelStore();