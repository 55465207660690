import React from "react";
import PropTypes from 'prop-types';
import ImgEditor from './ImageEditor';

import { Img } from '@components';

//Style
import '../styles/inputFile.scss';

// reactstrap components
import {
  Button
} from "reactstrap";

class UploadImageItem extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      tmpSource: null,
      imageScale: '1'
    }
  }

  render() {
    let { idx, source } = this.props;
    let { tmpSource } = this.state;
    let { VodImgOptions, onAdd, onDelete, error } = this.props;

    return (
      <div className='imagesContainer'>
        <div className='imgContainer' style={(error)?{backgroundColor: '#693737'}:{}}>
        {((source && source.length > 0) || (tmpSource))?
          (!tmpSource)?
          // Show Preview
          <div>
            <Img className='vodImage' size='256' alt={`img_${idx}`} src={source}
              style={{maxWidth:`${VodImgOptions.width}px`, maxHeight:`${VodImgOptions.height}px`}}/>;
            {/* <img className='vodImage' alt={`img_${idx}`} src={source}
              style={{maxWidth:`${VodImgOptions.width}px`, maxHeight:`${VodImgOptions.height}px`}}/> */}
            <div className='editorControls'>
              {/* <IconButton
                className='imgBtn'
                color="danger"
                aria-label="Delete"
                onClick={onDelete}
              >
                <Delete />
              </IconButton> */}
              <Button color="danger" onClick={() => {
                console.log('Image - remove');
                source = null;
                this.setState({ tmpSource:null });

                if(onDelete){  
                  onDelete();
                }
              }}>
                <i className="tim-icons icon-simple-remove" />
              </Button>
            </div>
          </div>
          :
          //Show Resizer
          <div>
            <ImgEditor {...VodImgOptions} 
              id={`editImg_${idx}`}
              ref={(editor) => this.editor = editor}
              scale={parseFloat(this.state.imageScale)}
              image={tmpSource}/>
            <div className='editorControls'>
              Zoom:
              <input
                name="scale"
                type="range"
                onChange={(e) => {
                  const scale = parseFloat(e.target.value)
                  this.setState({ imageScale:scale });
                }}
                min='0.1'
                max="2"
                step="0.01"
                defaultValue="1"
              />
              <br/>
              {/* <IconButton
                className='imgBtn'
                color="success"
                aria-label="Done"
                onClick={() => {
                  // Get blob from Editor
                  if (this.editor) {
                    // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
                    // drawn on another canvas, or added to the DOM.
                    // const canvas = this.editor.getImage();
                    // const img = this.editor.getImageScaledToCanvas().toDataURL();
              
                    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
                    const canvasScaled = this.editor.getImageScaledToCanvas();
                    const imdData = canvasScaled.toDataURL(tmpSource.type);

                    source = imdData;
                    // Setup Preview
                    this.setState({source: source, tmpSource: null});
                    onAdd(imdData);
                  }
                }}
              >
                <Done />
              </IconButton> */}
              <Button color="success" 
                onClick={() => {
                  // Get blob from Editor
                  if (this.editor) {
                    // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
                    // drawn on another canvas, or added to the DOM.
                    // const canvas = this.editor.getImage();
                    // const img = this.editor.getImageScaledToCanvas().toDataURL();
              
                    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
                    const canvasScaled = this.editor.getImageScaledToCanvas();
                    const imdData = canvasScaled.toDataURL(tmpSource.type);
                    const raw = tmpSource;
                    // source = imdData;

                    // Setup Preview
                    this.setState({source: imdData, tmpSource: null});
                    onAdd(imdData, raw);
                  }
                }}>
                <i className="tim-icons icon-check-2" />
              </Button>
            {/* <IconButton
              className='imgBtn'
              color="danger"
              aria-label="Delete"
              onClick={onDelete}
            >
              <Delete />
            </IconButton> */}
            <Button color="danger" onClick={() => {
              console.log('Image - cancel');
              source = null;
              this.setState({ tmpSource:null });

              if(onDelete){  
                onDelete();
              }
            }}>
              <i className="tim-icons icon-simple-remove" />
            </Button>
            </div>
          </div>
        :<div>
            <label className='fileContainer'>
                Select your Image
                <input type='file' 
                  name={`image_${idx}`} 
                  accept="image/*" // Only Images
                  onChange={(event)=>{
                    if(event && event.target && event.target.files
                      && event.target.files.length > 0){
                        let file = event.target.files[0];
                        console.log('Added Image file file', file);

                        tmpSource = URL.createObjectURL(file);
                
                        this.setState({tmpSource});
                      }
                  }}/>
            </label>
          </div>
        }
        </div>
      </div>
    );
  }
}

UploadImageItem.propTypes = {
  idx: PropTypes.string.isRequired,
  source: PropTypes.string,

  VodImgOptions: PropTypes.object.isRequired,

  // Functions
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default (UploadImageItem);