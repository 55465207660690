
export const CharSets = {
    ALL_UPPER_ALPHA: [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'],
    ALL_LOWER_ALPHA: [...'abcdefghijklmnopqrstuvwxyz'],
    ALL_UNIQUE_CHARS: [...'~!@#$%^&*()_+-=[]\\{}|;:\'",./<>?'],
    ALL_NUMERIC: [...'0123456789']
};

export const RandomString = (()=> {
    const base = [...CharSets.ALL_UPPER_ALPHA, ...CharSets.ALL_LOWER_ALPHA, ...CharSets.ALL_UNIQUE_CHARS, ...CharSets.ALL_NUMERIC];
    
    return {
        generate: (charSet=base, length=10)=> {
            return [...Array(length)]
                .map(()=> (charSet[Math.random()*charSet.length|0]))
                .join('');
        }
    };
})();

export const toKebabCase = (str) =>
    str && str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-');