/**
 * Convert Object Array to DataTable Array[Array]
 */
const objToDataTableArray = function(objArr, ignoreElements=[]){
  let ret = [];
  objArr.forEach(obj => {
    let intArr = [];
    for(let key in obj){
      if((typeof obj[key] === 'string' || 
          typeof obj[key] === 'number' || 
          typeof obj[key] === 'boolean') && ignoreElements.indexOf(key) < 0){
        intArr.push(`${obj[key]}`); // Has to be string
      } else if(obj[key] === 'object'){
        intArr.push(obj[key]); // Could be an element ;)
      }
    }
    ret.push(intArr);
  });

  return ret;
}

/**
 * Convert Object Array to DataTable Array[Array]
 */
const objToDataTableArrayWithColumns = function(objArr, columnArray, ignoreElements=[]){
  let ret = [];
  objArr.forEach(obj => {
    let intArr = [];
    columnArray.forEach(column => {
      intArr.push(obj[column] || '');
    });
    ret.push(intArr);
  });

  return ret;
}

/**
 * Convert DataTable Array[Array]to DataTable
 */
const dataToObjTableArray = function(dataArray, columnArray, ignoreElements=[]){
  let ret = [];
  //Validate
  if(dataArray && dataArray.length > 0 && 
    columnArray && columnArray.length === dataArray[0].length){
      let retObjArr = [];
      dataArray.forEach((objArr, idxd) => {
        let obj = {};
        columnArray.forEach((colItem, idxc) => {
          if(ignoreElements.indexOf(colItem) <= -1){
            obj[colItem] = objArr[idxc];
          }
        });
        retObjArr.push(obj);
      });

      return retObjArr;
  } else {
    console.error('Invalid Columns to DataArray');
    return {};
  }
}

const validateAndFix = function(dataArray, columnArray){
  let retObjArr = [];
  dataArray.forEach((objArr, idxd) => {
    let obj = [];
    columnArray.forEach((colItem, idxc) => {
      obj.push(objArr[idxc] || '');
    });
    retObjArr.push(obj);
  });

  return retObjArr;
}

const hexToRgb = function(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

const hexToRgba = function(hex, alpha) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: alpha
  } : null;
}

export default {
  objToDataTableArray,
  objToDataTableArrayWithColumns,
  dataToObjTableArray,
  validateAndFix,
  hexToRgb,
  hexToRgba
}