import React from 'react';
import { inject, observer } from 'mobx-react';

import './style.scss';

@inject('commonStore')
@observer
class Status extends React.Component {

  async componentDidMount(){
    const {commonStore} = this.props;
    await commonStore.checkConnection();
  }
  
  render() {
    let connected = this.props.commonStore.connected;
    document.documentElement.scrollTop = 0;
    
    if(!connected){
      return (
      <div className='StatusWrapper'>
        Disconnected
      </div>);
    } else {
      return null;
    }
  }
}

export default Status;