let domain = process.env.REACT_APP_DOMAIN || 'mhdev-prd-rpl1.adlytics.tv';

// ### For Debug
const debug = (process.env.NODE_ENV === 'development') || false;
const isLocal = (process.env.REACT_APP_LOCAL === 'local') || false;

const protocol = process.env.PROTOCOL || 'https';

const getConfig = () => {
    let ret = {
        domain: domain,
        showHidden: false,
        debug: debug,
        serverURL: `${protocol}://${domain}`,
        imgUrl: '', // Relative path
        // sioURL: `${protocol}://${domain}`,
        sioOpt: {
            transports: ['websocket', 'polling'], // For cluster API
            upgrade: false
        },
        allowNotifications: false,
        authRoute: '/api/auth',
        maxLoadingTime: 10 * 1000, // 10 Sec
        apiTimeout: 30 * 1000,
        apiDeadline: 60 * 1000,
        channelImgSize: 96, // px
        apiRoute: '/api', // '/api',
        gaTrackingId: 'UA-88183816-1', // MediaHost Tracking ID
        version: process.env.REACT_APP_VERSION,

        live: `/api/transcoders/live`,// `${protocol}://${domain}/vod`,

        AUDIO_CHANNEL_COUNT: 2,
        CANVAS_PIXEL_RATIO: 3.5,

        contentDuration: 30,
        epgImageURL: `${protocol}://${domain}/api`,

        hideTags: true, // TODO: In the future, turn this off!
        errorMessage: 'No recorded content, please return to EPG', 
    }

    // Debug Config
    if (debug) {
        ret.serverURL = 'http://10.160.3.88:4050';

        // ret.sioURL = 'http://10.160.3.88:4050';

        ret.allowNotifications = true;

        ret.apiRoute = '';
        ret.authRoute = '/auth';
        ret.live = `/transcoders/live`;

        // Fix Content/Img
        // ret.imgUrl = `http://${domain}`;
        // ret.contUrl = `http://${domain}`;

        // ret.epgImageURL = 'http://10.160.3.88:4050';

        if (isLocal) {
            const localURL = window.location.hostname;
            ret.serverURL = `http://${localURL}:4050`; //'https://admin.tysflo.io/api'
            ret.live = `http://${localURL}:4050/transcoders/live`;

            ret.epgImageURL = `http://${localURL}:4050`;

            // ret.imgUrl = '';
        }
    }

    return ret;
}

const config = getConfig();

export default config;
export const isDebug = debug;