import React from 'react';
import { inject, observer } from 'mobx-react';

import './style.scss';

@inject('loadingStore')
@observer
class Loading extends React.Component {
  render() {
    let loading = this.props.loadingStore.loading;
    let { override } = this.props;
    document.documentElement.scrollTop = 0;
    
    if(loading || override){
      return (
      <div className='LoadingWrapper'>
        <div className='LoadingIndicator'>
          <object type="image/svg+xml" data={require('./loading.svg')} width="200" height="200">
            Your browser does not support SVG.
          </object>
        </div>
      </div>);
    } else {
      return null;
    }
  }
}

export default Loading;