
import React from 'react';
import PropTypes from 'prop-types';

export const ExportChoice = ({ onChoice })=> {

    return (
        <div className='Scrubber-Navigation-Export-Choice-Container'>
            <div className='Scrubber-Navigation-Export-Choice-Wrapper'>
                <button className='Scrubber-Navigation-Export-Choice-Button' onClick={()=> { onChoice('EXPORT'); }}>Exports</button>
                <button className='Scrubber-Navigation-Export-Choice-Button' onClick={()=> { onChoice('EVENT_TAGGING'); }}>Event Tagging</button>
            </div>
        </div>
    );
};

ExportChoice.propTypes = {
    onChoice: PropTypes.func
};