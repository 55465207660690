const VodImgOptions16_9 = {
    width: 160,
    height: 90,
    border: 10,
    multiplier: 1, // Image size multiplier
    color: [0, 0, 0, 0.6],
    scale: 1.2,
    rotate: 0
  }

const VodImgOptions512 = {
    width: 256,
    height: 256,
    border: 20,
    multiplier: 2, // Image size multiplier
    color: [0, 0, 0, 0.6],
    scale: 1.2,
    rotate: 0
}

const VodImgOptions128 = {
    width: 128,
    height: 128,
    border: 20,
    multiplier: 2, // Image size multiplier
    color: [0, 0, 0, 0.6],
    scale: 1,
    rotate: 0
}

const storeTypes = {
    //channel, playlist, vod
    CHANNEL: 'CHANNEL',
    //PLAYLIST: 'PLAYLIST',
    CONTENT: 'CONTENT',
    // OVERLAY: 'OVERLAY',
    APP: 'APP'
}

const defaultImgOptions = VodImgOptions512;

export {
    storeTypes,
    defaultImgOptions,
    VodImgOptions16_9,
    VodImgOptions512,
    VodImgOptions128
};