
export default {
    id: 'event_tag',
    label: 'EventTags List',
    name: 'EventTag',
    namePlural: 'EventTags',
    hideTitle: true,
    titleField: 'name',

    noEditViewField: true,
    newButtons: {
        allowScrubber: true
    },

    cardOptions: {
        labelField: 'name',
        descField: 'status',
        masonryOptions: {
            transitionDuration: 100,
            gutter: 10,
            originTop: true,
            resize: true,
            fitWidth: true
        }
    },
    gridOptions: {
        columns: [],
        download: true,
        print: false,
        filter: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 20, 50, 100, 200],
        downloadOptions: {
            filename: 'event_tags.csv',
            separator: ','
        }
    },
    fields: [
        {
            id: '_id',
            identity: true,
            type: 'text',
            listColumnOptions: { display: false },
            required: false,
            width: 0,
        },
        {
            id: 'date',
            label: 'Date',
            type: 'text',
            width: 100,
        },
        {
            id: 'title',
            inMany: true,
            label: 'Title',
            type: 'text',
            width: 100,
        }, 
        {
            id: 'shortComment',
            label: 'Synopsis',
            type: 'text',
            width: 150
        }, 
        {
            id: 'tags',
            label: 'Tags',
            type: 'text',
            width: 100
        }
    ],
    groups: [
        {
            id: 'p1', type: 'panel',
            label: 'Details', width: 100,
            fields: ['name', 'Synopsis']
        }
    ]
}