// Panel to group fields in views Edit and Browse (styled w/ Bootstrap).

import React from 'react';
import PropTypes from 'prop-types';

class Panel extends React.Component {

	constructor(props){
		super(props);

		this.state ={
			opened: true
		}

		this.clickToggle = this.clickToggle.bind(this);

	}

	clickToggle(){
		this.setState({opened: !this.state.opened})
	}

	render() {
		const title = this.props.title ? (
				<div className="panel-heading">
					{this.props.collapsible ? (
						<i className={'evol-title-toggle glyphicon glyphicon-chevron-'+(this.state.opened ? 'up':'down')} 
							onClick={this.clickToggle}></i> 
						) : null}
					<h3 className="panel-title">{this.props.title}</h3>
				</div>
			) : null
		
		return (
			<div className="evol-pnl" style={{width: (this.props.width || 100)+'%'}}>
				<div className="panel panel-default">
					{(!this.props.noHeading)?title:null}
					<fieldset style={{display: (this.state.opened ? 'block':'none')}}>
						{this.props.children}
					</fieldset>
				</div>
			</div>
		)
	}
	
}

Panel.propTypes = {
	title: PropTypes.string,
	width: PropTypes.number,
	collapsible: PropTypes.bool,
	noHeading: PropTypes.bool
}

export default Panel;
