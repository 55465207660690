
import React from 'react';
import { inject, observer } from 'mobx-react';

import { EvoUI } from '@components';

import './style.scss';

@inject('adminStore')
@observer
class TagsManager extends React.Component {
    constructor(props) {
        super(props);

        this.getData = this.getData.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.editData = this.editData.bind(this);

        this.state = {
            entity: null
        };
    }

    componentDidMount() {
        const { adminStore } = this.props;
        adminStore.getTagEntity().then((entity)=> {
            this.setState({ entity: entity });
        });
    }

    getData(refresh=true) {
        const { adminStore } = this.props;
        console.log(`[getData tags] refresh: ${refresh}`);

        return adminStore.getTags(refresh).then((tags) => {
            return { payload: adminStore.tags, error: null };
        });
    }

    deleteData(rows) {
        const { adminStore } = this.props;
        console.log(`Delete: ${JSON.stringify(rows)}`);

        let idField = (this.state.entity && this.state.entity.fields) ? this.state.entity.fields.filter(f => f.identity) : 'id';
        if (typeof idField !== 'string') {
            idField = idField[0].id;
        }
        let delIds = [];
        rows.forEach(row => {
            if (row[idField]) {
                delIds.push(row[idField]);
            } else {
                console.error('Identity Field not found for: ' + JSON.stringify(row));
            }
        });

        adminStore.deleteTagsMany(delIds).then(() => {
            console.log('Delete done.');
        }).finally(() => {
            if (this.refreshData) {
                this.refreshData(); // Refresh Data
            }
        });
    }

    editData(target) {
        const { adminStore } = this.props;
        console.log(`Edit: ${JSON.stringify(target)}`);
        
        adminStore.addUpdateTag(target).finally(() => {
            const entity = adminStore.getTagEntity();
            this.setState({ entity });
            if (this.refreshData) {
                this.refreshData(); // Refresh Data
            };
        });
    }

    render() {
        const { adminStore } = this.props;

        const view = 'list';
        const isNew = false;

        return (
            <div className='Tags-Manager-Container'>
                {(this.state.entity) ?
                    <EvoUI key='evo' {...this.props}
                        entity={this.state.entity}
                        view={view}
                        isNew={isNew}
                        getData={this.getData}
                        refreshData={(refresh) => this.refreshData = refresh}
                        deleteOne={this.deleteData}
                        deleteMany={this.deleteData}
                        editOne={this.editData}
                        noViewCards={true} /> :
                    <h3>Loading...</h3>
                }
            </div>
        );
    }
}

export default TagsManager;