import { observable, action, reaction} from 'mobx';
// decorate, configure, computed, reaction, toJS
import utils from './utils';
import config from '../constants/config';
import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import adminStore from './adminStore';
import channelStore from './channelStore';
// import contentStore from './contentStore';

// Set Strict to ensure data is updated in Alias actions when using async/promises.
//configure({ enforceActions: 'strict' });

const defaultData = {
  appName: 'Selectcast Admin',
  appLoaded: false,
  notifications: [],
  notificationMessages: [],
  isLoadingTags: false,
  errorMessage: null
};

class CommonStore {

  @observable appName = 'Selectcast Admin';
  @observable token = null;//utils.getItem('jwt');
  @observable connected = true;

  @observable appLoaded = false;
  @observable notifications = [];
  @observable notificationMessages = [];
  //@observable errorMessages = [];
  @observable errorMessage = null;

  @observable isLoadingTags = false;

  constructor() {
    this.token = utils.getLocalItem('jwt');
    reaction(
      () => this.token,
      token => {
        if (token) {
          utils.setLocalItem('jwt', token);
        } else {
          utils.removeLocalItem('jwt');
        }
      }
    );

    reaction(()=> (this.connected), ()=> {
      if (this.connected !== true) {
        this.connected = true;
      }
    });

    let _this = this;
    setTimeout(() => {
      _this.load().then(() => {
        _this.setAppLoaded();
      });
    }, 500);

    // Check Connections
    setInterval(() => {
      _this.checkConnection();
    }, 30*1000); // every 30sec
  }

  @action load(){
    return new Promise((res, rej)=>{
      if(this.isAuthenticated()){
        // Initial Data load
        let ret = this.loadAllStores().then(() => {
          this.loadNotifications();
        }).catch(err => {
          console.log('[loadAllStores]', err);
          rej(err);
        });

        res(ret);
      } else {
        res(false);
      }
    });
  }

  @action setConnected(val){
    //this.connected = val;
  }

  @action checkConnection(){
    /*
    return agentAJAX.Ping.ping().then(() => {
      this.connected = true;
    }).catch(() => {
      this.connected = false;
    });
    */
  }

  @action loadAllStores(){
    // return adminStore.load().then(() => {
    //   return channelStore.load();
    // });
    return Promise.resolve(true);
  }

  @action getToken(){
    return this.token;
  }

  

  /**
   * Set the User Token and loads all Data
   * 
   * @param {String} token JWT Token
   */
  @action setToken(token) {
    this.token = token;
  }

  @action isAuthenticated() {
    if(this.token){
      return true;
    }

    return false;
  }

  @action setAppLoaded() {
    this.appLoaded = true;
  }

  //Notifications
  @action loadNotifications(){
    let n = window.localStorage.getItem('notifications');
    if(n){
      let notifications = JSON.parse(n);
      this.notifications = notifications;
    }
  }

  @action getNotifications() {
    return this.notifications;
  }

  @action closeNotificationMsg(idx) {
    return this.notificationMessages[idx].show = false;
  }

  @action addNotificationMsg(msg, type = null) {
    return this.notificationMessages.push({
      msg: msg,
      show: true,
      type: type
    })
  }

  @action getNotificationMsg(){
    let retArr = this.notificationMessages;
    retArr.forEach((item, ix) => {
      item.idx = ix;
    });
    return retArr.filter(f => f.show === true);
  }

  // @action closeErrorMsg(idx) {
  //   return this.errorMessages[idx].show = false;
  // }

  @action addErrorMsg(msg) {
    // return this.errorMessages.push({
    //   msg: msg,
    //   show: true
    // })
    this.errorMessage = msg;

    let _this = this;
    setTimeout(() => {
      _this.errorMessage = null;
    }, 10000);
  }

  @action addNotification(item) {
    this.notifications.push(item);
    window.localStorage.setItem('notifications', JSON.stringify(this.notifications));
  }

  @action clearNotification(idx) {
    this.notifications.splice(idx, 1);
    window.localStorage.setItem('notifications', JSON.stringify(this.notifications));
  }

  @action clearAllNotification() {
    this.notifications = [];
    window.localStorage.removeItem('notifications');
  }

  getMessageForType(type, entity, data){
    if(data && data.name){
      // let user = userData.username || userData.firstName;
      // user = user.charAt(0).toUpperCase() + user.slice(1);

      if(type === 'CREATE'){
        return `A new ${entity.toLowerCase()} ${data.name} has been created.`; //by ${user}
      } else if(type === 'UPDATE'){
        // return `${user} updated ${entity.toLowerCase()} ${data.name}.`;
        return `${entity.toLowerCase()} ${data.name} updated.`;
      } else if(type === 'DELETE'){
        // return `${user} deleted ${entity.toLowerCase()}.`; // data._id
        return `${entity.toLowerCase()} ${data.name} deleted.`;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  // SIO
  @action Subscribe(type, entity, payload){
    console.log('[SIO CommonStore STORE]', type, payload);
    let allowEntities = ['channel'];
    let allow = config.allowNotifications || false;

    // Something has happend... create notification
    if(allow){
      if(allowEntities.indexOf(entity.toLowerCase()) > -1){
        let notificationMsg = this.getMessageForType(type, entity, payload);
        if(notificationMsg){
          this.addNotification(notificationMsg);
          this.notificationMessages.push({
            msg: notificationMsg,
            show: true
          });
          if(this.notificationMessages.length > 6){
            let len = this.notificationMessages.length-6;
            this.notificationMessages = this.notificationMessages.slice(len);
          }
        }
      }
    }

    // adminStore.actionCreate(type, entity, payload);
  }

  @action clearStore(){
    // Set Defaults
    Object.keys(defaultData).forEach(key => {
      this[key] = defaultData[key];
    });
  }

}

// Create the one instance
// var commonStore = SIOStore(CommonStore, 'ALL');
var commonStore = new CommonStore(); 

export default commonStore;


  // @action testAPI(){
  //   let testArr = [
  //     agentAJAX.Ping.ping(),
  //     agentAJAX.Tenants.getAll(),
  //     agentAJAX.Organisations.getAll(),
  //     agentAJAX.Users.getAll(),
  //     agentAJAX.Tags.getAll(),
  //     agentAJAX.Category.getAll(),
  //     agentAJAX.Playground.getAll(),
  //     agentAJAX.Destination.getAll(),
  //     agentAJAX.Content.getAll(),
  //     agentAJAX.Channel.getAll(),
  //     agentAJAX.LvodSchedule.getAll(),
  //     agentAJAX.ContentLibrary.getAll(),
  //     agentAJAX.DestinationChannelMap.getAll(),
  //     agentAJAX.Analytics.getSessions7Days()
  //   ];
    
  //   return Promise.all(testArr);
  // }