
import React from 'react';
import PropTypes from 'prop-types';

import { MdClose } from 'react-icons/md';
import { Modal } from '../components';
import { HLSPlayer } from '../../HLSPlayer';

import './style.scss';

class ModalHLSPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleHLSReady = this.handleHLSReady.bind(this);

        this.audioPeakMeterContainerRef = React.createRef();
        this.containerRef = React.createRef();
        this.mediaPlayerRef = React.createRef();

        this.state = {
            audioContext: null,
            hlsState: null
        }
    }

    componentDidUpdate() {
        try {
            if (this.mediaPlayerRef && this.mediaPlayerRef.current) {
                this.mediaPlayerRef.current.addEventListener('play', this.onPlay);
            }
        } catch(err) {}
    }

    componentWillUnmount() {
        try {
            this.mediaPlayerRef.current.removeEventListener('play', this.onPlay);
        } catch(err) {}
    }
    
    onPlay() {
        try {
            this.audioContext.resume();
        } catch(err) {}
    };

    handleClose() {
        const { onClose } = this.props;
        const { hlsState } = this.state;

        if(hlsState){
            try{
                hlsState.destroy();
            } catch(err) {}
        }

        this.setState({ audioContext: null, hlsState: null });
        onClose();
    }

    handleHLSReady(hls) {
        if(hls){
            this.setState({ hlsState: hls });
        }
    }

    renderModalControls() {
        const { modalControls=[] } = this.props;

        return (
            modalControls.map((control)=> {
                control.className = 'Modal-HLS-Player-Control';
                return control;
            })
        );
    }

    render() {
        const { autoPlay=false, controls=false, isOpen, source } = this.props;

        return (
            <Modal clickAway isOpen={isOpen} onClose={this.handleClose}>
                <div className='Modal-HLS-Player-Container' onClick={this.handleClose}>
                    <div className='Modal-HLS-Player-Wrapper' ref={this.containerRef} onClick={(event)=> { event.stopPropagation(); }}>
                        <div style={{ aspectRatio: '16/9', background: 'black', display: 'flex' }}>
                            {(isOpen) &&
                                <HLSPlayer
                                    autoPlay={autoPlay}
                                    controls={controls}
                                    innerRef={this.mediaPlayerRef}
                                    style={{ margin: '36px 0', width: 'calc(100% - 5em)' }}
                                    source={source}
                                    onHLSReady={this.handleHLSReady} />
                            }
                        </div>
                        <div className='Modal-HLS-Player-Controls-Container'>
                            <div className='Modal-HLS-Player-Controls-Wrapper'>
                                <div className='Modal-HLS-Player-Control' onClick={this.handleClose}>
                                    <MdClose size={24} />
                                </div>
                                {this.renderModalControls()}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ModalHLSPlayer;

ModalHLSPlayer.propTypes = {
    autoPlay: PropTypes.bool,
    controls: PropTypes.bool,
    isOpen: PropTypes.bool,
    modalControls: PropTypes.array,
    selectedChannel: PropTypes.object,
    source: PropTypes.string,
    onClose: PropTypes.func.isRequired
};