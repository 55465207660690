
import React from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import config from '../../constants/config';

import './style.scss';

@inject('replayerStore', 'scrubberStore')
@observer
class Breakout extends React.Component {
    constructor(props) {
        super(props);

        this.handlePause = this.handlePause.bind(this);
        this.handlePlay = this.handlePlay.bind(this);

        this.audioPeakMeter = React.createRef();
        this.player = React.createRef();

        this.audioContext = null;

        this.state = {
            lastRefresh: null,
            source: null,
            isMP4: false,
        };
    }

    componentDidMount() {
        const parsedQueryString = queryString.parse(window.location.search);
        let source = null;
        let isMP4 = false;
        if (parsedQueryString && parsedQueryString.src) {
            source = `${config.serverURL}/${parsedQueryString.src}`;
            isMP4 = source.endsWith('mp4');
        }
        
        this.setState({
            lastRefresh: (new Date()).getTime(),
            source,
            isMP4
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { replayerStore, scrubberStore } = this.props;

        if (scrubberStore.source) {
            if (this.player && this.player.current) {
                if (!replayerStore.hls) {
                    replayerStore.setPlayer(this.player.current);
                    replayerStore.setSource(scrubberStore.source);
                    replayerStore.setupHLS();

                    this.setState({ lastRefresh: (new Date()).getTime() });
                } else {
                    setTimeout(()=> {
                        if (Math.floor(replayerStore.currentTime) !== Math.floor(scrubberStore.currentTime)) {
                            replayerStore.setCurrentTime(scrubberStore.currentTime);
                        } else if (replayerStore.source !== scrubberStore.source) {
                            window.location.reload();
                        } else {
                            if (scrubberStore.isPlaying) {
                                replayerStore.play();
                            } else {
                                replayerStore.pause();
                            }
                        }
                        this.setState({ lastRefresh: (new Date()).getTime() });
                    }, 100);
                }
            }
        } else {
            setTimeout(()=> { this.setState({ lastRefresh: (new Date()).getTime() }); }, 500);
        }
    }

    handlePause() {
        const { replayerStore } = this.props;
        replayerStore.pause();
    }

    handlePlay() {
        const { replayerStore } = this.props;
        replayerStore.play();
    }

    render() {

        return (
            <div className='Breakout-Container'>
                <div className='Breakout-HLS-Player-Container'>
                    <div className='Breakout-HLS-Player-Wrapper'>
                        {(this.state.isMP4) &&
                            <video controls
                                src={this.state.source}
                                controlsList={'nodownload'}
                                style={{ height: '100%', width: '100%' }} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Breakout;

Breakout.propTypes = {

};