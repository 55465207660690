import React from 'react';
import PropTypes from "prop-types";
import Modal from 'react-modal';
import merge from 'lodash.merge';

//Styles
import '../styles/evoUIStyles.scss';

// Components & Icons
import IconButton from '../../complex/IconButton';
import { ViewList, GridOn, PieChart, Add, Search, Refresh, Backspace } from '@material-ui/icons';
import { withStyles, Button, Tooltip } from '@material-ui/core';

import { MUIDataTableSearch, textLabels } from 'mui-datatables';

//Views
import List from './List';
import ListExpand from './ListExpand';
import Cards from './Cards';
import Browse from './Browse';
import Edit from './Edit';

// For Modal
Modal.setAppElement('#root');

class EvoUI extends React.Component {

    constructor(props) {
        super(props);

        let _key = new Date().getTime();

        this.state = {
            view: props.view || 'list',
            lastView: 'list',
            viewData: props.viewData || null,
            entity: {},
            listViewKey: _key,
            listViewOptions: props.listViewOptions || null,
            isNew: false,
            deleteConfirmation: false,
            deleteConfirmationMany: false,
            showControls: false,
            deleteRows: null,
            showSearch: false,
            searchText: null
        }

        this.getData = this.getData.bind(this);
        this.editOne = this.editOne.bind(this);
        this.deleteMany = this.deleteMany.bind(this);

        this.deleteDataMany = this.deleteDataMany.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.confirmDeleteMany = this.confirmDeleteMany.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showView = this.showView.bind(this);
        this.setViewState = this.setViewState.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.searchTextUpdate = this.searchTextUpdate.bind(this);
        this.searchHide = this.searchHide.bind(this);
        this.getDataSearch = this.getDataSearch.bind(this);

        this.refreshData = this.refreshData.bind(this);
    }

    // componentWillMount() {
    //     Modal.setAppElement('body');
    // }

    componentDidMount() {
        Modal.setAppElement('body');
        if (this.props.refreshData) {
            this.props.refreshData(this.refreshData);
        }

        let _entity = (this.props.entityEdit) ? this.props.entityEdit() : this.props.entity;

        let listViewOptions = {};
        if (_entity && _entity.gridOptions) {
            listViewOptions = merge(_entity.gridOptions, this.props.listViewOptions);
        } else {
            listViewOptions = this.props.listViewOptions;
        }

        let _key = new Date().getTime();
        this.setState({ listViewOptions, listViewKey: _key });
    }

    confirmDelete() {
        this.setState({
            deleteConfirmation: true
        });
    }

    confirmDeleteMany() {
        this.setState({
            deleteConfirmationMany: true
        });
    }

    closeModal() {
        this.setState({
            deleteConfirmation: false,
            deleteConfirmationMany: false,
            deleteRows: null
        });
    }

    deleteData(rows) {
        console.log('EVOUI-deleteData', rows);
        this.setState({
            deleteRows: rows
        });

        if (rows.length > 1) {
            this.confirmDeleteMany();
        } else {
            this.confirmDelete();
        }
    }

    /** Will refresh the current active ViewState */
    refreshData() {
        if (this.refreshDataCards) {
            this.refreshDataCards(true);
        }

        if (this.refreshDataList) {
            this.refreshDataList(true);
        }
    }

    async deleteDataMany(rows) {
        await this.deleteMany(rows);
        this.closeModal();

        if (this.refreshDataCards) {
            this.refreshDataCards();
        }

        if (this.refreshDataList) {
            this.refreshDataList();
        }
    }

    searchTextUpdate(text) {
        console.log(`Search ${text}`);

        this.setState({ searchText: text }, () => {
            if (this.refreshDataCards) {
                this.refreshDataCards();
            }
        });
    }

    searchHide() {
        this.setState({ showSearch: false, searchText: null }, () => {
            if (this.refreshDataCards) {
                this.refreshDataCards();
            }
        });
    }

    getData(refresh) {
        return Promise.resolve(this.props.getData(refresh))
            .then((data) => {
                return data;
            });
    }

    editOne(obj) {
        console.log('EDIT ONE');
        return Promise.resolve(this.props.editOne(obj))
            .then((data) => {
                this.getData(true);
                if (this.props.postEdit) {
                    this.props.postEdit();
                } else {
                    if (data !== false) {
                        this.setViewState('back');
                    }
                }
                return data;
            });
        //.finally(() => ); // Refresh Data
    }

    deleteMany(objs) {
        return Promise.resolve(this.props.deleteMany(objs))
            .then((data) => {
                if (this.props.postDelete) {
                    this.props.postDelete();
                }
                return data;
            })
            .finally(() => this.getData(true)); // Refresh Data
    }

    /** For Cards to search */
    getDataSearch() {
        let data = this.getData();
        if (data.payload && this.state.searchText) {
            let search = this.state.searchText;
            let searchData = data.payload.filter(f => {
                let searchtxt = '';
                for (let key in f) {
                    searchtxt += f[key];
                }
                return searchtxt.indexOf(search) > -1;
            });

            return { payload: searchData };
        } else {
            return data;
        }
    }

    /**
       * Change the View
       * @param {string} newView To View State - browse, edit, list, cards, charts
       * @param {object} viewData Data required by the View
       */
    setViewState(newView, viewData) {
        if (newView === 'back') {
            this.setState({ view: this.state.lastView, viewData: viewData || null });
        } else {
            let lastV = `${this.state.view}`;
            if (['browse', 'edit'].indexOf(lastV) > -1) {
                lastV = this.state.lastView;
            }
            this.setState({ view: newView || 'list', lastView: lastV, viewData: viewData || null });
        }
    }

    showView() {
        let { listViewOptions, listViewKey } = this.state;
        let _entity = (this.props.entityEdit) ? this.props.entityEdit() : this.props.entity;
        if (this.state.view === 'browse' || this.state.view === 'edit') {
            _entity = (this.props.entityEdit) ? this.props.entityEdit(this.state.viewData) : this.props.entity;
        }

        switch (this.state.view) {
            case 'list':
                if (_entity && _entity.expanded) {
                    return (
                        <ListExpand
                            key={listViewKey}
                            entity={_entity}
                            style={{ width: '100%' }}
                            getData={this.getData}
                            setViewState={this.setViewState}
                            listViewOptions={listViewOptions}
                            refreshData={click => this.refreshDataList = click}
                            //pageSize={this.props.pageSize}
                            //location={this.state.location}
                            noEdit={this.props.noEdit || false}
                            deleteMany={this.deleteData}
                            postDelete={this.postDelete}
                        />
                    );
                } else {
                    return (
                        <List
                            key={listViewKey}
                            entity={_entity}
                            style={{ width: '100%' }}
                            getData={this.getData}
                            setViewState={this.setViewState}
                            listViewOptions={listViewOptions}
                            refreshData={click => this.refreshDataList = click}
                            //pageSize={this.props.pageSize}
                            //location={this.state.location}
                            noEdit={this.props.noEdit || false}
                            deleteMany={this.deleteData}
                            postDelete={this.postDelete}
                        />
                    );
                }
            case 'cards': return (
                <Cards
                    key={listViewKey}
                    entity={_entity}
                    style={{ width: '100%' }}
                    refreshData={click => this.refreshDataCards = click}
                    getData={this.getDataSearch}
                    setViewState={this.setViewState}
                    deleteOne={this.deleteData}
                    noEdit={this.props.noEdit || false}
                />
            );
            case 'browse':
                if (this.state.viewData) {
                    if (this.props.customBrowse) {
                        let _props = {
                            entity: _entity,
                            data: this.state.viewData,
                            setViewState: this.setViewState,
                            noEdit: this.props.noEdit || false
                        }
                        return this.props.customBrowse(_props);
                    } else {
                        return (
                            <Browse
                                key={listViewKey}
                                entity={_entity}
                                data={this.state.viewData}
                                setViewState={this.setViewState}
                                noEdit={this.props.noEdit || false}
                            />
                        );
                    }
                } else {
                    return <h2 style={{ margin: '20px auto' }}>Invalid Data Supplied</h2>
                }
            case 'edit':
                if (this.props.customEdit) {
                    let _props = {
                        entity: _entity,
                        data: this.state.viewData,
                        setViewState: this.setViewState,
                        editOne: this.editOne,
                        postEdit: this.postEdit
                    }
                    return this.props.customEdit(_props);
                } else {
                    return (
                        <Edit
                            key={listViewKey}
                            entity={_entity}
                            data={this.state.viewData}
                            setViewState={this.setViewState}
                            editOne={this.editOne}
                            postEdit={this.postEdit}
                            options={this.props.editOptions || {}} />
                    );
                }
            case 'charts': return (
                <h2>Charts coming soon</h2>
            );
            default:
                break;
        }
    }

    render() {
        let { view, showControls } = this.state;
        // let { classes } = this.props;
        let actions = [];

        let _entity = (this.props.entityEdit) ? this.props.entityEdit() : this.props.entity;

        if (_entity) {
            //Delete Ask Modal
            const delModal = this.state.deleteConfirmation ? (
                <Modal className="modal-dialog"
                    isOpen={this.state.deleteConfirmation}
                    onRequestClose={this.closeModal}
                    style={{ content: { position: 'absolute', top: 'calc(50% - 200px)', left: 'calc(50% - 150px)', height: '200px', width: '300px' } }}>
                    <div>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete item</h4>
                                <button onClick={this.closeModal} className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {/* {models[entity].name} */}
                                Do you really want to delete the {_entity.name}?
                            </div>
                            <div className="modal-footer">
                                <Button size="medium" variant='outlined' className='evo-browse-btn' onClick={this.closeModal} color='primary'>
                                    CANCEL
                                </Button>
                                <Button size="medium" variant='contained' className='evo-browse-btn save' onClick={() => { this.deleteDataMany(this.state.deleteRows); }} color='secondary'>
                                    OK
                                </Button>
                                {/* <button key="bDelCancel" onClick={this.closeModal} className="btn btn-default" data-dismiss="modal">{'Cancel'}</button>
                            <button key="bDelOK" onClick={() => {this.props.deleteOne(this.state.deleteRows);this.closeModal();}} className="btn btn-info" data-dismiss="modal">{'Ok'}</button> */}
                            </div>
                        </div>
                    </div>
                </Modal>
            ) : null;
            const delModalMany = this.state.deleteConfirmationMany ? (
                <Modal className="modal-dialog"
                    isOpen={this.state.deleteConfirmationMany}
                    onRequestClose={this.closeModal}
                    style={{ content: { position: 'absolute', top: 'calc(50% - 200px)', left: 'calc(50% - 150px)', height: '200px', width: '300px' } }}>
                    <div>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete items</h4>
                                <button onClick={this.closeModal} className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body">
                                {/* {models[entity].name} */}
                                Do you really want to delete {_entity.namePlural}?
                            </div>
                            <div className="modal-footer">
                                <Button size="medium" variant='outlined' className='evo-browse-btn' onClick={this.closeModal} color='primary'>
                                    CANCEL
                                </Button>
                                <Button size="medium" variant='contained' className='evo-browse-btn save' onClick={() => { this.deleteDataMany(this.state.deleteRows); }} color='secondary'>
                                    OK
                                </Button>
                                {/* <button key="bDelCancel" onClick={this.closeModal} className="btn btn-default" data-dismiss="modal">{'Cancel'}</button>
                              <button key="bDelOK" onClick={} className="btn btn-info" data-dismiss="modal">{'Ok'}</button> */}
                            </div>
                        </div>
                    </div>
                </Modal>
            ) : null;

            //Setup Actions
            if (['list', 'cards'].indexOf(view) > -1) {
                actions.push(
                    <Tooltip key="5" title={"Refresh"}>
                        <IconButton customClass='evo-toolbar-button' onClick={this.refreshData}>
                            <Refresh />
                        </IconButton>
                    </Tooltip>
                );
                if (!this.props.noAdd) {
                    actions.push(
                        <Tooltip key="4" title={"Add New"}>
                            <IconButton customClass='evo-toolbar-button'
                                onClick={() => {
                                    if (this.props.customAdd) {
                                        this.props.customAdd();
                                    } else {
                                        this.setViewState('edit');
                                    }
                                }}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    );
                }
            }

            return (
                <div className="evo-toolbar">
                    {(this.props.backBtn) ?
                        <Tooltip key="1" title={"Go Back"}>
                            <IconButton customClass='evo-toolbar-button' onClick={() => this.props.backBtn()}>
                                <Backspace />
                            </IconButton>
                        </Tooltip> : null}
                    {(!this.props.noView) ?
                        <Tooltip key="2" title={"View List"}>
                            <IconButton customClass='evo-toolbar-button' onClick={() => { this.setViewState('list') }}>
                                <ViewList />
                            </IconButton>
                        </Tooltip> : null}
                    {(_entity.cardOptions && !this.props.noViewCards) ?
                        <Tooltip key="3" title={"View Cards"}>
                            <IconButton customClass='evo-toolbar-button' onClick={() => { this.setViewState('cards') }}>
                                <GridOn />
                            </IconButton>
                        </Tooltip> : null}
                    {(_entity.chartOptions && !this.props.noViewCharts) ?
                        <Tooltip key="4" title={"View Charts"}>
                            <IconButton customClass='evo-toolbar-button' onClick={() => { this.setViewState('charts') }}>
                                <PieChart />
                            </IconButton>
                        </Tooltip> : null}
                    {actions}

                    {(this.state.view === 'cards') ?
                        (this.state.showSearch) ?
                            <MUIDataTableSearch customClass='searchText' onSearch={this.searchTextUpdate} onHide={this.searchHide} options={{ textLabels }} />
                            :
                            <Tooltip key="2b" title={"Search"}>
                                <IconButton customClass='evo-toolbar-button searchbtn' onClick={() => { this.setState({ showSearch: !this.state.showSearch }) }}>
                                    <Search />
                                </IconButton>
                            </Tooltip>
                        : null}

                    {(_entity) ?
                        this.showView()
                        : null}

                    {delModal}
                    {delModalMany}

                </div>
            );
        } else {
            return <h2>No Entity Provided</h2>
        }
    }

}

EvoUI.propTypes = {
    /**
     * Holds the Data Entity Description
     */
    entity: PropTypes.object.isRequired,
    /**
     * Holds the Data Entity Description for a given Object, resolves to Object - For Edit/Browse Only
     */
    entityEdit: PropTypes.func,
    /**
     * size of the page
     */
    pageSize: PropTypes.number,
    /**
     * If Edit -> is New
     */
    isNew: PropTypes.bool,
    /**
     * Typs of views supported - "browse", "edit", "list", "cards", "charts"
     */
    view: PropTypes.oneOf(["browse", "edit", "list", "cards", "charts"]),

    /**
     * View Data for - "browse", "edit" to load without list or card view first.
     */
    viewData: PropTypes.object,

    /**
     * Hook function to force data refresh from Parent Component, call from parent
     */
    refreshData: PropTypes.func,

    //## View Options
    /**
     * Options parsed to the DataTable
     */
    listViewOptions: PropTypes.object,

    //## CRUD Actions
    /**
     * Get all data
     */
    getData: PropTypes.func.isRequired,
    /**
     * Delete one object
     */
    //deleteOne: PropTypes.func.isRequired, // Input: id, respond: Promise<()>
    /**
     * Delete Many
     */
    deleteMany: PropTypes.func.isRequired,
    postDelete: PropTypes.func,
    /**
     * Edit Object
     */
    editOne: PropTypes.func.isRequired,
    postEdit: PropTypes.func,
    /**
     * Uppy Configuration Object
     */
    uppyConfig: PropTypes.shape({
        uppy_options: PropTypes.object,
        uppy_DragDropOptions: PropTypes.shape({
            inputName: PropTypes.string,
            allowMultipleFiles: PropTypes.bool,
            width: PropTypes.string,
            height: PropTypes.string,
            note: PropTypes.string
        }),

        /**
         * Remove btns
         */
        noAdd: PropTypes.bool,
        noEdit: PropTypes.bool,
        noView: PropTypes.bool,
        noViewCards: PropTypes.bool,
        noViewCharts: PropTypes.bool,

        /**
         * Custom Buttons
         */
        backBtn: PropTypes.func,

        /**
         * Additional Options
         */
        editOptions: PropTypes.object,

        /**
         * Additional Options
         */
        checkRole: PropTypes.func, // Callback function to check the User Role, used for Entity field Role

        /**
         * Custom Views
         */
        customAdd: PropTypes.bool,
        customEdit: PropTypes.func, // function recieving (entity,data,setViewState,editOne)
        customBrowse: PropTypes.func, // function recieving (entity,data,setViewState,noEdit)

    }),

    className: PropTypes.string
}

EvoUI.defaultProps = {
    pageSize: 50
}

const EvoUIStyles = {
    MuiPaper: {
        root: {
            background: 'transparent'
        }
    }
}

export default withStyles(EvoUIStyles)(EvoUI);