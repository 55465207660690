
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export const NowOverlay = ({ currentTime })=> {
    const [width, setWidth] = useState(0);

    useEffect(()=> {
        if (!width) {
            setWidth(94);
        }
    }, []);

    useEffect(()=> {
        setTimeout(()=> {
            if (width >= 0) {
                setWidth((width-1));
            }
        }, 10000);
    }, [width]);

    return (
        <div className='NowOverlay-Container' style={{ width: `calc(${width}% - 160px)` }} />
    );
};

NowOverlay.propTypes = {
    currentTime: PropTypes.object
};