
import { action, observable, reaction } from 'mobx';

import dayjs from 'dayjs';

import config from '../constants/config';

import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import DataStore from './base';
import utils from './utils';

import authStore from './authStore';
import rechannelStore from './rechannelStore';

import prepareModel from './prepareModel';

const now = dayjs();
const API_ROOT = `${config.serverURL}${config.apiRoute}`;

const defaultData = {

    loadingState: {
        epgLoading: false,
        eventsLoading: false,
        exportsLoading: false,
    }
};

const dataKeys = { EPG: 'epg', EVENTS: 'events', EXPORTS: 'exports', TAGS: 'tags' };
const loadingStates = { EPG: 'epg', EVENTS: 'events', EXPORTS: 'exports', TAGS: 'tags' };

class ReScrubberStore extends DataStore {

    @observable loadingState = {
        epgLoading: false,
        eventsLoading: false,
        exportsLoading: false,
    }
}

// export default SIOStore(ReScrubberStore, 'ALL');
export default new ReScrubberStore();