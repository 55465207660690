// List view to display a collection as a list (table w/ sorting and paging).

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import merge from "lodash.merge";
import Table from "@material-ui/core/Table";
import Masonry from 'react-masonry-component';

import textLabels from "../components/textLabels";
import Card from '../components/Card';

import CardsPagination from '../components/CardsPagination';

class Cards extends React.Component {

	constructor(props){
		super(props);

		this.state ={
			viewId: 'list',
      data: [],
      allData: [],
      columns: [],
      loading: false,
      page: 0,
      rowsPerPage: 10,
    }
    //this.props.options
    this.options = merge({textLabels}, props.options);
    this.deleteRow = this.deleteRow.bind(this);
    this.getData = this.getData.bind(this);
  }

  // componentWillMount() {
  //   this.setState({
  //     data: [],
  //     loading: false
  //   });
  // }

  async deleteRow(row){
    if(this.props.deleteOne){
      await this.props.deleteOne([row]);
    }

    // Load data
    await this.getData();
  }
  

  componentDidMount() {
    this.getData();
    this.parentNode = ReactDOM.findDOMNode(this).parentNode;

    if(this.props.refreshData){
      this.props.refreshData(this.getData);
    }

    if(this.parentNode && this.parentNode.getBoundingClientRect){
      const { top, right, bottom, left, width, height } = this.parentNode.getBoundingClientRect();
      this.width = width;
    } else {
      this.width = 200;
    }
    // Setup DataTable Options
    if(this.props.listViewOptions){
      let gridOptions = {...this.state.options, ...this.props.listViewOptions};
      this.setState({options: gridOptions});
    }
  }

  changeRowsPerPage = rows => {
    this.setState(
      () => ({
        rowsPerPage: rows,
      })
      ,
      () => {
        this.getData();
      },
    );
  };

  changePage = page => {
    this.setState(
      () => ({
        page: page,
      })
      ,
      () => {
        this.getData();
      },
    );
  };

  // setServerRequest = action => {
  //   if (this.options.serverSide) {
  //     this.options.onServerRequest(action, this.state);
  //   }
  // };

  async getData(refresh){
    let data = await this.props.getData(refresh);
    if(data && data.payload){
      let payload = data.payload.slice(0);

      // Setup Page size
      let from = this.state.page*this.state.rowsPerPage;
      let to = from + this.state.rowsPerPage;
      let dataDisp = payload.slice(from, to);

      this.setState({allData: payload, data: dataDisp, loading: false});

    } else {
      this.setState({error: {title: 'Error', message: 'Couldn\'t retrieve data.'} ,loading: false});
    }
  }

	render(){
    let { allData, data, page, rowsPerPage } = this.state;
    let { entity, setViewState, noEdit } = this.props;
    const rowCount = allData.length;
    const size = { 
        width: this.width
    }

    let masonryOptions = {
        transitionDuration: 0
    };

    let deleteRow = this.deleteRow;

    if(entity){
      let viewOptions = this.props.entity.cardOptions || {}; // Should have this if you get here ;)
      if(viewOptions && viewOptions.masonryOptions){
        masonryOptions = merge(masonryOptions, viewOptions.masonryOptions);
      }

      return (
        <div className='evo-cards'>

        <Masonry
            className={'evo-card-container'}
            onClick={this.handleClick}
            options={masonryOptions}
        >
        {data.map(function(obj, idx){

          // Suport comma seperated Fields for Title
          let cardTitle = '';
          if(viewOptions.labelField){
            if(viewOptions.labelField.indexOf(',')>-1){
              let retCardTitle = '';
              viewOptions.labelField.split(',').forEach(field => {
                let _field = field.replace(/\s/g,''); // Removing spaces
                retCardTitle += `${obj[_field]} `;
              });
              cardTitle = retCardTitle;
            } else {
              cardTitle = obj[viewOptions.labelField];
            }
          } else {
            cardTitle = obj.id;
          }

          let img = null;
          if(typeof obj[viewOptions.imgField] === 'object'){
            img = (obj[viewOptions.imgField].source)?obj[viewOptions.imgField].source:null;
          } else {
            img = obj[viewOptions.imgField];
          }

          let video = null;
          if(typeof obj[viewOptions.videoField] === 'object'){
            video = (obj[viewOptions.videoField].sources)?obj[viewOptions.videoField].sources:null;
          } else {
            video = obj[viewOptions.videoField];
          }

          return (
            <div key={idx} className='evo-card'>
              <Card 
                title={cardTitle}
                desc={(viewOptions.descField)?
                  obj[viewOptions.descField]
                  :null}
                onClick={() => setViewState('browse',obj)}
                color={(viewOptions.colorField)?
                  obj[viewOptions.colorField]
                  :null}
                buttons={[
                  (!noEdit)?{
                    text: 'EDIT',
                    onClick: () => setViewState('edit',obj),
                    color: 'primary'
                  }:null,
                  {
                    text: 'DELETE',
                    onClick: () => deleteRow(obj),
                    color: 'secondary'
                  },
                ]}
                video={(viewOptions.videoField && video)?video:null}
                image={(viewOptions.imgField && img)?{
                  img: img,
                  title: obj.id
                }:null}
              />
            </div>
          )
        })}
        </Masonry>

        <Table className='evo-cards-pagination'>
          <CardsPagination
            count={rowCount}
            page={page}
            rowsPerPage={rowsPerPage}
            changeRowsPerPage={this.changeRowsPerPage}
            changePage={this.changePage}
            component={"div"}
            options={this.options}
          />
        </Table>
        </div>
      );
    } else {
      return null;
    }
	}
}

Cards.propTypes = {
	entity: PropTypes.object.isRequired,

  // Options
  options: PropTypes.shape({
    rowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
  }),

	// Actions
  getData: PropTypes.func.isRequired,
  refreshData: PropTypes.func,
	deleteOne: PropTypes.func.isRequired,
  setViewState: PropTypes.func.isRequired,
  
  noEdit: PropTypes.bool
};

Cards.defaultProps ={
  options: {
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 100],
  }
}

export default Cards;
