/* eslint-disable no-useless-escape */
import _ from 'underscore';
import mUtils from './modelUtils';

const validation = {
    //incomplete: 'Some information is missing or invalid.',
    incomplete: 'Missing information.',
    invalid: 'Invalid format.',
    //invalidList: '{0} values in "{1}" are invalid.',
    //invalidList1: '1 value in "{1}" is invalid.',
    //intro: 'You are not finished yet: ',
    empty: '"{0}" must have a value.',
    email: '"{0}" must be a valid email formatted like "name@domain.com".',
    integer: '"{0}" must only use numbers.',
    decimal: '"{0}" must be a valid decimal numbers.',
    money: '"{0}" must be a valid number.',
    date: '"{0}" must be a valid date, format must be "MM/DD/YYYY" like "12/24/2017".',
    datetime: '"{0}" must be a valid date/time, format must be "MM/DD/YYYY hh:mm AM/PM" like "12/24/2017 10:30 AM".',
    time: '"{0}" must be a valid date/time, format must be "hh:mm AM/PM" like "10:30 AM".',
    json: '"{0}" must be a valid JSON expression like "{"a": 1}".',
    max: '"{0}" must be smaller or equal to {1}.',
    min: '"{0}" must be greater or equal to {1}.',
    maxLength: '"{0}" must be {1} characters long maximum.',
    minLength: '"{0}" must be at least {1} characters long.',
    minMaxLength: '"{0}" must be between {1} and {2} characters long.',
    regExp: '"{0}" is not of the expected format.'
    //regExp: '"{0}" must match the regular expression pattern for "{1}".'
};

export default {

    valRegEx: {
        email: /^[\w\.\-]+@[\w\.\-]+\.[\w\.\-]+$/,
        integer: /^[-+]?\d+$/, // /^[0-9]*/,
        decimal: /(\+|-)?(\d*\.\d*)?$/,
    },

    validateField: function(f, v){
        var numberField = mUtils.fieldIsNumber(f);
        var ft = mUtils.fieldTypes

        function formatMsg(fLabel, msg, r2, r3){
            return msg.replace('{0}', fLabel)
                .replace('{1}', r2)
                .replace('{2}', r3);
        }
        function fieldLabel(f){
            return f.label || f.labelMany;
        }

        if(!f.readonly){

            // Check required and empty
            if (f.required && (v===null || v==='' || _.isUndefined(v) ||
                (numberField && isNaN(v)) ||
                (f.type===ft.lov && v==='0') ||
                (f.type===ft.list && v && !v.length) //||
                //(f.type===ft.color && v==='#000000')
            )){
                return formatMsg(f.label, validation.empty);
            } else if(!_.isUndefined(v)){

                // Check field type
                if( !(isNaN(v) && numberField)) {
                    if (v!==null && v!=='' && !_.isArray(v)){
                        switch (f.type) {
                            case ft.int:
                            case ft.email:
                                if (!this.valRegEx[f.type].test(v)) {
                                    return formatMsg(fieldLabel(f), validation[f.type]);
                                }
                                break;
                            case ft.dec:
                            case ft.money:
                                var regex = this.valRegEx[ft.dec];
                                if (!regex.test(v)){
                                    return formatMsg(fieldLabel(f), validation[f.type]);
                                }
                                break;
                            case ft.date:
                            case ft.datetime:
                            case ft.time:
                                if ((v !== '') && (!_.isDate(new Date(v)))) {
                                    return formatMsg(fieldLabel(f), validation[f.type]);
                                }
                                break;
                            case ft.json:
                                var obj;
                                if(_.isObject(v)){
                                    obj=v;
                                }else{
                                    try{
                                        obj=JSON.parse(v);
                                    }catch(err){}
                                    if(_.isUndefined(obj)){
                                        return formatMsg(fieldLabel(f), validation[f.type]);
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }

                // Check regexp
                if (f.regExp !== null && !_.isUndefined(f.regExp)) {
                    var rg = new RegExp(f.regExp);
                    if (!v.match(rg)) {
                        return formatMsg(fieldLabel(f), validation.regExp, fieldLabel(f));
                    }
                }

                // Check min & max
                if (numberField) {
                    if (v !== '') {
                        if (f.max && parseFloat(v) > f.max) {
                            return formatMsg(fieldLabel(f), validation.max, f.max);
                        }
                        if (f.min && parseFloat(v) < f.min) {
                            return formatMsg(fieldLabel(f), validation.min, f.min);
                        }
                    }
                }
            }

            // Check custom validation
            if (f.fnValidate) {
                var fValid = f.fnValidate(f, v);
                if (fValid !== '') {
                    return formatMsg(fieldLabel(f), fValid);
                }
            }

            // Check minLength and maxLength
            if (_.isString(v) && !numberField) {
                var len = v.length,
                    badMax = f.maxLength?len > f.maxLength:false,
                    badMin = f.minLength?len < f.minLength:false;
                if(badMax || badMin){
                    if(f.maxLength && f.minLength){
                        return formatMsg(fieldLabel(f), validation.minMaxLength, f.minLength, f.maxLength);
                    }else if(f.maxLength){
                        return formatMsg(fieldLabel(f), validation.maxLength, f.maxLength);
                    }else{
                        return formatMsg(fieldLabel(f), validation.minLength, f.minLength);
                    }
                }
            }

        }

        return null;
    },

}
