
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { createUseStyles } from 'react-jss';

import { Panel } from './components';

export const ResizePanel = ({ left, leftStyle, minWidth=48, right, rightStyle, onResize })=> {
    const classes = useStyles();

    const horizontalDivider = useRef();
    const wrapperRef = useRef();

    const [leftHeight, setLeftHeight] = useState(0);
    const [leftWidth, setLeftWidth] = useState(0);

    const [rightHeight, setRightHeight] = useState(0);
    const [rightWidth, setRightWidth] = useState(0);

    const [isDragging, setIsDragging] = useState(false);

    const onMouseMove = (event)=> {
        event.preventDefault();
        handleResizeProgress(event.clientX);
    };
    const onMouseUp = ()=> { handleResizeEnd(); };
    const onTouchMove = (event)=> { handleResizeProgress(event.touches[0].clientX); };

    useEffect(()=> {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('touchmove', onTouchMove);

        return ()=> {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('touchmove', onTouchMove);
        }
    });

    const handleResizeStart = ()=> { setIsDragging(true); };
    const handleResizeEnd = ()=> { setIsDragging(false); };

    const handleResizeProgress = (clientX)=> {
        if (isDragging) {
            const newLeftWidth = (parseInt(clientX));
            const newRightWidth = (parseInt(wrapperRef.current.clientWidth)-parseInt(clientX));
            setLeftWidth(((newLeftWidth < minWidth) ? minWidth : newLeftWidth));
            setRightWidth(((newRightWidth < minWidth) ? minWidth : newRightWidth));
        }
        onResize();
    };

    return (
        <div className={classes.Container}>
            <div className={classes.Wrapper} ref={wrapperRef}>
                <Panel style={leftStyle}
                    height={leftHeight} setHeight={setLeftHeight}
                    width={leftWidth} setWidth={setLeftWidth}>
                    {left}
                </Panel>
                <div className={classes.HorizontalDivider} ref={horizontalDivider}
                    onMouseDown={handleResizeStart} />
                <Panel style={rightStyle}
                    height={rightHeight} setHeight={setRightHeight}
                    width={rightWidth} setWidth={setRightWidth}>
                    {right}
                </Panel>
            </div>
        </div>
    );
};

const useStyles = createUseStyles({
    Container: {
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    Wrapper: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%',
        overflow: 'auto',

        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',

        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    HorizontalDivider: {
        display: 'none',

        '@media (min-width: 600px)': {
            border: '1px solid #009cbd',
            cursor: 'col-resize',
            display: 'block',
            height: 'calc(100% - 12px)',
        },
    }
}, { name: 'Resize-Panel' });

ResizePanel.propTypes = {
    left: PropTypes.node,
    leftStyle: PropTypes.object,
    minWidth: PropTypes.number,
    right: PropTypes.node,
    rightStyle: PropTypes.object,
    onResize: PropTypes.func
};