
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AutoComplete } from '../../../../components';

import config from '../../../../constants/config';

export const ChannelSelect = ({ 
    channels=[], 
    selectedChannel={}, 
    isChildren = false, 
    onSelectedChannelChanged 
}) => {
    const [allItems, setAllItems] = useState([]);

    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const [childChannels, setChildChannels] = useState([]);
    const [childValue, setChildValue] = useState('');


    const [isChildChannel, setIsChildChannel] = useState(false);
    const [parentChannel, setParentChannel] = useState({});
    const [childNum, setChildNum] = useState(0);

    const altFeedColors = ['#1B785A', '#C2CC31', '#976CBD', '#CF748A', '#C98442'];

    // debug
    useEffect(()=> {
        console.log('childChannels: ', childChannels);
    }, [childChannels]);

    useEffect(()=> {
        if(allItems.length > 0 && selectedChannel && selectedChannel._id){
            if(selectedChannel && selectedChannel.parentChannelId && selectedChannel.parentChannelId.length > 0){
                const selectedParent = allItems.find((item)=> item._id === selectedChannel.parentChannelId);
                if(selectedParent){
                    setIsChildChannel(true);
                    setParentChannel(selectedParent);
                    setValue(selectedParent.name);
                    setChildChannels(allItems.filter((f) => f.parentChannelId === `${selectedParent._id}`));
                }
                setChildValue(selectedChannel.name);
            } else {
                setValue(selectedChannel.name);
                setIsChildChannel(false);
                // Set Child
                if(selectedChannel._id){
                    setChildChannels(allItems.filter((f) => f.parentChannelId === `${selectedChannel._id}`));
                }  
            }
        }
    }, [selectedChannel, allItems]);

    useEffect(()=> {

        const _allItems = channels.map((channel) => ({ ...channel, image: `${config.imgUrl}${channel.image}`, label: channel.name }));
        setAllItems(_allItems);
        
        // All Parents
        setItems(_allItems.filter((f) => !(f.parentChannelId !== undefined && f.parentChannelId.length > 0)));

        // Children
        if(value){
            const selectedItem = _allItems.find((item)=> item.name === value);
            if(selectedItem){
                setChildChannels(_allItems.filter((f) => f.parentChannelId === selectedItem._id));
            }            
        }
        
    }, [channels]);

    const handleSelectionChanged = (newItem)=> {
        const item = allItems.find((item)=> (item.name === newItem));
        onSelectedChannelChanged(item);
        // setItems(allItems);
    };
    const handleChildSelectionChanged = (newItem)=> {
        onSelectedChannelChanged(newItem);
    };

    return (
        <div className='Scrubber-Controls-Channel-Select-Container'>
            <div className='Scrubber-Controls-Channel-Select-Wrapper'>
                <AutoComplete
                    options={items}
                    value={value}
                    getItemValue={(item)=> ((item && item.name) ? item.name : '')}
                    renderItem={(item, isHighlighted)=> (
                        <div key={item.id} style={{ 
                            background: isHighlighted ? 'lightgray' : 'white', 
                            color: '#000', display: 'flex', padding: '8px' }}>
                            <img src={item.image} alt={''} style={{ height: '40px', margin: '0 8px 0 0', width: '40px' }} />
                            <p style={{ color: '#000', margin: 'auto 0' }}>{item.name}</p>
                        </div>
                    )}
                    onSelect={handleSelectionChanged}
                    onChange={(event)=> {
                        setValue(event.target.value);
                        setItems(allItems.filter((item)=> {
                            const label = item.label.replace(/[^\w\s]/gi, '').toLowerCase();
                            return label.indexOf(event.target.value.toLowerCase()) !== -1;
                        }));
                    }} />
                {childChannels && childChannels.length > 0 && !isChildChannel && (
                    childChannels.map((child, index)=> (
                        <div className='Scrubber-Controls-ChildBtn' key={index} onClick={() => {
                            setChildNum(index);
                            handleChildSelectionChanged(child);
                            setChildValue(child.name);
                        }} style={{ backgroundColor: altFeedColors[index % altFeedColors.length] }}>
                            {childChannels.length > 1 ? `Child ${index+1}` : `Child Channel`}
                        </div>
                    ))

                )}
                {isChildChannel && parentChannel.name !== undefined && (
                    <div className='Scrubber-Controls-ChildBtn' onClick={() => {
                        handleChildSelectionChanged(parentChannel);
                    }} style={{ backgroundColor: '#11b0ee' }}>
                        Parent Channel
                    </div>
                )}
            </div>
        </div>
    );
};

ChannelSelect.propTypes = {
    channels: PropTypes.array,
    selectedChannel: PropTypes.object,
    onSelectedChannelChanged: PropTypes.func
};