
import ReactGA from 'react-ga';

import config from '../constants/config';

class GoogleAnalytics {

    constructor() {
        ReactGA.initialize(config.gaTrackingId, { debug: /*config.debug*/false });
    }
    
    /**
     * Sends PageView actions to GA.
     * Optionally, will send custom dimensions/metrics to GA.
     * @param {String} source - The Page/View to track.
     * @param {String} [title] - A custom label to apply to the source.
     * @param {Array<String>} [trackerNames] - A list of extra trackers to run the command on.
     * @param {Object} [fieldsObject] - The fields to send to GA, e.g. { dimension1: 'new-users', metric1: 1 }
     */
    sendGAPageView(source, title=null, trackerNames=null, fieldsObject=null) {
        if (fieldsObject) {
            ReactGA.set(fieldsObject);
        }
        ReactGA.pageview(source, trackerNames, title);
    }

    /**
     * Sends Event actions to GA.
     * Optionally, will send custom dimensions/metrics to GA.
     * @param {String} category - A top-level category for the events.
     * @param {String} action - A description of the behaviour.
     * @param {String} [label] - More precise labelling of the related action.
     * @param {Number} [value] - A means of recording a numerical value against an event.
     * @param {Array<String>} [trackerNames] - A list of extra trackers to run the command on.
     * @param {Object} [fieldsObject] - The fields to send to GA, e.g. { dimension1: 'new-users', metric1: 1 }
     */
    sendGAEvent(category, action, label=null, value=null, trackerNames=null, fieldsObject=null) {
        const details = { category, action };
        if (label) {
            details.label = label;
        }
        if (value) {
            details.value = value;
        }
        if (fieldsObject) {
            ReactGA.set(fieldsObject);
        }
        ReactGA.event(details, trackerNames);
    }
}

export default new GoogleAnalytics();