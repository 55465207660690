
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { createUseStyles } from 'react-jss';

import { backgroundClass } from '../../index';

export const RightDrawer = ({ background, children, isOpen })=> {
    const classes = useStyles();
    
    return (
        <div className={clsx({
            [classes.Container]: true,
            [classes.SlideInRight]: (isOpen),
            [classes.SlideOutRight]: (!isOpen)})}>
            <div className={clsx({
                [classes.Wrapper]: true,
                [classes.Clear]: (background===backgroundClass.CLEAR),
                [classes.Opaque]: (background===backgroundClass.OPAQUE),
                [classes.Transparent]: (background===backgroundClass.TRANSPARENT)})}>
                {children}
            </div>
        </div>
    );
};

const useStyles = createUseStyles({
    '@keyframes slideInRight': {
        from: { display: 'none', right: '-100%', opacity: '0' },
        to: { display: 'block', right: '0', opacity: '1' }
    },
    '@keyframes slideOutRight': {
        from: { display: 'block', right: '0', opacity: '1' },
        to: { display: 'none', right: '-100%', opacity: '0' }
    },
    Container: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        zIndex: '99',
    },
    Wrapper: {
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        right: '0',
        width: '100%'
    },
    Clear: {
        background: 0
    },
    Opaque: {
        background: '#000'
    },
    Transparent: {
        background: '#00000060'
    },
    SlideInRight: {

        animationName: '$slideInRight',
        animationDuration: '1s',
        animationTimingFunction: 'ease-out'
    },
    SlideOutRight: {
        right: '-100%',
        
        animationName: '$slideOutRight',
        animationDuration: '1s',
        animationTimingFunction: 'ease-in'
    }
}, { name: 'RightDrawer' });

RightDrawer.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool,
};