
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { createUseStyles } from 'react-jss';

export const Panel = ({ children, height, width, setHeight, setWidth, style={} })=> {
    const classes = useStyles();

    const containerRef = useRef();

    useEffect(()=> {
        if (containerRef.current) {
            if (!width) {
                setWidth(containerRef.current.clientWidth);
                return;
            }
            containerRef.current.style.maxWidth = `${width}px`;
            style = { ...containerRef.current.style, ...style };
        }
    }, [containerRef, width, setWidth]);

    return (
        <div className={classes.Container} ref={containerRef} style={style}>
            {children}
        </div>
    );
};

const useStyles = createUseStyles({
    Container: {
        flex: '1',
        height: '100%',
        margin: 'auto 0',
    }
}, { name: 'Panel' });

Panel.propTypes = {
    children: PropTypes.node,
    height: PropTypes.number,
    width: PropTypes.number,
    setHeight: PropTypes.func,
    setWidth: PropTypes.func,
    style: PropTypes.object
};