
import React from 'react';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';

import { Button } from 'reactstrap';

import { MdClose/*, MdFullscreen, MdFullscreenExit, */, MdLaunch, MdPause, MdPlayArrow } from 'react-icons/md';

import EpgScreen from './EpgScreen';

import config from '../../constants/config';

import Dialog from '../../components/Dialog';
import { HLSPlayer } from '../../components/HLSPlayer';

import './style.scss';

export const DialogContext = React.createContext({ value: null, setValue: null });

@inject('epgStore', 'channelStore', 'loadingStore', 'replayerStore')
@observer
class EPGManager extends React.Component {
    constructor(props) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.seekToLive = this.seekToLive.bind(this);
        this.openLiveLink = this.openLiveLink.bind(this);

        this.handleProgramSelected = this.handleProgramSelected.bind(this);
        this.handleDateChanged = this.handleDateChanged.bind(this);

        this.handleOpenLiveDialog = this.handleOpenLiveDialog.bind(this);

        this.handleFullscreenEnter = this.handleFullscreenEnter.bind(this);
        this.handleFullscreenExit = this.handleFullscreenExit.bind(this);

        this.handlePause = this.handlePause.bind(this);
        this.handlePlay = this.handlePlay.bind(this);

        this.player = React.createRef();

        this.state = {
            data: [],
            key: undefined,
            dialog: {
                initialSeek: true,
                isOpen: false,
                selectedChannel: null,
                mounted: false
            }
        };
    }

    async componentDidMount() {
        const { channelStore, epgStore, replayerStore } = this.props;
 
        if (!epgStore.selectedDate) {
            await epgStore.setDate(dayjs());
        } else {
            const cacheKeys = Object.keys(epgStore.epgCache);
            if (!cacheKeys.length) {
                await epgStore.refreshEPGData();
            }
        }

        if (this.player && this.player.current) {
            if (!replayerStore.player) {
                replayerStore.setPlayer(this.player.current);
                replayerStore.setupHLS(this.seekToLive);
            }
        }

        channelStore.getChannels(true).then((channelStoreChannels)=> {
            if (Object.keys(epgStore.epgCache)) {
                const data = this._buildEPGViewData(channelStoreChannels, epgStore.epgCache);
                this.setState({ data });
            }
        });
        setInterval(()=> {
            this.setState({ key: undefined });
        }, 5000);
    }

    componentDidUpdate() {
        const { channelStore, epgStore, replayerStore } = this.props;

        if (!this.state.key) {
            channelStore.getChannels(false).then((channelStoreChannels)=> {
                const data = this._buildEPGViewData(channelStoreChannels, epgStore.epgCache);
                this.setState({ data, key: epgStore.selectedDate.unix() });
            });
        }

        if (this.player && this.player.current) {
            if (!replayerStore.player) {
                replayerStore.setPlayer(this.player.current);
                replayerStore.setupHLS(this.seekToLive);
            }
        }
    }

    _buildEPGViewData(channels, epgData) {
        if (!channels) {
            channels = [];
        }
        const data = [];
        if (channels && epgData) {
            // No Children
            channels = channels.filter((f) => !(f.parentChannelId !== undefined && f.parentChannelId.length > 0));
            channels = channels.slice().sort((a, b)=> ((a.order === b.order) ? 0 : ((a.order > b.order) ? 1 : -1)));
            for (const channel of channels) {
                const schedules = [];
                if (channel && channel.epg_id) {
                    for (const epgEntry of (epgData[channel.epg_id] || [])) {
                        const { end_datetime, end_datetimeUTC, epg_id, id, start_datetime, start_datetimeUTC, program_title } = epgEntry;
                        const item = { epg_id, end: end_datetimeUTC, end_l: end_datetime, id, start: start_datetimeUTC, start_l: start_datetime, title: program_title };
                        const check = schedules.find((schedule)=> ((schedule.epg_id === item.epg_id) && (schedule.start === item.start)));
                        if (!check) {
                            schedules.push(item);
                        }
                    }
                }
                const { id, name, image } = channel;
                if (id) {   // Test that there is an id.
                    data.push({ id, images: { logo: image }, schedules, title: name });
                }
            }
        }
        return data;
    }

    openDialog() {
        const dialog = { ...this.state.dialog,
            isOpen: true
        };
        this.setState({ dialog });
    }
  
    closeDialog() {
        const { replayerStore } = this.props;
  
        const dialog = { ...this.state.dialog,
            initialSeek: true,
            isOpen: false,
            selectedChannel: null
        };
        this.setState({ dialog });
  
        replayerStore.hls = null;
        replayerStore.setPlayer(null);
    }

    seekToLive() {
        const { replayerStore } = this.props;
  
        if (this.state.dialog.initialSeek) {
            const dialog = { ...this.state.dialog,
                initialSeek: false
            };
            this.setState({ dialog });
    
            if (replayerStore.player) {
                replayerStore.player.currentTime = (replayerStore.player.duration-120);
            }
        }
    }
  
    openLiveLink() {
        const url = `${config.serverURL}/home/live?channelId=${this.state.dialog.selectedChannel.id}`;
        window.open(url, '_blank').focus()
  
        this.closeDialog();
    }

    handleProgramSelected(programChannel, program) {
        const { epgStore, channelStore, history } = this.props;

        const channel = channelStore.channels.find((channel)=> (channel.id === programChannel.id));
        epgStore.setSelectedChannel(channel, false, false);
        console.log('[program Select]', program);
        epgStore.setSelectedDateUTC(program.start, true, true);

        const timestamp = new Date(program.start).getTime();
        history.push(`/home/scrubber?ct=${timestamp}&sc=${channel.id}`);

        this.setState({ key: undefined });
    }

    handleDateChanged(newDate) {
        const { epgStore } = this.props;

        epgStore.setSelectedDateUTC(newDate, true).then(()=> {
            this.setState({ key: undefined });
        });
    }

    handleOpenLiveDialog(channel) {
        const { replayerStore } = this.props;
  
        const dialog = { ...this.state.dialog,
            isOpen: true,
            selectedChannel: channel
        };
        this.setState({ dialog });
        
        replayerStore.setSource(`${config.live}/${channel.id}/source.m3u8`, true);
    }

    handleFullscreenEnter(event) {
        const { replayerStore } = this.props;
        replayerStore.setIsFullscreen(true);
    }

    handleFullscreenExit(event) {
        const { replayerStore } = this.props;
        replayerStore.setIsFullscreen(false);
    }

    handlePause() {
        const { replayerStore } = this.props;
        replayerStore.pause();
    }

    handlePlay() {
        const { replayerStore } = this.props;
        replayerStore.play();
    }

    render() {
        const { epgStore, replayerStore } = this.props;
        const { source } = replayerStore;

        const dialogProps = { ...this.state.dialog,
            openDialog: this.openDialog
        };
        return (
            <DialogContext.Provider value={dialogProps}>
            <div className='EPG-Container'>
                <div className='EPG-Wrapper'>
                    <EpgScreen
                        channels={this.state.data || []}
                        viewAll={epgStore.viewAll}
                        currentDate={epgStore.selectedDate || dayjs()}
                        onProgramSelected={this.handleProgramSelected}
                        onOpenLiveDialog={this.handleOpenLiveDialog}
                        onDateChanged={this.handleDateChanged} />
                </div>
            </div>
            <Dialog clickaway isModal
                isOpen={this.state.dialog.isOpen}
                onClose={this.closeDialog}>
                <div className='EPG-Live-Player-Container'>
                    <div className='EPG-Live-Player-Wrapper'>
                        <div className='EPG-Live-Player'>
                            <HLSPlayer
                                controls={false}
                                innerRef={this.player}
                                source={source}
                                style={{ width: '100%' }}
                                onClick={this.closeDialog}
                                onFullscreenEnter={this.handleFullscreenEnter}
                                onFullscreenExit={this.handleFullscreenExit}
                                onPause={this.handlePause}
                                onPlay={this.handlePlay} />
                            <div className='Live-Player-Controls-Container'>
                                {(replayerStore.isPlaying) ?
                                    <Button onClick={this.handlePause}>
                                        <MdPause size={24} />
                                    </Button> :
                                    <Button onClick={this.handlePlay}>
                                        <MdPlayArrow size={24} />
                                    </Button>
                                }
                                {/* TODO: Read up on removing Fullscreen controls... */}
                                {/*(replayerStore.isFullscreen) ?
                                    <Button onClick={this.handleFullscreenExit}>
                                        <MdFullscreenExit size={24} />
                                    </Button> :
                                    <Button onClick={this.handleFullscreenEnter}>
                                        <MdFullscreen size={24} />
                                    </Button>
                                */}
                            </div>
                        </div>
                    </div>
                    <div className='EPG-Live-Player-Controls'>
                        <div className='Dialog-Button' onClick={this.closeDialog}>
                            <MdClose size={24} />
                        </div>
                        <div className='Dialog-Button' onClick={this.openLiveLink}>
                            <MdLaunch size={24} />
                        </div>
                    </div>
                </div>
            </Dialog>
            </DialogContext.Provider>
        );
    }
}

export default EPGManager;

  