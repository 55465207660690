
import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

import { BsDownload } from 'react-icons/bs';
import { MdRefresh } from 'react-icons/md';

export const Exports = ({ exports, onExportClicked, onRefreshExports, onDownload })=> {

    const handleDownload = (event, record)=> {
        event.stopPropagation();
        onDownload(record);
    }

    const renderExport = (record, index)=> {
        let date = 'N/A';
        if (record.date) {
            date = dayjs(record.date).format('DD-MM-YYYY');
        } else if (record.start_time) {
            date = dayjs(record.start_time*1000).format('DD-MM-YYYY');
        }
        return (
            <li className='Scrubber-Navigation-Export-Item-Container' key={index} onClick={()=> { onExportClicked(record); }}>
                <div className='Scrubber-Navigation-Export-Item-Wrapper'>
                    <div className='Scrubber-Navigation-Export-Item-Details'>
                        <p><strong>Date:</strong> {`${date} ${dayjs(record.start_time*1000).format('HH:mm:ss')} - ${dayjs(record.end_time*1000).format('HH:mm:ss')}`}</p>
                        <p><strong>Channel Name:</strong> {record.channel_name}</p>
                        <p><strong>Comments:</strong> {((record.comments && record.comments.length<150) ? record.comments : `${record.comments.substring(0, 150)}...`)}</p>
                        <p><strong>Tags:</strong> {`${((record.tags) ? record.tags : []).join(', ')}`}</p>
                    </div>
                    <div className='Scrubber-Navigation-Export-Item-Metadata'>
                        {(record.status !== 'DONE') ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h6>{record.status}</h6>
                                {(record.status !== 'FAILED') &&
                                    <MdRefresh style={{ margin: '0 auto' }} size={24} onClick={onRefreshExports} />
                                }
                            </div> :
                            /*<a href={`${document.location.origin}/${record.url}`} 
                                target='_blank' 
                                rel='noopener noreferrer'>
                                <BsDownload size={24}></BsDownload>
                            </a>*/
                            <div className='Exports-Meta-Actions'>
                                <div onClick={(event)=> { handleDownload(event, record); }}>
                                    <BsDownload size={24}></BsDownload>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </li>
        );
    };

    return (
        <div className='Scrubber-Navigation-Exports-Container'>
            <ul className='Scrubber-Navigation-Exports-List'>
                {exports.map(renderExport)}
            </ul>
        </div>
    );
};

Exports.propTypes = {
    exports: PropTypes.array,
    onExportClicked: PropTypes.func,
    onRefreshExports: PropTypes.func
};