import Home from '../views/website/Home';
import Login from 'views/pages/Login';
import Scrubber from '../views/Scrubber';

import Detection from '../views/Detection';

const _routes = [
    {
        component: Home,
        identifier: 'home',
        hidden: true,
        label: 'Home',
        layout: '/web',
        path: '/web/home'
    },
    {
        component: Detection,
        identifier: 'detection',
        hidden: false,
        label: 'Detection',
        layout: '/web',
        path: '/web/detection'
    },
    {
        component: Scrubber,
        identifier: 'scrubbing',
        hidden: false,
        label: 'Scrubber',
        layout: '/web',
        path: '/web/scrubbing',
    },
    {
        component: Login,
        identifier: 'login',
        hidden: true,
        label: 'Login',
        layout: '/web',
        path: '/web/login'
    }
];

const getRoutes = (isAdmin = false) => {
    return _routes;
}

export default getRoutes;
