
import React, { useEffect, useState } from 'react';

import { createUseStyles } from 'react-jss';

import { Button } from 'reactstrap';

import { Popover } from '@material-ui/core';

import dayjs from 'dayjs';

export const DatePicker = ({ selectedDate, onDateChanged })=> {
    const classes = useStyles();

    const [date, setDate] = useState(dayjs());
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(()=> {
        if (selectedDate) {
            setDate(dayjs(selectedDate));
        }
    }, [selectedDate]);

    const toggleDatePicker = (event) => { setAnchorEl(event.currentTarget); };
    const closeDatePicker = () => { setAnchorEl(null); };
    const handleDateChanged = (event)=> {
        if (!event.target.value || event.target.value === '0') {
            const formattedDate = date.format('YYYY-MM-dd');
            event.target.value = `${formattedDate.slice(0, formattedDate.length-2)}01`
        }
        setDate(dayjs(event.target.value).startOf('day'));
    }
    const handleKeyUp = (event)=> {
        if (event.keyCode === 13) {
            event.preventDefault();
            closeDatePicker();
            handleDateChanged(event);
        }
    }

    const formattedDate = ((date && date.format) ? date.format('DD-MM-YYYY') : '');

    return (
        <div className={classes.Container}>
            <Button className={[classes.DatePicker, classes.Button].join(' ')}
                onClick={toggleDatePicker}>
                {formattedDate}
            </Button>
            <Button className={classes.Button} onClick={()=> { onDateChanged(date.startOf('day')); }}>
                Go
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeDatePicker}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ className: classes.Paper }}>
                <div>
                    {(Boolean(anchorEl)) &&
                    <input
                        type={'date'}
                        day
                        value={date.format('YYYY-MM-DD')}
                        onChange={handleDateChanged}
                        onKeyUp={handleKeyUp} />
                    }
                </div>
            </Popover>
        </div>
    );
};

const useStyles = createUseStyles({
    Container: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 32px',
        padding: '4px 0',

        '& button': {
            cursor: 'pointer',
            margin: '0 4px',
            padding: '8px 4px'
        }
    },
    Button: {
        borderRadius: '4px',
        padding: '6px 16px !important'
    },
    DatePicker: {
        cursor: 'pointer',
        flexGrow: '1',
        fontSize: 'x-small',
        padding: '8px 16px',
        width: '96px'
    },
    Paper: {
        padding: '8px'
    }
}, { name: 'Date-Picker' });