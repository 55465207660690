import React from 'react';
import PropTypes from 'prop-types';

import { Img } from '../../../../components';

class ChannelCaptions extends React.PureComponent {

    render() {
        const { channels, onOpenLiveDialog } = this.props;

        return (
            <div className='timetable__captions'>
                {channels.map((channel) => (
                    <div key={channel.id} className='timetable__captions-item' onClick={() => { onOpenLiveDialog(channel); }}>
                        {(channel.images && channel.images.logo) &&
                            <Img src={channel.images.logo} />
                            //<img alt='logo' src={channel.images.logo} />
                        }
                        <p>{channel.title}</p>
                    </div>
                ))}
            </div>
        );
    }
}

export default ChannelCaptions;

ChannelCaptions.propTypes = {
    channels: PropTypes.array,
    onOpenLiveDialog: PropTypes.func
};