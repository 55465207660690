import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    minWidth: 320,
    maxWidth: 320
  },
  media: {
    minHeight: 140
  },
};

function ListCard(props) {
  const { classes, color } = props;

  return (
    <Card className={classes.card}>
      <CardActionArea className='EVOCardActionArea'>
        {(color && color.length > 0)?
          <div className='EVOCardColor' style={{backgroundColor: color, width:'100%', height:'20px'}}></div>
          :null}
        {(!props.video && props.image && props.image.img)?
          <CardMedia
            className={classes.media}
            image={props.image.img}
            title={props.image.title || ''}
            onClick={props.onClick}
          />
        :null}
        <CardContent onClick={props.onClick} className='EVOCardContent'>
          <Typography gutterBottom variant="h4" component="h4">
            {props.title}
          </Typography>
          {(props.desc)?
          <Typography component="p">
            {props.desc}
          </Typography>:null}
        </CardContent>
      </CardActionArea>
      {(props.buttons && props.buttons.length > 0)?
      <CardActions>
        {props.buttons.map((btn, idx) => {
          if(btn && btn.text && btn.onClick){
            return (
              <Button key={idx} size="small" className='evo-card-btn' onClick={btn.onClick} color={btn.color || 'primary'}>
                {btn.text}
              </Button>
              )
          } else {
            return null;
          }
        })}
      </CardActions>:null}
    </Card>
  );
}

ListCard.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  color: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string
  })),// [{text:'',onClick:func, color:''}]
  image: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string
  }),
  video: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListCard);