
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const InOut = (props)=> {
    const { media={}, onInClicked, onOutClicked } = props;

    return (
        <div className='Scrubber-Controls-In-Out-Container'>
            <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={(!media.canPlay)} onClick={onInClicked}>In</button>
            <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={(!media.canPlay)} onClick={onOutClicked}>Out</button>
        </div>
    );
};

InOut.propTypes = {
    hasExport: PropTypes.bool,
    media: PropTypes.object,
    onInClicked: PropTypes.func,
    onOutClicked: PropTypes.func
};