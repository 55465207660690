
import React from 'react';
import { inject, observer } from 'mobx-react';

import { EvoUI } from '@components';

import './style.scss';

@inject('adminStore')
@observer
class UserManager extends React.Component {
    constructor(props) {
        super(props);

        this.getData = this.getData.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.editData = this.editData.bind(this);

        this.state = {
            entity: null
        };
    }

    componentDidMount() {
        const { adminStore } = this.props;

        adminStore.getUserEntity().then((entity)=> {
            this.setState({ entity });
        });
    }

    deleteData(rows) {
        const { adminStore } = this.props;
        const { entity } = this.state;

        console.log(`DELETE: ${JSON.stringify(rows)}`);

        const idField = ((entity && entity.fields) ? entity.fields.find((field)=> (field.identity)) : 'id');
        const deletedRecords = [];
        for (const row of rows) {
            if (row[idField]) {
                deletedRecords.push(row[idField]);
            } else {
                console.log(`Identity Field not found for: ${JSON.stringify(row)}`);
            }
        }
        adminStore.deleteUserMany(deletedRecords).then(()=> {
            console.log('Completed Delete Operation');
        }).finally(()=> {
            if (this.refreshData) {
                this.refreshData();
            }
        });
    }

    editData(target) {
        const { adminStore } = this.props;

        adminStore.addUpdateUser(target).finally(()=> {
            adminStore.getUserEntity().then((entity)=> {
                this.setState({ entity });
                if (this.refreshData) {
                    this.refreshData();
                }
            });
        });
    }

    getData(refresh) {
        const { adminStore } = this.props;

        console.log(`[getData users] refresh:${refresh}`);
        return adminStore.getUsers(refresh).then((data)=> {
            return { payload: data, error: null };
        });
    }

    render() {
        const view = 'list';
        const isNew = false;

        return (
            <div className='Admin-Users-Container'>
                <div className='Admin-Users-Wrapper'>
                    <div className='evoDisplay' style={{ height: '100%', width: '100%' }}>
                        {(this.state.entity) ?
                            <EvoUI key='evo' {...this.props}
                                entity={this.state.entity}
                                view={view}
                                isNew={isNew}
                                getData={this.getData}
                                refreshData={(refresh) => (this.refreshData = refresh)}
                                deleteOne={this.deleteData}
                                deleteMany={this.deleteData}
                                editOne={this.editData}
                                noViewCards={true} /> :
                            <h3>Loading...</h3>}
                    </div>
                </div>
            </div>
        );
    }
}

export default UserManager;