
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class CookieBanner extends React.Component {

    render() {
        const { onAcceptCookies, onDeclineCookies } = this.props;

        return (
            <div className='Cookie-Banner-Container'>
                <p>
                This website uses cookies to improve service, for analytical and advertising purposes.
                Please read our <a href={'/cookies'} className='Cookie-Banner-Link'>Cookie Policy</a>.
                Confirm your consent to the use of cookies.
                </p>
                <div className='Cookie-Banner-Controls'>
                    <div id='accept' onClick={onAcceptCookies}>Accept</div>
                    <div onClick={onDeclineCookies}>Decline</div>
                </div>
            </div>
        );
    }
};

export default CookieBanner;

CookieBanner.propTypes = {
    onAcceptCookies: PropTypes.func,
    onDeclineCookies: PropTypes.func
}