
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

export const DateTimePicker = ({ currentTime, onCurrentTimeChanged })=> {
    const [date, setDate] = useState(currentTime);
    const [hasChanges, setHasChanges] = useState(false);
    const [time, setTime] = useState('00:00:00');

    useEffect(()=> {
        if ((currentTime && currentTime.format)) {
            setDate(currentTime.format('YYYY-MM-DD'));
            setTime(currentTime.format('HH:mm:ss'));
        }
    }, [currentTime]);

    const handleDateChanged = (event)=> {
        setDate(event.target.value);
        setHasChanges(true);
    };

    const handleTimeChanged = (event)=> {
        setTime(event.target.value);
        setHasChanges(true);
    };

    const handleDateTimeChanged = ()=> {
        const newDateTime = dayjs(`${date} ${time}`);
        onCurrentTimeChanged(newDateTime);
        setHasChanges(false);
    };

    const handleNowClicked = ()=> {
        const newDateTime = dayjs().subtract(5, 'minute');
        onCurrentTimeChanged(newDateTime);
    };

    return (
        <div className='Scrubber-Date-Time-Picker-Container'>
        <div className='Scrubber-Date-Time-Picker-Wrapper'>
            <input className='Scrubber-Time-Picker' type='Time' step={1} value={time} onChange={handleTimeChanged} />
            <input className='Scrubber-Date-Picker' type='Date' value={date} onChange={handleDateChanged} />
            <button className='Scrubber-Control' disabled={(!hasChanges)} onClick={handleDateTimeChanged}>Go To</button>
            <button className='Scrubber-Control' onClick={handleNowClicked}>Now</button>
        </div>
    </div>
    );
};

DateTimePicker.propTypes = {
    currentTime: PropTypes.object,
    onCurrentTimeChanged: PropTypes.func,
    onOpenBreakout: PropTypes.func
};