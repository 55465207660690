
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { MdPause, MdPlayArrow } from 'react-icons/md';

export const PlaybackControls = ({ media, onSeekBack, onSeekForward, onPlayPause, onSetPlaybackRate })=> {
    
    return (
        <div className='Scruber-Controls-Playback-Controls'>
            <div className='Scrubber-Controls-Seek-Controls'>
                <div className='Scrubber-Controls-Left-Container'>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekBack((5*60)); }}>
                        - 5 Min
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekBack((1*60)); }}>
                        - 1 Min
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekBack(30) }}>
                        - 30 Sec
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekBack(10); }}>
                        - 10 Sec
                    </button>

                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSetPlaybackRate(1); }}>
                        1x
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSetPlaybackRate(2); }}>
                        2x
                    </button>
                </div>
                <div className='Scrubber-Controls-PlayPause-Container'>
                    <button className={clsx({ 'disabled': !media.canPlay })} disabled={!media.canPlay} onClick={onPlayPause}>
                    {(media.isPlaying) ?
                        <MdPause size={48} /> :
                        <MdPlayArrow size={48} />
                    }
                    </button>
                </div>
                <div className='Scrubber-Controls-Right-Container'>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekForward(10); }}>
                        + 10 Sec
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekForward(30); }}>
                        + 30 Sec
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekForward((1*60)); }}>
                        + 1 Min
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSeekForward(5*60); }}>
                        + 5 Min
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSetPlaybackRate(5); }}>
                        5x
                    </button>
                    <button className={clsx({ 'Scrubber-Control': true, 'disabled': !media.canPlay })} disabled={!media.canPlay}
                        onClick={()=> { onSetPlaybackRate(10); }}>
                        10x
                    </button>
                </div>
            </div>
        </div>
    );
};

PlaybackControls.propTypes = {
    media: PropTypes.object,
    onSeekBack: PropTypes.func,
    onSeekForward: PropTypes.func,
    onPlayPause: PropTypes.func,
    onSetPlaybackRate: PropTypes.func
};