
export default {
    id: 'channel',
    label: 'Channel List',
    name: 'channel',
    namePlural: 'channels',
    hideTitle: true, // Hides Title From Edit & View
    titleField: 'name',

    cardOptions: {
        labelField: 'name',
        descField: 'description',
        imgField: 'image',
        masonryOptions: { // masonryCardOptions Overrides
            transitionDuration: 100,
            gutter: 10,
            originTop: true,
            resize: true,
            fitWidth: true
        }
    },
    gridOptions: {
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 20, 50, 100, 200]
        // download: false,
        // print: false,
        // filter: false,
        // columns: []
    },
    fields: [{
        id: '_id',
        label: 'id',
        identity: true,
        type: 'text',
        width: 0,
        listColumnOptions: { display: false }, // mui-Datatable column options... display:false, width etc.
        required: false
    }, {
        id: 'image',
        type: 'image',
        inMany: true,
        label: 'Channel Image'
    }, {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 100,
        required: true,
        inMany: true
    }, {
        id: 'description',
        type: 'textmultiline',
        label: 'Description',
        width: 100,
        height: 5
    }, {
        id: 'epg_id',
        label: 'EPG Data Code',
        type: 'text',
        width: 100,
        listColumnOptions: { display: false },
    }, {
        id: 'feedurl',
        label: 'UDP Multicast Address',
        type: 'text',
        width: 100,
        listColumnOptions: { display: false },
    }, {
        id: 'order',
        label: 'Order',
        type: 'integer',
        listColumnOptions: { display: false }
    }, {
        id: 'archiveDays',
        label: 'How Long to keep in Cold, in Days (Archive)',
        type: 'integer',
        default: 90,
        listColumnOptions: { display: false }
    }, {
        id: 'recordingOffset',
        label: 'Recording Offset to apply, is Sec',
        type: 'integer',
        default: 0,
        listColumnOptions: { display: false }
    }, {
        id: 'hotStorageDuration',
        label: 'How Long to keep in Hot, in Min (Default 168 - 7days)',
        type: 'integer',
        default: 168,
        listColumnOptions: { display: false }
    }, {
        id: 'channelgroups',
        label: 'Channel Groups',
        type: 'list',
        list: [], // populate list in Store
        width: 100,
        inMany: true,
        listColumnOptions: { display: false }
    }, {
        id: 'tags',
        label: 'Tags',
        type: 'list',
        list: [], // populate list in Store
        inMany: true,
        listColumnOptions: { display: false }
    }, {
        id: 'audioOnly',
        type: 'boolean',
        inMany: true,
        label: 'Audio Only',
        labelTrue: 'Yes',
        labelFalse: 'No',
        listColumnOptions: { display: false },
    }, {
        id: 'active',
        type: 'boolean',
        inMany: true,
        label: 'Activate / Deactivate Channel',
        labelTrue: 'Active',
        labelFalse: 'Inactive',
        listColumnOptions: { display: false },
    }, {
        id: 'status',
        label: 'Status',
        type: 'text',
        disabled: true,
        width: 100,
    }, {
        id: 'color',
        label: 'Channel Color',
        type: 'color',
        width: 100,
        inMany: true,
        listColumnOptions: { display: false },
    }, {
        id: 'parentChannelId',
        label: 'Parent Channel, will make this a child',
        type: 'lov',
        list: [], // populate list in Store
        width: 100,
        inMany: true,
        notSelf: true, // Removes self from list
        listColumnOptions: { display: false }
    }, {
        id: 'hotStorage',
        label: 'Hot Storage',
        type: 'lov',
        list: [], // populate list in Store
        width: 100,
        inMany: true,
        required: true,
        listColumnOptions: { display: false }
    }, {
        id: 'coldStorages',
        label: 'Cold Storage',
        type: 'lov',
        list: [], // populate list in Store
        width: 100,
        inMany: true,
        required: true,
        listColumnOptions: { display: false }
    }],
    groups: [{
        id: 'p1', type: 'panel',
        label: 'Details', width: 60,
        fields: ['name', 'description', 'feedurl', 'epg_id', 'order', 'channelgroups', 'tags']
    }, {
        id: 'p2', type: 'panel',
        label: '', width: 40,
        fields: ['active', 'audioOnly', 'status', 'image', 'color']
    }, {
        id: 'p3', type: 'panel',
        label: 'Storage Settings', width: 50,
        fields: ['parentChannelId', 'hotStorage', 'coldStorages']
    }, {
        id: 'p4', type: 'panel',
        label: 'Duration Settings', width: 50,
        fields: ['recordingOffset', 'archiveDays', 'hotStorageDuration']
    }]
}