import io from 'socket.io-client';
import config from '../../constants/config';

import commonStore from '../../stores/commonStore';

let socket = {};
if(config.sioURL){
    //Socket
    socket = io(config.sioURL, config.sioOpt);

    socket.on('PING', (id) =>{
        socket.emit('PONG', id);
    });

    // Check Connection
    socket.on('disconnect', () => {
        console.log('[SIO Disconnected]');
        commonStore.setConnected(false);
    });

    // reconnect
    socket.on('reconnect', () => {
        console.log('[SIO Reconnect]');
        commonStore.setConnected(true);
    });

    // connect
    socket.on('connect', () => {
        console.log('[SIO Connected]');
        commonStore.setConnected(true);
    });
}

// The instance
export default socket;
