
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { createUseStyles } from 'react-jss';

import { backgroundClass } from '../../index';

export const TopDrawer = ({ background, children, isOpen })=> {
    const classes = useStyles();
    
    return (
        <div className={clsx({
            [classes.Container]: true,
            [classes.SlideInTop]: (isOpen),
            [classes.SlideOutTop]: (!isOpen)})}>
            <div className={clsx({
                [classes.Wrapper]: true,
                [classes.Clear]: (background===backgroundClass.CLEAR),
                [classes.Opaque]: (background===backgroundClass.OPAQUE),
                [classes.Transparent]: (background===backgroundClass.TRANSPARENT)})}>
                {children}
            </div>
        </div>
    );
};

const useStyles = createUseStyles({
    '@keyframes slideInTop': {
        from: { display: 'none', top: '-100%', opacity: '0' },
        to: { display: 'block', top: '0', opacity: '1' }
    },
    '@keyframes slideOutTop': {
        from: { display: 'block', top: '0', opacity: '1' },
        to: { display: 'none', top: '-100%', opacity: '0' }
    },
    Container: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        zIndex: '99',
    },
    Wrapper: {
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        width: '100%'
    },
    Clear: {
        background: 0
    },
    Opaque: {
        background: '#000'
    },
    Transparent: {
        background: '#00000060'
    },
    SlideInTop: {
        display: 'block',
        top: '0',

        animationName: '$slideInTop',
        animationDuration: '1s'
    },
    SlideOutTop: {
        top: '-100%',
        
        animationName: '$slideOutTop',
        animationDuration: '1s'
    }
}, { name: 'TopDrawer' });

TopDrawer.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool,
};