
import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

export const EPG = ({ currentTime, data=[], onProgramSelected })=> {
    const epgData = Array.from(new Set(data));

    const now = dayjs();

    const renderListItem = (program, index)=> {
        const end = dayjs(program.end_datetimeUTC);
        const start = dayjs(program.start_datetimeUTC);

        const isFutureContent = (start.unix() > now.unix());

        const handleClick = ()=> {
            if (!isFutureContent) {
                onProgramSelected(program);
            }
        }
        if (!currentTime.isSame(start, 'day')) {
            return null;
        }
        return (
            <li className='Scrubber-Navigation-EPG-Program' key={index} onClick={handleClick}>
                <div className='program-timestamp'>{start.format('HH:mm:ss')} - {end.format('HH:mm:ss')}</div>
                <div className='program-title'>{program.program_title}</div>
                {(isFutureContent) &&
                    <div className='program-future'>[Future]</div>
                }
            </li>
        );
    };
    
    return (
        <div className='Scrubber-Navigation-EPG-Container'>
            <ul className='Scrubber-Navigation-EPG-List'>
                {epgData.map(renderListItem)}
            </ul>
        </div>
    );
};

EPG.propTypes = {
    currentTime: PropTypes.object,
    data: PropTypes.array,
    onProgramSelected: PropTypes.func
};