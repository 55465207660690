
import { observable, action } from 'mobx';
import config from '../constants/config';

class LoadingStore {
    @observable loading = false;
    @observable loadingStop = null;

    @observable loadingWait = 2.5; // Sec
    @observable loadingTime = null;

    @action setLoadingForce(val) {
        this.loading = val;
    }

    @action setLoading(val) {
        if (val) {
            this.loading = val;
            this.loadingTime = ((new Date().getTime()/1000)+this.loadingWait);
            try {
                if (this.loadingStop) {
                    clearTimeout(this.loadingStop);
                    this.loadingStop = null;
                }
                this.loadingStop = setTimeout(() => {
                    this.setLoading(false);
                    this.loadingStop = null;
                }, config.maxLoadingTime); // Max loading Time
            } catch(err) {
                console.log(err);
            }
        } else {
            const now = (new Date().getTime()/1000);
            if (this.loadingTime < now) {
                this.loading = false;
            } else if (!this.loadingTime) {
                this.loading = false;
            } else {
                setTimeout(() => {
                    this.setLoading(false);
                    this.loadingStop = null;
                }, (this.loadingWait*1000)); // Max wait Time
            }
        }
    }
}

export default new LoadingStore();