import { action, observable, reaction, toJS } from 'mobx';

import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import utils from './utils';

import adminStore from './adminStore';
import channelStore from './channelStore';
import commonStore from './commonStore';
import loadingStore from './loadingStore';
import viewStore from './viewStore';

const defaultData = {
    loading: false,
    failedAttempts: 0,
    authentication: {
        email: '',
        failedAttempts: 0,
        password: '',
        username: '',
    },
    user: null,
    roles: null,
};

class AuthStore {
    @observable loading = false;
    @observable errors = null;

    @observable user = null;
    @observable role = null;

    @observable authentication = {
        email: '',
        failedAttempts: 0,
        password: '',
        username: '',
    };

    constructor() {
        this.user = utils.getLocalItem('usr');
        reaction(()=> (this.user), (data)=> {
            (data) ?
                utils.setLocalItem('usr', data) :
                utils.removeLocalItem('usr');
        });
        reaction(()=> (this.isLoading), ()=> {
            loadingStore.setLoading(this.isLoading);
        });
    }

    @action isLoggedIn() {
        return utils.hasItem('usr');
    }

    @action getErrors() {
        return this.errors;
    }

    @action setUsername(username) {
        this.authentication.username = username;
    }

    @action setEmail(email) {
        this.authentication.email = email;
    }

    @action setPassword(password) {
        this.authentication.password = password;
    }

    @action setAll(values) {
        this.authentication = values;
    }

    @action resetAuth() {
        this.setAll({
            email: '',
            failedAttempts: 0,
            password: '',
            username: '',
        });
    }

    /**
     * Attempts to perform a login with the details provided.
     * @param {object} details - The login-details to post to the backend. 
     * @returns {Promise<boolean>}
     */
    @action async login(details) {
        this.setIsLoading(true);
        this.isLoading = true;
        this.errors = null;

        const identifier = ((details.email && details.email.length) ? details.email : details.username);
        const password = details.password;

        if (!identifier || !password) {
            this.errors = true;
            return;
        }

        try {
            const data = { identifier, password };
            const response = await agentAJAX.Auth.login(data);
            if (response && response.user && !response.user.blocked && response.jwt) {
                await commonStore.setToken(response.jwt);
                await this.getUser();
                return true;
            }
            return false;
        } catch(err) {
            this.errors = (err.response && err.response.body && err.response.body.errors);
            throw err;
        } finally {
            this.setIsLoading(false);
        }
    }

    /**
     * Builds the local user-object if possible.
     * @param {object} user - The data of the user.
     */
     @action setUserData(user) {
        const result = {};
        if (user) {
            Object.keys(user).forEach(key => {
                result[key] = user[key];
            });
            utils.setLocalItem('usr', result);
            this.user = result;
            if (result.role) {
                this.role = result.role;
            }
        } else {
            this.user = null;
            this.role = null;
        }
    }

    /**
     * Retrieve the user from the backend.
     * @returns {Promise}
     */
    @action async fetchUser() {
        this.setIsLoading(true);
        this.setUserData(await agentAJAX.Auth.current());
        this.setIsLoading(false);
    }

    /**
     * Update the data of the user on the backend.
     * @param {object} newUser - The updated user-data.
     * @returns 
     */
    @action updateUser(newUser) {
        this.isLoading = true;
        return agentAJAX.Auth.save(newUser).then((user) => {
            this.setUserData(user);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    /**
     * Retrieves the user-data, either from cache or the backend.
     * @param {boolean?} retry - Should an attempt to fetch the backend-data be made.
     * @returns {Promise<object>}
     */
    @action async getUser(retry=false) {
        if (this.user && this.user._id) {
            return toJS(this.user);
        }
        if (!retry) {
            await this.fetchUser();
            return this.getUser(!retry);
        }
    }

    /**
     * Retrieves the user-role-data, either from cache or the backend.
     * @param {boolean?} retry - Should an attempt to fetch the backend-data be made.
     * @returns {Promise<object>} 
     */
    @action async getUserRole(retry=false) {
        if (this.role && this.role._id) {
            return toJS(this.role);
        }
        if (!retry) {
            await this.fetchUser();
            return this.getUserRole(!retry);
        }
    }

    /**
     * Discard cached user-data.
     */
    @action forgetUser() {
        utils.clearLocal();
        this.user = undefined;
        this.role = null;
    }

    /**
     * Determine if a user has an "Admin" role.
     * @param {boolean?} retry - Should an attempt to fetch the backend-data be made.
     * @returns {Promise<boolean>}
     */
    @action async isAdmin(retry=false) {
        if (this.user) {
            if (this.user.role && this.user.role.type) {
                return (this.user.role.type.toLowerCase() === 'root');
            }
            return false;
        } else {
            await this.getUser();
            return this.isAdmin(!retry);
        }
    }

    /**
     * Determine if a user has a "Manager" role.
     * @param {boolean?} retry - Should an attempt to fetch the backend-data be made.
     * @returns {Promise<boolean>}
     */
    @action async isManager(retry=false) {
        if (this.user) {
            if (this.user.role && this.user.role.type) {
                return (this.user.role.type.toLowerCase() === 'manager');
            }
            return false;
        } else {
            await this.getUser();
            return this.isManager(!retry);
        }
    }

    /**
     * Determine if a user has a "User" role.
     * @param {boolean?} retry - Should an attempt to fetch the backend-data be made.
     * @returns {Promise<boolean>}
     */
    @action async isUser(retry=false) {
        if (this.user) {
            if (this.user.role && this.user.role.type) {
                return (this.user.role.type.toLowerCase() === 'authenticated');
            }
            return false;
        } else {
            await this.getUser();
            return this.isUser(!retry);
        }
    }

    /**
     * Tries to determine the users' username.
     * @param {boolean} retry - Should an attempt be made to retry determining the users' username.
     * @returns 
     */
    @action getUsername(retry) {
        return new Promise((resolve) => {
            if (this.user && this.user.username && this.user.username.length) {
                resolve(this.user.username);
            } else if (this.user && this.user.firstName && this.user.firstName.length) {
                resolve(this.user.firstName);
            } else if (!retry) {
                this.pullUser().then(() => { resolve(this.getUsername(true)); });
            } else {
                resolve(null);
            }
        });
    }

    @action clearAll() {
        commonStore.clearStore();
        viewStore.clearStore();
        adminStore.clearStore();
        channelStore.clearStore();
        this.clearStore();
    }

    @action logout() {
        commonStore.setToken(undefined);
        this.forgetUser();
        utils.clearLocal();
        window.localStorage.clear();
        this.clearAll();
        return Promise.resolve();
    }

    /**
     * Sets whether this store is currently loading data.
     * @param {boolean} isLoading - The loading state to set.
     * @param {boolean?} force - Should present state be forcefully overwritten.
     */
    @action setIsLoading(isLoading, force=false) {
        const setIsLoading = (loading)=> {
            this.isLoading = loading;
        };

        if (!force) {
            if (this.isLoading !== isLoading) {
                setIsLoading(isLoading);
            }
        } else {
            setIsLoading(isLoading);
        }
    }

    /**
     * Restores this store to the initial-state (defaultData).
     */
    @action clearStore() {
        Object.keys(defaultData).forEach(key => {
            this[key] = defaultData[key];
        });
    }
}

// const authStore = SIOStore(AuthStore, 'ALL');
const authStore = new AuthStore();
export default authStore;