
import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import { ChannelSelect } from './ChannelSelect';
import { DateTimePicker } from './DateTimePicker';
import { InOut } from './InOut';
import { PlaybackControls } from './PlaybackControls';

import './style.scss';

export const ControlsPanel = (props)=> {
    const {
        channels, currentTime, media, selectedChannel, userTags=[], exportData={},
        onSeekBack, onSeekForward, onPlayPause, onSetPlaybackRate,
        onSelectedChannelChanged,
        onCurrentTimeChanged,
        onInClicked, onOutClicked, onClearExportRecord,
        onOpenBreakout
    } = props;

    return (
        <div className='Scrubber-Controls-Panel-Container'>
            <Grid container>
                <Grid item xs={6}>
                    <PlaybackControls
                        media={media}
                        onSeekBack={onSeekBack}
                        onSeekForward={onSeekForward}
                        onPlayPause={onPlayPause}
                        onSetPlaybackRate={onSetPlaybackRate} />
                </Grid>
                <Grid container item direction='column' xs={6}>
                    <Grid item>
                        <div className='Scrubber-Controls-Channel-Select-In-Out-Container'>
                            <ChannelSelect
                                channels={channels}
                                selectedChannel={selectedChannel}
                                onSelectedChannelChanged={onSelectedChannelChanged} />
                            <InOut
                                media={media}
                                exportRecord={exportData.record}
                                userTags={userTags}
                                onInClicked={onInClicked}
                                onOutClicked={onOutClicked}
                                onClearExport={()=> { onClearExportRecord(); }} />
                        </div>
                    </Grid>
                    <Grid item>
                        <DateTimePicker
                            currentTime={currentTime}
                            onCurrentTimeChanged={onCurrentTimeChanged}
                            onOpenBreakout={onOpenBreakout} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

ControlsPanel.propTypes = {
    channels: PropTypes.array,
    currentTime: PropTypes.object,
    media: PropTypes.object,
    selectedChannel: PropTypes.object,
    onSeekBack: PropTypes.func,
    onSeekForward: PropTypes.func,
    onPlayPause: PropTypes.func,
    onSetPlaybackRate: PropTypes.func,
    onCurrentTimeChanged: PropTypes.func,
    onInClicked: PropTypes.func,
    onOutClicked: PropTypes.func,
    onOpenBreakout: PropTypes.func,
    onSelectedChannelChanged: PropTypes.func,
};