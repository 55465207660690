
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { createUseStyles } from 'react-jss';

import { backgroundClass } from '../../index';

export const CenterDrawer = ({ background, children, isOpen })=> {
    const classes = useStyles();
    
    return (
        <div className={clsx({
            [classes.Container]: true,
            [classes.SlideInCenter]: (isOpen),
            [classes.SlideOutCenter]: (!isOpen)})}>
            <div className={clsx({
                [classes.Wrapper]: true,
                [classes.Clear]: (background===backgroundClass.CLEAR),
                [classes.Opaque]: (background===backgroundClass.OPAQUE),
                [classes.Transparent]: (background===backgroundClass.TRANSPARENT)})}>
                {children}
            </div>
        </div>
    );
};

const useStyles = createUseStyles({
    '@keyframes appearCenter': {
        from: { display: 'none', transform: 'scale(0)', opacity: '0' },
        to: { display: 'block', transform: 'scale(1)', opacity: '1' }
    },
    '@keyframes disappearCenter': {
        from: { display: 'block', transform: 'scale(1)', opacity: '1' },
        to: { display: 'none', transform: 'scale(0)', opacity: '0' }
    },
    Container: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        zIndex: '99',
    },
    Wrapper: {
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        width: '100%'
    },
    Clear: {
        background: 0
    },
    Opaque: {
        background: '#000'
    },
    Transparent: {
        background: '#00000060'
    },
    SlideInCenter: {

        animationName: '$appearCenter',
        animationDuration: '1s',
        animationTimingFunction: 'ease-out'
    },
    SlideOutCenter: {
        transform: 'scale(0)',
        
        animationName: '$disappearCenter',
        animationDuration: '1s',
        animationTimingFunction: 'ease-in'
    }
}, { name: 'CenterDrawer' });

CenterDrawer.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool,
};