
import React from 'react';

import { Paper, Typography } from '@material-ui/core';
import { Button, Input } from 'reactstrap';

import { MdSearch } from 'react-icons/md';

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.detectionIdRef = React.createRef();
        this.detectionDateTimeRef = React.createRef();

        this.handleDetectionIdChanged = this.handleDetectionIdChanged.bind(this);
        this.handleDetectionDateTimeChanged = this.handleDetectionDateTimeChanged.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleView = this.handleView.bind(this);

        this.state={
            searchChanged: false,
            previousSearch: { detectionId: null, detectionDateTime: null },
            resultList: [{
                id: 'xxx',
                name: 'abc',
                channel: '123'
            }, {
                id: 'yyy',
                name: 'def',
                channel: '456'
            }, {
                id: 'zzz',
                name: 'ghi',
                channel: '123'
            }]
        };
    }

    handleDetectionIdChanged(event) {
        const newValue = event.target.value;
        if (newValue) {
            this.setState({ searchChanged: true });
        } else {
            this.setState({ searchChanged: false });
        }
    }

    handleDetectionDateTimeChanged(event) {
        const newValue = event.target.value;
        if (newValue) {
            this.setState({ searchChanged: true });
        } else {
            this.setState({ searchChanged: false });
        }
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            console.log('Got It');
            this.handleSearch();
        }
    }

    handleSearch() {
        if (this.detectionIdRef && this.detectionIdRef.current &&
            this.detectionDateTimeRef && this.detectionDateTimeRef.current) {
            const detectionIdValue = this.detectionIdRef.current.value;
            const detectionDateTimeValue = this.detectionDateTimeRef.current.value;

            const previousSearch = { ...this.state.previousSearch };

            if (previousSearch.detectionId !== detectionIdValue) {
                // Perform Search...
                previousSearch.detectionId = detectionIdValue;
            }
            if (previousSearch.detectionDateTime !== detectionDateTimeValue) {
                // Perform Search...
                previousSearch.detectionDateTime = detectionDateTimeValue;
            }
            // TODO: Might need to merge search-results here...
            this.setState({ previousSearch, searchChanged: false });
        }
    }

    handleView(item) {
        window.location.replace(`/web/detection/player?id=${item.refID}`);
    }

    renderSearch() {
        return (
            <div className='Detection-Search-Form'>
                    <Paper className='mr-1' variant='outlined'>
                        <Input ref={this.detectionIdRef}
                            type='text'
                            placeholder='Detection ID'
                            onChange={this.handleDetectionIdChanged}
                            onKeyDown={this.handleKeyDown} />
                    </Paper>
                    <span className='ml-1 mr-1'>or</span>
                    <Paper className='ml-1 mr-1' variant='outlined'>
                        <Input ref={this.detectionDateTimeRef}
                            type='datetime-local'
                            onChange={this.handleDetectionDateTimeChanged}
                            onKeyDown={this.handleKeyDown} />
                    </Paper>
                    <Button className='ml-1'
                        disabled={!this.state.searchChanged}
                        onClick={this.handleSearch}>
                        Search
                        <MdSearch className='ml-2' size={16} />
                    </Button>
                </div>
        );
    }

    renderResult() {
        const renderResultItem = (item, index)=> (
            <li className='Detection-Search-Result-List-Item' key={index}>
                <div>
                    <span><img alt='' src={item.image} /></span>
                    <span>{item.channel}</span>
                </div>
                <div>{item.name}</div>
                <Button size='sm'
                    onClick={()=> { this.handleView(item); }}>
                    View
                </Button>
            </li>
        );
        if (this.state.resultList.length) {
            return (
                <div className='Detection-Search-Result-Container'>
                    <Paper variant='outlined'>
                        <Typography gutterBottom variant='subtitle1'>Search Results:</Typography>
                        <ul className='Detection-Search-Result-List'>
                            <li className='Detection-Search-Result-List-Item'>
                                <div>Channel</div>
                                <div>Detection</div>
                                <div style={{ width: '64px' }}/>
                            </li>
                            {this.state.resultList.map((item, index)=> (
                                renderResultItem(item, index)
                            ))}
                        </ul>
                    </Paper>
                </div>
            );
        }
    }

    render() {
        return (
            <div className='Detection-Search-Container'>
                {this.renderSearch()}
                {this.renderResult()}
            </div>
        );
    }
};

export default Search;