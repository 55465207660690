import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import googleAnalytics from './services/googleAnalytics';

import AuthLayout from './layouts/Auth/Auth';
import AdminLayout from './layouts/Admin/Admin';
import DefaultLayout from './layouts/Default/Default';

import { Loading } from '@components';

// Main Styles - Global
import './main.scss';

const hist = createBrowserHistory();

@inject('commonStore')
@observer
class App extends Component {
    render() {
        return (
            <Router history={hist}>
                <Switch>
                    <Route path='/auth' render={props => <AuthLayout {...props} />} />
                    <Route path='/web' render={props => <DefaultLayout {...props} />} />
                    <Route path='/home' render={props => <AdminLayout {...props} />} />
                    <Redirect from='/' to='/web/login' />
                </Switch>
            </Router>
        );
    }
}

export default App;