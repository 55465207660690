import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import { withStyles, IconButton } from '@material-ui/core';

import iconButtonStyle from "./iconButtonStyle";

const IconCustomButton = React.forwardRef(function IconCustomButton({ ...props }, ref) {
  const { classes, color, children, customClass, noBg, ...rest } = props;
  const buttonClasses =
    classes.button +
    cx({
      [" " + classes[color]]: color,
      [" " + customClass]: customClass
    });
  return (
    <IconButton {...rest} className={buttonClasses} ref={ref}>
      {children}
    </IconButton>
  );
});

IconCustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "white",
    "simple",
    "defaultNoBackground",
    "primaryNoBackground",
    "secondaryNoBackground",
    "infoNoBackground",
    "successNoBackground",
    "warningNoBackground",
    "dangerNoBackground",
    "roseNoBackground",
    "twitter",
    "twitterNoBackground",
    "facebook",
    "facebookNoBackground",
    "google",
    "googleNoBackground",
    "linkedin",
    "linkedinNoBackground",
    "pinterest",
    "pinterestNoBackground",
    "youtube",
    "youtubeNoBackground",
    "tumblr",
    "tumblrNoBackground",
    "github",
    "githubNoBackground",
    "behance",
    "behanceNoBackground",
    "dribbble",
    "dribbbleNoBackground",
    "reddit",
    "redditNoBackground"
  ]),
  customClass: PropTypes.string,
  disabled: PropTypes.bool
};

export default withStyles(iconButtonStyle)(IconCustomButton);
