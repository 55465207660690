import React from 'react';
import PropTypes from 'prop-types';

// Components & Icons
import { Info, Warning, Check, Error  } from '@material-ui/icons';

function icon(name) {
	switch(name){
		case 'info': return <Info/>
		case 'success': return <Check/>
		case 'warning': return <Warning/>
		case 'danger': return <Error/>
		default: return <Info/>
	}
}

class Alert extends React.Component{

	render() {
		const aType = this.props.type || 'danger',
			css = "alert alert-" + aType
		return (
			<div className={css} role="alert">
				{this.props.title ? icon(aType) : null}
				<strong>{this.props.title}</strong>
				<p>{this.props.message}</p>
			</div>
		)
	}
}

Alert.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string.isRequired,
	type: PropTypes.oneOf([
		'info', 	// - blue
		'success', 	// - green
		'warning', 	// - yellow
		'danger' 	// - red
	])
};

export default Alert;
