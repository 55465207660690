
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const Captcha = (props)=> {
    const classes = useStyles();
    const { text, height, width } = props;

    const [canvas, setCanvas] = useState(null);

    useEffect(()=> {
        if (canvas) {
            const context = canvas.getContext('2d');
            context.font = '24px Verdana';
            context.fillText(text, 16, 48);
        }
    }, [text, canvas]);

    return (
        <div className={classes.container}>
            <canvas
                ref={(element)=> { setCanvas(element); }}
                height={height}
                width={width}>
            </canvas>
        </div>
    );
};

export default Captcha;