
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';

import { observable, action } from 'mobx';

import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import prepareModel from './prepareModel';

import channelStore from './channelStore';
import loadingStore from './loadingStore';
import playerStore from './playerStore';
import scrubberStore from './scrubberStore';
import authStore from './authStore';
import commonStore from './commonStore';

import config, { isDebug } from '../constants/config';
const API_ROOT = `${config.serverURL}${config.apiRoute}`;

const fullTimestampFormat = 'YYYY-MM-DD HH:mm:ss';
const sanitizedTimestampFormat = 'YYYY-MM-DDTHH:mm:ss';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(timezone);

/**
 * The following will probably be made into a dynamic value in the future.
 * The idea is to divide the value by 2 and use one half before the timestamp and the other after.
 * This is used for video-feed, events, etc.
 */
const contentDuration = 30;

const defaultData = {
    channels: null,
    channelSkip: 0,
    currentTime: null,
    epgData: null,
    events: null,
    exportRecord: null,
    latestEPGSet: null,
    previousDate: null,
    recordsToFetch: 0,
    refreshSource: false,
    resetTimestamps: true,
    selectedChannel: null,
    selectedDate: null,
    selectedProgram: null,
    updateSourceInterval: null,
};

class EPGStore {
    @observable channelSkip = 0;
    @observable currentTime = null;
    @observable epgData = null;
    @observable epgCache = {};
    @observable viewAll = false;
    @observable epgStart = null;
    @observable epgEnd = null;
    @observable events = [];
    @observable exportRecord = null;
    @observable latestEPGSet = null;
    @observable previousDate = null;
    @observable recordsToFetch = 0;
    @observable refreshSource = false;
    @observable resetTimestamps = true;
    @observable selectedChannel = null;
    @observable selectedDate = null;
    @observable selectedProgram = null;
    @observable updateSourceInterval = null;

    @action startTimer(){
        let that = this;
        setInterval(()=> {  // Update 'now' every 10 seconds...
            // that.setCurrentTime(dayjs());
            that.currentTime = dayjs();
        }, 10000);
    }

    /**
     * Will trigger various refresh-functions, which will manage the store-state.
     * @param {Boolean} refreshPlayer - Indicates whether the PlayerStore should be reset.
     */
    refreshStoreData(refreshPlayer) {
        this.refreshEventData();
        this.refreshExportData();
        this.updatePlayerSource(refreshPlayer);
        
        this.setResetTimestamps(true);
    }

    /**
     * Refreshes the EPG-data based on the selected timestamp.
     * @returns {Promise}
     */
    async refreshEPGData() {
        if (this.selectedDate) {
            // Clear EPG Cache
            this.epgCache = {};

            await this.getEPGData(true);
            return this.epgCache;
        }
    }

    /**
     * Refreshes the event-data based on the selected channel and the set timestamp.
     * @returns {Promise}
     */
    refreshEventData() {
        return new Promise((res, rej)=> {
            if (this.selectedChannel && this.selectedDate) {
                const channelId = this.selectedChannel.id;
                const startDateTime =  this.selectedDate.subtract((contentDuration/2), 'minute');
                const endDateTime = this.selectedDate.add((contentDuration/2), 'minute');

                this.getChannelEvents(true, channelId, startDateTime.format(sanitizedTimestampFormat), endDateTime.format(sanitizedTimestampFormat)).then((events)=> {
                    this.events = events;
                    res(this.events);
                }).catch((err)=> rej(err));
            }
        });
    }

    /**
     * Refreshes the export-data based on the selected channel and the set timestamp.
     * @returns {Promise}
     */
    refreshExportData(noload=true) {
        return new Promise((res, rej)=> {
            if (this.selectedChannel && this.selectedDate) {
                const channelId = this.selectedChannel.id;
                const startDateTime =  this.selectedDate.subtract(((contentDuration/2)*60), 'second');
                const endDateTime = this.selectedDate.add(((contentDuration/2)*60), 'second');

                this.getChannelExports(noload, channelId, startDateTime.format(sanitizedTimestampFormat), endDateTime.format(sanitizedTimestampFormat)).then((exports)=> {
                    this.exports = exports;
                    res(this.exports);
                }).catch((err)=> rej(err));
            }
        });
    }
    
    updatePlayerSource(refreshPlayer=true) {
        if (this.selectedChannel && this.selectedDate) {
            const channelId = this.selectedChannel._id;
            console.log(`Selected Date Store: ${this.selectedDate.format('YYYY-MM-DD HH:mm:ss')}`);

            const startDateTime = this.selectedDate.subtract(((contentDuration/2)*60), 'second');
            const endDateTime = this.selectedDate.add(((contentDuration/2)*60), 'second');

            const source = `${API_ROOT}/transcoders/playlist/${channelId}/${startDateTime.unix()}/${endDateTime.unix()}/source.m3u8`;
            //const source = `https://mhdev-prd-rpl1.adlytics.tv/vod/${channelId}/source.m3u8`;
            //playerStore.setSource(source, refreshPlayer);
            //scrubberStore.setSource(source);
        }
    }

    /**
     * Retrieves the channels from ChannelStore.
     * @param {boolean?} refresh - Should data be fetched from the DB.
     * @param {boolean?} noload - Display a Loading Overlay.
     * @returns {Promise<Array<object>>}
     */
    @action async getChannels(refresh=false, noload=false) {
        return await channelStore.getChannels(refresh, noload);
    }

    fixDateTime(dateTime){
        // 2021-01-30T00:00:00
        let d = new Date(dateTime);
        let y = d.getFullYear();
        let m = d.getMonth()+1;
        let dy = d.getDate();

        let H = d.getHours();
        let M = d.getMinutes();
        let S = d.getSeconds();

        H = (H < 10)?`0${H}`:`${H}`;
        M = (M < 10)?`0${M}`:`${M}`;
        S = (S < 10)?`0${S}`:`${S}`;

        let ret = `${y}-${(m < 10)?`0${m}`:`${m}`}-${(dy < 10)?`0${dy}`:`${dy}`}T${H}:${M}:${S}`;
        // console.log('[fixDateTime] : ', ret);
        return ret;
    }

    fixDateTime_StartEnd(dateTime, start = true){
        // 2021-01-30T00:00:00
        let d = new Date(dateTime);
        let y = d.getFullYear();
        let m = d.getMonth()+1;
        let dy = d.getDate();

        let ret = '';
        if(start){
            ret = `${y}-${(m < 10)?`0${m}`:`${m}`}-${(dy < 10)?`0${dy}`:`${dy}`}T00:00:00`;
        } else {
            ret = `${y}-${(m < 10)?`0${m}`:`${m}`}-${(dy < 10)?`0${dy}`:`${dy}`}T23:59:59`;
        }
        // console.log('[fixDateTime] : ', ret);
        return ret;
    }


    // restructured for new EPG and backwords compatable with the old UI
    @action getEPGDataBetween(startDateTime, endDateTime) {
        return new Promise((res, rej)=> {
            console.log('[getEPGDataBetween] : ', this.fixDateTime(startDateTime), this.fixDateTime(endDateTime));
            
            agentAJAX.Epg.getByTimestamps(false, this.fixDateTime(startDateTime), this.fixDateTime(endDateTime)).then((epgData)=> {
                const result = {};

                this.timestamps.forEach((timestamp)=> {

                    let records = epgData.filter(f => {
                        let checkStart = (new Date(f.start_datetime).getTime() >= new Date(timestamp.start).getTime());
                        let checkEnd = (new Date(f.start_datetime).getTime() <= new Date(timestamp.end).getTime());

                        return checkStart && checkEnd;
                    });
                    records.forEach((record)=> {
                        const start = dayjs(record.start_datetime);
                        const end = dayjs(record.end_datetime);

                        result[timestamp.key] = (result[timestamp.key]) ? result[timestamp.key] : { key: timestamp.key, value: [] };
                        result[timestamp.key].value.push({ ...record, start, end });
                    });
                });
                res(result);
            }).catch((err)=> rej(err));
        });
    }    

    @action getEPGDataByTimestamp(timestamp) {
        return new Promise((res, rej)=> {
            const result = {};
            result[timestamp.key] = { key: timestamp.key };

            console.log('[getEPGDataByTimestamp] : ', this.fixDateTime(timestamp.start), this.fixDateTime(timestamp.end));
            agentAJAX.Epg.getByTimestamps(true, this.fixDateTime(timestamp.start), this.fixDateTime(timestamp.end)).then((epgData)=> {
                result[timestamp.key].value = epgData.map((record)=> ({ ...record, start: dayjs(record.start_datetime), end: dayjs(record.end_datetime) }));
                Object.keys(result).forEach(key => {
                    this.epgData[key] = result[key];
                });

                res(result);
            }).catch((err)=> rej(err));
            
        });
    }

    @action getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    }

    /**
     * Builds the EPG-Cache data
     * @param {boolean?} refresh - Should new data be retrieved
     * @returns {Promise}
     */
    @action getEPGData(refresh=false) {
        // One day
        let startDateTime = new Date(this.fixDateTime_StartEnd(this.selectedDate, true));
        let endDateTime = new Date(this.fixDateTime_StartEnd(this.selectedDate, false));

        console.log('[getEPGData] ', startDateTime, ' | ', endDateTime);

        let from = startDateTime.getTime();
        let to = endDateTime.getTime();

        if(this.epgStart && this.epgEnd){
            if((this.epgStart !== from) &&  (this.epgEnd !== to)) { // Always start and end of day ;)
                this.epgStart = from;
                this.epgEnd = to;
            } else if(this.epgCache && Object.keys(this.epgCache).length > 0) {
                return this.epgCache; // Return cached data
            }
        } else {
            this.epgStart = from;
            this.epgEnd = to;
        }

        return new Promise(async (res, rej)=> {
            let channels = channelStore.channels;
            if(!channelStore.channels || channelStore.channels.length < 1 || refresh){
                // Make sure channels are loaded
                channels = await this.getChannels(true, false);
            }

            let promiseArr = [];
            channels.forEach(chn => {
                if(chn && chn.epg_id){
                    let _promise = agentAJAX.Epg.getAllByEPGId(true, chn.epg_id, this.fixDateTime(startDateTime), this.fixDateTime(endDateTime))
                    .then((epgData)=> {
                        epgData = epgData.slice().sort((a, b) => (new Date(a.start_datetime) > new Date(b.start_datetime)) ? 1 : -1);
                        this.epgCache[chn.epg_id] = epgData;
                        this.latestEPGSet = new Date().getTime();
                    })
                    .catch((err)=> rej(err))
                    promiseArr.push(_promise);
                }
            });
            Promise.all(promiseArr).then((result)=> {
                this.epgData = result;
                setTimeout(()=> {
                    res(true);
                }, 200);
            });
        });
    }

    @action getChannelEvents(noload=false, channelId, start, end) {
        return new Promise((res, rej)=> {
            if (channelId && start && end) {                                                        // These parameters are needed.
                const fetchEventsData = async (noload, channelId, start)=> {
                    console.log('[Fetching Channel Events]');
                    
                    return await agentAJAX.Events.getByChannel(noload, channelId, start);
                    // TODO: return await agentAJAX.Events.getByChannelUTC(noload, channelId, start);
                };
                fetchEventsData(noload, channelId, start).then((events)=> {
                    const result = events.filter((event)=> (
                        
                        //TODO: (dayjs.tz(event.detect_timeUTC).isBetween(dayjs(start), dayjs(end), null, '[]'))
                        (dayjs.tz(event.detect_timelocal).isBetween(dayjs(start), dayjs(end), null, '[]'))
                    ));
                    res(result);
                }).catch((err)=> rej(err));
            } else {
                res([]);
            }
        });
    }

    @action getChannelExports(noload=false, channelId, start, end) {
        return new Promise((res, rej)=> {
            if (channelId && start && end) {                                                        // These parameters are needed.
                const fetchExportsData = async (noload, channelId)=> {
                    console.log('[Fetching Channel Exports]');
                    return await agentAJAX.Exports.getByChannel(noload, channelId);
                };
                fetchExportsData(noload, channelId).then((exports)=> {
                    const result = exports.filter((entry)=> (
                        (dayjs(entry.start_time*1000).isBetween(dayjs(start), dayjs(end))) ||
                        (dayjs(entry.end_time*1000).isBetween(dayjs(start), dayjs(end)))
                    )).reverse();
                    res(result);
                }).catch((err)=> rej(err));
            }
        });
    }

    /**
     * Creates a new Export record without persisting it.
     * @param {boolean} withUser - Should a user be attached to this export.
     * @returns Promise<object>
     */
    @action async addExport(withUser=true) {
        try {
            this.exportRecord = {
                start_time: this.selectedDate,
                end_time: '',
                comments: '',
                channel: this.selectedChannel
            };
            if (withUser) {
                const user = authStore.getUser();
                this.exportRecord.user = user;
            }
            return this.exportRecord;
        } catch(err) {
            console.error('addExport', err);
            commonStore.addErrorMsg(err);
        }
    }

    @action updateExport() {
        const updateExportRecord = ()=> {
            const startTime = dayjs(this.exportRecord.start_time);
            const endTime = dayjs(this.selectedDate);
            endTime.set('year', startTime.get('year'));
            endTime.set('month', startTime.get('month'));
            endTime.set('date', startTime.get('date'));

            this.exportRecord.end_time = endTime;
        };
        
        updateExportRecord();
    }

    @action removeExport() {
        this.exportRecord = null;
    }

    @action async saveExport() {
        console.log(this.exportRecord);
        const data = prepareModel.prepareExport(this.exportRecord, channelStore.channels);
        try {
            const response = await agentAJAX.Exports.add(data);
            if (response.message) {
                throw new Error(response.message);
            }
            this.exports = this.exports.slice().push(response);
            await this.refreshExportData();
            return this.exports;
        } catch(err) {
            console.error(err);
        }
    }

    @action async downloadExport(record) {
        if (this.selectedChannel) {
            console.log(JSON.parse(JSON.stringify(record)));
            const channelName = this.selectedChannel.name;
            const startTime = dayjs(record.start_time*1000).format('HH-mm-ss');
            const endTime = dayjs(record.end_time*1000).format('HH-mm-ss');
            const date = dayjs(record.start_time*1000).format('DD-MM-YYYY');

            let endpoint = `${config.serverURL}/${record.url}`;
            if (isDebug) {
                endpoint = `${config.contUrl}/${record.url}`;
            }
            console.log(`Download URL: ${endpoint}`);

            window.URL = window.URL || window.webkitURL;
            
            loadingStore.setLoadingForce(true);
            const xhr = new XMLHttpRequest();
            const link = document.createElement('a');
            xhr.open('GET', endpoint, true);
            xhr.responseType = 'blob';
            xhr.onload = function () {
                const file = new Blob([xhr.response], { type : 'application/octet-stream' });
                link.href = window.URL.createObjectURL(file);
                link.download = (`${channelName}_${date}_${startTime}_${endTime}.mp4`).toLocaleLowerCase().replace(/ /g, '_');
                link.click();
                loadingStore.setLoadingForce(false);
            };
            xhr.send();
        }
    }

    @action setCurrentTime(newTime) {
        if (newTime) {
            this.currentTime = newTime;
        }
    }

    @action setResetTimestamps(resetTimestamps) {
        if (this.resetTimestamps !== resetTimestamps) {
            this.resetTimestamps = resetTimestamps;
        }
    }

    @action setSelectedChannel(newChannel, refreshRelated=true, refreshPlayer) {
        if (newChannel) {
            this.selectedChannel = newChannel;

            if (refreshRelated) {
                this.refreshStoreData(refreshPlayer);
            }
        }
    }

    // UTC FIX
    @action _setSelectedDate(newDate){
        if(!newDate){
            newDate = new Date();
        }
        let tzOff = new Date().getTimezoneOffset(); // Min
        let _newDate = dayjs(newDate).add(tzOff, 'minute');
        this.selectedDate = _newDate;
    }

    @action getUTCDT(newDate){
        if(!newDate){
            newDate = new Date();
        }
        let tzOff = new Date().getTimezoneOffset(); // Min
        let _newDate = dayjs(newDate).add(tzOff, 'minute');
        return dayjs(_newDate);
    }

    @action setSelectedDate(newDate, refreshRelated=true, refreshPlayer) {
        if (newDate) {
            this._setSelectedDate(newDate);

            if (refreshRelated) {
                this.refreshStoreData(refreshPlayer);
            }
        }
    }

    @action async setSelectedDateUTC(newDate, refreshRelated=true, refreshPlayer) {
        if (newDate) {
            this.selectedDate = dayjs(newDate);
            // console.log('[setSelectedDateUTC]', this.selectedDate);

            if (refreshRelated) {
                this.refreshStoreData(refreshPlayer);
                await this.getEPGData(true);
            }
        }
    }

    // Wait for new data Load
    @action async setDate(newDate) {
        // let _newDate = this.getUTCDT(newDate);
        if(!newDate){
            newDate = dayjs();
        }
        if(this.selectedDate){
            let c = new Date(this.selectedDate).getTime();
            let n = new Date(newDate).getTime();
            if(c !== n){
                this._setSelectedDate(newDate);
                // this.selectedDate = newDate;
                
                // this.epgCache = {};
                this.getEPGData();
            }
        } else {
            this._setSelectedDate(newDate);
            this.getEPGData();
        }
    }

    @action setViewAll(val){
        this.viewAll = val;
    }

    @action clearStore() {
        Object.keys(defaultData).forEach(key => {
            this[key] = defaultData[key];
        });
    }
}

// const epgStore = SIOStore(EPGStore, 'ALL');
const epgStore = new EPGStore();
export default epgStore;