
import { observable, action, toJS } from 'mobx';
import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import DataStore from './base';

import channelStore from './channelStore';
import commonStore from './commonStore';
import loadingStore from './loadingStore';
import prepareModel from './prepareModel';
import authStore from './authStore';

// Data Entity
import { EventTagEntity, ExportEntity, TagEntity, UserEntity } from '../entities';

import dayjs from 'dayjs';

// eslint-disable-next-line no-extend-native
String.prototype.fakeReplace = function(str, newstr) {
    return this.split(str).join(newstr);
};

const defaultData = {
    originalData: {},
    eventTags: null,
    exports: null,
    roles: null,
    tags: null,
    users: null,

    loadingState: {
        eventTagsLoading: false,
        exportsLoading: false,
        tagsLoading: false,
    }
};

class AdminStore extends DataStore {

    @observable originalData = {};

    @observable eventTags = null;
    @observable exports = null;
    @observable roles = null;
    @observable tags = null;
    @observable users = null;

    @observable loadingState = {
        eventTagsLoading: false,
        exportsLoading: false,
        tagsLoading: false
    }

    wait = 1000;

    @action load() {
        // return new Promise((res,rej)=>{
        //   res(this.loadInitialData());
        // });
    }

    @action setLoadingData(prop) {
        this[prop] = true;
        let _this = this;
        setTimeout(() => {
            // Stop loading
            _this[prop] = false;
        }, 30 * 1000);
    }

    @action originalDelete(type, cId) {
        if (this.originalData && this.originalData[type]) {
            //Remove local Original
            let _newArr = this.originalData[type].slice();
            _newArr = _newArr.filter(f => f._id !== cId);
            this.originalData[type] = _newArr;
        }
    }

    @action loadInitialData() {
        return new Promise((res, rej) => { res(); });
    }

    //########################### Roles ###########################
    @action getUserRoles() {
        return new Promise(async (resolve)=> {
            const response = await agentAJAX.Roles.getAll();
            resolve(response);
        });
    }

    //########################### Users ###########################

    @action getUserEntity() {
        return new Promise(async (resolve)=> {
            const entity = UserEntity;
            try {
                if (entity && entity.fields) {
                    let targetField = entity.fields.find((field) => (field.id === 'channelgroups'));
                    if (targetField && targetField.list) {
                        const channelGroups = await channelStore.getChannelGroups(true);
                        targetField.list = channelGroups.map((channelGroup) => ({ id: channelGroup._id, text: channelGroup.name }));
                    }

                    targetField = entity.fields.find((field) => (field.id === 'role'));
                    if (targetField && targetField.list) {
                        const userRoles = await this.getUserRoles();
                        targetField.list = userRoles.roles.map((role)=> ({ id: role._id, text: role.name }));
                    }
                }
            } catch(err) {
                console.log(err);
            } finally {
                resolve(entity);
            }
        });
    }

    /**
     * Create or Update a Channel.
     * @param {object} newUser - The data to persist to the DB.
     * @returns {Promise<object>}
     */
    @action async addUpdateUser(newUser) {
        const data = JSON.parse(JSON.stringify(newUser)); // Clone Data...

        this.setIsLoading(true);

        // Test User Password
        if (data && data.password && data.password.length > 20) {
            delete data.password; // No Changes...
        } 
        
        const preparedUser = prepareModel.prepareUser(data);
        console.log('[SAVE]', preparedUser);

        let response = null;
        try {
            if (data && data._id) { // Edit
                response = await agentAJAX.Users.edit(data._id, preparedUser);
                if (response && response._id) {
                    console.log('[EDIT USER]', response);
                }
            } else {    // Create
                if (!preparedUser.password || !preparedUser.password.length) {
                    preparedUser.password = '1qaz2wsx'; // Temporary Password
                }
                
                const { username, email, password, confirmed } = preparedUser;
                const registerResponse = await agentAJAX.Auth.register(username, email, password, (confirmed || false));
                if (registerResponse && registerResponse.user && registerResponse.user._id) {
                    const { firstName, lastName, channelgroups } = data;
                    const user = { ...registerResponse.user, firstName, lastName, channelgroups };

                    response = await agentAJAX.Users.edit(user._id, user);
                    if (response && response._id) {
                        console.log('[CREATE USER]', response);
                    }
                }
            }
        } catch(err) {
            console.error('[addUpdateUser]', err);
            commonStore.addErrorMsg(err);
        } finally {
            await this.getUsers(true, true);    // Refresh Users from Server.
            this.setIsLoading(false);
            return response;
        }
    }

    @action async addUpdateUserDep(data) {
        let user = prepareModel.prepareUser(toJS(data)); // To JS Object
        return new Promise(async (res, rej) => {

            // Check Password
            if (user && user.password && user.password.length > 20) {
                delete user.password; // Hasn't updated so remove.
            }

            // Get Role
            if (user && user._id) {
                let _user = user;
                // Edit
                agentAJAX.Users.edit(user._id, _user)
                    .then((res) => {
                        if (res) {
                            this.users.forEach((_user, idx) => {
                                if (_user._id === user._id) {
                                    this.users[idx] = user;
                                }
                            });
                        } else {
                            commonStore.addErrorMsg('No response recieved');
                        }
                    }).catch((err) => {
                        console.error('[addUpdateUser]', err);
                        commonStore.addErrorMsg(err);
                    }).finally(() => {
                        //Get updated
                        res(this.getUsers(true));
                    });
            } else {

                if (user && (!user.password || user.password.length <= 0)) {
                    // no pass
                    user.password = '1qaz2wsx';
                }

                // first Register
                let newUserRet = await agentAJAX.Auth.register(user.username, user.email, user.password, user.confirmed || false);
                if (newUserRet && newUserRet.user && newUserRet.user._id) {
                    let _user = newUserRet.user;

                    _user.firstName = user.firstName;
                    _user.lastName = user.lastName;
                    _user.channelgroups = user.channelgroups;

                    // Update
                    agentAJAX.Users.edit(_user._id, _user)
                        .then((ret) => {
                            if (ret && ret._id) {
                                user._id = ret._id;
                                this.users.push(user);
                            }
                        }).catch((err) => {
                            console.error('[addUpdateUser]', err);
                            commonStore.addErrorMsg(err);
                        }).finally(() => {
                            //Get updated
                            res(this.getUsers(true));
                        });
                } else {
                    console.error('[addUpdateUser]', newUserRet);
                    commonStore.addErrorMsg('User Registration failed');
                }
            }
        });
    }

    @action deleteUser(usrId) {
        let newUsersArr = this.users.slice();
        newUsersArr = newUsersArr.filter(f => f._id !== usrId);
        this.users = newUsersArr;

        return new Promise((res, rej) => {
            //Delete
            agentAJAX.Users.delete(usrId)
                .then((ret) => {
                    if (ret && ret.responseCode !== 0 && ret.responseMessage) {
                        commonStore.addErrorMsg(ret.responseMessage);
                    }
                    res(ret);
                }).catch((err) => {
                    console.error('[deleteUser]', err);
                    commonStore.addErrorMsg(err);
                    rej(err);
                });
        });
    }

    @action deleteUserMany(ids) {
        return new Promise((res, rej) => {
            let delPromiseArr = [];
            ids.forEach(id => {
                delPromiseArr.push(this.deleteUser(id));
            });

            Promise.all(delPromiseArr).then(() => {
                res('Done');
            }).catch((err) => {
                rej(err);
            });
        });
    }

    @action getUsers(refresh, noload=false) {
        return new Promise((resolve, reject)=> {
            try {
                if (!refresh && this.users) {
                    resolve(this.users);
                } else if (!this.usersLoading) {
                    this.usersLoading = true;
                    agentAJAX.Users.getAll(noload).then((response)=> {
                        console.log('[REFRESH] : getUsers');
                        const users = [];
                        if (response) {
                            response.forEach((user)=> {
                                user.avatar = `${user.firstName} ${user.lastName}`;
                                user.role = user.role._id;
                                users.push(user);
                            });
                        }
                        this.users = users;
                        this.usersLoading = false;
                        resolve(this.users);
                    }).catch((err)=> { throw err });
                } else {
                    console.log('[REFRESH] : getUsers waiting');
                    setTimeout(()=> {
                        resolve(this.getUsers(false));
                    }, this.wait);
                }
            } catch(err) {
                console.log(err);
            } finally {
                loadingStore.setLoadingForce(false);
            }
        });
    }

    // ########################### Tags ###########################

    @action getTagEntity() {
        return new Promise(async (resolve)=> {
            const entity = TagEntity;
            try {
                if (entity && entity.fields) {
                    let targetField = entity.fields.find((field) => (field.id === 'channelgroups'));
                    if (targetField && targetField.list) {
                        const channelGroups = await channelStore.getChannelGroups(true);
                        targetField.list = channelGroups.map((channelGroup) => ({ id: channelGroup._id, text: channelGroup.name }));
                    }
                }
            } catch(err) {
                console.log(err);
            } finally {
                resolve(entity);
            }
        });
    }

    @action addUpdateTag(data) {
        let tag = prepareModel.prepareTag(toJS(data)); // To JS Object
        return new Promise((res) => {
            if (tag && tag._id) {
                // Edit
                agentAJAX.Tags.edit(tag._id, tag).then((res) => {
                    if (res) {
                        this.tags.forEach((_tag, idx) => {
                            if (_tag._id === tag._id) {
                                this.tags[idx] = tag;
                            }
                        });
                    } else {
                        commonStore.addErrorMsg('No response recieved');
                    }
                }).catch((err) => {
                    console.error('[addUpdateTag]', err);
                    commonStore.addErrorMsg(err);
                }).finally(() => {
                    //Get updated
                    res(this.getTags(true));
                });
            } else {
                // Insert
                agentAJAX.Tags.add(tag).then((ret) => {
                    if (ret && ret._id) {
                        tag._id = ret._id;
                        this.tags.push(tag);
                    }
                }).catch((err) => {
                    console.error('[addUpdateTag]', err);
                    commonStore.addErrorMsg(err);
                }).finally(() => {
                    //Get updated
                    res(this.getTags(true));
                });
            }
        });
    }

    @action deleteTag(tagId) {
        let newTagsArr = this.tags.slice();
        newTagsArr = newTagsArr.filter(f => f._id !== tagId);
        this.tags = newTagsArr;

        this.originalDelete('tags', tagId);

        return new Promise((res, rej) => {
            //Delete
            agentAJAX.Tags.delete(tagId)
                .then((ret) => {
                    if (ret && ret.responseCode !== 0 && ret.responseMessage) {
                        commonStore.addErrorMsg(ret.responseMessage);
                    }
                    res(ret);
                }).catch((err) => {
                    console.error('[deleteTag]', err);
                    commonStore.addErrorMsg(err);
                    rej(err);
                });
        });
    }

    @action deleteTagsMany(ids) {
        return new Promise((res, rej) => {
            let delPromiseArr = [];
            ids.forEach(id => {
                delPromiseArr.push(this.deleteTag(id));
            });

            Promise.all(delPromiseArr).then(() => {
                res('Done');
            }).catch((err) => {
                rej(err);
            });
        });
    }

    @action getTags(refresh, noload=false) {
        return new Promise(async (resolve, reject)=> {
            if (this.tags && !refresh) {
                resolve(this.tags);
            }
            if (!this.loadingState.tagsLoading) {
                const fetchTags = async ()=> {
                    let result = [];
                    const isAdmin = await authStore.isAdmin();
                    if (isAdmin) {  // Get ALL Tags
                        result = await agentAJAX.Tags.getAll(noload);
                    } else { // TODO: Fetch Group Tags
                        const userId = (authStore.user._id || authStore.user.id);
                        const channelGroups = await agentAJAX.ChannelGroups.getByUserId(userId);
                        const allTags = await agentAJAX.Tags.getAll(noload);
                        for (const tag of allTags) {
                            for (const group of channelGroups) {
                                if (tag.channelgroups.includes(group._id)) {
                                    result.push(tag);
                                }
                            }
                        }
                    }
                    return result;
                };
                console.log('[REFRESH] : getTags');
                this.setLoadingState('tagsLoading', true);
                const result = await fetchTags();
                this.tags = ((result) ? result : []);
                this.setLoadingState('tagsLoading', false);
                this.clearLoading();
                resolve(this.tags); 
            } else {
                setTimeout(()=> {
                    resolve(this.getTags(false, true));
                }, this.wait);
            }
        });
    }

    @action getTagsNames() {
        return new Promise((res) => {
            if (this.tags) {
                let retTags = toJS(this.tags).map(t => t.name);
                res(retTags);
            } else {
                // Populate Tags if it's empty
                this.getTags().then(() => res(this.getTagsNames()));
            }
        });
    }

    @action getTagName(id, stop = false) {
        let _this = this;
        return new Promise((res) => {
            if (this.tags) {
                let _name = toJS(this.tags).filter(f => f._id === id);
                if (_name && _name[0]) {
                    res(_name[0]);
                } else if (!stop) {
                    res(this.getTags(true).then(() => _this.getTagName(id, true))); // Try to get all
                }
            } else {
                // Populate Tags if it's empty
                res(this.getTags().then(() => res(_this.getTagName(id))));
            }

            res(null);
        });
    }

    /**
     * Gets a String tag by name or insert a new one
     */
    @action getTagByName(name) {
        return new Promise((res) => {
            if (this.tags) {
                let retTag = toJS(this.tags).filter(f => f.name === name);
                if (retTag && retTag[0]) {
                    res(retTag[0]);
                } else {
                    // Tag not found - Insert New
                    let newTagData = {
                        name: name,
                        description: 'Description about ' + name,
                        color: '#ffffff'
                    }
                    this.addUpdateTag(newTagData).then((ret) => {
                        // Refresh
                        this.getTags(true).then(() => {
                            res(this.getTagByName(name));
                        });
                    });
                }
            } else {
                this.getTags().then(() => res(this.getTagByName(name)));
            }
        });
    }

    @action isTagMain(name) {
        return new Promise((res) => {
            if (this.tags) {
                let retTag = toJS(this.tags).filter(f => f.name === name);
                if (retTag && retTag[0]) {
                    res(retTag[0].isMain);
                } else {
                    res(false);
                }
            } else {
                this.getTags().then(() => res(this.isTagMain(name)));
            }
        });
    }

    // ########################### Exports ###########################

    @action getExportEntity() {
        const entity = ExportEntity;
        if (entity && entity.fields) {
            return entity;
        }
    }

    @action async addUpdateExport(data) {
        const record = prepareModel.prepareTag(toJS(data));
        try {
            if (record && record._id) { // Existing Record.
                const response = await agentAJAX.Exports.edit(record._id, record);
                if (response) {
                    this.exports.forEach((item, index)=> {
                        if (item._id === record._id) {
                            this.exports[index] = record;
                        }
                    });
                } else {
                    throw new Error('No response recieved');
                }
            } else { // Create Record.
                const response = await agentAJAX.add(record);
                if (response && response._id) {
                    record._id = response._id;
                    this.exports.push(record);
                } else {
                    throw new Error('No response recieved');
                }
            }

        } catch(err) {
            console.error('[addUpdateExport]', err);
            commonStore.addErrorMsg(err);
        } finally {
            return await this.getExports(true);
        }
    }

    @action async deleteExport(exportId) {
        this.exports = this.exports.slice().filter((item)=> (item._id !== exportId));
        this.originalDelete('exports', exportId);

        try {
            const response = await agentAJAX.Exports.delete(exportId);
            if (response && response.responseCode !== 0 && response.responseMessage) {  // Something went wrong.
                commonStore.addErrorMsg(response.responseMessage);
            }
            return true;
        } catch(err) {
            console.error('[deleteExport]', err);
            commonStore.addErrorMsg(err);
            return false;
        }
    }

    @action async deleteExportsMany(ids) {
        let success = true;
        try {
            for (const id of ids) {
                success = await this.deleteExport(id);
            }
        } catch(err) {
            console.error('[deleteExportsMany]', err);
            commonStore.addErrorMsg(err);
        } finally {
            return success;
        }
    }

    @action getExports(refresh, noload=false) {
        return new Promise(async (resolve)=> {
            if (this.exports && !refresh) {
                resolve(this.exports);
            }
            if (!this.loadingState.exportsLoading) {
                console.log('[REFRESH] : getExports');
                const fetchExports = async ()=> {
                    let result = [];
                    try {
                        const isAdmin = await authStore.isAdmin();
                        const userId = (authStore.user._id || authStore.user.id);
                        if (isAdmin) {  // Get ALL Exports
                            result = await agentAJAX.Exports.getAll(noload);
                        } else {    // Get User-Specific Exports
                            result = await agentAJAX.Exports.getByUserId(userId);
                        }
                    } catch(err) {
                        result = [];
                    }
                    return result;
                };
                this.setLoadingState('exportsLoading', true);
                const result = await fetchExports();
                const channels = await channelStore.getChannels();
                result.forEach((record)=> {
                    const channel = ((record.channelName) ?
                        record.channelName :
                        ((record.channel_name) ?
                            record.channel_name :
                            ((record.channel) ?
                                channels.find((channel)=> (channel.id === record.channel)).name :
                                'N/A'))
                    );
                    let date;
                    if (record.start_time) {
                        date = dayjs(new Date(record.start_time*1000).toISOString().substring(0, 10));
                    } else {
                        date = dayjs('1970-01-01');
                    }
                    record.date = date;
                    record.times = `${dayjs(new Date(record.start_time*1000)).format('HH:mm:ss')} - ${dayjs(new Date(record.end_time*1000)).format('HH:mm:ss')}`;
                    record.label = `${channel} - ${date.format('YYYY-MM-DD')}`;
                    record.shortComment = ((record.comments && record.comments.length < 15) ?
                        record.comments :
                        ((record.comments) ?
                            `${record.comments.substring(0, 14)}...` : 'N/A')
                    );
                });
                this.exports = ((result) ? result : []);
                this.setLoadingState('exportsLoading', false);
                this.clearLoading();
                resolve(this.exports);
            } else {
                setTimeout(()=> {
                    resolve(this.getExports(false));
                }, this.wait);
            }
        });
    }

    // ########################### EventTags ###########################

    @action getEventTagEntity() {
        const entity = EventTagEntity;
        if (entity && entity.fields) {
            return entity;
        }
    }

    @action getEventTags(refresh) {
        return new Promise(async (resolve)=> {
            if (this.eventTags && !refresh) {
                resolve(this.eventTags);
            }
            if (!this.loadingState.eventTagsLoading) {
                console.log('[REFRESH] : getEventTags');
                const fetchEventTags = async ()=> {
                    let result = [];
                    try {
                        const isAdmin = await authStore.isAdmin();
                        const userId = (authStore.user._id || authStore.user.id);
                        if (isAdmin) {  // Get ALL Exports
                            result = await agentAJAX.EventTags.getAll();
                        } else {    // Get User-Specific Exports
                            result = await agentAJAX.EventTags.getByUserId(userId);
                        }
                    } catch(err) {
                        result = [];
                    }
                    result.sort((a, b)=> {
                        if (a.start_time === b.start_time) {
                            return 0;
                        } else if (a.start_time > b.start_time) {
                            return 1;
                        }
                        return -1;
                    })
                    return result;
                };
                this.setLoadingState('eventTagsLoading', true);
                const result = await fetchEventTags();
                result.forEach((record)=> {
                    record.times = `${dayjs(new Date(record.start_time*1000)).format('HH:mm:ss')} - ${dayjs(new Date(record.end_time*1000)).format('HH:mm:ss')}`;
                    record.label = record.title;
                    record.shortComment = ((record.synopsis && record.synopsis.length < 500) ?
                        record.synopsis :
                        ((record.synopsis) ?
                            `${record.synopsis.substring(0, 500)}...` : 'N/A')
                    );
                });
                this.eventTags = ((result) ? result : []);
                this.setLoadingState('eventTagsLoading', false);
                this.clearLoading();
                resolve(this.eventTags);
            } else {
                setTimeout(()=> {
                    resolve(this.getEventTags(false));
                }, this.wait);
            }
        });
    }

    @action getMainClassificationTags(model) {
        return new Promise((res) => {
            if (this.tags) {
                let retTags = toJS(this.tags).filter(f => (f.isMain === true && f.mainOn.indexOf(model) > -1)).map(t => t.name);
                res(retTags);
            } else {
                this.getTags().then(() => res(this.getMainClassificationTags(model)));
            }
        });
    }
}

// export default SIOStore(AdminStore, 'ALL');
export default new AdminStore();
