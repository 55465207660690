
export default {
    id: 'tag',
    label: 'Tag List',
    name: 'tag',
    namePlural: 'tags',
    hideTitle: true,
    titleField: 'name',

    cardOptions: {
        labelField: 'name',
        descField: 'description',
        colorField: 'color',
        masonryOptions: {
            transitionDuration: 100,
            gutter: 10,
            originTop: true,
            resize: true,
            fitWidth: true
        }
    },
    gridOptions: {
        download: true,
        print: false,
        filter: false,
        columns: [],
        downloadOptions: {
            filename: 'tags.csv',
            separator: ','
        }
    },
    fields: [
        {
            id: '_id',
            identity: true,
            type: 'text',
            width: 0,
            listColumnOptions: { display: false },
            required: false
        },
        {
            id: 'name',
            label: 'Name',
            type: 'text',
            width: 100,
            required: true,
            inMany: true
        },
        {
            id: 'description',
            type: 'textmultiline',
            label: 'Description',
            height: 5
        },
        {
            id: 'color',
            label: 'Color Code',
            type: 'color',
            width: 100,
            required: true,
            inMany: true
        },
        {
            id: 'channelgroups',
            label: 'Channel Group',
            type: 'list',
            list: [], // populate list in Store
            width: 100,
            inMany: true,
            listColumnOptions: { display: false },
        }
    ],
    groups: [
        {
            id: 'p1', type: 'panel',
            label: 'Details', width: 60,
            fields: ['name', 'description', 'channelgroups']
        },
        {
            id: 'p2', type: 'panel',
            label: 'Color Selection', width: 35,
            fields: ['color']
        }
    ]
}