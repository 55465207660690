
import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.portalRoot = document.createElement('div');

        this.handleClick = this.handleClick.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);

        this.hasClick = false;
        this.hasKeyUp = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { isOpen } = this.props;

        if (isOpen) {
            if (this.portalRoot) {
                const root = this.portalRoot;

                root.id = 'Modal-Root';
                root.className = 'Modal-Container';
                root.style.opacity = 1;

                document.getElementById('root').appendChild(root);

                if (!this.hasClick) {
                    root.addEventListener('click', this.handleClick);
                    this.hasClick = true;
                }
                if (!this.hasKeyUp) {
                    document.addEventListener('keyup', this.handleKeyUp);
                    this.hasKeyUp = true;
                }
            }
        } else {
            if (this.portalRoot) {
                this.fadeOut(()=> {
                    this.portalRoot.remove();
                });
            }
        }
    }

    componentWillUnmount() {
        if (this.portalRoot) {
            if (this.hasClick) {
                this.portalRoot.removeEventListener('click', this.handleClick);
                this.hasClick = false;
            }
            if (this.hasKeyUp) {
                document.removeEventListener('keyup', this.handleKeyUp);
                this.hasKeyUp = false;
            }
            this.portalRoot.remove();
        }
    }

    fadeOut(callback=()=>{}) {
        if (this.portalRoot) {
            const fade = setInterval(()=> {
                const root = this.portalRoot;
                if (root.style.opacity > 0) {
                    root.style.opacity -= 0.1;
                } else {
                    clearInterval(fade);
                    callback();
                }
            }, 50);
        }
    }

    handleClick(event) {
        const { clickaway } = this.props;

        if (this.portalRoot && (event.target === this.portalRoot)) {
            if (clickaway) {
                this.handleClose();
            }
        }
    }

    handleKeyUp(event) {
        if (event.key === 'Escape') {
            this.handleClose();
        }
    }

    handleClose() {
        const { onClose } = this.props;

        onClose();
    }

    render() {
        const { children } = this.props;
        
        return ReactDOM.createPortal(children, this.portalRoot);
    }
};

export default Modal;

Modal.propTypes = {
    children: PropTypes.element.isRequired,
    clickaway: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};