
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'

import { Panel } from './components';

import './style.scss';

export const Tabs = ({ tabs=[], activeTab, onActiveTabChanged })=> {
    const tabsRef = useRef();

    const handleTabSelected = (index)=> {
        onActiveTabChanged(index);
    };
    
    return (
        <div className='Tabs-Container'>
            <ul className='Tabs-List' ref={tabsRef}>
            {tabs.map((tab, index)=> (
                (tab.show) &&
                <li className={clsx('Tabs-List-Item', { 'Tabs-List-Item-Selected': (activeTab===index) })}
                    key={index}
                    onClick={()=> { handleTabSelected(index); }}>
                    {tab.label}
                </li>
            ))}
            </ul>
            <Panel
                tabsRef={tabsRef}>
                {tabs.map((tab, index)=> (
                    (activeTab===index) &&
                    tab.content
                ))}
            </Panel>
        </div>
    );
};

Tabs.propTypes = {
    activeTab: PropTypes.number,
    tabs: PropTypes.array,
    onActiveTabChanged: PropTypes.func
};