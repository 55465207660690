
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class EPGDisabled extends React.Component {

    render() {
        const { width } = this.props;

        return (
            <div className='EPG-Disabled-Overlay-Container' style={{ width: width }} />
        );
    }
}

export default EPGDisabled;

EPGDisabled.propTypes = {
    width: PropTypes.string
};