import React from 'react';
import { Route, Switch } from 'react-router-dom';

import googleAnalytics from '../../services/googleAnalytics';

import { AuthNavbar, Footer, Loading } from '@components';

import getRoutes from '../../routes/routes_auth';

class Auth extends React.Component {
    constructor(props) {
        super(props);

        this.routes = [];
    }

    componentWillMount() {
        this.routes = getRoutes(false);
    }

    componentDidMount() {
        document.documentElement.classList.remove('nav-open');
    }
    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout && prop.layout === '/auth') {
                return (
                    <Route key={key}
                        path={prop.path}
                        component={prop.component} />
                );
            }
            return null;
        });
    };
    getActiveRoute = routes => {
        let activeRoute = 'Default';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.pathname.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    getFullPageName = routes => {
        const view = this.getActiveRoute(routes);
        googleAnalytics.sendGAPageView(view.name);
        switch (view) {
            // case 'Pricing':
            //   return 'pricing-page';
            case 'Login':
                return 'login-page';
            case 'Sign Up':
                return 'register-page';
            // case 'Lock Screen':
            //   return 'lock-page';
            default:
                return 'login-page';
        }
    };

    render() {
        return (
            <>
                <Loading />
                <AuthNavbar brandText={this.getActiveRoute(this.routes) + ' Page'} />
                <div className='wrapper wrapper-full-page' ref='fullPages'>
                    <div className={'full-page ' + this.getFullPageName(this.routes)}>
                        <Switch>{this.getRoutes(this.routes)}</Switch>
                        <Footer fluid />
                    </div>
                </div>
            </>
        );
    }
}

export default Auth;
