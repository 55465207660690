// Read-only view to browse one record.

import React from 'react';
import PropTypes from 'prop-types';

import modelUtils from '../utils/modelUtils';
import Alert from '../components/Alert';
import Field from '../components/Field';
import Panel from '../components/Panel';
import Button from '@material-ui/core/Button';
//import List from '../many/List';

// Components & Icons
import { Info, Warning, Check, Error, Remove, Edit, ArrowBack } from '@material-ui/icons';

class Browse extends React.Component{

  constructor(props){
    super(props);

    this.state ={
      error: false
    };

    this.fnFieldReadOnly = this.fnFieldReadOnly.bind(this);
  }

  fnFieldReadOnly(f, data){
    if(f){
      const isLOV = f.type==='lov';
      const attr = isLOV ? f.id+'_txt' : f.id;

      if(data.hasOwnProperty(attr)){
        return (
          <Field 
            key={f.id}
            meta={f}
            value={data[attr]}
            data={data}
            valueId={isLOV?data[f.id]:null}
            readOnly={true}
            entity={this.props.entity}
            setViewState={this.props.setViewState}
          />
        );
      } else {
        return null;
      }
    }
    return null;
  }

  groupMapping(m, g, idx, data){
    let _fnFieldReadOnly = this.fnFieldReadOnly;
    const groupFields = modelUtils.fieldId2Field(g.fields, m.fields);
    const _width = g.width-2;
    let that = this;
    return (
      <Panel key={g.id||('g'+idx)} title={g.label || g.title || ''} width={_width}>
        <div className="evol-fset">
          {/* {groupFields.map(this.fnFieldReadOnly)} */}
          {(groupFields)?groupFields.map((field) => {
            return _fnFieldReadOnly(field, data);
          }):null}
          {(g.groups)?
							g.groups.map(function(gg, idx){
								return that.groupMapping(m, gg, idx, data);
							})
						:null}
        </div>
      </Panel>
    )
  }

  render() {
    const {data={}, entity=null} = this.props;
    const m = entity,
        title = modelUtils.dataTitle(m, data, false);

    let _fnFieldReadOnly = this.fnFieldReadOnly;
    let that = this;

    if(!m){
      return <Alert title="Error" message={`Invalid parameter: entity=${entity.id}".`}/>
    }else{
        return ( 
        <div className="evo-one">
          {(title)?
          <h3 className="evo-page-title">{title}</h3>:null}

          <div className="evo-one-edit">

                {this.state.error ? (
                    <Alert title="Error" message={this.state.error.message}/>
                  ):(
                    <div className="evol-pnls">

                      {(m && m.groups) ? (
                          m.groups.map(function(g, idx){
                            return that.groupMapping(m, g, idx, data);
                          })
                      ) : (
                        <Panel key="pOne" title={title}>
                          <div className="evol-fset"> 
                            {m.fields.map((field) => {
                              return _fnFieldReadOnly(field, data);
                            })}
                          </div>
                        </Panel>
                      )}

                      {m.collections ? (
                        m.collections.map((c, idx)=>{
                          return (
                            <Panel title={c.title} key={'collec_'+c.id}>
                              {/* <List key={'collec'+idx}
                                params={this.props.params} 
                                paramsCollec={c}
                                style={{width:'100%'}}
                                location={this.props.location}
                              /> */}
                            </Panel>
                          )
                        })
                      ) : null}

                      <Panel key="formButtons">
                        <div className="evol-buttons"> 
                            {/* <Link to={link+id} className="btn btn-info">
                              <i className="glyphicon glyphicon-edit"></i> {'Edit'}
                            </Link> */}
                            <Button size="medium" variant='contained' className='evo-browse-btn' onClick={() => this.props.setViewState('back')} color='primary'>
                              <ArrowBack/> BACK
                            </Button>
                            {(this.props.noEdit)?null:
                              <Button size="medium" variant='outlined' className='evo-browse-btn' onClick={() => this.props.setViewState('edit', data)} color='secondary'>
                                <Edit/> EDIT
                              </Button>
                            }
                            {/* <button className="btn btn-default" onClick={() => this.props.setViewState('back')}>'BACK'</button> */}
                        </div>
                      </Panel>

                    </div>
                  )
                }
          </div>
        </div>
        )      
    }
  }
}

Browse.propTypes = {
  entity: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,

  noEdit: PropTypes.bool,

  // Actions
  setViewState: PropTypes.func.isRequired
};

export default Browse;