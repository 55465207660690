import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Hls from 'hls.js';
import { WebAudioPeakMeter } from 'web-audio-peak-meter';

import './style.scss';
import config from '../../constants/config';
// import { Button } from '@material-ui/core';

export const HLSPlayer = ({ 
    autoPlay=false, 
    controls=true, 
    controlsList,
    media,
    innerRef, source, selectedChannel, 
    style={}, onHLSReady=(()=> {}), onEnded, onPause, onPlay
})=> {
    const sourceRef = useRef();
    const [hls, setHls] = useState(null);
    const [audioOnlyOverlay, setAudioOnlyOverlay] = useState(false);
    // const [errState, setErrState] = useState(false);

    const [audioNodeInstanceId, setAudioNodeInstanceId] = useState(new Date().getTime());
    // const [errorText, setErrorText] = useState('');

    const audioPeakMeterContainerRef = useRef();

    const [currentSource, setCurrentSource] = useState('');

    useEffect(()=> {
        if (selectedChannel) {
            setAudioOnlyOverlay(selectedChannel.audioOnly);
        }
    }, [selectedChannel]);

    let timeoutRef = null;
    // const checkAndSetErr = () => {
    //     if (errState) { // Check again
    //         setErrorText(config.errorMessage);
    //     } else {
    //         setErrorText('');
    //     }
    // };
    // useEffect(()=> {
    //     if(timeoutRef){
    //         clearTimeout(timeoutRef);
    //     }
    //     if(errState){
    //         timeoutRef = setTimeout(()=> {
    //             checkAndSetErr();
    //         }, 15*1000); // 15 seconds
    //     }
    // }, [errState]);

    useEffect(()=> {
        console.log('[HLSPlayer: useEffect: media]', media);
        // if(media && media.canPlay){
        //     setErrorText('');
        //     setErrState(false);
        // }
    }, [media]);

    useEffect(()=> {
        if (hls && source && (source !== currentSource)) {
            hls.detachMedia();
            hls.destroy();
            setHls(null);
        }
        setCurrentSource(source);
        setupHLS(source);
    }, [source, selectedChannel]);

    const connectAudio = ()=> {
        let onPlay;
        setAudioNodeInstanceId(new Date().getTime());
        if ((audioPeakMeterContainerRef && audioPeakMeterContainerRef.current) && innerRef) {
            try {
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                if(audioContext){
                    const sourceNode = audioContext.createMediaElementSource(innerRef.current);
                    sourceNode.connect(audioContext.destination);

                    onPlay = ()=> { 
                        try{
                            audioContext.resume();
                            // setErrorText('');
                        } catch(err) {
                            console.error('ERROR', err);
                        } 
                    };

                    const meterNode = new WebAudioPeakMeter(sourceNode, audioPeakMeterContainerRef.current, {
                        vertical: true,
                    });
                    innerRef.current.addEventListener('play', onPlay);

                    console.log('CONNECTED AUDIO PEAK METER');
                }
            } catch(err) {
                console.error('ERROR PEAK METER', err);
            }
        }
        return ()=> {
            if (innerRef.current) {
                innerRef.current.removeEventListener('play', onPlay);
            }
        };
    };

    const setupHLS = (src)=> {
        if (innerRef && innerRef.current) {
            const hlsConfig = {
                autoStartLoad: true,
                enableWorker: true,
                lowLatencyMode: true,
                highBufferWatchdogPeriod: 0.5,
                maxBufferHole: 0.5,
                maxBufferLength: 120,
                backBufferLength: 0,
                debug: config.debug
            };
            const hls = new Hls(hlsConfig);

            // Attach the video element to the HLS instance...
            hls.attachMedia(innerRef.current);

            hls.once(Hls.Events.MEDIA_ATTACHED, ()=> {
                // setErrorText('');
                // setErrState(false);
                hls.loadSource(src); 
            });
            hls.on(Hls.Events.MANIFEST_PARSED, ()=> {
                // setErrorText('');
                // setErrState(false);
                setTimeout(()=> {   // Give the player 1000ms to stabilize...
                    if (autoPlay) {
                        innerRef.current.play();
                    }
                    onHLSReady(hls);
                    connectAudio();
                }, 1000);
            });
            hls.on(Hls.Events.ERROR, (_, data)=> { 
                console.error('[HLS ERROR]', data);
                // setErrState(true);
            });
            setHls(hls);
        }
    };

    style={ minWidth: '720px', ...style };

    const k = (source || '').replace(/[^\w\s]/gi, '');
    return (
        <div className='HLS-Player-Main' id={k} key={`${k}`}>
            <div ref={audioPeakMeterContainerRef} id={audioNodeInstanceId} style={{ width: '5em'}} />
            <div className='HLS-Player-Container'>
                <video className='HLS-Player' ref={innerRef} style={style}
                    controlsList={controlsList}
                    controls={controls}
                    onPause={onPause}
                    onPlay={onPlay}
                    onEnded={onEnded}>
                    <source ref={sourceRef} src={`${source}`} type='video/mp4' />
                </video>
                {(audioOnlyOverlay) &&
                    <div className='HLS-Player-Overlay'>
                        <img
                            alt="Channelimg"
                            height={150}
                            width={150}
                            src={selectedChannel.image} />
                    </div>
                }
                {/* {(errorText.length > 0 && errState) &&
                    <div className='HLS-Player-Overlay-error'>
                        <p>{errorText}</p>
                        <Button size="small" className='evo-btn' onClick={() => setErrState(false)} color={'primary'}>
                            Close
                        </Button>
                    </div>
                } */}
            </div>
        </div>
    );
};

HLSPlayer.propTypes = {
    autoPlay: PropTypes.bool,
    controlsList: PropTypes.string,
    controls: PropTypes.bool,
    innerRef: PropTypes.object,
    style: PropTypes.object,
    source: PropTypes.string,
    onHLSReady: PropTypes.func,
    onEnded: PropTypes.func,
    onPause: PropTypes.func,
    onPlay: PropTypes.func
};