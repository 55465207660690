// List view to display a collection as a list (table w/ sorting and paging).

import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'mui-datatables';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import dataUtils from '../utils/dataUtils';

//import merge from 'lodash.merge';
import {isMobile} from 'is-mobile';
import utils from '../utils/dataUtils';
import { Img } from '@components';

import './ListExpand.scss';

// Components & Icons
import IconButton from '../../complex/IconButton';
import { Edit, Pageview, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

class ListExpand extends React.Component {

	_isMounted = false;
	constructor(props){
		super(props);

		this.state ={
      viewId: 'list',
      parentField: '',
      data: [],
      _staticData: [],
      dataClean: [],
      columns: [],
      options: {},
			loading: false
    }
    
    this.getData = this.getData.bind(this);
    this.deleteRows = this.deleteRows.bind(this);
    this.checkTypeAndCreateControl = this.checkTypeAndCreateControl.bind(this);
    this.renderExpand = this.renderExpand.bind(this);
  }

  // componentWillMount() {
  //   this.setState({
  //     data: [],
  //     loading: false
  //   });
  // }

  componentWillUnmount(){
    this._isMounted = false; // To check state before setting
  }
  

  componentDidMount() {
    this._isMounted = true;
    this.getData();

    // console.log('EVOUI - ListExpand');

    if(this.props.refreshData){
      this.props.refreshData(this.getData);
    }

    // Setup DataTable Options
    if(this.props.listViewOptions){
      let gridOptions = {...this.state.options, ...this.props.listViewOptions};
      gridOptions.onRowsDelete = this.deleteRows; // Inject Function to Options
      gridOptions.expandableRows = true;
      gridOptions.renderExpandableRow = this.renderExpand;
      this.setState({options: gridOptions});
    }

    // Set ParentField
    if(this.props.entity && this.props.entity.expandedParentField){
      this.setState({parentField: this.props.entity.expandedParentField});
    }
  }

  deleteRows(rows){
    if(this.props.deleteMany && rows.data){
      let _rows = [];
      rows.data.forEach(elm => {
        if(elm.dataIndex || elm.dataIndex > -1){
          _rows.push(this.state.dataClean[elm.dataIndex]);
        } else if(elm.index || elm.index > -1){
          _rows.push(this.state.dataClean[elm.index]);
        }
      });
      this.props.deleteMany(_rows);
    }

    return false;
  }

  checkTypeAndCreateControl(_key, val, entity, payload = null){
    let entField = entity.fields.filter(f => f.id === _key)[0];
    if(entField && entField.type){

      if(isMobile()){
        if(entField.listColumnOptions && !entField.listColumnOptions.showOnMobile){
          return null;
        }
      }

      switch(entField.type){
        case 'image':
          let source = '';
          if(val && typeof val === 'string'){
            source = val;
          } else if(val && typeof val === 'object' && val.source){
            source = val.source;
          }
          if(payload && entField.objField){
            let _val = payload[entField.objField];
            if(_val){
              source = (_val.source)?_val.source:_val;
            }
          }
          return (
            <Img src={source} alt={_key || 'evoImage'} size='64' key={new Date().getTime()} className='evo-grid-images' />
          );
        case 'color':
          if(val.indexOf('#') > -1){
            return <div className={`evol-colorIndicatorTable ${(entField && entField.asTableBackground)?'useBackground':''}`} style={{backgroundColor: `${val}`}}></div>
          } else {
            return <div>No Color</div>
          }
        case 'boolean':
          if(val === true || val === 'true'){
            return <CheckBox color='primary' />
          } else {
            return <CheckBoxOutlineBlank color='primary'/>
          }
        case 'lov':
          if(entField.list){
            let txt = entField.list.filter(f=>f.id === val);
            if(txt && txt[0] && txt[0].text){
              return txt[0].text;
            } else {
              return val;
            }
          } else {
            return val;
          }
        default:
          break;
      }
    }
    return val;
  }

  async getData(refresh){
    let { entity, parentId, isChild = false, staticData } = this.props;
    let data = null;
    let payload = null;

    if(staticData && staticData.length > 0){
      if(refresh && !isChild){
        data = await this.props.getData(refresh);
      } else {
        payload = staticData.slice(0); // Copy
      }
    } else {
      data = await this.props.getData(refresh);
    }

    if(data && data.payload && data.payload.length > 0) {
      payload = data.payload.slice(0); // Copy
      this.setState({_staticData: data.payload});
    }

    if((data && data.payload && data.payload.length > 0) || (staticData && staticData.length > 0)){

      let oldParentCol = null;
      let newParentCol = null;
      //Filter parentId data
      let parentField = entity.expandedParentField || 'parentId';
      if(parentId){
        let _payload = payload.filter(f => f[parentField] === parentId);
        // Add the Parent Names
        let _newPayload = [];
        _payload.forEach(elm => {
          let pLoad = JSON.parse(JSON.stringify(elm));
          let parent = payload.filter(f => (f._id || f.id) === elm[parentField]);
          pLoad[`${parentField}_name`] = (parent.length > 0 && (parent[0].name || parent[0].title))?(parent[0].name || parent[0].title):'';
          newParentCol = `${parentField}_name`;
          oldParentCol = `${parentField}`;

          _newPayload.push(pLoad);
        });
        payload = _newPayload;
      } else {
        // Root Parents
        payload = payload.filter(f => f[parentField] === null || f[parentField] === undefined || f[parentField] === "");
      }

      //Transpile Data

      let columns = [];
      let colFields = [];

      if(entity && entity.gridOptions && 
        entity.gridOptions.columns && entity.gridOptions.columns.length > 0){
          entity.gridOptions.columns.forEach(col => {
            if(col.field && col.name){
              columns.push({name: col.name, options: col.options})
              colFields.push(col.field);
            }
          });
      } else if(entity && entity.fields){
        entity.fields.forEach(field => {
          if(field.id){
            colFields.push(field.id);
            columns.push({
              name: field.label || field.id, 
              options: field.listColumnOptions||{}
            });
          }
        });
      } else {
        for(let key in payload[0]){
          if(key === 'id'){
            colFields.push(key);
            columns.push({name: key, options: {display:false}});
          } else {
            colFields.push(key);
            columns.push(key);
          }
        }
      }

      //Filter Through and replace with Controls where needed
      let newDataPayload = [];
      payload.forEach(obj => {
        let newObj = {};
        for(let key in obj){
          newObj[key] = this.checkTypeAndCreateControl(key, obj[key], this.props.entity, obj);
        }
        newDataPayload.push(newObj);
      });

      // Fix Cols
      if(oldParentCol && newParentCol){
        let _colFields = [];
        colFields.forEach(cf => {
          if(cf === oldParentCol){
            _colFields.push(newParentCol);
          } else {
            _colFields.push(cf);
          }
        });
        colFields = _colFields;
      }

      let gridData = dataUtils.objToDataTableArrayWithColumns(newDataPayload, colFields);
      gridData = dataUtils.validateAndFix(gridData, columns);

      //Edit btn
      columns.push('');
      gridData.forEach((elm, idx) => {
        let payloadItem = payload[idx];//.filter(f => f[0] === elm[0])[0];

       // Check for - noEditViewField
       let allowBtns = true;
       let editViewFieldText = null;
       let showTranscoding = false;
       let allowView = false;

       if(this.props.entity && 
         this.props.entity.noEditViewField){
         if(Array.isArray(this.props.entity.noEditViewField)){
           this.props.entity.noEditViewField.forEach(elem => {
             let prop = elem.field;
             let check = (elem.rev)?!payloadItem[prop]:payloadItem[prop];
             if(allowBtns && !check){
               editViewFieldText = (elem.textField)?payloadItem[elem.textField]:elem.text;
               allowView = elem.allowView || false;
               if(elem.showTrans){
                 showTranscoding = true;
               }
             }
             allowBtns = allowBtns && check;
           });
         } else {
           let prop = this.props.entity.noEditViewField.field;
           let check = (this.props.entity.noEditViewField.rev)?!payloadItem[prop]:payloadItem[prop];
           allowBtns = check;
           if(!check){
             editViewFieldText = (this.props.entity.noEditViewField.textField)?payloadItem[this.props.entity.noEditViewField.textField]:this.props.entity.noEditViewField.text;
             allowView = this.props.entity.noEditViewField.allowView || false;
             if(this.props.entity.noEditViewField.showTrans){
               showTranscoding = true;
             }
           }
         }
       }
        if(allowBtns){
          elm.push(
            <div className='evo-list-editbtns'>
            {(this.props.noEdit)?null:
              <IconButton className='evo-list-editbtns' color="secondary" onClick={(elm) => {this.props.setViewState('edit', payloadItem)}}>
                <Edit />
              </IconButton>
            }
              <IconButton className='evo-list-editbtns' color="info" onClick={(elm) => {this.props.setViewState('browse', payloadItem)}}>
                <Pageview />
              </IconButton>
            </div>
          );
        } else if(!allowBtns && allowView){
          elm.push(
            <div className='evo-list-editbtns'>
              <IconButton className='evo-list-editbtns' color="info" onClick={(elm) => {this.props.setViewState('browse', payloadItem)}}>
                <Pageview />
              </IconButton>
            </div>
          );
        } else {
          // TODO: Make Generic - add Progress Bar
          if(this.props.entity && editViewFieldText){
            if(showTranscoding){
              let _color = (editViewFieldText > 50)?((editViewFieldText > 80)?'green':'orange'):'red';
              elm.push(
                <div className='transcodeProgress'>
                  Transcoding<br/><div className={`transcodeProgressPerc ${_color}`}>{editViewFieldText}%</div>
                </div>
              );
            } else {
              elm.push(
                <div className='noEditMsg'>
                  {editViewFieldText}
                </div>
              );
            }
          }
        }
      });

      if(this._isMounted){
        this.setState({
          data: gridData,
          dataClean: payload,
          columns: columns,
          loading: false
        });
      }

    } else {
      if(this._isMounted){
        this.setState({error: {title: 'Error', message: 'Couldn\'t retrieve data.'} ,loading: false});
      }
    }
  } 

  renderExpand(rowDataArr, rowMeta) {
    let { entity, staticData } = this.props;
    const { data, _staticData } = this.state;

    let sData = staticData || _staticData;
    if(data && data.length > 0){
      console.log(rowDataArr, rowMeta);

      let columns = [];
      let colFields = [];

      if(entity && entity.gridOptions && 
        entity.gridOptions.childColumns && entity.gridOptions.childColumns.length > 0){
          entity.gridOptions.childColumns.forEach(col => {
            if(col.field && col.name){
              columns.push({id: col.id, name: col.name, options: col.options})
              colFields.push(col.field);
            }
          });
      } else if(entity && entity.fields){
        entity.fields.forEach(field => {
          if(field.id){
            colFields.push(field.id);
            columns.push({
              id: field.id,
              name: field.label || field.id, 
              options: field.listColumnOptions||{}
            });
          }
        });
      }

      // Arr To Object
      let rowData = {};
      let rowDataId = null;
      entity.fields.forEach((field, idx) => {
        if(field.id && rowDataArr.length > idx){
          rowData[field.id] = rowDataArr[idx] || null;
          if(field.identity){
            rowDataId = rowDataArr[idx];
          }
        }
      });

      

      // Add Child Background Color
      let colorForChild = null;
      let style = {};
      if(rowData.color && rowData.color.props && rowData.color.props.style && rowData.color.props.style.backgroundColor){
        let hexColor = rowData.color.props.style.backgroundColor;
        // let rgba = utils.hexToRgba(hexColor, 0.5);
        style.backgroundColor = `${hexColor}`;//`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a}) !important`;
      }

      // Remove Expand for Non Parent
      let _listViewOptions = this.props.listViewOptions;
      if(entity.expandedParentField){
        let isParent = sData.filter(f => f[entity.expandedParentField] === rowDataId).length > 0;
        if(!isParent){
          return <TableRow>
            <TableCell 
              className='childTableClear' 
              colSpan={columns.length+1}>
                <hr/>
            </TableCell></TableRow>;
        }
      }
      
      return (
        <TableRow style={style}>
          <TableCell 
            className='childTable' 
            colSpan={columns.length+1}>
          <ListExpand
              key={`${rowMeta.dataIndex}-${rowMeta.rowIndex}`}
              entity={entity} 
              style={{width:'100%'}}
              staticData={sData}
              getData={this.props.getData} // Only root parent should fetch data
              parentId={rowDataId || null}
              isChild={true}
              setViewState={this.props.setViewState}
              listViewOptions={_listViewOptions}
              refreshData={click => this.refreshDataList = click}
              noEdit={this.props.noEdit || false}
              deleteMany={this.props.deleteMany}
          />
          </TableCell>
        </TableRow>
      );
      /*
      return (
        <TableRow>
          <TableCell></TableCell>
          {columns.map((m, idx) => {
            if(m.options && m.options.display === false){
              return null;
            }
            return (<TableCell key={`${idx}-${m.id}`} colSpan={1}>
              {(rowData && rowData[m.id])?rowData[m.id]:null}
            </TableCell>);
            }
          )}
          {/* <TableCell colSpan={columns.length}>
            <div className='evo-list-editbtns'>
              {(this.props.noEdit)?null:
                <IconButton className='evo-list-editbtns' color="secondary" onClick={(elm) => {this.props.setViewState('edit', payloadItem)}}>
                  <Edit />
                </IconButton>
              }
                <IconButton className='evo-list-editbtns' color="info" onClick={(elm) => {this.props.setViewState('browse', payloadItem)}}>
                  <Pageview />
                </IconButton>
            </div>
          </TableCell> *
        </TableRow>
      );
      */
    }
  }

	// render(){
	// 	return (
	// 		<DataTable 
	// 			data={this.state.data} 
	// 			columns={this.state.columns} 
	// 			options={this.state.options}
	// 		/>
	// 	);
  // }
  render(){
    let name = (this.props.entity && this.props.entity.namePlural)?this.props.entity.namePlural:'data';
    if(this.state.data && this.state.data.length > 0){
      return (
        <DataTable 
          data={this.state.data} 
          columns={this.state.columns} 
          options={this.state.options}
        />
      );
    } else {
      return (
        <>
          <hr/>
          <h1>No {name} to display.</h1>
        </>
      );
    }		
	}
}

ListExpand.propTypes = {
	entity: PropTypes.object.isRequired,
	//paramsCollec: PropTypes.object,
  pageSize: PropTypes.number,
  listViewOptions: PropTypes.object,
  refreshData: PropTypes.func,

  noEdit: PropTypes.bool,
  parentId: PropTypes.string,
  staticData: PropTypes.array,
  isChild: PropTypes.bool,

	// Actions
	getData: PropTypes.func,
	deleteMany: PropTypes.func.isRequired,
	setViewState: PropTypes.func.isRequired,
};

export default ListExpand;
