
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import dayjs from 'dayjs';

import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import config from '../../../../../../constants/config';

export const EventTagging = ({ fields, userTags=[], onCancel, onPersist })=> {

    const [channelName] = useState(fields.channelName);
    const [synopsis, setSynopsis] = useState(fields.synopsis);
    const [date, setDate] = useState(fields.date);
    const [endTime, setEndTime] = useState(fields.endTime);
    const [startTime, setStartTime] = useState(fields.startTime);
    const [tags, setTags] = useState(fields.tags);
    const [title, setTitle] = useState(fields.title);
    const [username] = useState(fields.username);

    useEffect(()=> {
        setDate(fields.date);
        setEndTime(fields.endTime);
        setStartTime(fields.startTime);
    }, [fields]);

    const handleDateChanged = (event)=> { setDate(dayjs(event.target.value)); };
    const handleStartTimeChanged = (event)=> {
        const newValue = `${date.format('YYYY-MM-DD')} ${event.target.value}`;
        setStartTime(dayjs(newValue, 'YYYY-MM-DD HH:mm:ss'));
    };
    const handleEndTimeChanged = (event)=> {
        const newValue = `${date.format('YYYY-MM-DD')} ${event.target.value}`;
        setEndTime(dayjs(newValue, 'YYYY-MM-DD HH:mm:ss'));
    };
    const handleSynopsisChanged = (event)=> { setSynopsis(event.target.value); };
    const handleTagsChanged = (event)=> {
        let opts = [], opt;
        for (let i=0, len=event.target.options.length; i<len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        setTags(opts);
    };
    const handleTitleChanged = (event)=> { setTitle(event.target.value); };

    const validateForm = ()=> {
        return (!title || !date || !startTime || !endTime || !synopsis);
    }

    const handlePersist = ()=> {
        onPersist({ channelName, synopsis, date, endTime, startTime, tags, title, username });
    };

    return (
        <div className='Scrubber-Navigation-Event-Container'>
            <div className='Scrubber-Navigation-Event-Form'>
                <Form>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='username'>Username</Label>
                                <Input disabled type='text' id='username' value={username} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='channelName'>Channel Name</Label>
                                <Input disabled type='text' id='channelName' value={channelName} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for='title'>Title*</Label>
                                <Input type='text' id='title' value={title} onChange={handleTitleChanged} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for='date'>Date*</Label>
                                <Input type='date' id='date' value={date.format('YYYY-MM-DD')} onChange={handleDateChanged} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for='startTime'>Start Time*</Label>
                                <Input type='time' id='startTime' step='1' value={startTime.format('HH:mm:ss')} onChange={handleStartTimeChanged} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for='endTime'>End Time*</Label>
                                <Input type='time' id='endTime' step='1' value={endTime.format('HH:mm:ss')} onChange={handleEndTimeChanged} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {(!config.hideTags) &&
                    <Row form>
                        <Col>
                            <FormGroup>
                                <Label for='tags'>Tags</Label>
                                <Input multiple type='select' id='tags' value={tags} onChange={handleTagsChanged}>
                                    {userTags.map((tag, index)=> (
                                        <option key={index}>{tag.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    }
                    <Row form>
                        <Col>
                            <FormGroup>
                                <Label for='synopsis'>Synopsis*</Label>
                                <Input type='textarea' id='synopsis' value={synopsis} onChange={handleSynopsisChanged} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className='Scrubber-Navigation-Export-Controls'>
                <button className='Scrubber-Navigation-Cancel-Button' onClick={onCancel}>Cancel</button>
                <button className={clsx({ 'Scrubber-Navigation-Export-Button': true, 'disabled': validateForm() })} disabled={validateForm()} onClick={handlePersist}>
                    Create
                </button>
            </div>
        </div>
    );
};

EventTagging.propTypes = {
    fields: PropTypes.object,
    userTags: PropTypes.array,
    onCancel: PropTypes.func,
    onExport: PropTypes.func
};