/* eslint-disable jsx-a11y/anchor-is-valid */
// Model-driven field (possible types specified in dico.fieldTypes).

import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import config from '../../../constants/config';

import format from '../utils/format';
import FieldLabel from './FieldLabel';
import { defaultImgOptions } from '../../../constants/constant';

//import Wheel from '../../ColorWheel';

import { SketchPicker } from 'react-color';

// Components & Icons
import { Info, Warning, Check, Error, Remove, FiberManualRecord } from '@material-ui/icons';
import { WithContext } from 'react-tag-input';
import ReactTags from 'react-tag-autocomplete'

// - components:
import UploadImageItem from './UploadImageItem';
import JSONInput from 'react-json-editor-ajrm';

import { withStyles, Radio, FormControlLabel } from '@material-ui/core';

// For HTML Editor
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import '../styles/wysiwyg_editor.scss';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';
// import htmlToDraft from 'html-to-draftjs';

import CodeEditor from './CodeEditor';
import Prism from 'prismjs';
import dedent from 'dedent';
import { highlight, languages } from 'prismjs/components/prism-core';

import 'prismjs/themes/prism-dark.css';
import '../styles/codeEditor.scss';
require('prismjs/components/prism-clike');
require('prismjs/components/prism-javascript');
require('prismjs/components/prism-css');
require('prismjs/components/prism-markup');

function emHeight(f) {
    let fh = parseInt(f.height || 2, 10);
    if (fh < 2) {
        fh = 2;
    }
    return Math.trunc(fh * 1.6);
}

function createMarkup(d) {
    // TODO: good enough?
    return { __html: (d && (typeof d === 'string')) ? d.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br/>') : '' }
}

function createOption(opt) {
    return <option key={opt.id} value={'' + opt.id}>{opt.text}</option>
}

const actions = {
    browse: 'Browse',
    edit: 'Edit',
    // login: 'Login',
    new: 'New',
    newEntity: 'New {0}', //'New Item',
    //newUpload: 'New Upload',
    //search: 'Search',
    //newSearch: 'New Search',
    //searchRes: 'Search Result',
    //selection: 'Selection',
    //selections: 'Selections',
    export1: 'Export',
    //import: 'Import',
    //massUpdate: 'Mass Update',
    delete1: 'Delete',
    //bAll: 'All',
    list: 'List',
    cards: 'Cards',
    //bJSON: 'JSON',
    filter: 'Filter',
    //bScatter:'Scatter',
    charts: 'Charts',
    //refresh: 'Refresh',
    //print: 'Print',
    save: 'Save',
    //saveAdd: 'Save and Add Another',
    ok: 'OK',
    cancel: 'Cancel',

    // --- navigation/pagination ---
    prev: 'Previous',
    next: 'Next',

    dropFile: 'Drop the file here, or click to select the file to upload.',

    remove_image: 'Remove current image',
    remove_document: 'Remove current document'
}

class Field extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            help: false,
            editorState: null, //EditorState.createEmpty()
            editorHTML: '',
            editorJSON: '',
            showEditorCode: false
        }

        this._fieldElem = this._fieldElem.bind(this);

        //Colors
        this.hexToRgb = this.hexToRgb.bind(this);
        this.componentToHex = this.componentToHex.bind(this);
        this.rgbToHex = this.rgbToHex.bind(this);

        // Html Editor
        this.onEditEditorHTML = this.onEditEditorHTML.bind(this);
        this.toggleEditorCode = this.toggleEditorCode.bind(this);
        this.editorBackground = this.editorBackground.bind(this);
    }

    componentDidMount() {
        let f = this.props.meta;
        let d = this.props.value;

        if (f && f.type === 'html') {
            const contentBlock = htmlToDraft(d || '<p></p>');

            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            let _editorState = EditorState.createWithContent(contentState);

            this.setState({ editorState: _editorState });
        }
    }

    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [];
    }

    componentToHex(c) {
        var hex = Number(c).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }

    rgbToHex(rgb) {
        if (rgb && rgb.length >= 3) {
            return `#${this.componentToHex(rgb[0])}${this.componentToHex(rgb[1])}${this.componentToHex(rgb[2])}`;
        } else {
            return '';
        }
    }

    // Editor Code Button
    onEditEditorHTML = (e, f) => {
        const editorHTML = e.target.value;

        let editor;
        const contentBlock = htmlToDraft(editorHTML);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editor = EditorState.createWithContent(contentState);
        } else {
            editor = EditorState.createEmpty();
        }
        console.log('editorState:', editor);
        this.setState({ editorState: editor, editorHTML });
        // fix Background
        this.editorBackground(f);
    }

    editorBackground = (f) => {
        if (f.background && f.background.length > 0) {
            let editorElm = document.getElementsByClassName('evoui-html-editor');
            if (editorElm && editorElm.length > 0) {
                let elm = editorElm[0];
                if (elm.style['backgroundImage'] === '') {
                    elm.style['backgroundImage'] = `url(${f.background})`;
                }
            }
        }
    }

    toggleEditorCode = () => {
        const { showEditorCode } = this.state
        this.setState({ showEditorCode: !showEditorCode });
    }

    /** Renders an Editable field based on the Entity type provided */
    _fieldElem(f, d, cbs, data) {

        // Hide Display Only Fields
        if (f.displayOnly) {
            return null;
        }

        try {
            // - return the widget needed for the specific field type
            if (f.type === 'boolean') {
                return <div className='evoCheckbox'>
                    <input
                        id={f.id}
                        key={f.id}
                        type='checkbox'
                        checked={d ? true : false}
                        onChange={cbs.change} />
                    {(f.labelTrue || f.labelFalse) ?
                        <p> - {d ? f.labelTrue : f.labelFalse}</p>
                        : null}
                </div>;
            } else if (f.type === 'textmultiline') { // && f.height>1
                return <textarea
                    id={f.id}
                    key={f.id}
                    rows={f.height}
                    className='form-control'
                    value={d ? d : ''}
                    onChange={cbs.change} />
            } else if (f.type === 'json') {
                let { editorJSON } = this.state;
                if (editorJSON.length <= 0) {
                    editorJSON = d;
                }
                return (
                    <JSONInput
                        id={f.id}
                        placeholder={editorJSON}
                        theme='dark_vscode_tribute'
                        colors={{ background: '#000813' }}
                        height='200px'
                        width='100%'
                        waitAfterKeyPress={4000} // 4 sec wait
                        reset={false} // Don't re-render
                        onChange={(data) => {
                            let changeData = {
                                target: {
                                    id: f.id,
                                    value: data.jsObject,
                                }
                            };
                            cbs.change(changeData);
                            this.setState({ editorJSON: data.jsObject });
                        }} />
                );
            } else if (f.type === 'script') {
                let template =
                    `function App() {
                        alert('Demo Code');
                    }
                    //App();`;
                return <CodeEditor
                    value={d || template}
                    onValueChange={(code) => {
                        let changeData = {
                            target: {
                                id: f.id,
                                value: code,
                            }
                        };
                        cbs.change(changeData);
                    }}
                    highlight={code => highlight(code, languages.js)}
                    preClassName='preCodeEditor'
                    textareaClassName='codeEditor'
                    padding={10}
                    style={{
                        fontFamily: "'Fira code', 'Fira Mono', monospace",
                        fontSize: 12,
                        minHeight: '200px',
                        backgroundColor: '#000813'//'rgb(30, 30, 30)'
                    }}
                />;
            } else if (f.type === 'css') {
                return <CodeEditor
                    value={d || '.container{}'}
                    onValueChange={(code) => {
                        let changeData = {
                            target: {
                                id: f.id,
                                value: code,
                            }
                        };
                        cbs.change(changeData);
                    }}
                    highlight={code => highlight(code, languages.css)}
                    preClassName='preCodeEditor'
                    textareaClassName='codeEditor'
                    padding={10}
                    style={{
                        fontFamily: "'Fira code', 'Fira Mono', monospace",
                        fontSize: 12,
                        minHeight: '200px',
                        backgroundColor: '#000813'//'rgb(30, 30, 30)'
                    }} />;
            } else if (f.type === 'html') {
                const { editorState, showEditorCode, editorHTML } = this.state;
                if (editorState && editorState.getCurrentContent) {
                    const ShowEditorCode = () => (
                        <div className='rdw-option-wrapper btn-show-code'
                            onClick={this.toggleEditorCode}>
                            {showEditorCode ? 'Hide' : 'Show'} Code
                        </div>);

                    return (
                        <div className='evoui-html'>
                            <Editor
                                toolbarAlwaysVisible
                                editorState={editorState}
                                toolbarClassName='evoui-html-toolbar'
                                wrapperClassName='evoui-html-wrapper'
                                editorClassName={`evoui-html-editor ${(showEditorCode) ? 'hideMe' : ''}`}//{showEditorCode ? 'editor evoui-html-editor' : 'editorHide evoui-html-editor'}
                                toolbarCustomButtons={[<ShowEditorCode />]}
                                onEditorStateChange={(_editorState) => {
                                    let editorHTML = draftToHtml(convertToRaw(_editorState.getCurrentContent()));
                                    this.setState({
                                        editorState: _editorState,
                                        editorHTML
                                    });
                                    let changeData = {
                                        target: {
                                            id: f.id,
                                            value: editorHTML
                                        }
                                    };
                                    cbs.change(changeData);
                                }}
                                onFocus={() => {
                                    this.editorBackground(f);
                                }} />
                            {showEditorCode && <CodeEditor
                                value={editorHTML}
                                onValueChange={(code) => {
                                    let changeData = {
                                        target: {
                                            id: f.id,
                                            value: code,
                                        }
                                    };
                                    this.onEditEditorHTML(changeData, f);
                                }}
                                highlight={code => highlight(code, languages.markup)}
                                preClassName='preCodeEditor'
                                textareaClassName='codeEditor'
                                padding={10}
                                style={{
                                    fontFamily: "'Fira code', 'Fira Mono', monospace",
                                    fontSize: 12,
                                    minHeight: '200px',
                                    backgroundColor: '#000813'//'rgb(30, 30, 30)'
                                }}
                            />}
                        </div>
                    );
                } else {
                    return <div>Editor Loading...</div>;
                }
            } else if (f.type === 'custom') {
                if (f.edit) {
                    let EditObj = f.edit;
                    return <EditObj data={d} entity={f} />
                } else {
                    return <div key={f.id}>No Edit Component Found</div>
                }
            } else if (f.type === 'lov') {
                let opts;

                if (f.list) {
                    if(f.notSelf){
                        const selfId = data._id || data.id;
                        if(selfId){
                            // remove from list
                            f.list = f.list.filter((itm) => itm.id !== selfId);
                        }
                    }
                    opts = f.list.map(createOption)
                } else {
                    const optVal = {
                        id: f.id + 'loading',
                        text: 'Loading data...'
                    }
                    opts = [createOption(optVal)]
                    f.list = [optVal]
                }
                return (
                    <select
                        id={f.id}
                        key={f.id}
                        className='form-control'
                        value={d || ''}
                        onChange={cbs.change} >
                        <option />
                        {opts}
                    </select>
                );
            } else if (f.type === 'lov_sub') {
                let opts = [];
                let cssPath = '';
                function populateList(mainList, currentlist, selected = null) {
                    let retOpts = [];
                    currentlist.forEach(item => {
                        let _val = {
                            label: item.text,
                            value: item.id
                        };
                        if (item.id === selected) {
                            _val.checked = true;
                        }
                        let check = mainList.filter(f => f.parent === item.id);
                        if (check && check.length > 0) {
                            _val.children = populateList(mainList, check, selected);
                        }
                        retOpts.push(_val);
                    });
                    return retOpts;
                }
                const setCSS = (list) => {
                    if (cssPath) {
                        let cssPathArr = cssPath.split('.');
                        let current = list;
                        cssPathArr.forEach(key => {
                            if (current && current[key]) {
                                current = current[key];
                                current.className = 'selectedNode';
                            }
                        });
                    }
                }

                const assignObjectPaths = (obj, stack) => {
                    Object.keys(obj).forEach(k => {
                        const node = obj[k];
                        if (typeof node === 'object') {
                            node.path = stack ? `${stack}.${k}` : k;
                            if (node.checked) {
                                cssPath = node.path;
                            }
                            assignObjectPaths(node, node.path);
                        }
                    });
                };

                if (f.list) {
                    let mainParentItems = f.list.filter(f => f.parent === null);
                    opts = populateList(f.list, mainParentItems, d);

                } else {
                    const optVal = {
                        label: f.id + 'loading',
                        text: 'Loading data...'
                    }
                    opts = [optVal];
                }

                assignObjectPaths(opts, null);
                setCSS(opts);
                return <DropdownTreeSelect
                    id={f.id}
                    key={f.id}
                    data={opts}
                    mode={f.mode || 'multiSelect'}
                    onAction={(node, action) => {
                        console.log(action);
                    }}
                    onChange={(selectedNodes) => {
                        let val = [];
                        if (Array.isArray(selectedNodes)) {
                            selectedNodes.forEach(node => {
                                if (node.checked) {
                                    val.push(node.value);
                                }
                            });
                        } else {
                            if (selectedNodes.checked) {
                                val = selectedNodes.value;
                            }
                        }
                        let target = {
                            type: f.type,
                            id: f.id,
                            value: val
                        };
                        cbs.change({ target });
                    }}
                    className='form-control bootstrap-dropdownTreeSelect'
                />

            } else if (f.type === 'list') { // Array of Objects - needs 'text' field
                d = d || [];
                // Display Tags - Tags
                if (Array.isArray(d)) {
                    let _sug = [];
                    if (f.suggestions) {
                        _sug = f.suggestions;
                    } else if (f.list) {
                        _sug = JSON.parse(JSON.stringify(f.list));
                    }
                    _sug = (_sug.length > 0) ? _sug.map(m => { return { id: `${m.id}`, name: `${m.text}` } }) : _sug;
                    let tagsSelected = d.map(m => {
                        if (typeof m === 'string') {
                            return { id: m, name: m };
                        } else {
                            return { id: m._id || m.id, name: m.name || m.text };
                        }
                    }); // {id:int, text:str}
                    tagsSelected = tagsSelected.filter(f => f); // Remove Undefined..
                    return (
                        <ReactTags
                            id={f.id} 
                            key={f.id}
                            tags={tagsSelected}
                            labelField='text'
                            suggestions={_sug}
                            onDelete={(idx) => { cbs.tags_handleDelete(f.id, idx); }}
                            onAddition={(tag) => { cbs.tags_handleAddition(f.id, tag); }}
                            //handleDrag={(tag, currPos, newPos) => {cbs.tags_handleDrag(f.id, tag, currPos, newPos)}}
                            placeholder={`Add a new ${f.label || f.id}`}
                        />
                    );
                } else {
                    return <div>Invalid Source Data, Check your requirements for 'List' type</div>
                }

            } else if (f.type === 'tags') { // Array of Strings
                d = d || [];
                // Display Tags - Tags
                if (Array.isArray(d)) {
                    return <div className='evol-addTags'>
                        <TagsInput
                            value={d}
                            onChange={(tags) => { cbs.handleTags(f.id, tags) }}
                            tagProps={{ className: 'react-tagsinput-tag info' }}
                        />
                    </div>
                } else {
                    return <div>Invalid Source Data, Check your requirements for 'List' type</div>
                }
            } else if (f.type === 'enum') {
                if (f.list) {
                    return (
                        f.list.map((itm, idx) => {
                            return <FormControlLabel
                                key={idx}
                                control={
                                    <Radio
                                        checked={d === itm}
                                        onChange={cbs.change}
                                        value={itm}
                                        id={f.id}
                                        name={itm}
                                        aria-label={itm}
                                        icon={
                                            <FiberManualRecord className={this.props.classes.radioUnchecked} />
                                        }
                                        checkedIcon={
                                            <FiberManualRecord className={this.props.classes.radioChecked} />
                                        }
                                        classes={{
                                            checked: this.props.classes.radio
                                        }}
                                    />
                                }
                                classes={{
                                    label: this.props.classes.label
                                }}
                                label={itm}
                            />
                        })
                    );
                } else {
                    return <div>No List found</div>
                }
            } else if (f.type === 'date') {
                return <div>{d ? format.dateStringFromDate(d) : null}</div>
            } else if (f.type === 'image' || f.type === 'document') {
                let source = null;
                if (d && typeof d === 'string') {
                    source = d;
                } else if (d && typeof d === 'object' && d.source) {
                    source = d.source;
                } else if (d && typeof d === 'object' && d.preview) {
                    source = d.preview;
                }

                if (data && f.objField) {
                    let _val = data[f.objField];
                    if (_val) {
                        source = (_val.source) ? _val.source : _val;
                    }
                }

                const VodImgOptions = defaultImgOptions;
                return (
                    <div>
                        <UploadImageItem
                            idx={f.id}
                            key={0}
                            source={source}
                            error={(d && d.error) ? true : false}

                            VodImgOptions={f.options || VodImgOptions}
                            onAdd={(imgData, raw) => {
                                this.onDropFile(f.id, { preview: imgData, base64Data: imgData, raw: raw });
                            }}
                            onDelete={() => this.onDropFile(f.id, null)} />
                    </div>
                )
            } else if (f.type === 'color') {
                let evt = {
                    target: {
                        id: f.id,
                        value: '',
                        type: 'color'
                    }
                }
                return (
                    <div className='evol-colorWheel'>
                        <SketchPicker
                            color={d || '#ffffff'}
                            onChangeComplete={(color) => {
                                evt.target.value = (color && color.hex) ? color.hex : '';//this.rgbToHex(color);
                                cbs.change(evt);
                            }}
                        />
                    </div>);
            }
            let inputType = '';
            if (f.type === 'integer' || f.type === 'decimal') {
                inputType = 'number';
            } else if (f.type === 'email') {
                inputType = 'email';
            } else if (f.type === 'password') {
                inputType = 'password';
            }

            return <input
                id={f.id}
                //ref='e'
                disabled={f.disabled}
                type={inputType}
                value={d ? d : ''}
                onChange={cbs.change}
                className='form-control'
            />;
        } catch (err) {
            console.error('[EVOUI]', err);
            return (
                <div className='disabled evo-rdonly text'>
                    Something Wrong with this property
                </div>
            );
        }

    }

    /** Renders a static field with the data */
    _fieldElemReadOnly(f, d, d_id, data) {
        // - return the formated field value
        let fw;

        try {

            if (f.type === 'boolean') {
                if (f.labelTrue && f.labelFalse) {
                    return <div key={f.id} className='disabled evo-rdonly text'>
                        {d ? f.labelTrue : f.labelFalse}
                    </div>
                } else {
                    return <input
                        id={f.id}
                        key={f.id}
                        //ref='e'
                        type='checkbox'
                        disabled
                        checked={d ? true : false}
                    />
                }
            } else if (f.type === 'color' && d) {
                if (d.indexOf('#') > -1) {
                    return <div className='evol-colorIndicator' style={{ backgroundColor: `${d}` }}></div>
                } else {
                    return <div className='evol-colorIndicator'>No Color</div>
                }
            } else if (f.type === 'textmultiline') {
                const height = emHeight(f) + 'em'
                return <div key={f.id} className='disabled evo-rdonly text' style={{ height: height }}
                    dangerouslySetInnerHTML={createMarkup(d)}
                />;
            } else if (f.type === 'json') {
                return <JSONInput
                    id={f.id}
                    placeholder={d}
                    theme='dark_vscode_tribute'
                    colors={{ background: '#000813' }}
                    height='200px'
                    width='100%'
                    viewOnly={true}
                />
            } else if (f.type === 'script' || f.type === 'css' || f.type === 'html') {
                //return <textarea key={f.id} className='disabled evo-rdonly text'>{d}</textarea>
                let hl = (f.type === 'script') ? languages.js : (f.type === 'css') ? languages.css : languages.markup;
                return <CodeEditor
                    value={d || ''}
                    highlight={code => highlight(code, hl)}
                    preClassName='preCodeEditor'
                    textareaClassName='codeEditor'
                    padding={10}
                    readOnly={true}
                    style={{
                        fontFamily: "'Fira code', 'Fira Mono', monospace",
                        fontSize: 12,
                        minHeight: '200px',
                        backgroundColor: '#000813'//'rgb(30, 30, 30)'
                    }}
                />;
            } else if (f.type === 'image' && d) {
                let source = '';
                if (d && typeof d === 'string') {
                    source = d;
                } else if (d && typeof d === 'object' && d.source) {
                    source = d.source;
                } else if (d && typeof d === 'object' && d.preview) {
                    source = d.preview;
                }

                if (data && f.objField) {
                    let _val = data[f.objField];
                    if (_val) {
                        source = (_val.source) ? _val.source : _val;
                    }
                }

                let _img = (this.props.filesUrl) ? this.props.filesUrl + source : source;
                fw = format.image(_img);

                return (
                    <div key={f.id} className='disabled evo-rdonly image'>
                        {fw}
                    </div>
                );
                // }else if(f.type==='video'){
                // 	if(typeof d === 'object'){
                // 		if(d.sources){
                // 			let _vidSrc = d.sources;
                // 			if(config.debug && config.contUrl){
                // 				_vidSrc = `${config.contUrl}${_vidSrc}`;
                // 			}
                // 			return (
                // 				<ReactVideoPlay
                // 					sources={_vidSrc || d.sources}
                // 					poster={(d.poster)?d.poster:null}
                // 					advComponent={(d.advComponent)?d.advComponent:null}
                // 					enableSlider={(d.enableSlider)?d.enableSlider:false}
                // 					sliderSlides={(d.sliderSlides)?d.sliderSlides:null}
                // 					autoplay={(d.autoplay)?d.autoplay:false}
                // 					muted={(d.muted)?d.muted:false}
                // 				/>
                // 			);
                // 		}
                // 	}
            } else if (f.type === 'custom') {
                if (f.view) {
                    let ViewObj = f.view;
                    return <ViewObj data={d} entity={f} />
                } else {
                    return <div key={f.id}>No View Component Found</div>
                }
            } else if (f.type === 'tags') {
                if (Array.isArray(d)) {
                    return (
                        <div key={f.id} className='disabled evo-rdonly tags'>
                            <ul>
                                {d.map((_d, idx) => {
                                    return <li key={`tag_${idx}`}>{_d}</li>
                                })}
                            </ul>
                        </div>
                    );
                } else {
                    return (
                        <div key={f.id} className='disabled evo-rdonly tags'>
                            {d}
                        </div>
                    );
                }
            } else if (f.type === 'list') {
                if (Array.isArray(d)) {
                    return (
                        <div key={f.id} className='disabled evo-rdonly tags'>
                            <ul>
                                {d.map((_d, idx) => {
                                    if (_d && typeof _d === 'object') {
                                        return <li key={`tag_${idx}`}>{_d.name || _d.text}</li>
                                    } else {
                                        return <li key={`tag_${idx}`}>{_d}</li>
                                    }
                                })}
                            </ul>
                        </div>
                    );
                } else {
                    return (
                        <div key={f.id} className='disabled evo-rdonly tags'>
                            {d}
                        </div>
                    );
                }
            } else if (f.type === 'enum') {
                return <div key={f.id}>{d}</div>
            } else if (f.type === 'document') {
                fw = format.doc(d, this.props.filesUrl);

                return (
                    <div key={f.id} className='disabled evo-rdonly document'>
                        {fw}
                    </div>
                );
                // }else if(f.type==='json'){
                // 	return (
                // 		<JSONInput
                // 			id={f.id} 
                // 			placeholder={d}
                // 			theme='dark_vscode_tribute'
                //			colors={{background:'#000813'}}
                // 			height='350px'
                // 			width='100%'
                // 			viewOnly={true}
                // 	/>);
            } else if (f.type === 'lov' && f.entity) {
                fw = <a href='#' onClick={() => this.props.setViewState('browse', f)}>{format.fieldValue(f, d)}</a>// <Link to={'/'+f.entity+'/browse/'+d_id}>{format.fieldValue(f, d)}</Link>
            } else if (f.type === 'password') {
                return <input
                    id={f.id}
                    //ref='e'
                    type='password'
                    value={d ? d : ''}
                    disabled='disabled'
                    className='form-control'
                />
            } else {
                fw = format.fieldValue(f, d);
            }

            return (
                <div key={f.id} className='disabled evo-rdonly text'>
                    {fw}
                </div>
            );
        } catch (err) {
            console.error('[EVOUI]', err);
            return (
                <div className='disabled evo-rdonly text'>
                    Something Wrong with this data
                </div>
            );
        }
    }

    clickHelp() {
        this.setState({
            help: !this.state.help
        })
    }

    getDateFieldChange(fid) {
        // - for fields of type date (using react-datepicker)
        return (v) => {
            this.props.callbacks.change({
                target: {
                    id: fid,
                    value: v ? v.format() : null
                }
            })
        }
    }

    onDropFile(fid, imgData) {
        // - only for fields of type image or document
        this.props.callbacks.dropFile(fid, imgData);
    }

    // removeFile(){
    // 	// - only for fields of type image or document
    // 	const f = this.props.meta;
    // 	if(this.props.callbacks.dropFile){
    // 		this.props.callbacks.dropFile(f.id, null)
    // 	}
    // }

    render() {
        const f = this.props.meta || { type: 'text' },
            readOnly = this.props.readOnly || f.readOnly,
            cbs = this.props.callbacks || {},
            value = this.props.value || null,
            valueId = this.props.valueId || null,
            invalid = this.state.invalid,
            label = this.props.label || f.label,
            data = this.props.data || null;

        return (
            <div className={'evol-fld' + (invalid ? ' has-error' : '')} style={{ width: (f.width || 100) + '%' }}>

                <FieldLabel label={label}
                    field={f}
                    readOnly={readOnly}
                    clickHelp={this.clickHelp} />

                {f.help && this.state.help ? <div className='help-block'><i>{f.help}</i></div> : null}

                {readOnly ? this._fieldElemReadOnly(f, value, valueId, data)
                    : this._fieldElem(f, value, cbs, data)}

                {invalid ? <div className='text-danger'>{this.state.message}</div> : null}

            </div>
        )
    }
}

Field.propTypes = {
    entity: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    callbacks: PropTypes.object,
    //data: PropTypes.object,  // only some field types
    value: PropTypes.any, // field value
    label: PropTypes.string, //override label in meta
    readOnly: PropTypes.bool, //override label in meta
    filesUrl: PropTypes.string, // Where to Upload

    data: PropTypes.object, // Full Object
    //Actions
    setViewState: PropTypes.func.isRequired,
};

let stylesField = {
    label: {
        cursor: 'pointer',
        paddingLeft: '0',
        //color: 'rgba(0, 0, 0, 0.26)',
        fontSize: '14px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        display: 'inline-flex',
        transition: '0.3s ease all'
    },
    radio: {
        color: '#009cbd'
    },
    radioChecked: {
        width: '20px',
        height: '20px',
        border: '1px solid #009cbd',
        borderRadius: '50%'
    },
    radioUnchecked: {
        width: '0px',
        height: '0px',
        padding: '9px',
        border: '1px solid rgba(0, 0, 0, .54)',
        borderRadius: '50%'
    },
}

export default withStyles(stylesField)(Field);
