import Login from 'views/pages/Login';

const _routes = [
    {
        component: 'Login',
        identifier: 'login',
        hidden: true,
        label: 'Login',
        layout: '/auth',
        path: '/web/login'
    }
];

const getRoutes = (isAdmin = false) => {
    return _routes;
}

export default getRoutes;
