
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import dayjs from 'dayjs';

import { Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';

import config from '../../../../../../constants/config';

export const modes = { EXPORT: 'export', IMPORT: 'import' };
export const Export = ({ fields, userTags=[], mode, onCancel, onPersist })=> {

    const [channelName] = useState(fields.channelName);
    const [comments, setComments] = useState(fields.comments);
    const [date, setDate] = useState(fields.date);
    const [detections] = useState(fields.detections);
    const [endTime, setEndTime] = useState(fields.endTime);
    const [showDetections] = useState(fields.showDetections);
    const [startTime, setStartTime] = useState(fields.startTime);
    const [tags, setTags] = useState(fields.tags);
    const [username] = useState(fields.username);

    useEffect(()=> {
        setDate(fields.date);
        setEndTime(fields.endTime);
        setStartTime(fields.startTime);
    }, [fields]);

    const handleDateChanged = (event)=> { setDate(dayjs(event.target.value)); };
    const handleStartTimeChanged = (event)=> {
        const newValue = `${date.format('YYYY-MM-DD')} ${event.target.value}`;
        setStartTime(dayjs(newValue, 'YYYY-MM-DD HH:mm:ss'));
    };
    const handleEndTimeChanged = (event)=> {
        const newValue = `${date.format('YYYY-MM-DD')} ${event.target.value}`;
        setEndTime(dayjs(newValue, 'YYYY-MM-DD HH:mm:ss'));
    };
    const handleCommentsChanged = (event)=> { setComments(event.target.value); };
    const handleTagsChanged = (event)=> {
        let opts = [], opt;
        for (let i=0, len=event.target.options.length; i<len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        setTags(opts);
    };

    const validateForm = ()=> {
        return (!date || !startTime || !endTime || !comments);
    }

    const handlePersist = ()=> {
        onPersist({ channelName, comments, date, detections, endTime, showDetections, startTime, tags, username });
    };

    return (
        <div className='Scrubber-Navigation-Export-Container'>
            <div className='Scrubber-Navigation-Export-Form'>
                <Form>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='username'>Username</Label>
                                <Input disabled type='text' id='username' value={username} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='channelName'>Channel Name</Label>
                                <Input disabled type='text' id='channelName' value={channelName} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for='date'>Date*</Label>
                                <Input type='date' id='date' value={date.format('YYYY-MM-DD')} onChange={handleDateChanged} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for='startTime'>Start Time*</Label>
                                <Input type='time' id='startTime' step='1' value={startTime.format('HH:mm:ss')} onChange={handleStartTimeChanged} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for='endTime'>End Time*</Label>
                                <Input type='time' id='endTime' step='1' value={endTime.format('HH:mm:ss')} onChange={handleEndTimeChanged} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {(!config.hideTags) &&
                    <Row form>
                        <Col>
                            <FormGroup>
                                <Label for='tags'>Tags</Label>
                                <Input multiple type='select' id='tags' value={tags} onChange={handleTagsChanged}>
                                    {userTags.map((tag, index)=> (
                                        <option key={index}>{tag.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    }
                    <Row form>
                        <Col>
                            <FormGroup>
                                <Label for='comments'>Comments*</Label>
                                <Input type='textarea' id='comments' value={comments} onChange={handleCommentsChanged} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {(showDetections) &&
                        <Row form>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Flightcode</th>
                                            <th>Detection Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detections.map((detection, index)=> (
                                            <tr key={index}>
                                                <td>{detection.flightcode}</td>
                                                <td>{detection.detectiontime}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    }
                </Form>
            </div>
            <div className='Scrubber-Navigation-Export-Controls'>
                <button className='Scrubber-Navigation-Cancel-Button' onClick={onCancel}>Cancel</button>
                <button className={clsx({ 'Scrubber-Navigation-Export-Button': true, 'disabled': validateForm() })} disabled={validateForm()} onClick={handlePersist}>
                    {((mode===modes.IMPORT) ? 'Import' : 'Export')}
                </button>
            </div>
        </div>
    );
};

Export.propTypes = {
    fields: PropTypes.object,
    userTags: PropTypes.array,
    mode: PropTypes.string,
    onCancel: PropTypes.func,
    onPersist: PropTypes.func
};