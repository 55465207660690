
import React from "react";

export function connect(props) {
    return function (WrappedComponent) {
        class ConnectedComponent extends React.Component {
            render() {
                const {forwardedRef, ...rest} = this.props;
                return (
                    <WrappedComponent {...props} {...rest}
                        ref={forwardedRef} />
                )
            }
        }
        return React.forwardRef((props, ref) => {
            return <ConnectedComponent {...props} forwardedRef={ref} />;
        });
    }
}