
export default {
    id: 'channel_group',
    label: 'Channel Group List',
    name: 'Channel Group',
    namePlural: 'Channel Groups',
    hideTitle: true, // Hides Title From Edit & View
    titleField: 'name',

    cardOptions: {
        labelField: 'name',
        descField: 'description',
        masonryOptions: { // masonryCardOptions Overrides
            transitionDuration: 100,
            gutter: 10,
            originTop: true,
            resize: true,
            fitWidth: true
        }
    },
    gridOptions: {
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 20, 50, 100, 200],
        download: false,
        print: false,
        filter: false,
        columns: []
    },
    fields: [{
        id: '_id',
        label: 'id',
        identity: true,
        type: 'text',
        width: 0,
        listColumnOptions: { display: false }, // mui-Datatable column options... display:false, width etc.
        required: false
    }, {
        id: 'name',
        label: 'Name',
        type: 'text',
        width: 100,
        required: true,
        inMany: true
    }, {
        id: 'description',
        type: 'textmultiline',
        label: 'Description',
        height: 5
    }],
    groups: [{
        id: 'p1', type: 'panel',
        label: 'Details', width: 60,
        fields: ['name', 'description']
    }, {
        id: 'p2', type: 'panel',
        label: '', width: 40,
        fields: []
    }]
}