
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

import './style.scss';

export const Programs = ({ epg=[], media, selectedChannel })=> {
    const containerRef = useRef();

    let containerWidth = 0;
    let programs = [];

    if (containerRef && containerRef.current) {
        containerWidth = containerRef.current.clientWidth;
    }

    const getUniqueListBy = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];

    const removeDupEpg = (epgArr) => getUniqueListBy(epgArr, 'key');

    if (media.readyTimestamp && containerWidth) {
        const startTime = media.readyTimestamp.subtract(2, 'minute');
        programs = removeDupEpg(epg.slice());
        programs.forEach((program)=> {
            const start = dayjs(program.start_datetimeUTC);
            const end = dayjs(program.end_datetimeUTC);
            const duration = end.diff(start, 'second');
            const potentialWidth = (((duration)/media.duration)*100);
            program.width = ((potentialWidth !== Infinity) ? potentialWidth : 0)+'%';
            program.left = ((((start.diff(startTime))/1000)/media.duration)*100)+'%';
        });
    }

    const renderProgram = (program, index)=> {
        const isChild = selectedChannel && selectedChannel.parentChannelId && selectedChannel.parentChannelId.length > 0;
        return (
            <div className={`Scrubber-Panel-Timeline-Program ${isChild ?'child' : ''}`} key={index} style={{ left: program.left, width: program.width }}>
                {program.program_title}
                <p>{program.start_datetime.substring(11, 19)} - {program.end_datetime.substring(11, 19)}</p>
            </div>
        );
    };

    return (
        <div className='Scrubber-Panel-Timeline-Programs-Container' ref={containerRef}>
            <div className='Scrubber-Panel-Timeline-Programs-Wrapper'>
                {programs.map(renderProgram)}
            </div>
        </div>
    );
};

Programs.propTypes = {
    currentTime: PropTypes.object,
    epg: PropTypes.array,
    media: PropTypes.object
};