import React from "react";
import classnames from "classnames";
import { Switch, Route, Redirect, withRouter, NavLink } from "react-router-dom";


// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";

import config from '../../constants/config';
import images from '@assets';
import { Img } from '@components';
import './WebNavbar.scss';

import MHLogo from '../../assets/img/mhlogo.png';

@withRouter
class WebNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      selected: '/web/home',
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);

    let currentSelected = this.props.location.pathname;
    this.setState({selected: currentSelected});
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    let _path = this.state.selected || '';
    let menu = [
      {
        name: 'Login',
        path: '/web/login'
      },
    ];
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <img className='webLogo' alt='logo' src={MHLogo} />
        <Container fluid>
        <Nav navbar className="ml-auto">

          {menu.map((m, idx) => {
            if(m.ext){
              return <li className="nav-item" key={idx}>
                <a className="nav-link" href={m.path}>{m.name}</a>
              </li>
            } else {
              return <NavItem key={idx}>
                <NavLink to={m.path} onClick={() => this.setState({selected: m.path})}
                className={(_path.indexOf(m.path)>-1)?'nav-link text-primary':'nav-link'}>
                  {m.name}
                </NavLink>
              </NavItem>
            }
          })}

          {/* <NavItem className=''>
            <NavLink to="/web/home" onClick={() => this.setState({selected: currentSelected})}
            className={(_path.indexOf('/web/home')>-1)?'nav-link text-primary':'nav-link'}>
              Home
            </NavLink>
          </NavItem>
          <NavItem className=''>
            <NavLink to="/web/login" 
            className={(_path.indexOf('/web/login')>-1)?'nav-link text-primary':'nav-link'}>
              Login
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/web/register" 
            className={(_path.indexOf('/web/register')>-1)?'nav-link text-primary':'nav-link'}>
              Register
            </NavLink>
          </NavItem> */}
        </Nav>
          
          {/* <Collapse isOpen={this.state.collapseOpen} navbar>
            <Nav navbar className="ml-auto">
              <NavItem>
                <NavLink
                  to="/home/dashboard"
                  className="nav-link text-primary"
                >
                  <i className="tim-icons icon-minimal-left" /> Back to
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/register" className="nav-link">
                  <i className="tim-icons icon-laptop" /> Register
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/login" className="nav-link">
                  <i className="tim-icons icon-single-02" /> Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/pricing" className="nav-link">
                  <i className="tim-icons icon-coins" /> Pricing
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/lock-screen" className="nav-link">
                  <i className="tim-icons icon-lock-circle" /> Lock
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse> */}
        </Container>
      </Navbar>
    );
  }
}

export default WebNavbar;
