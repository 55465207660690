import adminStore from './adminStore';
import authStore from './authStore';
import dashboardStore from './dashboardStore';
import commonStore from './commonStore';
import epgStore from './epgStore';
import channelStore from './channelStore';
import loadingStore from './loadingStore';
import playerStore from './playerStore';
import scrubberStore from './scrubberStore';
import scrubberPlayerStore from './scrubberPlayerStore';
import viewStore from './viewStore';

import rechannelStore from './rechannelStore';
import replayerStore from './replayerStore';
import rescrubberStore from './rescrubberStore';

const stores = {
    adminStore,
    authStore,
    dashboardStore,
    channelStore,
    commonStore,
    epgStore,
    loadingStore,
    playerStore,
    scrubberStore,
    scrubberPlayerStore,
    viewStore,
    
    rechannelStore,
    replayerStore,
    rescrubberStore
}

export default stores;