
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import queryString from 'query-string';

import { Button } from 'reactstrap';

import { /*MdFullscreen, MdFullscreenExit, */MdPause, MdPlayArrow } from 'react-icons/md';

import config from '../../constants/config';
import { HLSPlayer } from '../../components/HLSPlayer';

import './style.scss';

@inject('replayerStore')
@observer
class Live extends React.Component {
    constructor(props) {
        super(props);

        this.player = React.createRef();

        this.handleFullscreenEnter = this.handleFullscreenEnter.bind(this);
        this.handleFullscreenExit = this.handleFullscreenExit.bind(this);

        this.handlePause = this.handlePause.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
    }

    componentDidMount() {
        const { replayerStore } = this.props;

        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString && parsedQueryString.channelId) {
            const source = `${config.live}/${parsedQueryString.channelId}/source.m3u8`;
            replayerStore.setSource(source);
        }
    }

    componentDidUpdate() {
        const { replayerStore } = this.props;

        if (this.player && this.player.current) {
            if (!replayerStore.hls) {
                replayerStore.setPlayer(this.player.current);
                replayerStore.setupHLS();

                // TODO: HACK to seek to live-location
                setTimeout(()=> { this.seekToLive(); }, 1000);
            }
        }
    }

    seekToLive() {
        const { replayerStore } = this.props;

        replayerStore.setCurrentTime(replayerStore.player.duration-120);
    }

    handleFullscreenEnter(event) {
        const { replayerStore } = this.props;
        replayerStore.setIsFullscreen(true);
    }

    handleFullscreenExit(event) {
        const { replayerStore } = this.props;
        replayerStore.setIsFullscreen(false);
    }

    handlePause() {
        const { replayerStore } = this.props;
        replayerStore.pause();
    }

    handlePlay() {
        const { replayerStore } = this.props;
        replayerStore.play();
    }

    render() {
        const { replayerStore } = this.props;
        const { source, setupHLS } = replayerStore;

        return (
            <div className='Live-Container'>
                <div className='Live-Player-Container'>
                    <div className='Live-Player-Wrapper'>
                        <HLSPlayer
                            controls={false}
                            innerRef={this.player}
                            source={source}
                            style={{ width: '100%' }}
                            onFullscreenEnter={this.handleFullscreenEnter}
                            onFullscreenExit={this.handleFullscreenExit}
                            onPause={this.handlePause}
                            onPlay={this.handlePlay}
                            onSetupHLS={setupHLS} />
                        <div className='Live-Player-Controls-Container'>
                            {(replayerStore.isPlaying) ?
                                <Button onClick={this.handlePause}>
                                    <MdPause size={24} />
                                </Button> :
                                <Button onClick={this.handlePlay}>
                                    <MdPlayArrow size={24} />
                                </Button>
                            }
                            {/* TODO: Read up on removing Fullscreen controls... */}
                            {/*(replayerStore.isFullscreen) ?
                                <Button onClick={this.handleFullscreenExit}>
                                    <MdFullscreenExit size={24} />
                                </Button> :
                                <Button onClick={this.handleFullscreenEnter}>
                                    <MdFullscreen size={24} />
                                </Button>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Live;

Live.propTypes = {};