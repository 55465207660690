
import { observable, action } from 'mobx';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import epgStore from './epgStore';
import scrubberStore from './scrubberStore';

dayjs.extend(isBetween);

const defaultData = {
    canPlay: false,
    currentTime: 0,
    duration: 0,
    isPlaying: false,
    isReady: false,
    playbackRate: 1,
    player: null,
    readyTimestamp: null,
    segmentDuration: 30,
    segmentInterval: 5,
    source: null,
};

class PlayerStore {
    @observable canPlay = false;
    @observable currentTime = 0;
    @observable duration = 0;
    @observable isPlaying = false;
    @observable isReady = false;
    @observable playbackRate = 1;
    @observable player = null;
    @observable readyTimestamp = null;
    @observable segmentDuration = 30;//30.167; //30
    @observable segmentInterval = 5;
    @observable source = null;

    resetPlayer() {
        console.log('Clearing Player');
        this.pause();
        this.setCanPlay(false);
        this.setIsReady(false);
        this.setCurrentTime(0, false, true);
        this.setDuration(0);
        this.setPlaybackRate(1);
    }

    @action setPlayer(player) {
        if (player) {
            console.log('Setting new Player');
            this.player = player;
        }
    }

    @action setSource(source, resetPlayer=true) {
        if (this.source !== source) {
            this.source = source;

            //scrubberStore.setSource(source);
            
            //if (resetPlayer) {
            //    this.resetPlayer();
            //}
        }
    }

    @action setPlaybackRate(playbackRate) {
        if (this.playbackRate !== playbackRate) {
            this.playbackRate = playbackRate;

            scrubberStore.setPlaybackRate(playbackRate);

            if (this.player) {
                this.player.playbackRate = playbackRate;
            }
        }
    }

    @action setReadyTimestamp(readyTimestamp) {
        if (readyTimestamp) {
            if (!this.readyTimestamp || (this.readyTimestamp.unix() !== readyTimestamp.unix())) {
                this.readyTimestamp = readyTimestamp;
            }
        }
    }

    @action setIsPlaying(isPlaying) {
        console.log(isPlaying);
        if (this.isPlaying !== isPlaying) {
            this.isPlaying = isPlaying;

            scrubberStore.setIsPlaying(isPlaying);
        }
    }

    @action play() {
        if (!this.isPlaying) {
            if (this.player) {
                this.player.play();
            }
            this.setIsPlaying(true);
        }
    }

    @action pause() {
        if (this.isPlaying) {
            if (this.player) {
                this.player.pause();
            }
            this.setIsPlaying(false);
        }
    }

    @action setCanPlay(canPlay) {
        if (this.canPlay !== canPlay) {
            this.canPlay = canPlay;

            scrubberStore.setCanPlay(canPlay);

            if (canPlay) {
                console.log('Player Can Play');
            }
        }
    }

    @action setCurrentTime(currentTime, updateSelectedDate=true, updatePlayer=false) {
        if (this.isReady) {                                                     // Do not respond if the player is NOT ready.
            if (Math.floor(this.currentTime) !== Math.floor(currentTime)) {     // Flooring this, as we only want to respond to seconds.
                this.currentTime = currentTime;

                scrubberStore.setCurrentTime(Math.floor(currentTime));

                if (this.readyTimestamp && updateSelectedDate) {
                    const startPoint = this.readyTimestamp.subtract(Math.floor((this.duration/2)), 'second');
                    const endPoint = startPoint.add((this.segmentDuration*60), 'second');

                    const newDate = startPoint.add(Math.floor(this.currentTime), 'second');
                    const firstSegment = startPoint.add((this.segmentInterval*60), 'second');
                    const lastSegment = endPoint.subtract((this.segmentInterval*60), 'second');

                    const inFirstSegment = newDate.isBetween(startPoint, firstSegment);
                    const inLastSegment = newDate.isBetween(endPoint, lastSegment);

                    if (inFirstSegment || inLastSegment) {
                        this.setIsReady(false);                                // Switches off the players' response to time-updates.
                        epgStore.setSelectedDateUTC(newDate, true, true);         // A new source should be loaded for the player.
                    } else if (newDate.unix() !== epgStore.selectedDate.unix()) {
                        epgStore.setSelectedDateUTC(newDate, false);
                    }
                }

                if (updatePlayer) {
                    this.player.currentTime = currentTime;
                }
            }
        }
    }

    @action setDuration(duration) {
        if (this.duration !== duration) {
            this.duration = duration;

            scrubberStore.setDuration(duration);
        }
    }

    @action setSegmentDuration(segmentDuration) {
        if (this.segmentDuration !== segmentDuration) {
            this.segmentDuration = segmentDuration;
        }
    }

    @action setSegmentInterval(segmentInterval) {
        if (this.segmentInterval !== segmentInterval) {
            this.segmentInterval = segmentInterval;
        }
    }

    @action setIsReady(isReady) {
        if (this.isReady !== isReady) {
            this.isReady = isReady;

            scrubberStore.setIsReady(isReady);

            if (isReady && this.player) {
                console.log('Seeking to Center');
                //this.player.currentTime = Math.floor(this.player.duration/2);
                this.setCurrentTime((Math.floor(this.player.duration/2)), false, true);
                this.setReadyTimestamp(epgStore.selectedDate);      // Capture when playback will start.
            } else {
                this.setReadyTimestamp(null);
            }
        }
    }

    @action clearStore() {
        Object.keys(defaultData).forEach(key => {
            this[key] = defaultData[key];
        });
    }
}

// const playerStore = SIOStore(PlayerStore, 'ALL');
const playerStore = new PlayerStore();
export default playerStore;