
export const colors = {
  primary: '#009cbd', // rgb(0, 156, 189);
  primaryLight: '#00c9f4',
  secondary: '#ffa300', //rgb(255, 163, 0);
  secondaryLight: '#ffcc00',

  transparent: 'transparent',

  mainBackground: '#001024',
  secondBackgound: '#002149',
  thirdBackgound: '#002d64',
  fourthBackground: '#002b63',
  white: '#ffffff',
  black: '#000000',

  textColor: '#292929',
  textColorLight: '#fff',

  boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  primaryBoxShadow: "0 12px 20px -10px rgba(0, 156, 189, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 156, 189, 0.2)",
  secondaryBoxShadow: "0 12px 20px -10px rgba(255, 163, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 163, 0, 0.2)",
  infoBoxShadow: "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
  successBoxShadow: "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)",
  warningBoxShadow: "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
  dangerBoxShadow: "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
  purpleBoxShadow: "0 12px 20px -10px rgba(171, 71, 188, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(171, 71, 188, 0.2)",
  roseBoxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",

  primaryCardHeader: "linear-gradient(60deg, #00c9f4, #009cbd)",
  secondaryCardHeader: "linear-gradient(60deg, #ffcc00, #ffa300)",
  orangeCardHeader: "linear-gradient(60deg, #ffa726, #fb8c00)",
  greenCardHeader: "linear-gradient(60deg, #66bb6a, #43a047)",
  redCardHeader: "linear-gradient(60deg, #ef5350, #e53935)",
  blueCardHeader: "linear-gradient(60deg, #26c6da, #00acc1)",
  purpleCardHeader: "linear-gradient(60deg, #ab47bc, #8e24aa)",
  roseCardHeader: "linear-gradient(60deg, #ec407a, #d81b60)",

  cardBoxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.4)',

  headerBgd: '#001024',
  authBackground: '#373f43',
  shadow: '#323232',
  menuItemColor: '#a1a1a1',
  headerItemBoxShadow: 'rgb(26, 26, 26) 0px 5px 10px',
  headerItemBackgroundColor: '#273336',
  selectedMenuListItemBackgroundColor: '#36464a',

  primary1Color: '#4dd0e1',
  primary2Color: '#00bcd4',
  accent1Color: '#ffa726',
  accent2Color: '#ff9800',
  accent3Color: '#ffb74d',
  primary3Color: '#bdbdbd',

  warningColor: "#ff9800",
  dangerColor: "#f44336",
  successColor: "#4caf50",
  infoColor: "#009cbd",
  roseColor: "#e91e63",
  grayColor: "#999999"
};

