import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Global Wrapper for Socket : Once the component is wrapped, 
 * use IOConnect(childComponent) or @IOConnect on any child component
 * to retrieve the socket context.
 */
class IOProvider extends Component {
  static propTypes = {
    socket: PropTypes.oneOfType([
      PropTypes.bool, PropTypes.object
    ]),
  };

  static defaultProps = {
    socket: false,
  };

  static childContextTypes = {
    socket: PropTypes.oneOfType([
      PropTypes.bool, PropTypes.object
    ]),
  };

  getChildContext() {
    return {
      socket: this.props.socket,
    };
  }

  render() {
    return this.props.children;
  }
}

export default IOProvider;