
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import mUtils from '../utils/modelUtils';
import validation from '../utils/validation';

import Alert from '../components/Alert';
import Field from '../components/Field';
import Panel from '../components/Panel';

import { Button } from '@material-ui/core';
import { Save, ArrowBack } from '@material-ui/icons';

Modal.setAppElement('#root');

class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data || {},
            error: false,
            invalid: false
        };

        this.clickSave = this.clickSave.bind(this);
        this.fieldChange = this.fieldChange.bind(this);
        this.setDeltaField = this.setDeltaField.bind(this);
        this.validate = this.validate.bind(this);
        this.clearValidation = this.clearValidation.bind(this);
        this.uploadFileOne = this.uploadFileOne.bind(this);

        //Tags Callbacks
        this.tags_handleAddition = this.tags_handleAddition.bind(this);
        this.tags_handleDelete = this.tags_handleDelete.bind(this);

        this.handleTags = this.handleTags.bind(this);
        this.groupMapping = this.groupMapping.bind(this);
    }

    componentDidMount() {
        if (!this.props.data) {
            let NewData = {};

            this.props.entity.fields.forEach((f) => {
                NewData[f.id] = null;
            });
            this.setState({ data: NewData });
        }
    }

    async clickSave() {
        if (this.model) {
            const fields = this.model.fields,
                v = this.validate(fields, this.state.data)
            if (v.valid) {
                await this.props.editOne(this.state.data);
            } else {
                this.setState({
                    invalid: !v.valid,
                    invalids: v.invalids
                })
            }
        }
    }

    fieldChange(evt) {
        let fid = null, newData = null, v = null;
        if (evt.target && evt.target.type === 'color' && evt.target.id && evt.target.value) {
            fid = evt.target.id;
            v = evt.target.value; //value in as RGB, convert to Hex
            newData = JSON.parse(JSON.stringify(this.state.data || {}));
        } else {
            fid = evt.target.id;
            newData = JSON.parse(JSON.stringify(this.state.data || {}));
            v = evt.target.value;
            if (evt.target.type === 'checkbox') {
                v = evt.target.checked;
            }
        }

        // Update
        newData[fid] = v;
        this.setDeltaField(fid, v);
        this.setState({ data: newData });
    }

    setDeltaField(fid, value) {
        if (!this.delta) {
            this.delta = {}
        }
        this.delta[fid] = value;
        this._dirty = true
    }

    validate(fields, data) {
        let messages = [],
            invalids = {},
            cMsg;

        this.clearValidation();

        fields.forEach((f) => {
            if (f.required && !data[f.id]) {
                this.setState({ invalid: true, message: `Missing data for ${f.id}` });
            }

            cMsg = validation.validateField(f, data[f.id]);
            if (cMsg) {
                messages.push(cMsg);
                invalids[f.id] = true;
                this.setState({ invalid: true, message: cMsg });
            }
        });

        return {
            valid: messages.length < 1,
            messages: messages,
            invalids: invalids
        }
    }

    clearValidation() {
        if (this.model) {
            this.setState({ invalid: false, message: null, invalids: null });
        }
    }

    uploadFileOne(field, data) {
        let _data = this.state.data;
        _data[field] = data;
        this.setState({ data: _data });
    }

    //Tags/Lists
    tags_handleDelete(field, i) {
        console.log(`[tags_handleDelete] ${field} ${JSON.stringify(i)}`);
        const data = JSON.parse(JSON.stringify(this.state.data)); //Copy

        data[field] = (data[field]) ? data[field].filter((tag, index) => index !== i) : null;

        this.setDeltaField(field, data[field]);
        this.setState({ data: data });
    }

    tags_handleAddition(field, tag) {
        let ent = this.props.entity.fields.filter(f => f.id === field)[0];
        const data = JSON.parse(JSON.stringify(this.state.data)); //Copy
        if (ent && ent.type) {
            console.log(`[tags_handleAddition] ${field} - ${ent.type} ${JSON.stringify(tag)}`);
            if (ent.type === 'tags') {
                data[field] = ((data[field]) ? [...data[field], tag.text] : [tag.text]);
            } else {
                data[field] = ((data[field]) ? [...data[field], tag] : [tag]);
            }
        }
        this.setDeltaField(field, data[field]);
        this.setState({ data: data });
    }


    handleTags(field, regularTags) {
        const data = JSON.parse(JSON.stringify(this.state.data)); //Copy
        data[field] = regularTags;

        this.setDeltaField(field, data[field]);
        this.setState({ data: data });
    }

    groupMapping(m, g, idx, fnField) {
        const groupFields = mUtils.fieldId2Field(g.fields, m.fields);
        const _width = (g.width - 2);
        return (
            <Panel key={g.id || ('g' + idx)}
                collapsible={g.collapsible || false}
                noHeading={g.noLabel || false}
                title={g.label || g.title || ''}
                width={_width}>
                <div className='evol-fset'>
                    {(groupFields) &&
                        groupFields.map(fnField)
                    }
                    {(g.groups) &&
                        g.groups.map((gg, idx) => (this.groupMapping(m, gg, idx, fnField)))
                    }
                </div>
            </Panel>
        );
    }


    render() {
        const isNew = (this.state.data && Object.keys(this.state.data).length > 0) ? false : true,
            { entity = null, data, options } = this.props,
            m = entity,
            _data = this.state.data, //|| data || {},
            cbs = {
                //click: this.fieldClick,
                change: this.fieldChange,
                //leave: this.fieldLeave,
                dropFile: this.uploadFileOne,

                tags_handleDelete: this.tags_handleDelete,
                tags_handleAddition: this.tags_handleAddition,
                //tags_handleDrag: this.tags_handleDrag

                handleTags: this.handleTags
            },
            title = mUtils.dataTitle(m, _data, isNew);
        const fnField = (f) => {
            if (f) {
                if (['list', 'tags'].indexOf(f.type) > -1) {
                    return (
                        <Field
                            key={f.id}
                            meta={f}
                            readOnly={false}
                            value={_data[f.id]}
                            data={_data}
                            callbacks={cbs}
                            entity={entity}
                            setViewState={this.props.setViewState} />
                    );

                } else {
                    return (
                        <Field
                            key={f.id}
                            meta={f}
                            readOnly={false}
                            value={_data[f.id]}
                            data={_data}
                            callbacks={cbs}
                            entity={entity}
                            setViewState={this.props.setViewState} />
                    );
                }
            }
            return null;
        }

        this.isNew = isNew;
        this.model = m;

        if (!m) {
            return (
                <Alert title='Error' message={`Invalid parameter: entity=${entity.id}.`} />
            );
        } else {
            return (
                <div className='evo-one'>
                    {(title) &&
                        <h3 className='evo-page-title'>{title}</h3>
                    }
                    <div className='evo-one-edit'>
                        {this.state.error ?
                            (
                                <Alert title='Error' message={this.state.error.message} />
                            ) :
                            (
                                <div className='evol-pnls'>
                                    {(m && m.groups) ? (
                                        m.groups.map((g, idx) => (
                                            this.groupMapping(m, g, idx, fnField)
                                        ))
                                    ) :
                                        (
                                            <Panel title={title} key='pAllFields'>
                                                <div className='evol-fset'>
                                                    {m.fields.map(fnField)}
                                                </div>
                                            </Panel>
                                        )}
                                    {(m.collections) &&
                                        m.collections.map((c, idx) => (
                                            <Panel>
                                                {/* 
											<List key={'collec'+idx}
												params={this.props.params} 
												paramsCollec={c}
												style={{width:'100%'}}
												location={this.props.location} />
										*/}
                                            </Panel>
                                        ))
                                    }
                                    <Panel key='formButtons'>
                                        <div className='evol-buttons'>
                                            {(!options.hideSaveBtn) &&
                                                <Button size='medium' variant='contained' className='evo-browse-btn save' onClick={this.clickSave} color='secondary'>
                                                    <Save /> SAVE
                                                </Button>
                                            }
                                            {(!options.hideBackBtn) &&
                                                <Button size='medium' variant='outlined' className='evo-browse-btn' onClick={() => this.props.setViewState('back')} color='primary'>
                                                    <ArrowBack /> BACK
                                                </Button>
                                            }
                                            <span className='evol-err-msg'>{(this.state.invalid || this.state.error) ? 'Missing information.' : null}</span>
                                        </div>
                                    </Panel>
                                </div>
                            )}
                    </div>
                </div>
            )
        }
    }
}

Edit.propTypes = {
    entity: PropTypes.object.isRequired,
    data: PropTypes.object,

    // Actions
    editOne: PropTypes.func.isRequired,
    postEdit: PropTypes.func,
    options: PropTypes.object,
    setViewState: PropTypes.func.isRequired
};

export default Edit;