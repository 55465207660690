// List view to display a collection as a list (table w/ sorting and paging).

import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'mui-datatables';
import dataUtils from '../utils/dataUtils';

//import merge from 'lodash.merge';
import {isMobile} from 'is-mobile';
import { Img } from '@components';

// Components & Icons
import IconButton from '../../complex/IconButton';
import { Edit, GetApp, Pageview, CheckBox, CheckBoxOutlineBlank, Launch } from '@material-ui/icons';

import config from '../../../constants/config';

class List extends React.Component {

  _isMounted = false;
	constructor(props){
		super(props);

		this.state ={
			viewId: 'list',
      data: [],
      dataClean: [],
      columns: [],
      options: {},
			loading: false
    }
    
    this.getData = this.getData.bind(this);
    this.deleteRows = this.deleteRows.bind(this);
    this.checkTypeAndCreateControl = this.checkTypeAndCreateControl.bind(this);
  }

  // componentWillMount() {
  //   this.setState({
  //     data: [],
  //     loading: false
  //   });
  // }

  componentWillUnmount(){
    this._isMounted = false; // To check state before setting
  }
  

  componentDidMount() {
    this._isMounted = true;
    this.getData();

    if(this.props.refreshData){
      this.props.refreshData(this.getData);
    }

    // Setup DataTable Options
    if(this.props.listViewOptions){
      let gridOptions = {...this.state.options, ...this.props.listViewOptions};
      gridOptions.onRowsDelete = this.deleteRows; // Inject Function to Options
      this.setState({options: gridOptions});
    }
  }

  // async deleteRows(rows){
  //   if(this.props.deleteMany && rows.data){
  //     let _rows = [];
  //     rows.data.forEach(elm => {
  //       if(elm.dataIndex || elm.dataIndex > -1){
  //         _rows.push(this.state.dataClean[elm.dataIndex]);
  //       } else if(elm.index || elm.index > -1){
  //         _rows.push(this.state.dataClean[elm.index]);
  //       }
  //     });
  //     await this.props.deleteMany(_rows);
  //   }

  //   return false;
  // }

  deleteRows(rows){
    if(this.props.deleteMany && rows.data){
      let _rows = [];
      rows.data.forEach(elm => {
        if(elm.dataIndex || elm.dataIndex > -1){
          _rows.push(this.state.dataClean[elm.dataIndex]);
        } else if(elm.index || elm.index > -1){
          _rows.push(this.state.dataClean[elm.index]);
        }
      });
      this.props.deleteMany(_rows);
    }

    return false;
  }

  checkTypeAndCreateControl(_key, val, entity, payload = null){
    let entField = entity.fields.filter(f => f.id === _key)[0];
    if(entField && entField.type){

      if(isMobile()){
        if(entField.listColumnOptions && !entField.listColumnOptions.showOnMobile){
          return null;
        }
      }

      switch(entField.type){
        case 'image':
          let source = '';
          if(val && typeof val === 'string'){
            source = val;
          } else if(val && typeof val === 'object' && val.source){
            source = val.source;
          }

          if(payload && entField.objField){
            let _val = payload[entField.objField];
            if(_val){
              source = (_val.source)?_val.source:_val;
            }
          }
          return (
            <Img src={source} 
              alt={_key || 'evoImage'} 
              size='64'
              itemObj={payload}
              key={new Date().getTime()}
              className='evo-grid-images' />
          );
        case 'color':
          if(val.indexOf('#') > -1){
            return <div className={`evol-colorIndicatorTable ${(entField && entField.asTableBackground)?'useBackground':''}`} style={{backgroundColor: `${val}`}}></div>
          } else {
            return <div>No Color</div>
          }
        case 'boolean':
          if(val === true || val === 'true'){
            return <CheckBox color='primary' />
          } else {
            return <CheckBoxOutlineBlank color='primary'/>
          }
        case 'lov':
          if(entField.list){
            let txt = entField.list.filter(f=>f.id === val);
            if(txt && txt[0] && txt[0].text){
              return txt[0].text;
            } else {
              return val;
            }
          } else {
            return val;
          }
        case 'lov_sub':
          if(entField.list){
            let txt = entField.list.filter(f=>f.id === val);
            if(txt && txt[0] && txt[0].text){
              return txt[0].text;
            } else {
              return val;
            }
          } else {
            return val;
          }
        default:
          break;
      }
    }
    return val;
  }

  async getData(refresh){
    let data = await this.props.getData(refresh);
    if(data && data.payload && data.payload.length > 0){
      let payload = data.payload.slice(0); // Copy

      //Transpile Data

      let columns = [];
      let colFields = [];

      if(this.props.entity && this.props.entity.gridOptions && 
        this.props.entity.gridOptions.columns && this.props.entity.gridOptions.columns.length > 0){
          this.props.entity.gridOptions.columns.forEach(col => {
            if(col.field && col.name){
              columns.push({name: col.name, options: col.options})
              colFields.push(col.field);
            }
          });
      } else if(this.props.entity && this.props.entity.fields){
        this.props.entity.fields.forEach(field => {
          if(field.id){
            colFields.push(field.id);
            columns.push({
              name: field.label || field.id, 
              options: field.listColumnOptions||{}
            });
          }
        });
      } else {
        for(let key in payload[0]){
          if(key === 'id'){
            colFields.push(key);
            columns.push({name: key, options: {display:false}});
          } else {
            colFields.push(key);
            columns.push(key);
          }
        }
      }

      //Filter Through and replace with Controls where needed
      let newDataPayload = [];
      payload.forEach(obj => {
        let newObj = {};
        for(let key in obj){
          newObj[key] = this.checkTypeAndCreateControl(key, obj[key], this.props.entity, obj);
        }
        newDataPayload.push(newObj);
      });

      let gridData = dataUtils.objToDataTableArrayWithColumns(newDataPayload, colFields);
      gridData = dataUtils.validateAndFix(gridData, columns);

      //Edit btn
      columns.push('');
      gridData.forEach((elm, idx) => {
        let payloadItem = payload[idx];//.filter(f => f[0] === elm[0])[0];
        
        // Check for - noEditViewField
        let allowBtns = true;
        let editViewFieldText = null;
        let showTranscoding = false;
        let allowView = false;

        if(this.props.entity && 
          this.props.entity.noEditViewField){
          if(Array.isArray(this.props.entity.noEditViewField)){
            this.props.entity.noEditViewField.forEach(elem => {
              let prop = elem.field;
              let check = (elem.rev)?!payloadItem[prop]:payloadItem[prop];
              if(allowBtns && !check){
                editViewFieldText = (elem.textField)?payloadItem[elem.textField]:elem.text;
                allowView = elem.allowView || false;
                if(elem.showTrans){
                  showTranscoding = true;
                }
              }
              allowBtns = allowBtns && check;
            });
          } else {
            let prop = this.props.entity.noEditViewField.field;
            let check = (this.props.entity.noEditViewField.rev)?!payloadItem[prop]:payloadItem[prop];
            allowBtns = check;
            if(!check){
              editViewFieldText = (this.props.entity.noEditViewField.textField)?payloadItem[this.props.entity.noEditViewField.textField]:this.props.entity.noEditViewField.text;
              allowView = this.props.entity.noEditViewField.allowView || false;
              if(this.props.entity.noEditViewField.showTrans){
                showTranscoding = true;
              }
            }
          }
        }
        
        if(allowBtns){
          elm.push(
            <div className='evo-list-editbtns'>
            {(this.props.noEdit)?null:
              <IconButton className='evo-list-editbtns' color="secondary" onClick={(elm) => {this.props.setViewState('edit', payloadItem)}}>
                <Edit />
              </IconButton>
            }
              <IconButton className='evo-list-editbtns' color="info" onClick={(elm) => {this.props.setViewState('browse', payloadItem)}}>
                <Pageview />
              </IconButton>
            </div>
          );
        } else if(!allowBtns && allowView){
          elm.push(
            <div className='evo-list-editbtns'>
              <IconButton className='evo-list-editbtns' color="info" onClick={(elm) => {this.props.setViewState('browse', payloadItem)}}>
                <Pageview />
              </IconButton>
            </div>
          );
        } else if (this.props.entity.newButtons) {
          const { allowBreakout, allowDownload, allowScrubber } = this.props.entity.newButtons

          const renderElements = ()=> {
            const renderBreakout = ()=> {
              return (
                (allowBreakout) &&
                <div className='evo-list-editbtns'>
                  <IconButton className='evo-list-editbtns'
                    color="info"
                    onClick={(elm) => { window.open(`${config.serverURL}/home/breakout?hh=true&src=${payloadItem.url}`, '_blank').focus(); }}>
                    <Launch />
                  </IconButton>
                </div>
              );
            };
            const renderDownload = ()=> {
              return (
                (allowDownload) &&
                <div className='evo-list-editbtns'>
                  <IconButton className='evo-list-editbtns' color="secondary"
                    onClick={(elm) => {
                      let endpoint = `${config.serverURL}/${payloadItem.url}`;
    
                      window.URL = window.URL || window.webkitURL;
                      
                      const xhr = new XMLHttpRequest();
                      const link = document.createElement('a');
                      xhr.open('GET', endpoint, true);
                      xhr.responseType = 'blob';
                      xhr.onload = function () {
                          const file = new Blob([xhr.response], { type : 'application/octet-stream' });
                          link.href = window.URL.createObjectURL(file);
                          link.download = (`${payloadItem.label}_${payloadItem.times}.mp4`).toLocaleLowerCase().replace(/ /g, '_');
                          link.click();
                      };
                      xhr.send();
                    }}>
                    <GetApp />
                  </IconButton>
                </div>
              );
            };
            const renderScrubber = ()=> {
              return (
                (allowScrubber) &&
                <div className='evo-list-editbtns'>
                  <IconButton className='evo-list-editbtns'
                    color="info"
                    onClick={(elm) => {
                      console.log(payloadItem);
                      window.open(`${config.serverURL}/home/scrubber?ct=${new Date(payloadItem.start_time*1000).getTime()}&sc=${payloadItem.channel.id}`, '_blank').focus();
                    }}>
                    <Launch />
                  </IconButton>
                </div>
              );
            };

            return (
              <>
                {renderBreakout()}
                {renderDownload()}
                {renderScrubber()}
              </>
            );
          };

          const elements = renderElements();
          elm.push(elements);
        } else {
          // TODO: Make Generic - add Progress Bar
          if(this.props.entity && editViewFieldText){
            if(showTranscoding){
              let _color = (editViewFieldText > 50)?((editViewFieldText > 80)?'green':'orange'):'red';
              elm.push(
                <div className='transcodeProgress'>
                  Transcoding<br/><div className={`transcodeProgressPerc ${_color}`}>{editViewFieldText}%</div>
                </div>
              );
            } else {
              elm.push(
                <div className='noEditMsg'>
                  {editViewFieldText}
                </div>
              );
            }
          }
        }
      });

      if(this._isMounted){
        this.setState({
          data: gridData,
          dataClean: payload,
          columns: columns,
          loading: false
        });
      }


    } else {
      if(this._isMounted){
        this.setState({error: {title: 'Error', message: 'Couldn\'t retrieve data.'} ,loading: false});
      }
    }
  } 

	render(){
    let name = (this.props.entity && this.props.entity.namePlural)?this.props.entity.namePlural:'data';
    if(this.state.data && this.state.data.length > 0){
      return (
        <DataTable 
          data={this.state.data} 
          columns={this.state.columns} 
          options={this.state.options}
        />
      );
    } else {
      return (
        <>
          <hr/>
          <h1>No {name} to display.</h1>
        </>
      );
    }		
	}
}

List.propTypes = {
	entity: PropTypes.object.isRequired,
	//paramsCollec: PropTypes.object,
  pageSize: PropTypes.number,
  listViewOptions: PropTypes.object,
  refreshData: PropTypes.func,

  noEdit: PropTypes.bool,

	// Actions
	getData: PropTypes.func.isRequired,
	deleteMany: PropTypes.func.isRequired,
	setViewState: PropTypes.func.isRequired,
};

export default List;
