import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import queryString from 'query-string';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert';

import Cookies from 'js-cookie';

import { UncontrolledAlert } from 'reactstrap';

import googleAnalytics from '../../services/googleAnalytics';

// core components
import { AdminNavbar, Footer, Sidebar, Loading, Status } from '@components';

import CookieBanner from '../../components/CookieBanner';

import getRoutes from '../../routes/routes';

var ps;

@inject('commonStore', 'authStore')
@withRouter
@observer
class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor: 'blue',
            sidebarMini: true,
            opacity: 0,
            sidebarOpened: false,
            notifyRunner: null,
            showCookieBanner: false,
        };

        this.routes = [];
    }
    async componentDidMount() {
        const { commonStore, authStore, history } = this.props;
        const { notifyRunner } = this.state;

        // Check Authenticated
        const isAdmin = await authStore.isAdmin();
        const isManager = await authStore.isManager();
        const isUser = await authStore.isUser();
        this.routes = getRoutes(isAdmin, isManager, isUser);
        
        if (!commonStore.isAuthenticated()) {
            // Not Auth
            authStore.logout().then(() => {
                history.push('/web/login');
                window.location.reload();
            }).catch(() => {
                history.push('/web/login');
                window.location.reload();
            });
        }

        if (navigator.platform.indexOf('Win') > -1) {
            document.documentElement.className += ' perfect-scrollbar-on';
            document.documentElement.classList.remove('perfect-scrollbar-off');
            ps = new PerfectScrollbar(this.refs.mainPanel);
            if (document) {
                let tables = document.querySelectorAll('.table-responsive');
                for (let i = 0; i < tables.length; i++) {
                    ps = new PerfectScrollbar(tables[i]);
                }
            }
        }

        if (!notifyRunner || notifyRunner === null) {
            // Check for notifications every 5 sec
            let _this = this;
            let nofityInt = setInterval(() => {
                _this.handleNotifications();
            }, 5 * 1000);

            this.setState({ notifyRunner: nofityInt });
        }
        this.setState({ showCookieBanner: Cookies.get(`accept-cookies-${process.env.REACT_APP_VERSION}`) });
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps.destroy();
            document.documentElement.className += ' perfect-scrollbar-off';
            document.documentElement.classList.remove('perfect-scrollbar-on');
        }
    }
    componentDidUpdate(e) {
        if (e.location.pathname !== e.history.location.pathname) {
            if (navigator.platform.indexOf('Win') > -1) {
                if (document) {
                    let tables = document.querySelectorAll('.table-responsive');
                    for (let i = 0; i < tables.length; i++) {
                        ps = new PerfectScrollbar(tables[i]);
                    }
                }
            }
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }
    showNavbarButton = (evt) => {
        if (
            document.documentElement.scrollTop > 50 ||
            document.scrollingElement.scrollTop > 50 ||
            this.refs.mainPanel.scrollTop > 50
        ) {
            this.setState({ opacity: 1 });
        } else {
            if ((document.documentElement.scrollTop <= 50) || (document.scrollingElement.scrollTop <= 50) || (this.refs.mainPanel.scrollTop <= 50)) {
                this.setState({ opacity: 0 });
            }
        }
    };

    /**
     * Recursively maps the routes where the 'layout' is '/home'.
     * @param {Array<object>} routes - The routes to map. 
     * @returns {Array<object>}
     */
    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.views) {
                return this.getRoutes(prop.views);
            }
            if (prop && prop.layout === '/home') {
                return (
                    <Route key={key}
                        path={prop.path}
                        component={prop.component} />
                );
            }
            return (
                <Redirect to='/home/epg' />
            );
        });
    };
    getActiveRoute = (routes) => {
        const { location, authStore } = this.props;

        const recursedRoutes = [];
        for (let i=0; i< routes.length; i++) {
            const route = routes[i];
            for (let j=0; j<route.views.length; j++) {
                recursedRoutes.push(route.views[j]);
            }
        }
        const locationSegments = location.pathname.split('/').splice(1).map((segment)=> { return `/${segment}`; });
        const activeRoute = recursedRoutes.find((route)=> (route.path === locationSegments.join('')));

        authStore.getUser().then((currentUser)=> {
            const username = (currentUser) ? currentUser.username : '';
            googleAnalytics.sendGAEvent(`${username}`, `[PageView] ${window.location.pathname}`);
        });
        return ((activeRoute) ? activeRoute.name : '');
    };
    handleActiveClick = color => {
        this.setState({ activeColor: color });
    };
    handleMiniClick = () => {
        if (document.body.classList.contains('sidebar-mini')) {
            this.setState({ sidebarMini: false });
        } else {
            this.setState({ sidebarMini: true });
        }
        document.body.classList.toggle('sidebar-mini');
    };
    handleNotifications = () => {
        const { commonStore } = this.props;
        let _messages = commonStore.getNotificationMsg();
        _messages.forEach(message => {
            let idx = message.idx;
            let options = {
                place: 'br',
                message: message.msg,
                type: message.type || 'primary',
                icon: 'tim-icons icon-bell-55',
                autoDismiss: 5
            };
            this.refs.notificationAlert.notificationAlert(options);
            // Don't show again
            commonStore.closeNotificationMsg(idx);
        });
    };
    toggleSidebar = () => {
        console.log('toggling sidebar');
        this.setState({
            sidebarOpened: !this.state.sidebarOpened
        });
        document.documentElement.classList.toggle('nav-open');
    };
    closeSidebar = () => {
        this.setState({
            sidebarOpened: false
        });
        document.documentElement.classList.remove('nav-open');
    };
    acceptCookies(accept) {
        Cookies.set(`accept-cookies-${process.env.REACT_APP_VERSION}`, accept, { expires: 3650 });
        this.setState({ showCookieBanner: accept });
    }
    render() {
        let showCookieBanner = false;
        if (this.state && (this.state.showCookieBanner === null || this.state.showCookieBanner === undefined)) {
            showCookieBanner = true;
        }

        let hideHeader = false;
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.hh) {
            hideHeader = true;
        }

        return (
            <>
                <Loading />
                <Status />
                <div className='wrapper'>
                    {(this.props.commonStore && this.props.commonStore.errorMessage) &&
                        <UncontrolledAlert className='topNotification alert-with-icon' color='danger' fade={true}>
                            <span className='tim-icons icon-bell-55' data-notify='icon' />
                            <span>
                                <b>Error - </b>
                                {this.props.commonStore.errorMessage}
                            </span>
                        </UncontrolledAlert>
                    }
                    <div className='rna-container'>
                        <NotificationAlert ref='notificationAlert' />
                    </div>
                    <div
                        className='navbar-minimize-fixed'
                        style={{ opacity: this.state.opacity }} >
                        <button
                            className='minimize-sidebar btn btn-link btn-just-icon'
                            onClick={this.handleMiniClick} >
                            <i className='tim-icons icon-align-center visible-on-sidebar-regular text-muted' />
                            <i className='tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted' />
                        </button>
                    </div>
                    <Sidebar {...this.props}
                        routes={this.routes}
                        isOpen={this.state.sidebarOpened}
                        closeSidebar={this.closeSidebar} />
                    <div
                        className='main-panel'
                        ref='mainPanel'
                        data={this.state.activeColor} >
                        {(showCookieBanner) &&
                            <CookieBanner
                                onAcceptCookies={() => { this.acceptCookies(true); }}
                                onDeclineCookies={() => { this.acceptCookies(false); }} />
                        }
                        {(!hideHeader) &&
                            <AdminNavbar
                                {...this.props}
                                handleMiniClick={this.handleMiniClick}
                                brandText={this.getActiveRoute(this.routes)}
                                sidebarOpened={this.state.sidebarOpened}
                                toggleSidebar={this.toggleSidebar}
                                onToggleSidebar={this.toggleSidebar} />
                        }
                        <Switch>
                            {this.getRoutes(this.routes)}
                        </Switch>
                        {// we don't want the Footer to be rendered on full screen maps page
                        (this.props.location.pathname.indexOf('full-screen-map') !== -1) ?
                            null :
                            <Footer fluid />
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default Admin;
