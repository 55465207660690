
import React from 'react';
import { inject, observer } from 'mobx-react';

import { EvoUI } from '@components';

import dayjs from 'dayjs';

import './style.scss';

@inject('adminStore')
@observer
class ExportsManager extends React.Component {
    constructor(props) {
        super(props);

        this.getData = this.getData.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.editData = this.editData.bind(this);

        this.state = {
            entity: null
        };
    }

    componentDidMount() {
        const { adminStore } = this.props;

        this.setState({ entity: adminStore.getExportEntity() });
    }

    async getData(refresh=true) {
        const { adminStore } = this.props;
        console.log(`[GetData Exports] Refresh: ${refresh}`);

        const sortExports = (a, b)=> {
            const aExportDate = dayjs(a.export_date);
            const bExportDate = dayjs(b.export_date);
            return ((aExportDate.unix() < bExportDate.unix()) ? 1 : ((aExportDate.unix() > bExportDate.unix()) ? -1 : 0));
        };

        let exports = await adminStore.getExports(refresh);
        exports = exports.slice().sort(sortExports);
        return { payload: exports, error: null };
    }

    deleteData(rows) {
        const { adminStore } = this.props;
        console.log(`Delete: ${JSON.stringify(rows)}`);

        const { entity } = this.state;
        let idField = ((entity && entity.fields) ? entity.fields.filter((field)=> (field.identity)) : 'id');
        if (typeof idField !== 'string') {
            idField = idField[0].id;
        }
        const delIds = [];
        rows.forEach((row)=> {
            if (row[idField]) {
                delIds.push(row[idField]);
            } else {
                console.error(`Identity Field not found for: ${JSON.stringify(row)}`);
            }
        });

        adminStore.deleteExportsMany(delIds).then(() => {
            console.log('Delete done.');
        }).finally(() => {
            if (this.refreshData) {
                this.refreshData();
            }
        });
    }

    editData(target) {
        const { adminStore } = this.props;
        console.log(`Edit: ${JSON.stringify(target)}`);
        
        adminStore.addUpdateExport(target).finally(() => {
            const entity = adminStore.getExportEntity();
            this.setState({ entity });
            if (this.refreshData) {
                this.refreshData(); // Refresh Data
            };
        });
    }

    render() {

        return (
            <div className='Exports-Manager-Container'>
            {(this.state.entity) ?
                <EvoUI noAdd key='evo' {...this.props}
                    entity={this.state.entity}
                    view={'list'}
                    isNew={false}
                    getData={this.getData}
                    refreshData={(refresh)=> this.refreshData = refresh}
                    deleteOne={this.deleteData}
                    deleteMany={this.deleteData}
                    editOne={this.editData}
                    noViewCards={true} /> :
                <h3>Loading...</h3>
            }
            </div>
        );
    }
}

export default ExportsManager;