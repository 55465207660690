
import React from 'react';
import { inject, observer } from 'mobx-react';

import queryString from 'query-string';
import dayjs from 'dayjs';

import ModalHLSPlayer from '../../components/modals/ModalHLSPlayer';

import config from '../../constants/config';

import './style.scss';

import { ControlsPanel, MainPanel, ScrubberPanel } from './components';

@inject('authStore', 'scrubberStore', 'scrubberPlayerStore')
@observer
class Scrubber extends React.Component {
    constructor(props) {
        super(props);

        this.handleHLSReady = this.handleHLSReady.bind(this);
        this.handleExportChoice = this.handleExportChoice.bind(this);
        this.handleClearExportRecord = this.handleClearExportRecord.bind(this);
        this.handleClearEventRecord = this.handleClearEventRecord.bind(this);
        this.handlePersistExport = this.handlePersistExport.bind(this);
        this.handlePersistEventTag = this.handlePersistEventTag.bind(this);
        this.handleExportClicked = this.handleExportClicked.bind(this);
        this.handleRefreshExports = this.handleRefreshExports.bind(this);
        this.handleEventClicked = this.handleEventClicked.bind(this);
        this.handleDownload = this.handleDownload.bind(this);

        this.handleSeekBack = this.handleSeekBack.bind(this);
        this.handleSeekForward = this.handleSeekForward.bind(this);
        this.handleMediaTimeChanged = this.handleMediaTimeChanged.bind(this);
        this.handleProgramSelected = this.handleProgramSelected.bind(this);

        this.handlePlayPause = this.handlePlayPause.bind(this);
        this.handleSetPlaybackRate = this.handleSetPlaybackRate.bind(this);
        this.handleSelectedChannelChanged = this.handleSelectedChannelChanged.bind(this);
        this.handleCurrentTimeChanged = this.handleCurrentTimeChanged.bind(this);
        this.handleIn = this.handleIn.bind(this);
        this.handleOut = this.handleOut.bind(this);
        this.handleOpenBreakout = this.handleOpenBreakout.bind(this);
        this.handleSkipBack = this.handleSkipBack.bind(this);
        this.handleSkipForward = this.handleSkipForward.bind(this);

        this.handleCloseBreakout = this.handleCloseBreakout.bind(this);

        this.state = {
            lastRender: new Date().getTime(),
            openBreakout: false
        };
    }

    async onLoadChannel(selectedChannel, currentTime) {
        this.refreshEvents(selectedChannel);
        this.refreshEPG(selectedChannel);
        this.refreshExports(selectedChannel);
        this.refreshTags();

        this.setPlayerSource();

        this.handleEventClicked(currentTime.subtract(1, 'millisecond'));
    }

    componentDidMount() {
        const { scrubberStore } = this.props;

        scrubberStore.getChannels().then((channels)=> {
            const parsedQueryString = queryString.parse(window.location.search);
            let date = dayjs();
            if (parsedQueryString && parsedQueryString.ct) {
                date = dayjs.unix(parsedQueryString.ct/1000);
            } else if (parsedQueryString && parsedQueryString.time) {
                date = dayjs.unix(new Date(parsedQueryString.time).getTime()/1000);
            }
            scrubberStore.setCurrentTime(date);

            let selectedChannel = channels[0];
            if (parsedQueryString && parsedQueryString.sc) {
                selectedChannel = channels.find((channel)=> (channel.id === parsedQueryString.sc));
            } else if (parsedQueryString && parsedQueryString.channelId) {
                selectedChannel = channels.find((channel)=> (channel.id === parsedQueryString.channelId));
            }
            scrubberStore.setSelectedChannel(selectedChannel);

            this.onLoadChannel(selectedChannel, scrubberStore.currentTime);
        });
    }

    async refreshEvents(selectedChannel) {
        const { scrubberStore } = this.props;
        if (selectedChannel) {
            return await scrubberStore.getEvents(selectedChannel.id);
        }
    }

    async refreshEPG(selectedChannel) {
        const { scrubberStore } = this.props;
        if (selectedChannel) {
            return await scrubberStore.getEPG(selectedChannel.epg_id);
        }
    }

    async refreshExports(selectedChannel) {
        const { scrubberStore } = this.props;
        if (selectedChannel) {
            return await scrubberStore.getExports(selectedChannel.id);
        }
    }

    async refreshTags() {
        const { scrubberStore } = this.props;
        return await scrubberStore.getTags();
    }

    setPlayerSource() {
        const { scrubberStore, scrubberPlayerStore } = this.props;

        const segmentTimes = this.calcStartAndEndTimes(scrubberStore.currentTime);
        const source = `${config.serverURL}${config.apiRoute}/transcoders/playlist/${scrubberStore.selectedChannel.id}/${segmentTimes.start}/${segmentTimes.end}/source.m3u8`;

        scrubberPlayerStore.setSource(source);
    }

    calcStartAndEndTimes(currentTime) {
        const startTime = currentTime.subtract(2, 'minute');
        return {
            start: parseInt((startTime).unix()),
            end: parseInt(((startTime.add(30, 'minute'))).unix())
        };
    }

    handleHLSReady(hls, attempt=0) {
        const { scrubberStore, scrubberPlayerStore } = this.props;

        if (attempt < 3) {
            try {
                // Set the current-time to 2mins in...
                hls.media.currentTime = 120;
            } catch(err) {
                console.log('Failed to modify HLS Media state...');
                setTimeout(()=> { this.handleHLSReady(hls, attempt+1); }, 500);
            } finally {
                hls.media.addEventListener('durationchange', ()=> { scrubberPlayerStore.setDuration(hls.media.duration); });
                hls.media.addEventListener('pause', ()=> { scrubberPlayerStore.setIsPlaying(false); });
                hls.media.addEventListener('play', ()=> { scrubberPlayerStore.setIsPlaying(true); });
                hls.media.addEventListener('ended', ()=> {
                    try {
                        const newDateTime = scrubberPlayerStore.readyTimestamp.clone()
                            .subtract(120, 'second')
                            .add(hls.media.duration, 'second');
                        scrubberStore.setCurrentTime(newDateTime);
                        scrubberPlayerStore.resetPlayer();
                        scrubberPlayerStore.setAutoPlay(true);
                        this.setPlayerSource();
                    } catch(err){}
                });
                hls.media.addEventListener('canplaythrough', ()=> {
                    try{
                        if (!scrubberPlayerStore.readyTimestamp) {
                            scrubberPlayerStore.setReadyTimestamp(scrubberStore.currentTime);
                        }
                        if (!scrubberPlayerStore.duration) {
                            scrubberPlayerStore.setDuration(hls.media.duration);
                        }
                        scrubberPlayerStore.setCanPlay(true);
                    } catch(err) {}
                });
                hls.media.addEventListener('timeupdate', (event)=> {
                    try {
                        const currentTime = scrubberPlayerStore.readyTimestamp.clone()
                            .subtract(2, 'minute')
                            .add(event.target.currentTime, 'second');
                    
                        scrubberStore.setCurrentTime(currentTime, false);
                        scrubberPlayerStore.setCurrentTime(hls.media.currentTime, false);
                    } catch(err) {}
                });
                scrubberPlayerStore.setMediaElement(hls.media);
            }
        }
    }

    handleExportChoice(choice) {
        const { authStore, scrubberStore } = this.props;

        const fields = {
            channelName: scrubberStore.selectedChannel.name || '',
            comments: '',
            date: scrubberStore.currentTime,
            detections: [],
            endTime: scrubberStore.currentTime,
            showDetections: false,
            startTime: scrubberStore.currentTime, 
            started: true,
            tags: [],
            username: authStore.user.username || '',
        }

        if (choice === 'EXPORT') {
            scrubberStore.setExport({ ...fields, type: 'EXPORT' });
        } else if (choice === 'EVENT_TAGGING') {
            scrubberStore.setEvent({ ...fields, type: 'EVENT_TAGGING' });
        }
    }

    handleClearExportRecord() {
        const { scrubberStore } = this.props;
        
        scrubberStore.setExportStarted(false);
        scrubberStore.setExport(null);
    }

    handleClearEventRecord() {
        const { scrubberStore } = this.props;
        
        scrubberStore.setExportStarted(false);
        scrubberStore.setEvent(null);
    }

    async handlePersistExport(formData) {
        const { scrubberStore } = this.props;

        scrubberStore.setExport(formData);
        await scrubberStore.persistExport();
        await this.refreshExports(scrubberStore.selectedChannel);

        this.setState({ lastRender: new Date().getTime() });
        
        this.handleEventClicked(formData.endTime);
    }

    async handlePersistEventTag(formData) {
        const { scrubberStore } = this.props;

        scrubberStore.setEvent(formData);
        await scrubberStore.persistEventTag();
        await this.refreshEvents(scrubberStore.selectedChannel);

        this.setState({ lastRender: new Date().getTime() });

        this.handleEventClicked(formData.endTime.subtract(1, 'millisecond'));
    }

    handleExportClicked(exportRecord) {
        /*
        const { scrubberStore } = this.props;
        const newTime = (dayjs(exportRecord.start_time*1000));
        scrubberStore.setCurrentTime(newTime);
        */
    }

    handleRefreshExports() {
        const { scrubberStore } = this.props;
        this.refreshExports(scrubberStore.selectedChannel);
    }

    handleEventClicked(eventTime) {
        try{
            const { scrubberPlayerStore } = this.props;
            const start = scrubberPlayerStore.readyTimestamp.subtract(2, 'minute');
            const difference = eventTime.diff(start, 'second');
            scrubberPlayerStore.setCurrentTime(difference);
        } catch(err) {}
    }

    handleDownload(record) {
        const { scrubberStore } = this.props;
        scrubberStore.downloadExport(record);
    }

    handleSeekBack(amount) {
        const { scrubberPlayerStore } = this.props;
        scrubberPlayerStore.element.currentTime = scrubberPlayerStore.element.currentTime-amount;
    }

    handleSeekForward(amount) {
        const { scrubberPlayerStore } = this.props;
        scrubberPlayerStore.element.currentTime = scrubberPlayerStore.element.currentTime+amount;
    }

    handleMediaTimeChanged(_, newTime) {
        const { scrubberStore, scrubberPlayerStore } = this.props;
        scrubberStore.setCurrentTime(newTime, false);
        scrubberPlayerStore.setCurrentTime(newTime);
    }

    handleProgramSelected(program) {
        const { scrubberStore } = this.props;
        scrubberStore.setCurrentTime(dayjs(program.start_datetimeUTC));

        this.setPlayerSource();
    }

    handlePlayPause() {
        const { scrubberPlayerStore } = this.props;
        (scrubberPlayerStore.isPlaying) ? scrubberPlayerStore.element.pause() : scrubberPlayerStore.element.play();
    }

    handleSetPlaybackRate(rate) {
        const { scrubberPlayerStore } = this.props;
        scrubberPlayerStore.element.playbackRate = rate;
    }

    handleSelectedChannelChanged(newChannel) {
        const { scrubberStore, scrubberPlayerStore, history } = this.props;

        if (scrubberPlayerStore.element) {
            scrubberPlayerStore.element.pause();
        }
        scrubberPlayerStore.setIsPlaying(false);

        scrubberStore.setSelectedChannel(newChannel);

        const timestamp = scrubberStore.currentTime.toDate().getTime();
        this.onLoadChannel(newChannel, scrubberStore.currentTime);
        // history.replace(`/home/scrubber?ct=${timestamp}&sc=${newChannel.id}`);
        // window.location.reload();
    }

    async handleCurrentTimeChanged(newDateTime) {
        const { scrubberStore } = this.props;

        scrubberStore.setCurrentTime(newDateTime);

        this.setPlayerSource();

        await this.refreshEvents(scrubberStore.selectedChannel);
        await this.refreshEPG(scrubberStore.selectedChannel);
        await this.refreshExports(scrubberStore.selectedChannel);
        await this.refreshTags();
    }

    handleIn() {
        const { scrubberStore } = this.props;
        scrubberStore.setExportStarted(true);

        scrubberStore.setExport({ ...scrubberStore.export,
            startTime: scrubberStore.currentTime,
        });
        scrubberStore.setEvent({ ...scrubberStore.event,
            startTime: scrubberStore.currentTime,
        });
    }

    handleOut() {
        const { scrubberStore } = this.props;
        scrubberStore.setExportStarted(true);

        scrubberStore.setExport({ ...scrubberStore.export,
            endTime: scrubberStore.currentTime,
        });
        scrubberStore.setEvent({ ...scrubberStore.event,
            endTime: scrubberStore.currentTime,
        });
    }

    handleSkipBack() {
        const { scrubberStore } = this.props;
        const newDateTime = scrubberStore.currentTime.clone().subtract(30, 'minute');
        this.handleCurrentTimeChanged(newDateTime);

        this.setPlayerSource();

        setTimeout(async ()=> {
            await this.handleEventClicked(newDateTime.subtract(1, 'millisecond'));
        }, 200);
    }

    handleSkipForward() {
        const { scrubberStore } = this.props;
        const newDateTime = scrubberStore.currentTime.clone().add(30, 'minute');
        this.handleCurrentTimeChanged(newDateTime);

        this.setPlayerSource();

        setTimeout(async ()=> {
            await this.handleEventClicked(newDateTime.subtract(1, 'millisecond'));
        }, 200);
    }

    handleOpenBreakout() {
        this.setState({ openBreakout: true });
    }

    handleCloseBreakout() {
        this.setState({ openBreakout: false });
    }


    render() {
        const { scrubberStore, scrubberPlayerStore } = this.props;

        const media = {
            autoPlay: scrubberPlayerStore.autoPlay,
            canPlay: scrubberPlayerStore.canPlay,
            currentTime: scrubberPlayerStore.currentTime,
            duration: scrubberPlayerStore.duration,
            isPlaying: scrubberPlayerStore.isPlaying,
            readyTimestamp: scrubberPlayerStore.readyTimestamp,
            source: scrubberPlayerStore.source
        };

        const exportData = {
            started: scrubberStore.exportStarted,
            export: scrubberStore.export,
            event: scrubberStore.event
        };

        return (
            <div className='Scrubber-Container'>
                <div className='Scrubber-Wrapper'>
                    <div className='Scrubber-Top'>
                        <MainPanel
                            currentTime={scrubberStore.currentTime}
                            events={scrubberStore.events}
                            selectedChannel={scrubberStore.selectedChannel}
                            epgData={scrubberStore.epg}
                            exportData={exportData}
                            exports={scrubberStore.exports}
                            userTags={scrubberStore.tags}
                            media={media}
                            onHLSReady={this.handleHLSReady}
                            onExportChoice={this.handleExportChoice}
                            onClearExport={this.handleClearExportRecord}
                            onClearEvent={this.handleClearEventRecord}
                            onPersistExport={this.handlePersistExport}
                            onPersistEventTag={this.handlePersistEventTag}
                            onExportClicked={this.handleExportClicked}
                            onRefreshExports={this.handleRefreshExports}
                            onEventClicked={this.handleEventClicked}
                            onDownload={this.handleDownload}
                            onProgramSelected={this.handleProgramSelected} />
                    </div>
                    <div className='Scrubber-Bottom'>
                        <ControlsPanel
                            channels={scrubberStore.channels}
                            currentTime={scrubberStore.currentTime}
                            exportData={exportData}
                            media={media}
                            selectedChannel={scrubberStore.selectedChannel}
                            onSeekBack={this.handleSeekBack}
                            onSeekForward={this.handleSeekForward}
                            onPlayPause={this.handlePlayPause}
                            onSetPlaybackRate={this.handleSetPlaybackRate}
                            onChannelSelectInputChanged={this.handleChannelSelectInputChanged}
                            onSelectedChannelChanged={this.handleSelectedChannelChanged}
                            onCurrentTimeChanged={this.handleCurrentTimeChanged}
                            onInClicked={this.handleIn}
                            onOutClicked={this.handleOut}
                            onOpenBreakout={this.handleOpenBreakout} />
                        <ScrubberPanel
                            currentTime={scrubberStore.currentTime}
                            readyTimestamp={scrubberPlayerStore.readyTimestamp}
                            epg={scrubberStore.epg}
                            events={scrubberStore.events}
                            media={media}
                            selectedChannel={scrubberStore.selectedChannel}
                            onMediaTimeChanged={this.handleMediaTimeChanged}
                            onSeekBack={this.handleSkipBack}
                            onSeekForward={this.handleSkipForward} />
                    </div>
                </div>
                <ModalHLSPlayer
                    autoPlay={false}
                    controls={true}
                    isOpen={this.state.openBreakout}
                    selectedChannel={scrubberStore.selectedChannel}
                    showPeakMeter={true}
                    source={scrubberPlayerStore.source}
                    onClose={this.handleCloseBreakout} />
            </div>
        );
    }

}

export default Scrubber;