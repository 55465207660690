
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs } from '../../../../../../components/Tabs';

import { EPG } from './EPG';
import { Events } from './Events';
import { Export } from './Export';
import { Exports } from './Exports';
import { ExportChoice } from './ExportChoice';
import { Plugin } from './Plugin';

import './style.scss';
import { EventTagging } from './EventTagging';

export const Navigation = (props)=> {
    const [activeTab, setActiveTab] = useState(0);
    const [tabs, setTabs] = useState([]);
    const [showExportChoice, setShowExportChoice] = useState(false);
    const [showExportView, setShowExportView] = useState(false);
    const [showEventTagging, setShowEventTagging] = useState(false);

    useEffect(()=> {
        setTabs([{
            content: (<Events {...props} key='events' />),
            id: 'events',
            index: 0,
            label: 'Events',
            show: true,
        }, {
            content: (<Exports {...props} key='exports' />),
            id: 'exports',
            index: 1,
            label: 'Exports',
            show: true,
        }, {
            content: (
                <EPG key='epg'
                    currentTime={props.currentTime}
                    data={props.epgData}
                    onProgramSelected={props.onProgramSelected} />
            ),
            id: 'epg',
            index: 2,
            label: 'EPG',
            show: true
        }]);
    }, [props]);

    useEffect(()=> {
        setShowExportChoice(props.exportData.started);
    }, [props]);

    const handleActiveTabChanged = (activeTab)=> { setActiveTab(activeTab); };
    const handleExportChoice = (choice)=> {
        props.onExportChoice(choice);
        setShowExportChoice(false);

        if (choice === 'EXPORT') {
            setShowExportView(true);
        } else {
            setShowEventTagging(true);
        }
    };

    const closeExportView = ()=> {
        setShowExportView(false);
        props.onClearExport();
    };

    const closeEventTaggingView = ()=> {
        setShowEventTagging(false);
        props.onClearEvent();
    };

    const handleCancelExport = ()=> { closeExportView(); };
    const handleCancelEvent = ()=> { closeEventTaggingView(); };

    const handlePersistExport = (formData)=> {
        setActiveTab(1);
        props.onPersistExport(formData);
        closeExportView();
    };

    const handlePersistEvent = (formData)=> {
        setActiveTab(0);
        props.onPersistEventTag(formData);
        closeEventTaggingView();
    };

    return (
        <div className='Scrubber-Navigation-Container'>
            <div className='Scrubber-Navigation-Wrapper'>
                <Tabs
                    activeTab={activeTab}
                    tabs={tabs}
                    onActiveTabChanged={handleActiveTabChanged} />
                {(showExportChoice) &&
                    <ExportChoice
                        onChoice={handleExportChoice} />
                }
                {(showExportView) &&
                    <Export
                        fields={props.exportData.export}
                        userTags={props.userTags}
                        onCancel={handleCancelExport}
                        onPersist={handlePersistExport} />
                }
                {(showEventTagging) &&
                    <EventTagging
                        fields={props.exportData.event}
                        userTags={props.userTags}
                        onCancel={handleCancelEvent}
                        onPersist={handlePersistEvent} />
                }
            </div>
        </div>
    );
};

Navigation.propTypes = {
    export: PropTypes.object,
    userTags: PropTypes.array,
    onClearExport: PropTypes.func,
    onClearEvent: PropTypes.func,
    onExportChoice: PropTypes.func,
    onPersistExport: PropTypes.func,
    onPersistEventTag: PropTypes.func
};