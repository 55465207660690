
import { observable, action } from 'mobx';

import no_image from '../assets/img/no_image.png';

import config from '../constants/config';

import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import channelStore from './channelStore';
import loadingStore from './loadingStore';

const defaultData = {
    channels: [],
    channelImages: {}
};

class DashboardStore {
    @observable channelImages = {};

    @action async getChannelImages(refresh) {
        const fetchChannelImage = (channelId)=> {
            return new Promise(async (resolve)=> {
                const result = { timestamp: (new Date()).getTime(), data: null };
                try {
                    const blob = await agentAJAX.Dashboard.getChannelImage(channelId);
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = ()=> {
                        result.id = channelId;
                        result.timestamp = (new Date()).getTime();
                        result.data = (reader.result) ? reader.result : no_image;
                        resolve(result);
                    };
                } catch(err) {
                    resolve(result);
                }
            });
        };

        const promises = [];
        if (refresh) {
            const channels = await this.getChannels(false, true);
            channels.forEach((channel)=> {
                const channelId = channel.id;
                this.channelImages[channelId] = { timestamp: (new Date()).getTime(), data: null };
                promises.push(fetchChannelImage(channelId));
            });
            const result = await Promise.all(promises);
            result.forEach((channelImage)=> {
                let { data, id, timestamp } = channelImage;
                if (id) {
                    this.channelImages[id] = { data, timestamp };
                }
            });
        }
        return this.channelImages;
    }

    @action async getChannelImage(channelId) {
        const channelImageData = this.channelImages[channelId];
        if (channelImageData) {
            return channelImageData;
        } else {
            return { timestamp: (new Date()).getTime(), data: null };
        }
    }

    @action async getChannels(refresh, noLoad) {
        const channels = await channelStore.getChannels(refresh, noLoad);
        return channels.slice().sort((a, b)=> { return ((a.order === b.order) ? 0 : ((a.order < b.order) ? -1 : 1)) });
    }

    @action restartTranscoderChannel(channelId) {
        return agentAJAX.Dashboard.restartTranscoderChannel(channelId);
    }

    @action clearStore() {
        Object.keys(defaultData).forEach(key => {
            this[key] = defaultData[key];
        });
    }
}

// const dashboardStore = SIOStore(DashboardStore, 'ALL');
const dashboardStore = new DashboardStore();
export default dashboardStore;