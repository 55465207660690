
import React from 'react';
import { inject, observer } from 'mobx-react';

import { Grid } from '@material-ui/core';

import { ModalConfirmation, ModalHLSPlayer } from '../../components/modals';

import config from '../../constants/config';

import { Channel } from './components';

import './style.scss';

@inject('dashboardStore')
@observer
class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.closeConfirmation = this.closeConfirmation.bind(this);
        this.openConfirmation = this.openConfirmation.bind(this);

        this.closeModalPlayer = this.closeModalPlayer.bind(this);
        this.openModalPlayer = this.openModalPlayer.bind(this);

        this.handleChannelImageRequest = this.handleChannelImageRequest.bind(this);
        this.handleRestartTranscoder = this.handleRestartTranscoder.bind(this);

        this.state = {
            confirmation: {
                isOpen: false,
                showButtons: true,
                selectedChannel: null
            },
            modalPlayer: {
                isOpen: false,
                showPeakMeter: true,
                selectedChannel: null,
                source: null
            }
        };

        this.channelInterval = null;
    }

    async componentDidMount() {
        this.getChannelData();
        this.channelInterval = setInterval(async ()=> {
            console.log('Refreshing Channels');
            await this.getChannelData();
        }, 30*1000);
    }

    sortChannels(channels) {
        const newChannels = channels.sort((a, b) => {
            if(a.name > b.name) { return 1; }
            return -1;
        }).sort((a, b) => {
            if (a.status === 'PROCESSING') { return 1; }
            return -1;
        });
        return newChannels;
    }

    async getChannelData() {
        const { dashboardStore } = this.props;
        const channels = await dashboardStore.getChannels(true, true);
        this.setState({ channels: this.sortChannels(channels) });
    }

    componentWillUnmount() {
        clearInterval(this.channelInterval);
    }

    closeConfirmation() {
        const confirmation = { ...this.state.confirmation,
            isOpen: false,
            selectedChannel: null
        };
        this.setState({ confirmation });
    }

    openConfirmation(channel) {
        const confirmation = { ...this.state.confirmation,
            isOpen: true,
            selectedChannel: channel
        };
        this.setState({ confirmation });
    }
  
    closeModalPlayer() {
        const modalPlayer = { ...this.state.modalPlayer,
            isOpen: false,
            selectedChannel: null,
            source: null
        };
        this.setState({ modalPlayer });
    }

    openModalPlayer(channel) {
        const modalPlayer = { ...this.state.modalPlayer,
            autoPlay: true,
            isOpen: true,
            selectedChannel: channel,
            source: `${config.live}/${channel.id}/source.m3u8`
        };
        this.setState({ modalPlayer });
    }

    handleChannelImageRequest(channelId) {
        const { dashboardStore } = this.props;
        
        return dashboardStore.getChannelImage(channelId);
    }

    handleRestartTranscoder() {
        const { dashboardStore } = this.props;

        const confirmation = { ...this.state.confirmation,
            showButtons: false
        };
        this.setState({ confirmation });

        const channelId = this.state.confirmation.selectedChannel.id;
        dashboardStore.restartTranscoderChannel(channelId).then(()=> {
            this.closeConfirmation();
        }).catch((error)=> {
            this.closeConfirmation();
            console.log(`Unable to Reload Channel : ${error.message}`);
        });
    }

    renderChannels() {
        const { channels } = this.state;

        return (
            <Grid container spacing={3}>
                {(channels && channels.length) ?
                    channels.map((channel, index)=> (
                        <Channel {...channel} key={index}
                            requestImage={this.handleChannelImageRequest}
                            onOpenConfirmation={()=> { this.openConfirmation(channel); }}
                            onOpenModalPlayer={()=> { this.openModalPlayer(channel); }} />
                    )) : <h3>No Channels Found</h3>
                }
            </Grid>
        );
    }

    render() {

        return (
            <div className='Dashboard-Container'>
                <div className='Dashboard-Wrapper'>
                    {this.renderChannels()}
                </div>
                <ModalConfirmation  {...this.state.confirmation}
                    onAccept={this.handleRestartTranscoder}
                    onClose={this.closeConfirmation}
                    onDecline={this.closeConfirmation}>
                    <h3>Are you sure you want to restart this transcoder-channel?</h3>
                    <h3 style={{ textAlign: 'center' }}>
                    {(this.state.confirmation.selectedChannel) &&
                        this.state.confirmation.selectedChannel.name
                    }
                    </h3>
                </ModalConfirmation>
                <ModalHLSPlayer {...this.state.modalPlayer}
                    onClose={this.closeModalPlayer} />
            </div>
        );
    }
}

export default Dashboard;