import superagentPromise from 'superagent-promise';
import _superagent from 'superagent'; // Nice syntax for AJAX Calls
import commonStore from '../stores/commonStore';
import authStore from '../stores/authStore';
import config from '../constants/config';
import loadingStore from '../stores/loadingStore';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const today = dayjs.utc().startOf('day').valueOf();

// Extend SuperAgent with local promises
const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = config.serverURL;

const relationCheck = [];
const defaultParams = {
    withRelations: 'false'
};
const defaultParamsUnlimited = {
    withRelations: 'false',
    _limit: -1
};

//Global API Error handler
const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        // Logout User
        authStore.logout();
    }
    stopLoading();
    return err;
};

// Get ResponseBody from response
const responseBody = res => res.body;

// Recursively fix #####Id fields
const _fixIds = (obj) => {
    if (obj) {
        let keys = Object.keys(obj);
        keys.forEach(key => {
            if (relationCheck.indexOf(key) > -1) {
                if (typeof obj[key] === 'object') {
                    if (obj[key] && obj[key]._id) {
                        obj[key + 'Id'] = obj[key]._id;
                    }
                    _fixIds(obj[key]);
                } else {
                    obj[key + 'Id'] = obj[key];
                }
            }
        });
    }

    return obj;
}

const fixIds = res => {
    if (Array.isArray(res)) {
        let newRes = [];
        res.forEach(r => {
            newRes.push(_fixIds(r));
        });
        return newRes;
    } else {
        return _fixIds(res);
    }
}

// Fix Id's before Create/Update
const _fixIdsRev = (obj) => {
    if (obj) {
        let keys = Object.keys(obj);
        let _relationCheck = relationCheck.map(m => `${m}Id`);
        keys.forEach(key => {
            if (_relationCheck.indexOf(key) > -1) {
                let newKey = key.replace('Id', '');
                if (typeof obj[key] === 'object') {
                    if (obj[key] && obj[key]._id) {
                        obj[newKey] = obj[key]._id;

                        delete obj[key]; // remove ___Id fields
                    }
                    _fixIdsRev(obj[key]);
                } else {
                    obj[newKey] = obj[key];

                    delete obj[key]; // remove ___Id fields
                }
            }
        });
    }

    return obj;
}

const fixIdsRev = res => {
    if (res._data && res._data.data) {
        let data = res._data.data
        if (Array.isArray(data)) {
            let newRes = [];
            data.forEach(r => {
                newRes.push(_fixIdsRev(r));
            });
            res._data = newRes;
        } else {
            res._data = _fixIdsRev(data);
        }
        return res;
    } else {
        return res;
    }
}

// Removed nested data
const fixData = res => {
    if (res._data && res._data.data) {
        let data = res._data.data
        res._data = data;
        return res;
    } else {
        return res;
    }
}

// Append local Auth Token to all calls
const tokenPlugin = req => {
    // exclude 'Auth' url's
    if (req && req.url && req.url.indexOf(config.authRoute) <= -1) {
        startLoading();
        if (commonStore && commonStore.token) {
            req.header['Authorization'] = `Bearer ${commonStore.token}`;
        } else {
            // Try and get from Storage
            let token = window.localStorage.getItem('jwt');
            if (token) {
                req.header['Authorization'] = `Bearer ${token}`;
            } else {
                req.header['Authorization'] = `Bearer `; // No 403
            }
        }
    }
};

const tokenPluginNoLoading = req => {
    // exclude 'Auth' url's
    if (req && req.url && req.url.indexOf(config.authRoute) <= -1) {
        if (commonStore && commonStore.token) {
            req.header['Authorization'] = `Bearer ${commonStore.token}`;
        } else {
            // Try and get from Storage
            let token = window.localStorage.getItem('jwt');
            if (token) {
                req.header['Authorization'] = `Bearer ${token}`;
            } else {
                req.header['Authorization'] = `Bearer `; // No 403
            }
        }
    }
};

// Append local Auth Token to all calls
// const fixCORS = req => {
//   if (req.header && req.header['Access-Control-Allow-Origin']) {
//     console.log('[API CORS]', req.header['Access-Control-Allow-Origin']);
//   } else {
//     req.header['Access-Control-Allow-Origin'] = '*';
//   }
// };

// Loading Indicator
const startLoading = () => {
    loadingStore.setLoading(true);
}
const stopLoading = () => {
    loadingStore.setLoading(false);
}

// params = {_start:0, _limit:100, _sort:'email:ASC' or _sort:'email:DESC', firstName_eq:'John'}
const getParams = (params) => {
    let ret = '';
    if (params) {
        Object.keys(params).forEach(key => {
            if (ret.length > 0) {
                ret += `&${key}=${params[key]}`;
            } else {
                ret += `?${key}=${params[key]}`;
            }
        });
    }

    // Add Default
    // if(ret.length <= 0){
    //   ret += `?_limit=-1`;
    // }

    return ret;
}

// Setup Request types with Token
const requests = {
    //head
    head: (url) =>
        superagent
            .head(`${API_ROOT}${url}`)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .end(handleErrors)
            .then(responseBody),
    // Remove
    del: url =>
        superagent
            .del(`${API_ROOT}${url}`)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .use(tokenPlugin)
            .use(fixData)
            //.use(fixCORS)
            .end(handleErrors)
            .then(responseBody),
    // Get One
    get: (url, params) =>
        superagent
            .get(`${API_ROOT}${url}${getParams(params)}`)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .use(tokenPlugin)
            //.use(fixCORS)
            .end(handleErrors)
            .then(responseBody)
            .then(fixIds), // Fix ___Id's
    get_noLoad: (url, params) =>
        superagent
            .get(`${API_ROOT}${url}${getParams(params)}`)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .use(tokenPluginNoLoading)
            //.use(fixCORS)
            .end(handleErrors)
            .then(responseBody)
            .then(fixIds), // Fix ___Id's
    // Get Many
    get_list: (url, params) =>
        superagent
            .get(`${API_ROOT}${url}${getParams(params)}`)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .use(tokenPlugin)
            //.use(fixCORS)
            .end(handleErrors)
            .then(responseBody)
            .then(fixIds), // Fix ___Id's
    // Update
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .use(tokenPlugin)
            .use(fixIdsRev) // Fix ___Id's & Data Nested
            //.use(fixCORS)
            .end(handleErrors)
            .then(responseBody),
    // Create
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .use(tokenPlugin)
            .use(fixIdsRev) // Fix ___Id's & Data Nested
            //.use(fixCORS)
            .end(handleErrors)
            .then(responseBody),
    post_noLoad: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .timeout({ response: config.apiTimeout, deadline: config.apiDeadline })
            .use(tokenPluginNoLoading)
            .use(fixIdsRev) // Fix ___Id's & Data Nested
            //.use(fixCORS)
            .end(handleErrors)
            .then(responseBody),
};

// Mappings for API requests
// const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`; // For paging... exa. requests.get(`/datas?${limit(lim, page)}`),
// const lessThan = {}; //TODO
// const graterThan = {}; //TODO
// const orderBy = {}; //TODO

// Auth Related Requests
const Auth = {
    current: () =>
        requests.get(`${config.apiRoute}/users/me`),
    login: (data) =>
        requests.post(`${config.authRoute}/local`, data),
    // Gets the reset Code
    forgotPass: (email) =>
        requests.post(`${config.authRoute}/forgot-password`, { email: email, url: config.forgotPassUrl }),
    // Change Password with supplied reset Code
    resetPass: (code, pass) =>
        requests.post(`${config.authRoute}/reset-password`, { code: code, password: pass, passwordConfirmation: pass }),
    register: (username, email, password, confirmed) =>
        requests.post(`${config.authRoute}/local/register`, { username, email, password, confirmed }),
    save: (user) =>
        requests.put(`${config.apiRoute}/users/${user._id}`, { user })
};

const Ping = {
    ping: () => requests.head(`${config.apiRoute}/_health`)
}

const Users = {
    get: (id) => requests.get(`${config.apiRoute}/users/${id}`),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/users?_limit=-1`) : requests.get(`${config.apiRoute}/users?_limit=-1`),
    add: (data) => requests.post(`${config.apiRoute}/users`, { data }),
    edit: (id, data) => requests.put(`${config.apiRoute}/users/${id}`, { data }),
    delete: (id) => requests.del(`${config.apiRoute}/users/${id}`)
}

const Roles = {
    getAll: ()=> requests.get_noLoad(`${config.apiRoute}/users-permissions/roles?_limit=-1`),
}

const Channels = {
    get: (id) => requests.get(`${config.apiRoute}/channels/${id}?withRelations=true&exactRelations=channelgroups`),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/channels?_sort=order&_limit=-1&withRelations=true&exactRelations=channelgroups`) : requests.get(`${config.apiRoute}/channels?_sort=order&_limit=-1&withRelations=true&exactRelations=channelgroups`),
    add: (data) => requests.post(`${config.apiRoute}/channels`, { data }),
    edit: (id, data) => requests.put(`${config.apiRoute}/channels/${id}`, { data }),
    delete: (id) => requests.del(`${config.apiRoute}/channels/${id}`)
}

const Storage = {
    get: (id) => requests.get(`${config.apiRoute}/storages/${id}?withRelations=false`),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/storages?_sort=order&_limit=-1`) : requests.get(`${config.apiRoute}/storages?_sort=order&_limit=-1`),
    add: (data) => requests.post(`${config.apiRoute}/storages`, { data }),
    edit: (id, data) => requests.put(`${config.apiRoute}/storages/${id}`, { data }),
    delete: (id) => requests.del(`${config.apiRoute}/storages/${id}`)
}

const ChannelGroups = {
    get: (id) => requests.get(`${config.apiRoute}/channelgroups/${id}`, defaultParams),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/channelgroups`, defaultParamsUnlimited) : requests.get(`${config.apiRoute}/channelgroups`, defaultParamsUnlimited),
    add: (data) => requests.post(`${config.apiRoute}/channelgroups`, { data }),
    edit: (id, data) => requests.put(`${config.apiRoute}/channelgroups/${id}`, { data }),
    delete: (id) => requests.del(`${config.apiRoute}/channelgroups/${id}`),
    getByUserId: (uid) => requests.get(`${config.apiRoute}/channelgroups?withRelations=false&users_in=${uid}`)
}

const Exports = {
    get: (id) => requests.get(`${config.apiRoute}/exports/${id}`, defaultParams),
    getByChannel: (noload, channelId) => (noload) ? requests.get_noLoad(`${config.apiRoute}/exports?channel=${channelId}&_sort=start_time&_limit=100`) : requests.get_noLoad(`${config.apiRoute}/exports?channel=${channelId}&_sort=start_time&_limit=100`),
    getChannelExportsToday: (detectName)=> requests.get_noLoad(`${config.apiRoute}/exports?channel=${detectName}&start_time_gte=${today.toString().substring(0, today.toString().length-3)}&_sort=start_time&_limit=-1`),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/exports`, defaultParamsUnlimited) : requests.get(`${config.apiRoute}/exports`, defaultParamsUnlimited),
    getByUserId: (userId)=> requests.get_noLoad(`${config.apiRoute}/exports?user=${userId}&_limit=-1`),
    add: (data) => requests.post(`${config.apiRoute}/exports`, { data }),
    edit: (id, data) => requests.put(`${config.apiRoute}/exports/${id}`, { data }),
    delete: (id) => requests.del(`${config.apiRoute}/exports/${id}`)
}

const Events = {
    get: (id) => requests.get(`${config.apiRoute}/events/${id}`, defaultParams),
    getByChannel: (noload, channelId, start) => (noload) ? requests.get_noLoad(`${config.apiRoute}/events?channel_id=${channelId}&detect_timelocal_gte=${start}&_sort=detect_timelocal&_limit=10000`) : requests.get_noLoad(`${config.apiRoute}/events?channel_id=${channelId}&detect_timelocal=${start}&_sort=detect_timelocal&_limit=10000`),
    getByChannelUTC: (noload, channelId, start) => (noload) ? requests.get_noLoad(`${config.apiRoute}/events?channel_id=${channelId}&detect_timeUTC_gte=${start}&_sort=detect_timeUTC&_limit=10000`) : requests.get_noLoad(`${config.apiRoute}/events?channel_id=${channelId}&detect_timeUTC=${start}&_sort=detect_timeUTC&_limit=10000`),
    getChannelEventsToday: (channelId)=> requests.get(`${config.apiRoute}/events?channel_id=${channelId}&detect_timelocal_gte=${today}&_sort=detect_timelocal&_limit=-1`),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/events`, defaultParamsUnlimited) : requests.get(`${config.apiRoute}/events`, defaultParamsUnlimited),
    add: (data) => requests.post(`${config.apiRoute}/events`, { data }),
    edit: (id, data) => requests.put(`${config.apiRoute}/events/${id}`, { data }),
    delete: (id) => requests.del(`${config.apiRoute}/events/${id}`)
}

const EventTags = {
    //getByChannelUTC: (channelId, start)=> requests.get(`${config.apiRoute}/eventtags?channel_id=${channelId}&start_time_gte=${start}&_sort=start_time&_limit=-1`),
    getByChannelUTC: (channelId)=> requests.get_noLoad(`${config.apiRoute}/eventtags?channel_id=${channelId}&_limit=-1`),
    getByUserId: (userId)=> requests.get_noLoad(`${config.apiRoute}/eventtags?user_id=${userId}&_limit=-1`),
    getAll: ()=> requests.get_noLoad(`${config.apiRoute}/eventtags?_limit=-1`),
    add: (data)=> requests.post(`${config.apiRoute}/eventtags`, { data }),
}

const Tags = {
    get: (id) => requests.get(`${config.apiRoute}/tags/${id}`, defaultParams),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/tags?_limit=-1&withRelations=true`) : requests.get_noLoad(`${config.apiRoute}/tags?_limit=-1&withRelations=true`),
    getByUserId: (noload, userId)=> (noload) ? requests.get_noLoad(`${config.apiRoute}/tags?user_id=${userId}&_limit=-1`) : requests.get(`${config.apiRoute}/tags?user_id=${userId}&_limit=-1`),
    getByChannelGroup: (channelGroupId)=> requests.get_noLoad(`${config.apiRoute}/tags?_limit=-1&channelgroup=${channelGroupId}`),
    add: (data) => requests.post(`${config.apiRoute}/tags`, { data }),
    edit: (id, data) => requests.put(`${config.apiRoute}/tags/${id}`, { data }),
    delete: (id) => requests.del(`${config.apiRoute}/tags/${id}`),
    restore: (id) => requests.put(`${config.apiRoute}/tags/restore/${id}`)
}

const Epg = {
    get: (id) => requests.get(`${config.apiRoute}/epg/${id}`, defaultParams),
    getByChannel: (noload, channelDetectName, start, stop) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg?channel_detect_name=${channelDetectName}&start_datetime_gte=${start}&end_datetime_lte=${stop}&_sort=start_datetime&_limit=1000&withRelations=false`) : requests.get(`${config.apiRoute}/epg?channel_detect_name=${channelDetectName}&start_datetime_gte=${start}&end_datetime_lte=${stop}&_sort=start_datetime&_limit=-1&withRelations=false`),
    getAll: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg`, defaultParamsUnlimited) : requests.get(`${config.apiRoute}/epg`, defaultParamsUnlimited),
    getChannelEPGToday: (epgId)=> requests.get(`${config.apiRoute}/epg?epg_id=${epgId}&start_datetime=${today}&_sort=start_datetime&_limit=-1`),
    getAllByEPGId: (noload, epgId, start, stop) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg?epg_id=${epgId}&start_datetime_gte=${start}&end_datetime_lte=${stop}&_sort=start_datetime&_limit=-1&withRelations=false`) : requests.get(`${config.apiRoute}/epg?epg_id=${epgId}&start_datetime_gte=${start}&end_datetime_lte=${stop}&_sort=start_datetime&_limit=-1&withRelations=false`),
    getAllByEPGIdUTC: (noload, epgId, start, stop) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg?epg_id=${epgId}&start_datetimeUTC_gte=${start}&end_datetimeUTC_lte=${stop}&_sort=start_datetimeUTC&_limit=-1&withRelations=false`) : requests.get(`${config.apiRoute}/epg?epg_id=${epgId}&start_datetimeUTC_gte=${start}&end_datetimeUTC_lte=${stop}&_sort=start_datetimeUTC&_limit=-1&withRelations=false`),
    getAllByEPGId_min: (noload, epgId, start, stop) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg/min?epg_id=${epgId}&start_datetime_gte=${start}&end_datetime_lte=${stop}&_sort=start_datetime&_limit=-1&withRelations=false`) : requests.get(`${config.apiRoute}/epg/min?epg_id=${epgId}&start_datetime_gte=${start}&end_datetime_lte=${stop}&_sort=start_datetime&_limit=-1&withRelations=false`),
    getByTimestamps: (noload, start, stop) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg?start_datetime_gte=${start}&end_datetime_lte=${stop}&_limit=20000`) : requests.get(`${config.apiRoute}/epg?start_datetime_gte=${start}&end_datetime_lte=${stop}&_limit=20000`),
    // Not needed for UI, unless a full refresh request. Don't wait for it to complete - Takes about 10 to 20 min
    getCurrent: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg_current`, defaultParams) : requests.get(`${config.apiRoute}/epg_current`, defaultParams),
    getPeriod: (noload) => (noload) ? requests.get_noLoad(`${config.apiRoute}/epg_period`, defaultParams) : requests.get(`${config.apiRoute}/epg_period`, defaultParams)
}

const Dashboard = {
    getChannelImageV1: (channelId)=> requests.get_noLoad(`${config.apiRoute}/vod/${channelId}/thumbnail.jpg?v=${new Date.getTime()}`, ),
    getChannelImage: (address)=> requests.get_noLoad(address),
    restartTranscoderChannel: (channelId) => requests.get_noLoad(`${config.apiRoute}/transcoders/restartChannel/${channelId}`),
}

export default {
    Auth,
    Channels,
    ChannelGroups,
    Dashboard,
    Epg,
    Events,
    EventTags,
    Exports,
    Ping,
    Roles,
    Tags,
    Users,
    Storage,
};