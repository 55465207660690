
import dayjs from 'dayjs';

import utils from './utils';

const getUserData = ()=> {
    return utils.getLocalItem('usr');
}

const cleanNulls = (obj) => {
    let newObj = {};
    for (let key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

const prepareTag = (tag) => {
    let groups = (tag.channelgroups && tag.channelgroups.length > 0) ? tag.channelgroups.map(m => m._id || m.id) : [];
    let newTag = {
        name: tag.name,
        description: tag.description,
        color: tag.color,
        channelgroups: groups,
    };
    return cleanNulls(newTag);
}

const prepareUser = (usr) => {
    let groups = (usr.channelgroups && usr.channelgroups.length > 0) ? usr.channelgroups.map(m => m._id || m.id) : [];
    let newUsr = {
        firstName: usr.firstName,
        lastName: usr.lastName,
        email: usr.email,
        confirmed: usr.confirmed,
        blocked: usr.blocked || false,
        username: usr.username,
        password: usr.password,
        channelgroups: groups,
        //role: (usr.role && (usr.role._id || usr.role.id)) ? (usr.role._id || usr.role.id) : null,
        attributes: {}
    }

    //add id
    if (usr.id || usr._id) {
        newUsr._id = (usr.id) ? usr.id : usr._id;
    }
    if (usr.role) {
        if (usr.role.id || usr.role._id) {
            newUsr.role = (usr.role._id || usr.role.id);
        }
        if (typeof usr.role === 'string') {
            newUsr.role = usr.role;
        }
    }
    return cleanNulls(newUsr);
}

const prepareChannel = (channel) => {
    // const image = (channel.image && channel.image.base64Data) ? channel.image.base64Data : null;
    const channelgroups = (channel.channelgroups && channel.channelgroups.length) ?
        channel.channelgroups.map((channelGroup) => (channelGroup._id || channelGroup.id)) :
        [];

    let result = {
        channelgroups: channelgroups,

        name: channel.name,
        description: channel.description,
        feedurl: channel.feedurl,
        epg_id: channel.epg_id,

        order: channel.order,
        tags: channel.tags,

        active: channel.active || false,
        audioOnly: channel.audioOnly || false,

        parentChannelId: channel.parentChannelId,
        recordingOffset: channel.recordingOffset,
        hotStorageDuration: channel.hotStorageDuration,

        hotStorage: channel.hotStorage,
        coldStorages: channel.coldStorages,

        color: channel.color,
    }

    if(channel.image){
        result.image = channel.image;
    }

    if (channel.id || channel._id) {
        result._id = (channel.id) ? channel.id : channel._id;
    }
    return cleanNulls(result);
}

/**
 * Cleans the provided Channel Group for persistence.
 * @param {Object} data - The object to be sanitized.
 */
const prepareChannelGroup = (data) => {
    const result = {
        name: data.name,
        description: data.description,

        channels: data.channels,
        users: data.users
    }

    if (data.id || data._id) {
        result._id = (data.id) ? data.id : data._id;
    }
    return cleanNulls(result);
};

/**
 * Cleans the provided Export for persistence.
 * @param {Object} data - the object to be sanitized.
 */
const prepareExport = (data, channels)=> {
    const channel = channels.find((channel)=> (channel.name === data.channelName));

    if (channel) {
        const user = getUserData();

        const date = dayjs(data.date, 'YYYY-DD-MM');
        const startTime = date.clone().hour(data.startTime.hour()).minute(data.startTime.minute()).second(data.startTime.second());
        const endTime = date.clone().hour(data.endTime.hour()).minute(data.endTime.minute()).second(data.endTime.second());

        const result = {
            name: `${channel.id}_${startTime.unix()}_${endTime.unix()}`,
            date: date,
            start_time: startTime.unix(),
            end_time: endTime.unix(),
            comments: data.comments,
            export_date: dayjs(),
            channel_name: data.channelName,
            user_name: data.username,
            tags: data.tags,

            channel: channel.id,
            user: user.id
        }

        if (data.id || data._id) {
            result._id = (data.id) ? data.id : data._id;
        }
        return cleanNulls(result);
    }
    console.error('Channel not found');
    return null;
};

/**
 * Cleans the provided EventTag for persistence.
 * @param {Object} data - the object to be sanitized.
 */
const prepareEventTag = (data, channels)=> {
    console.log(data);
    const channel = channels.find((channel)=> (channel.name === data.channelName));

    if (channel) {
        const user = getUserData();

        const date = dayjs(data.date, 'YYYY-DD-MM');
        const startTime = date.clone().hour(data.startTime.hour()).minute(data.startTime.minute()).second(data.startTime.second());
        const endTime = date.clone().hour(data.endTime.hour()).minute(data.endTime.minute()).second(data.endTime.second());

        const result = {
            name: `${channel.id}_${startTime.unix()}_${endTime.unix()}`,
            date: date,
            start_time: startTime.unix(),
            end_time: endTime.unix(),
            synopsis: data.synopsis,
            export_date: dayjs(),
            channel_name: data.channelName,
            user_name: data.username,
            tags: data.tags,
            title: data.title,
            type: 'EVENT_TAG',

            channel: channel,
            channel_id: channel.id,
            user: user.id,
            user_id: user.id
        }

        if (data.id || data._id) {
            result._id = (data.id) ? data.id : data._id;
        }
        return cleanNulls(result);
    }
    console.error('Channel not found');
    return null;
};

export default {
    prepareChannel,
    prepareChannelGroup,
    prepareExport,
    prepareEventTag,
    prepareTag,
    prepareUser,
}