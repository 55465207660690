
import React from 'react';
import { inject, observer } from 'mobx-react';

import { EvoUI } from '@components';

import './styles.scss';

@inject('channelStore')
@observer
class ChannelBuilder extends React.Component {
    constructor(props){
      super(props);
  
      this.load = this.load.bind(this);
      this.getData = this.getData.bind(this);
      this.deleteData = this.deleteData.bind(this);
      this.editData = this.editData.bind(this);
  
      this.getEntity = this.getEntity.bind(this);
      
      this.setGstate = this.props.channelStore.setChannelManagement.bind(this.props.channelStore);
    }
    
    componentDidMount() {
        this.load();
    }

    getEntity() {
        const { channelStore } = this.props;
        const { entity } = channelStore.channelManagement;
        return entity
    }

    load() {
        const { channelStore, location } = this.props;
        channelStore.getChannelEntity().then((entity)=> {
            this.setGstate({ entity: entity });
        });
        // Check for Incoming state
        if (location && location && location.state && location.state.id && location.state.view) {
            channelStore.getChannel(location.state.id).then((channel) => {
                if (channel) {
                    this.setGstate({ editViewData: channel, initView: location.state.view });
                }
            });
        } else {
            this.setGstate({ editViewData: false });
        }
    }

    getData(refresh) {
        const { channelStore } = this.props;
        return channelStore.getChannels(refresh).then((items) => {
            items = items.filter((item)=> !item.isPoster).sort((a, b)=> ((a.order === b.order) ? 0 : ((a.order > b.order) ? 1 : -1)));
            this.setGstate({ data: items });
            return { payload: items, error: null };
        });
    }

    editData(obj) {
        const { channelStore } = this.props;
        console.log('EDIT ONE');
        channelStore.addUpdateChannel(obj).finally(() => {
            this.load();
            if (this.refreshData) {
                this.refreshData();
            }
        });
    }

    deleteData(rows) {
        const { channelStore } = this.props;
        const { entity } = channelStore.channelManagement;
        
        let idField = (entity && entity.fields) ? entity.fields.filter((field)=> field.identity) : 'id';
        if (typeof idField !== 'string') {
            idField = idField[0].id;
        }
        
        const delIds = [];
        rows.forEach((row)=> {
            if (row[idField]) {
                delIds.push(row[idField]);
            } else {
                console.error('Identity Field not found for: ' + JSON.stringify(row));
            }
        });
      
        channelStore.deleteChannelMany(delIds).then(() => {
            if(this.refreshData){
                this.refreshData();
            }
        });
    }

    render() {
        const { channelStore, history } = this.props;
        const { editViewData, initView, backUrl } = channelStore.channelManagement;

        const ent = channelStore.channelManagement.entity;
        const view = (initView) ? initView : 'list';
        const isNew = false;

        const goBack = ()=> {
            if (backUrl) {
                history.push(backUrl);
            }
        };

        return (
            <div className='Channel-Builder-Container'>
                <div className='evoDisplay' style={{ height: '100%', width: '100%' }}>
                    {(ent)?
                        <EvoUI key='evo' {...this.props} 
                            entity={ent}
                            entityEdit={this.getEntity}
                            view={view} 
                            isNew={isNew} 
                            refreshData={(refresh) => this.refreshData = refresh}
                            viewData={(editViewData)?editViewData:null}
                            getData={this.getData}
                            backBtn={(backUrl)?goBack:null}
                            deleteOne={this.deleteData}
                            deleteMany={this.deleteData}
                            editOne={this.editData}
                            noViewCards={true} /> :
                        <h2>Loading...</h2>
                    }
                </div>
            </div>
        );
    }
}

export default ChannelBuilder;