import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ChannelCaptions from './components/ChannelCaptions';
import ChannelList from './components/ChannelList';
import moment from 'moment';
import { DatePicker } from './components/DatePicker';
import dayjs from 'dayjs';
import { SCALE, MINS_IN_AN_HOUR, TIME_ACCURACY } from './settings';

import './style.scss';

@inject('epgStore', 'loadingStore')
@observer
class EpgScreen extends Component {

    constructor(props) {
        super(props);
        const { epgStore } = this.props;

        this.state = {
            currentTime: epgStore.getUTCDT().format(),
        }

        this.moveToCurrentPosition = this.moveToCurrentPosition.bind(this);
        this.setDate = this.setDate.bind(this);
    }

    componentDidMount() {
        const { epgStore } = this.props;

        this.isTimerStopped = false;
        this.timer = this.setTimer();

        this.setDate(epgStore.selectedDate);

        this.moveToCurrentPosition(false);
    }

    componentWillUnmount() {
        this.isTimerStopped = true;
        clearTimeout(this.timer);
    }

    setDate(newDate) {
        const { onDateChanged } = this.props;
        onDateChanged(newDate);
    }

    setTimer() {
        const { epgStore } = this.props;
        return setTimeout(() => {
            if (this.isTimerStopped) {
                return;
            }

            this.setState({ currentTime: epgStore.getUTCDT().format() });
            this.timer = this.setTimer();
        }, TIME_ACCURACY);
    }

    getCurrentPosition() {
        const { currentTime } = this.state;
        const currentTimeMoment = moment(currentTime);
        return (currentTimeMoment.hours() * MINS_IN_AN_HOUR + currentTimeMoment.minutes()) * SCALE;
    }

    getCurrentPosition_UTC_Offset() {
        const { currentTime } = this.state;
        let tzOff = new Date().getTimezoneOffset();
        const currentTimeMoment = moment(currentTime).add(tzOff * (-1), 'minutes');
        //console.log('[currentTime] ', currentTime);
        //console.log('[currentTimeMoment] ', currentTimeMoment.format());
        return (currentTimeMoment.hours() * MINS_IN_AN_HOUR + currentTimeMoment.minutes()) * SCALE;
    }

    async moveToCurrentPosition(newDate = true) {
        const { onDateChanged } = this.props;
        if (newDate){
            onDateChanged(dayjs());
        }

        const currentPosition = this.getCurrentPosition_UTC_Offset();
        let scr = document.getElementById('timetable__scrollable');
        // console.log('[moveToCurrentPosition]');
        if (scr && scr.scrollLeft >= 0) {
            let scrollL = currentPosition - window.innerWidth / 2;
            console.log('[timetable__scrollable] ', scrollL);
            document.getElementById('timetable__scrollable').scrollLeft = scrollL;
        }
    }

    renderNowButton() {
        return <button className="timetable__now-button" onClick={this.moveToCurrentPosition}>NOW</button>;
    }

    handleScroll = e => {
        let element = e.target;
        let tWith = element.scrollWidth - element.offsetWidth;
        let sPerc = ((element.scrollLeft / tWith) * 100).toFixed();
        // let pos = this.getOffsetLeft(element);//element.getBoundingClientRect();
        // console.log('SCROLL: ', sPerc);
    }

    render() {
        const { channels, epgStore, currentDate, onProgramSelected, onOpenLiveDialog } = this.props;
        const { currentTime } = this.state;
        const currentPosition = this.getCurrentPosition();
        const currentPositionUTC_Offset = this.getCurrentPosition_UTC_Offset();

        const _date = (epgStore.selectedDate) ? new Date(epgStore.selectedDate) : new Date();
        const m = _date.getMonth() + 1;
        const d = _date.getDate();
        const H = new Date().getHours();
        const M = new Date().getMinutes();
        const _time = `${(H < 10) ? `0${H}` : H}:${(M < 10) ? `0${M}` : M}`;
        const _offset = _date.getTimezoneOffset() / 60; //min
        let displayDate = `${_date.getFullYear()}/${(m < 10) ? `0${m}` : m}/${(d < 10) ? `0${d}` : d}`;
        let tz = '';// (_offset === 0)?'[UTC]':` [UTC ${(_offset > 0)?`+${_offset}`:`${_offset}`}]`;
        return (
            <div className='EPG-Screen-Container'>
                {(channels && channels.length > 0) ?
                    <>
                        <div className='cntDiv'>
                            <div className='linDiv'>
                                {this.renderNowButton()}
                            </div>
                            <div className='linDiv'>
                                <DatePicker selectedDate={epgStore.selectedDate} onDateChanged={this.setDate} />
                            </div>
                            <div className='dateDiv'>
                                {displayDate} <p style={{ fontSize: 12, textAlign: 'right' }}>{_time} {tz}</p>
                            </div>
                        </div>
                        <br />
                        <div className="timetable">
                            <div id="timetable__scrollable"
                                onScroll={this.handleScroll}
                                className="timetable__scrollable">
                                <ChannelCaptions channels={channels} onOpenLiveDialog={onOpenLiveDialog} />
                                <ChannelList
                                    channels={channels}
                                    currentTime={currentTime}
                                    currentDate={currentDate}
                                    currentPosition={currentPosition}
                                    currentPositionUTC_Offset={currentPositionUTC_Offset}
                                    onProgramSelected={onProgramSelected} />
                            </div>
                        </div>
                    </>
                    : null}
            </div>
        );
    }
}

EpgScreen.propTypes = {
    channels: PropTypes.array,
    currentDate: PropTypes.object,
    onProgramSelected: PropTypes.func,
    onOpenLiveDialog: PropTypes.func
};

export default EpgScreen;
