import { observable, computed, action } from 'mobx';
//, configure
import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

// Set Strict to ensure data is updated in Alias actions when using async/promises.
//configure({ enforceActions: 'strict' });

/**
 * Mobx View State
 * this.setGstate = this.props.store.setDashboardState.bind(this.props.store);
 */

const defaultData = {
  dashboardState: {
    key: new Date().getTime(),
    value: 0,
    change: null,
    destination: null,
    gaError: false,
    repInterval: null,
    mapData: null,
    mapDataTotal: null,
    mapDataTime: null,
    
    analyticsData: null,
    mounted: null,
    dataFetched: null,

    usersLive: null,
    usersLiveTime: null,

    errorEvents: null,
    errorEventsTotal: null,
    errorEventsTime: null,

    contentPlayedTotal: null,
    contentPlayed: null,
    contentPlayedTime: null,

    usersData: null,
    usersTotal: null,
    usersTime: null,

    totalUsersReached: null,
    totalUsersReachedTime: null,

    avgTimeData: null,
    avgTimeOnSite: null,
    avgTimeOnSiteTime: null,

    contentViewed: null,
    contentViewedTime: null,

    playsData: null,
    playsTotal: null,
    playsTime: null,

    sessions: null,
    sessionsTotal: null,
    sessionsTime: null,

    devices: null,
    devicesTotal: null,
    devicesTime: null,

    sources: null,
    sourcesTotal: null,
    sourcesTime: null,

    sessionsPm: null,
    sessionsPmTotal: null,
    sessionsPmTime: null,

    isDirty: null,
    timeframe: null
  },
  contentManager: {
    openInputModal: false,
    fileUUIDs:[],
    newObjs: [],
    scrollTop: 0,
    activeKey: ['1'],
    categories: [],
    deleteModal: false,
    images:{
      imgScale: '1',
      alphaImageScale: '1'
    },
    editViewData: null,
    initView: null,
    backUrl: null,
    contentInfo: null,
    contentProps: null,
    contentInfoModal: false
  },
  overlayManager: {},
  appManager: {},
  userState: {
    orgName:''
  }
};

class ViewStore {

  @observable dashboardState = {
    key: new Date().getTime(),
    value: 0,
    change: null,
    destination: null,
    gaError: false,
    repInterval: null,
    mapData: null,
    mapDataTotal: null,
    mapDataTime: null,
    
    analyticsData: null,
    mounted: null,
    dataFetched: null,

    usersLive: null,
    usersLiveTime: null,

    errorEvents: null,
    errorEventsTotal: null,
    errorEventsTime: null,

    contentPlayedTotal: null,
    contentPlayed: null,
    contentPlayedTime: null,

    usersData: null,
    usersTotal: null,
    usersTime: null,

    totalUsersReached: null,
    totalUsersReachedTime: null,

    avgTimeData: null,
    avgTimeOnSite: null,
    avgTimeOnSiteTime: null,

    contentViewed: null,
    contentViewedTime: null,

    playsData: null,
    playsTotal: null,
    playsTime: null,

    sessions: null,
    sessionsTotal: null,
    sessionsTime: null,

    devices: null,
    devicesTotal: null,
    devicesTime: null,

    sources: null,
    sourcesTotal: null,
    sourcesTime: null,

    sessionsPm: null,
    sessionsPmTotal: null,
    sessionsPmTime: null,

    isDirty: false,
    timeframe: null
  };

  @observable contentManager = {
    openInputModal: false,
    fileUUIDs:[],
    newObjs: [],
    scrollTop: 0,
    activeKey: ['1'],
    categories: [],
    deleteModal: false,
    images:{
      imgScale: '1',
      alphaImageScale: '1'
    },
    editViewData: null,
    initView: null,
    backUrl: null,
    contentInfo: null,
    contentProps: null,
    contentInfoModal: false
  };

  @observable posterContentManager = {
    openInputModal: false,
    fileUUIDs:[],
    newObjs: [],
    scrollTop: 0,
    activeKey: ['1'],
    categories: [],
    deleteModal: false,
    images:{
      imgScale: '1',
      alphaImageScale: '1'
    },
    editViewData: null,
    initView: null,
    backUrl: null,
    contentInfo: null,
    contentProps: null,
    contentInfoModal: false
  };

  @observable overlayManager = {};
  @observable appManager = {};

  @observable userState = {
    orgName:''
  };

  @action setDashboardState(data, callback) {
    this.setViewState('dashboardState', data, callback);
    if(data && data.usersLive){
      //skip
    } else {
      this.dashboardState.key = new Date().getTime();
    }
  }

  @action setContentManagerState(data, callback) {
    this.setViewState('contentManager', data, callback);
  }

  @action setPostersContentManagerState(data, callback) {
    this.setViewState('posterContentManager', data, callback);
  }

  @action setUserState(data, callback) {
    this.setViewState('userState', data, callback);
  }

  @action setOverlayManagerState(data, callback) {
    this.setViewState('overlayManager', data, callback);
  }

  @action setAppManagerState(data, callback) {
    this.setViewState('appManager', data, callback);
  }

  @action hasAnalyticsData() {
    return this.analyticsData;
    //return !!(this.dashboardState.devices || this.dashboardState.sessions || this.dashboardState.contentPlayed);
  }

  /**
   * Generic State Set  
   * @param {String} objStr Local Observable Name
   * @param {Object} data Data to be saved
   * @param {Function} callback Optional Callback
   */
  @action setViewState(objStr, data, callback){
    if(objStr){
      Object.keys(data).forEach(key => {
        this[objStr][key] = data[key];
      });
    }

    if(callback && typeof callback === 'function'){
      callback();
    }
  }

  // For SIO
  // @action Subscribe(type, payload){
  //   console.log('[PING STORE]', type, payload);
  // }

  @action clearStore(){
    // Set Defaults
    Object.keys(defaultData).forEach(key => {
      this[key] = defaultData[key];
    });
  }
}

// var viewStore = SIOStore(ViewStore, 'ALL');
var viewStore = new ViewStore();

// One instance
export default viewStore;