
import React from 'react';
// import { createUseStyles } from 'react-jss';

import { inject, observer } from 'mobx-react';

import { EvoUI } from '@components';

import './styles.scss';

@inject('channelStore')
@observer
class ChannelGroups extends React.Component {
    constructor(props){
      super(props);
  
      this.load = this.load.bind(this);
      this.getData = this.getData.bind(this);
      this.deleteData = this.deleteData.bind(this);
      this.editData = this.editData.bind(this);
  
      this.getEntity = this.getEntity.bind(this);
      
      this.setGstate = this.props.channelStore.setChannelGroupManagement.bind(this.props.channelStore);
    }
    
    componentDidMount() {
        this.load();
    }

    getEntity(obj=null) {
        const { channelStore } = this.props;
        const { entity, entityEdit } = channelStore.channelGroupManagement;
        return (obj) ? entityEdit : entity;
    }

    load() {
        const { channelStore, location } = this.props;
        const { entity, entityEdit } = channelStore.channelGroupManagement;
        if (!entity || !entityEdit) {
            const model = channelStore.getChannelGroupEntity();
            this.setGstate({ entity: model, entityEdit: model });
        }
        // Check for Incoming state
        if (location && location && location.state && location.state.id && location.state.view) {
            channelStore.getChannelGroup(location.state.id).then((channelGroup) => {
                if (channelGroup) {
                    this.setGstate({ editViewData: channelGroup, initView: location.state.view });
                }
            });
        } else {
            this.setGstate({ editViewData: false });
        }
    }

    getData(refresh) {
        const { channelStore } = this.props;
        return channelStore.getChannelGroups(refresh).then((items) => {
            this.setGstate({ data: items });
            return { payload: items, error: null };
        });
    }

    editData(data) {
        const { channelStore } = this.props;
        channelStore.addUpdateChannelGroup(data).finally(() => {
            this.load();
            if (this.refreshData) {
                this.refreshData();
            }
        });
    }

    deleteData(rows) {
        const { channelStore } = this.props;
        const { entity } = channelStore.channelGroupManagement;
        
        let idField = (entity && entity.fields) ? entity.fields.filter((field)=> field.identity) : 'id';
        if (typeof idField !== 'string') {
            idField = idField[0].id;
        }
        
        const delIds = [];
        rows.forEach((row)=> {
            if (row[idField]) {
                delIds.push(row[idField]);
            } else {
                console.error('Identity Field not found for: ' + JSON.stringify(row));
            }
        });
      
        channelStore.deleteChannelGroupsMany(delIds).then(() => {
            if(this.refreshData){
                this.refreshData();
            }
        });
    }

    render() {
        const { classes, channelStore, history } = this.props;
        const { entity, editViewData, initView, backUrl } = channelStore.channelGroupManagement;

        const view = (initView) ? initView : 'list';
        const isNew = false;

        const goBack = ()=> {
            if (backUrl) {
                history.push(backUrl);
            }
        };

        return (
            // <div className={classes.Container}>
            <div className='Channel-Groups-Container'>
                <div className='evoDisplay' style={{ height: '100%', width: '100%' }}>
                    {(entity)?
                    <EvoUI key='evo' {...this.props} 
                        entity={entity}
                        entityEdit={this.getEntity}
                        view={view} 
                        isNew={isNew} 
                        refreshData={(refresh) => this.refreshData = refresh}
                        viewData={(editViewData)?editViewData:null}
                        getData={this.getData}
                        backBtn={(backUrl)?goBack:null}
                        deleteOne={this.deleteData}
                        deleteMany={this.deleteData}
                        editOne={this.editData}
                        noViewCards={true} />:<h2>Loading...</h2>}
                </div>
            </div>
        );
    }
}

export default ChannelGroups;