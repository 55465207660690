
import { observable, action } from 'mobx';

import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

const defaultData = {
    autoPlay: false,
    canPlay: false,
    currentTime: 120,
    duration: 0,
    element: null,
    hls: null,
    isPlaying: false,
    readyTimestamp: null,
    source: '',
};

class ScrubberPlayerStore {
    @observable autoPlay = false;
    @observable canPlay = false;
    @observable currentTime = 120;
    @observable duration = 1800;
    @observable element = null;
    @observable hls = null;
    @observable isPlaying = false;
    @observable readyTimestamp = null;
    @observable source = '';

    @action resetPlayer() {
        this.autoPlay = false;
        this.canPlay = false;
        this.currentTime = 120;
        this.duration = 0;
        this.isPlaying = false;
        this.readyTimestamp = null;

        if (this.element) {
            this.element.currentTime = 120;
        }
    }

    @action setAutoPlay(autoPlay) {
        if (this.autoPlay !== autoPlay) {
            this.autoPlay = autoPlay;
        }
    }

    @action setCanPlay(canPlay) {
        if (this.canPlay !== canPlay) {
            this.canPlay = canPlay;
        }
    }

    @action setCurrentTime(newTime, updatePlayer=true) {
        if (this.currentTime !== newTime) {
            this.currentTime = newTime;
            if (this.element && updatePlayer) {
                this.element.currentTime = newTime;
            }
        }
    }

    @action setDuration(newDuration) {
        if (this.duration !== newDuration) {
            this.duration = newDuration;
        }
    }

    @action setIsPlaying(isPlaying) {
        if (this.isPlaying !== isPlaying) {
            this.isPlaying = isPlaying;
        }
    }

    @action setMediaElement(newElement) {
        if (this.element !== newElement) {
            this.element = newElement;
        }
    }

    @action setReadyTimestamp(newTimestamp) {
        if ((!this.readyTimestamp) || (this.readyTimestamp.unix() !== newTimestamp.unix())) {
            this.readyTimestamp = newTimestamp;
        }
    }

    @action setSource(newSource) {
        if (this.source !== newSource) {
            this.source = newSource;
        }
    }
}

// const scrubberPlayerStore = SIOStore(ScrubberPlayerStore, 'ALL');
const scrubberPlayerStore = new ScrubberPlayerStore();
export default scrubberPlayerStore;