/* eslint-disable no-restricted-globals */
// Additional DOM Utils & Checks

if(self && self.Prism && self.Prism.fileHighlight){
    // All good ;)
} else {
    self.Prism.fileHighlight = ()=>{}
}

// CONSOLE
let debug = (process.env.NODE_ENV === 'development') || false;
// define a new console
var console=(function(oldCons){
    return {
        log: function(message, ...optionalParams){
            if(debug){
                oldCons.log(message, ...optionalParams);
            }
        },
        info: function (message, ...optionalParams) {
            if(debug){
                oldCons.info(message, ...optionalParams);
            }
        },
        debug: function (message, ...optionalParams) {
            if(debug){
                oldCons.debug(message, ...optionalParams);
            }
        },
        warn: function (message, ...optionalParams) {
            if(debug){
                oldCons.warn(message, ...optionalParams);
            }
        },
        error: function (message, ...optionalParams) {
            oldCons.error(message, ...optionalParams);
        },
        assert: function (message, ...optionalParams) {
            oldCons.assert(message, ...optionalParams);
        },
    };
}(window.console));

//Then redefine the old console
window.console = console;