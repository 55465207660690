import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// reactstrap components
import {
    Button, Card, CardHeader, CardBody, CardFooter, CardTitle, Col, Form,
    Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row
} from 'reactstrap';

import images from '@assets';

import { RandomString, CharSets } from '../../utils/string';

import MHLogo from '../../assets/img/mhlogo.png';

import Captcha from '../../components/Captcha';

@inject('authStore', 'loadingStore')
@withRouter
@observer
class Login extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: 'cardHidden',
            // Needs local state to decern Username or Email
            data: { username: '', password: '', captcha: ''},
            hasErrors: false,
            errors: { username: false, password: false, message: null, captcha: false },
            captchaText: '',
            maxFailedAttempts: 3
        };

        this.login = this.login.bind(this);
        this.answerCaptcha = this.answerCaptcha.bind(this);
        this.validate = this.validate.bind(this);
        this.validateCaptcha = this.validateCaptcha.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }
    componentDidMount() {
        const {loadingStore} = this.props;
        document.body.classList.toggle('login-page');

        this.setState({ captchaText: RandomString.generate([...CharSets.ALL_UPPER_ALPHA, ...CharSets.ALL_LOWER_ALPHA, ...CharSets.ALL_NUMERIC], 6) });

        // Animate in
        setTimeout(
            function () {
                this.setState({ cardAnimaton: '' });
                loadingStore.setLoadingForce(false);
            }.bind(this),
            350
        );        
    }
    componentWillUnmount() {
        document.body.classList.toggle('login-page');
    }

    validate() {
        const { password, username } = this.state.data;

        const errors = { message: '', password: false, username: false, captcha: false };
        let valid = false;
        if (username && username.length) {
            if (password && password.length) {
                valid = true;
            } else {
                errors.password = true;
            }
        } else {
            errors.username = true;
        }
        
        if (!valid) {
            errors.message = 'Missing Required Fields';
        }

        this.setState({ errors, hasErrors: !valid });
        return valid;
    }

    async login(event) {
        const { authStore, history } = this.props;
        const { password, username } = this.state.data;

        event.preventDefault();

        if (!this.validate()) { // Validate the supplied data...
            return false;
        }

        const data = {
            username: (username.indexOf('@') < 0) ? username : '',
            email: (username.indexOf('@') > -1) ? username : '',
            password: password
        };

        this.setState({ cardAnimaton: 'cardHidden' });
        
        try {
            const loggedIn = await authStore.login(data);
            if (loggedIn) {
                authStore.authentication.failedAttempts = 0;
                history.replace('/home/epg');
            }
        } catch (err) {
            const errors = this.state.errors;
            if (err && (err.status === 401 || !err.success)) {
                authStore.authentication.failedAttempts = (authStore.authentication.failedAttempts+1);
                errors.message = 'Invalid Username or Password';
            } else {
                const errorMsg = authStore.getErrors();
                errors.message = errorMsg;
            }
            if (authStore.authentication.failedAttempts >= this.state.maxFailedAttempts) {
                errors.message = '';
            }

            // Set errors
            this.setState({ cardAnimaton: '', errors, hasErrors: true });
            console.error(err);
        }
    }

    answerCaptcha() {
        const { data, captchaText, errors } = this.state;
        const { authStore } = this.props;
        if (!this.validateCaptcha()) {
            return false;
        }
        if (data.captcha !== captchaText) {
            this.setState({ errors: { ...errors, message: 'CAPTCHA Failed', captcha: true } });
        } else {
            authStore.authentication.failedAttempts = 0;
            this.setState({ data: { ...data, captcha: '' } });
            this.setState({ captchaText: RandomString.generate([...CharSets.ALL_UPPER_ALPHA, ...CharSets.ALL_LOWER_ALPHA, ...CharSets.ALL_NUMERIC], 6) });
        }
    }

    validateCaptcha() {
        const { data, errors } = this.state;
        if (!data.captcha) {
            this.setState({ errors: { ...errors, message: 'CAPTCHA Failed', captcha: true } });
            return false;
        }
        return true;
    }

    handleChange(event) {
        let field = (event.target.name && event.target.name.length > 0) ? event.target.name : event.target.id;
        let val = event.target.value;
        let data = this.state.data;
        data[field] = val;

        // Save State & Clear errors
        let errors = { message: '', password: false, username: false }
        this.setState({ data, errors });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            let field = (event.target.name && event.target.name.length > 0) ? event.target.name : event.target.id;
            switch (field) {
                case 'username':
                    if (this.inputPassword) {
                        this.inputPassword.focus();
                    }
                    break;
                case 'password': this.login(event);
                    break;
                case 'captcha':
                    this.answerCaptcha(); break;
                default:
                    break;
            }
        }
    }

    render() {
        const { authStore } = this.props;
        let { data, errors, hasErrors, cardAnimaton } = this.state;

        // TODO: Perhaps this could be extracted into its own component to make this more readable...
        if (authStore.failedAttempts >= this.state.maxFailedAttempts) {
            return (
                <div className='content'>
                    <Container>
                        <Row>
                            <Col className='ml-auto mr-auto text-center' md='6'>
                                <h3 className='description'>

                                </h3>
                            </Col>
                        </Row>
                        <Col className='ml-auto mr-auto' lg='4' md='6'>
                            <Form className={cardAnimaton + ' loginForm form'}>
                                <Card className='card-login card-white'>
                                    <CardHeader>
                                        <img alt='...' src={MHLogo} />
                                        <CardTitle tag='h4'></CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Captcha
                                            text={this.state.captchaText}
                                            height={72}
                                            width={160} />
                                        <Input
                                            id='captcha'
                                            placeholder='Answer'
                                            name='captcha'
                                            value={data.captcha}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            type='text' />
                                    {(hasErrors) ?
                                        <div className='errorText'>{errors.message}</div>
                                        : null
                                    }
                                    </CardBody>
                                    <CardFooter>
                                        <Button block
                                            className='mb-3'
                                            color='primary'
                                            onClick={this.answerCaptcha}
                                            size='lg'>
                                            Submit
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Container>
                </div>
            );
        }

        return (
            <div className='content'>
                <Container>
                    <Row>
                        <Col className='ml-auto mr-auto text-center' md='6'>
                            <h3 className='description'>
                                
                            </h3>
                        </Col>
                    </Row>
                    <Col className='ml-auto mr-auto' lg='4' md='6'>
                        <Form className={cardAnimaton + ' loginForm form'}>
                            <Card className='card-login card-white'>
                                <CardHeader>
                                    <img alt='...' src={MHLogo} />
                                    <CardTitle tag='h4'></CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <InputGroup>
                                        <InputGroupAddon addonType='prepend'>
                                            <InputGroupText>
                                                <i className='tim-icons icon-email-85' />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            id='username'
                                            placeholder='Email'
                                            name='username'
                                            value={data.username}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            type='text' />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType='prepend'>
                                            <InputGroupText>
                                                <i className='tim-icons icon-lock-circle' />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            id='password'
                                            placeholder='Password'
                                            name='password'
                                            value={data.password}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            type='password' />
                                    </InputGroup>
                                {(hasErrors) ?
                                    <div className='errorText'>{errors.message}</div>
                                    : null
                                }
                                </CardBody>
                                <CardFooter>
                                    <Button block
                                        className='mb-3'
                                        color='primary'
                                        onClick={this.login}
                                        size='lg'>
                                        Let's Go
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                </Container>
            </div>
        );
    }
}

export default Login;
