
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export const Panel = ({ children, tabsRef })=> {
    const [height, setHeight] = useState(0);

    useEffect(()=> {
        if (tabsRef && tabsRef.current) {
            setHeight(`calc(100% - ${tabsRef.current.offsetHeight}px)`);
        }
    }, [tabsRef, tabsRef.current]);

    return (
        <div className='Tabs-Panel-Container' style={{ height: height }}>
            <div className='Tabs-Panel-Wrapper'>
                {children}
            </div>
        </div>
    );
};

Panel.propTypes = {
    children: PropTypes.node,
    tabsRef: PropTypes.object
};