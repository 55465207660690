import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import ChannelItem from './ChannelItem';
import TimeLine from './TimeLine';
import {SCALE, MINUTES_IN_A_DAY} from '../settings';

import EPGDisabledOverlay from './overlays/DisabledOverlay';

dayjs.extend(isBetween);

class ChannelList extends PureComponent {

  getContainerStyles() {
    const width = MINUTES_IN_A_DAY * SCALE;

    return {width};
  }

  isToday(_date){
    let startDateTime = new Date(dayjs().startOf('day'));
    let endDateTime = new Date(dayjs().endOf('day'));
    let date = new Date(_date);

    // console.log('[isBetween] ', `${_date}`, ' ', dayjs(_date).isBetween(startDateTime, endDateTime, null, '[]'));

    if(date){
      return (date >= startDateTime && date <= endDateTime);
    }

    return false;
  }

  isFuture(target) {
      const endOfToday = dayjs().endOf('day').toDate();
      const date = new Date(target);

      return ((date) && (date >= endOfToday));
  }

  render() {
    const {channels, currentTime, currentDate, currentPosition, currentPositionUTC_Offset, onProgramSelected} = this.props;
    const containerStyles = this.getContainerStyles();
    const channelElements = channels.map(channel => {
      return <ChannelItem currentTime={currentTime} channel={channel} key={channel.id} onProgramSelected={onProgramSelected}/>
    });

    // console.log('[currentTime]', currentTime);
    return (
      <div className="timetable__container" style={containerStyles}>
        <div className="timetable__header">
          <TimeLine channels={channels}/>
        </div>
        <div className="timetable__body">
          {channelElements}
        </div>
        {(this.isToday(currentDate))?
          <div className="timetable__current" style={{left: currentPositionUTC_Offset}}> </div>
        :null}
        {(this.isToday(currentDate) || this.isFuture(currentDate)) &&
          <EPGDisabledOverlay
            width={((this.isFuture(currentDate)) ? '100%' : `calc(${MINUTES_IN_A_DAY*SCALE}px - ${currentPositionUTC_Offset}px)`)} />
        }
      </div>
    );
  }
}

ChannelList.propTypes = {
  channels: PropTypes.array,
  currentTime: PropTypes.string,
  currentDate: PropTypes.object,
  currentPosition: PropTypes.number,
  currentPositionUTC_Offset: PropTypes.number,
  onProgramSelected: PropTypes.func
};

export default ChannelList
