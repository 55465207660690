
export default {
    id: 'export',
    label: 'Exports List',
    name: 'export',
    namePlural: 'exports',
    hideTitle: true,
    titleField: 'name',

    noEditViewField: true,
    newButtons: {
        allowBreakout: true,
        allowDownload: true,
    },

    cardOptions: {
        labelField: 'name',
        descField: 'status',
        imgField: 'export_date',
        masonryOptions: {
            transitionDuration: 100,
            gutter: 10,
            originTop: true,
            resize: true,
            fitWidth: true
        }
    },
    gridOptions: {
        columns: [],
        download: false,
        print: false,
        filter: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 20, 50, 100, 200],
        downloadOptions: {
            filename: 'exports.csv',
            separator: ','
        }
    },
    fields: [
        {
            id: '_id',
            identity: true,
            type: 'text',
            listColumnOptions: { display: false },
            required: false,
            width: 0,
        },
        {
            id: 'label',
            inMany: true,
            label: 'Label',
            type: 'text',
            width: 100,
        }, {
            id: 'times',
            label: 'Times',
            type: 'text',
            width: 100
        },
        {
            id: 'status',
            label: 'Status',
            type: 'text',
            width: 100,
        },
        {
            id: 'export_date',
            label: 'Export Date',
            listColumnOptions: { display: false },
            type: 'text',
            width: 100,
        },
        {
            id: 'shortComment',
            label: 'Comments',
            type: 'text',
            width: 100
        }
    ],
    groups: [
        {
            id: 'p1', type: 'panel',
            label: 'Details', width: 100,
            fields: ['name', 'status', 'export_date', 'comments']
        }
    ]
}