/*eslint-disable*/
import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { inject, observer } from 'mobx-react';
// import config from '../../constants/config';
import images from '@assets';
import utils from '../../stores/utils';

// used for making the prop types of this component
import PropTypes from "prop-types";

@inject('adminStore')
@observer
class Img extends React.Component {

  // TODO: Depricate This
  constructor(props){
    super(props);

    this.state = {
      src: null
    };

    this.imageExists = this.imageExists.bind(this);
    this.setState = this.setState.bind(this);
  }

  // TODO: Depricate This
  async componentDidMount(){
    const { src, adminStore, itemObj } = this.props;
    let _src = (itemObj && itemObj.source)?itemObj.source:null;

    if(src && src.indexOf('/') >= 0){
      // Has a Path
      _src = src;
      // if(_src.indexOf('base64') < 0){
      //   if(config.debug && config.imgUrl){
      //     _src = `${config.imgUrl}${_src}`;
      //   }
      // }
      //_src = this.setSize(_src); // Don't have this on API
      this.setState({src: _src});
    } else if(src && src.length > 0) {
      // Try and get path
      let that = this;
      await adminStore.getImage(src).then((res) => {
        if(res && res.source){
          _src = res.source;
          // if(_src.indexOf('base64') < 0){
          //   if(config.debug && config.imgUrl){
          //     _src = `${config.imgUrl}${_src}`;
          //   }
          // }
          //_src = this.setSize(_src);
          that.setState({src: _src});
        }
      });
    }

    // console.debug('[IMG]', _src);
  }

  // Don't have this on API
  // setSize(src){
  //   let { size } = this.props;

  //   size = size || '256';
  //   try{
  //     let imageParts = src.split('/');
  //     let imageName = imageParts[imageParts.length - 1];
  //     let imageNameParts = imageName.split('.');
  //     let imgName = imageNameParts[0];
  //     let imgExt = imageNameParts[1];
  //     let imagePath = src.replace(imageName, '');

  //     let newPath = `${imagePath}${imgName}_${size}.${imgExt}`;
  //     // Check if it exists
  //     if(this.imageExists(newPath)){
  //       src = newPath;
  //     }

  //   } catch(err) {
  //     // 
  //   }
    

  //   return src;
  // }

  imageExists(image_url){

    // Check Local Cache
    if(utils.hasSession(image_url)){
      return true;
    }

    // Check Web
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    let exist = http.status != 404;

    if(exist){
      // set Session
      utils.setSession(image_url, '');
    }

    return exist;
  }

  render() {
    let { key, alt, style, className, itemObj, wrapperClassName } = this.props;
    let { src } = this.state;

    if(src){
      return (
        <LazyLoadImage
          key={key || new Date().getTime()}
          alt={alt || ''}
          className={`${(className || '')} lazyImg`}
          src={src}
          effect='opacity'
          placeholder={<div>...</div>}
          wrapperClassName={wrapperClassName}
          style={style || {}} />
      );
    } else {
      return (
        <img 
          key={key || new Date().getTime()}
          src={images.image_placeholder}
          className={`${(className || '')} lazyImgPh`}
          style={style || {}}
          alt={key}/>
      );
    }
  }
}

Img.propTypes = {
  key: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.string, // 512, 256, 128, 64
  style: PropTypes.object,
  itemObj: PropTypes.object,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string
};

export default Img;
