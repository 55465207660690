
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

const ValueLabelComponent = ({ children, media, open, value })=> {
  
    return (
        <HtmlTooltip open={open}
            title={<div>{value}</div>}>
            {children}
        </HtmlTooltip>
    );
  }

export const Thumb = ({ currentTime, media={}, onMediaTimeChanged }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <StyledSlider
                ValueLabelComponent={ValueLabelComponent}
                valueLabelFormat={()=> {
                    if (media.readyTimestamp) {
                        const date = currentTime.add(2, 'hour')   // TODO: This might be wrong...
                        return date.toISOString().substr(11, 8);
                    }
                    return '';
                }}
                defaultValue={120}
                min={0}
                max={media.duration||5400}
                value={media.currentTime}
                onChange={onMediaTimeChanged} />
        </div>
    );
};

Thumb.propTypes = {
    currentTime: PropTypes.object,
    media: PropTypes.object,
    onCurrentTimeChanged: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        margin: '0 0 0 80px',
        position: 'absolute',
        top: '0',
        width: 'calc(100% - 160px)',
    }
}));

const StyledSlider = withStyles({
    root: {
        color: '#00000000',
        height: '100%',
        padding: '0',
    },
    thumb: {
        backgroundColor: 'red',
        borderRadius: '2px',
        height: '100%',
        margin: '0 0 0 -2px',
        width: '8px',
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        '&::after': {
            bottom: '0',
            left: '0',
            right: '0',
            top: '0'
        }
    },
    active: {},
    track: {
        borderRadius: '2px',
        height: '100%',
    },
    rail: {
        borderRadius: '2px',
        height: '100%',
    },
})(Slider);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'red',
      color: 'white',
      transform: 'translateY(50px) !important',
      zIndex: '0',
    },
  }))(Tooltip);