
const parseJSON = (data)=> {
    if (data && data.indexOf('{') !== -1) {
        try { return JSON.parse(data); }
        catch(err) { return null; }
    }
    return data;
};

export default {
    dateFormat: 'DD/MM/YYYY HH:mm:ss',

    clearLocal: ()=> {
        window.localStorage.clear();
    },
    getLocalItem: (key)=> {
        const data = window.localStorage.getItem(key);
        return parseJSON(data);
    },
    hasLocalItem: (key)=> {
        return !!(window.localStorage.getItem(key))
    },
    removeLocalItem: (key)=> {
        window.localStorage.removeItem(key);
    },
    setLocalItem: (key, data)=> {
        const _data = ((typeof data === 'object') ? JSON.stringify(data) : data);
        window.localStorage.setItem(key, _data);
    },
    clearSession: ()=> {
        window.localStorage.clear();
    },
    getSessionItem: (key)=> {
        const data = window.localStorage.getItem(key);
        return parseJSON(data);
    },
    hasSessionItem: (key)=> {
        return !!(window.localStorage.getItem(key))
    },
    removeSessionItem: (key)=> {
        window.localStorage.removeItem(key);
    },
    setSessionItem: (key, data)=> {
        const _data = ((typeof data === 'object') ? JSON.stringify(data) : data);
        window.localStorage.setItem(key, _data);
    },

    asyncForEach: async (array, callback)=> {
        for (let index=0; index<array.length; index++) {
            await callback(array[index], index, array);
        }
    },
    orderChannels: (a, b)=> ((a.order < b.order) ? -1 : ((a.order > b.order) ? 1 : 0)),
    orderChannelGroups: (a, b)=> ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0))
};