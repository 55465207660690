import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col
} from "reactstrap";

import images from '@assets';
import Lottie from 'lottie-react-web';

class Home extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("home-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("home-page");
  }
  render() {
    return (
      <>
        <div className="content">
          <Container>
            <br/>
            <Row>
              <Col className="ml-auto mr-auto text-center" offset='3' md="6">
                <h1 className="title">Welcome to Selectcast</h1>
                {/* <h4 className="description">
                We’re a bunch of crazy, bright and creative people who love being part of the new video economy and turning the tables on the status quo.
                </h4> */}
              </Col>
            </Row>
            <br/>
            <Row>
              <Col className="ml-auto mr-auto text-center blue-row rounded-row" offset='1' md="10">
                <Row>
                  <Col>
                  <Lottie
                    // height='250px'
                    options={{
                      animationData: images.animBackground
                    }}
                  />
                  </Col>
                  <Col>
                  <h2>
                    Every minute, 400 hrs of content is uploaded to large internet video sharing platforms, which makes them the most cluttered place on the Internet.
                  </h2>
                  <h3>How can your content compete with 9 billion videos?</h3>
                  <br/>
                  {/* <p>How can your content compete with 9 billion videos?</p> */}
                  <br/>
                  <p>You need your own destination, you need Selectcast; </p>
                  <br/>
                  <p>A custom video enviroment where audiences can purposefully engage with content, distracted only by you and your brand.</p>
                  <br/>
                  <p>letstalk@tysflo.com</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br/><br/>
            {/* <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h1 className="title">Pick the best plan for you</h1>
                <h4 className="description">
                  You have Free Unlimited Updates and Premium Support on each
                  package.
                </h4>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col lg="3" md="6">
                <Card className="card-pricing card-primary">
                  <CardBody>
                    <CardTitle tag="h1">pro</CardTitle>
                    <CardImg
                      alt="..."
                      src={images['cardprimary']}
                    />
                    <ListGroup>
                      <ListGroupItem>300 messages</ListGroupItem>
                      <ListGroupItem>150 emails</ListGroupItem>
                      <ListGroupItem>24/7 Support</ListGroupItem>
                    </ListGroup>
                    <div className="card-prices">
                      <h3 className="text-on-front">
                        <span>$</span>
                        95
                      </h3>
                      <h5 className="text-on-back">95</h5>
                      <p className="plan">Professional plan</p>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center mb-3 mt-3">
                    <Button className="btn-round btn-just-icon" color="primary">
                      Get started
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6">
                <Card className="card-pricing card-success card-white">
                  <CardBody>
                    <CardTitle tag="h1">basic</CardTitle>
                    <CardImg
                      alt="..."
                      src={images['card-success']}
                    />
                    <ListGroup>
                      <ListGroupItem>50 messages</ListGroupItem>
                      <ListGroupItem>100 emails</ListGroupItem>
                      <ListGroupItem>24/7 Support</ListGroupItem>
                    </ListGroup>
                    <div className="card-prices">
                      <h3 className="text-on-front">
                        <span>$</span>
                        57
                      </h3>
                      <h5 className="text-on-back">57</h5>
                      <p className="plan">Basic plan</p>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center mb-3 mt-3">
                    <Button className="btn-round btn-just-icon" color="success">
                      Get started
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6">
                {/* card-raised *
                <Card className="card-pricing card-warning card-white">
                  <CardBody>
                    <CardTitle tag="h1">mid</CardTitle>
                    <CardImg
                      alt="..."
                      src={images['card-warning']}
                    />
                    <ListGroup>
                      <ListGroupItem>200 messages</ListGroupItem>
                      <ListGroupItem>130 emails</ListGroupItem>
                      <ListGroupItem>24/7 Support</ListGroupItem>
                    </ListGroup>
                    <div className="card-prices">
                      <h3 className="text-on-front">
                        <span>$</span>
                        72
                      </h3>
                      <h5 className="text-on-back">72</h5>
                      <p className="plan">Medium plan</p>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center mb-3 mt-3">
                    <Button className="btn-round btn-just-icon" color="warning">
                      Get started
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6">
                <Card className="card-pricing card-danger card-white">
                  <CardBody>
                    <CardTitle tag="h1">trial</CardTitle>
                    <CardImg
                      alt="..."
                      src={images['card-danger']}
                    />
                    <ListGroup>
                      <ListGroupItem>50 messages</ListGroupItem>
                      <ListGroupItem>50 emails</ListGroupItem>
                      <ListGroupItem>No Support</ListGroupItem>
                    </ListGroup>
                    <div className="card-prices">
                      <h3 className="text-on-front">
                        <span>$</span>9
                      </h3>
                      <h5 className="text-on-back">9</h5>
                      <p className="plan">Trial plan</p>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center mb-3 mt-3">
                    <Button className="btn-round btn-just-icon" color="danger">
                      Get started
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Col className="mt-5" md="12">
              <h3 className="title">Professional Plan</h3>
            </Col>
            <Row>
              <Col md="4">
                <p>
                  Premium pricing is the strategy of consistently pricing at, or
                  near, the high end of the possible price range to help attract
                  status-conscious consumers. The high pricing of a premium
                  product ...
                </p>
              </Col>
              <Col className="ml-auto" md="6">
                <div className="progress-container progress-warning">
                  <span className="progress-badge">500GB</span>
                  <Progress max="100" value="75" />
                </div>
                <div className="progress-container progress-primary">
                  <span className="progress-badge">4 years</span>
                  <Progress max="100" value="50" />
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
      </>
    );
  }
}

export default Home;
