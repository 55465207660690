import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SCALE, MINS_IN_AN_HOUR, HOURS_IN_A_DAY} from '../settings';

window.observer = new IntersectionObserver(function(entries) {
	// isIntersecting is true when element and viewport are overlapping
	// isIntersecting is false when element and viewport don't overlap
	if(entries[0].isIntersecting === true){
    let el = entries.target;
		console.log(`Element visible ${el.id}`, (el)?el.id:entries);
  }
}, { threshold: [0] });

//observer.observe(document.querySelector("#main-container"));

class TimeLine extends React.PureComponent {

  componentDidUpdate(){
    const hours = new Array(HOURS_IN_A_DAY).fill(null).map((item, index) => index);
    hours.forEach(item => {
      let el = document.querySelector(`tl_${item}`);
      if(el){
        window.observer.observe(el); // Element Scroll
        console.log('[observe] ', el);
      }
    });
  }

  render() {
    const hours = new Array(HOURS_IN_A_DAY).fill(null).map((item, index) => index);
    const width = MINS_IN_AN_HOUR * SCALE;
    const cellStyles = {width};
    const hoursElements = hours.map(item => {      
      return <span 
        id={`tl_${item}`}
        className={'timetable__header-cell'} 
        style={cellStyles} 
        key={item}>{item}:00</span>
    });

    return (
      <div>
        <div className="timetable__header-row">
          {hoursElements}
        </div>
      </div>
    );
  }
}

TimeLine.propTypes = {
  channels: PropTypes.array
};

export default TimeLine;
