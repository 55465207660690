
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export const Timeline = ({ currentTime, media, selectedChannel })=> {
    const [segments, setSegments] = useState([]);
    
    const segmentCount = 30;
    const toTimeString = (target)=> {
        const date = target.toDate();
        return date.toISOString().substr(11, 8)
    };

    useEffect(()=> {
        if (media.readyTimestamp) {
            const segments = [];
            for (let i=0; i<segmentCount; i++) {
                const data = { identifier: i, timestamp: '' };
                const timestring = (toTimeString((currentTime.add(2, 'hour').subtract(2, 'minute').add(i, 'minute'))));      // TODO: This might be wrong...
                if (i === 7 || i === 12 || i === 17 || i === 22 || i === 27) {
                    data.timestamp = timestring;
                } else {
                    data.timestamp = '';
                }
                segments.push(data);
            }
            setSegments(segments);
        }
    }, [media.readyTimestamp]);

    const renderSegment = (segment, index)=> {
        return (
            <div className='Scrubber-Panel-Timeline-Segment-Container' key={index}>
                <div className='Scrubber-Panel-Timeline-Segment'>
                    {segment.timestamp}
                </div>
            </div>
        );
    };

    return (
        <div className='Scrubber-Panel-Timeline-Container'>
            {segments.map(renderSegment)}
            {(selectedChannel.audioOnly) &&
                <div className='Scrubber-Panel-Timeline-Audio-Only-Bar' />
            }
        </div>
    );
};

Timeline.propTypes = {
    currentTime: PropTypes.object,
    media: PropTypes.object,
    selectedChannel: PropTypes.object
};