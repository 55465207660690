const assets = {
    "apple-icon": require('./img/apple-icon.png'),
    logo: require('./img/logo.png'),
    logo_white: require('./img/logo_white.png'),
    asc: require('./img/asc.gif'),
    bg: require('./img/bg.gif'),
    "card-danger": require('./img/card-danger.png'),
    "card-info": require('./img/card-info.png'),
    "cardprimary": require('./img/card-primary.png'),
    "card-success": require('./img/card-success.png'),
    "card-warning": require('./img/card-warning.png'),
    defaultAvatar: require('./img/default-avatar.png'),
    desc: require('./img/desc.gif'),
    favicon: require('./img/favicon.png'),
    placeholder: require('./img/placeholder.jpg'),

    // Animations - Lottie
    animUpload: require('./anim/upload.json'),
    player_background: require('./img/player_background.png'),
    animBackground: require('./anim/animation-for-bg.json'),
    animVideo: require('./anim/video-icon.json'),
    animDashboard: require('./anim/dashboard-funnel.json'),
    animPayment: require('./anim/payments.json'),
    animLiveStream: require('./anim/live-stream.json')
}

export default assets;
