import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from 'classnames';

// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    UncontrolledTooltip
} from 'reactstrap';

import images from '@assets';
import { Img } from '@components';
import config from '../../constants/config';

function createOption(opt) {
    return <option key={opt.id} value={'' + opt.id}>{opt.text}</option>
}

@inject('authStore', 'commonStore')
@withRouter
@observer
class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapseOpen: false,
            modalSearch: false,
            showId: false,
            color: 'navbar-transparent'
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateColor);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateColor);
    }

    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: 'bg-white'
            });
        } else {
            this.setState({
                color: 'navbar-transparent'
            });
        }
    };
    
    toggleCollapse = () => {
        if (this.state.collapseOpen) {
            this.setState({
                color: 'navbar-transparent'
            });
        } else {
            this.setState({
                color: 'bg-white'
            });
        }
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };
    
    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    };

    render() {
        const { history, authStore, commonStore, onToggleSidebar } = this.props;

        const { notifications } = commonStore;
        const selectedDest = {};

        let profilePic = images.defaultAvatar || '';

        const user = JSON.parse(localStorage.getItem('usr'));
        if (!user) {
            return null;
        }

        return (
            <>
                <Navbar
                    className={classNames('navbar-absolute', { [this.state.color]: this.props.location.pathname.indexOf('full-screen-map') === -1 })}
                    expand='lg'>
                    <Container fluid>
                        <div className='navbar-wrapper'>
                            <div className='navbar-minimize d-inline'>
                                <Button className='minimize-sidebar btn-just-icon'
                                    color='link'
                                    id='tooltip209599'
                                    onClick={onToggleSidebar}>
                                    <i className='tim-icons icon-align-center visible-on-sidebar-regular' />
                                    <i className='tim-icons icon-bullet-list-67 visible-on-sidebar-mini' />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target='tooltip209599'
                                    placement='right'>
                                    Sidebar toggle
                                </UncontrolledTooltip>
                            </div>
                            <div
                                className={classNames('navbar-toggle d-inline', { toggled: this.props.sidebarOpened })}>
                                <button className='navbar-toggler'
                                    type='button'
                                    onClick={this.props.onToggleSidebar}>
                                    <span className='navbar-toggler-bar bar1' />
                                    <span className='navbar-toggler-bar bar2' />
                                    <span className='navbar-toggler-bar bar3' />
                                </button>
                            </div>
                            <NavbarBrand href='#' onClick={e => { e.preventDefault(); this.setState({ showId: !this.state.showId }) }}>
                                {this.props.brandText} {(selectedDest && selectedDest.name) ? `| ${(this.state.showId) ? (selectedDest.id || selectedDest._id) : selectedDest.name}` : null}
                            </NavbarBrand>
                        </div>
                        <button className='navbar-toggler'
                            type='button'
                            data-toggle='collapse'
                            data-target='#navigation'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={this.toggleCollapse}>
                            <span className='navbar-toggler-bar navbar-kebab' />
                            <span className='navbar-toggler-bar navbar-kebab' />
                            <span className='navbar-toggler-bar navbar-kebab' />
                        </button>
                        <Collapse navbar isOpen={this.state.collapseOpen}>
                            <Nav className='ml-auto' navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle caret nav
                                        color='default'
                                        data-toggle='dropdown'>
                                        {(notifications && notifications.length > 0) &&
                                            <div className='notification d-none d-lg-block d-xl-block' />
                                        }
                                        <i className='tim-icons icon-sound-wave' />
                                        <p className='d-lg-none'>Notifications</p>
                                    </DropdownToggle>
                                    <DropdownMenu className='dropdown-navbar' right tag='ul'>
                                    {(notifications && notifications.length) ?
                                        <>
                                            <NavLink tag='li' onClick={() => commonStore.clearAllNotification()}>
                                                <DropdownItem className='nav-item'><b>Clear All</b></DropdownItem>
                                            </NavLink>
                                            {notifications.map((m, idx) =>
                                                <NavLink tag='li' key={idx} onClick={() => commonStore.clearNotification(idx)}>
                                                    <DropdownItem className='nav-item'>{m}</DropdownItem>
                                                </NavLink>
                                            )}
                                        </> :
                                        <NavLink tag='li'>
                                            <DropdownItem className='nav-item'>
                                                No notifications.
                                            </DropdownItem>
                                        </NavLink>
                                    }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle caret nav
                                        color='default'
                                        data-toggle='dropdown'
                                        onClick={e => e.preventDefault()}>
                                        <div className='photo'>
                                            <img alt='...' src={profilePic} />
                                        </div>
                                        <b className='caret d-none d-lg-block d-xl-block' />
                                        <p className='d-lg-none'>Log out</p>
                                    </DropdownToggle>
                                    <DropdownMenu className='dropdown-navbar' right tag='ul'>
                                        <DropdownItem divider tag='li' />
                                        <NavLink tag='li'>
                                            <DropdownItem
                                                className='nav-item'
                                                onClick={() => { authStore.logout().then(() => {
                                                        history.push('/web/login');
                                                        window.location.reload();
                                                    })
                                                    .catch(() => {
                                                        history.push('/web/login');
                                                        window.location.reload();
                                                    });
                                                }}
                                            >Log out</DropdownItem>
                                        </NavLink>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <li className='separator d-lg-none' />
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
                <Modal modalClassName='modal-search'
                    isOpen={this.state.modalSearch}
                    toggle={this.toggleModalSearch}>
                    <div className='modal-header'>
                        <Input id='inlineFormInputGroup' placeholder='SEARCH' type='text' />
                        <button className='close'
                            aria-label='Close'
                            data-dismiss='modal'
                            type='button'
                            onClick={this.toggleModalSearch}>
                            <i className='tim-icons icon-simple-remove' />
                        </button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default AdminNavbar;
