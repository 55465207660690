
import React from 'react';
import clsx from 'clsx';

import { NavLink } from 'react-router-dom';

import './style.scss';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeRoute: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { location, routes } = this.props;

        const recursedRoutes = [];
        for (let i=0; i< routes.length; i++) {
            const route = routes[i];
            for (let j=0; j<route.views.length; j++) {
                recursedRoutes.push(route.views[j]);
            }
        }

        const locationSegments = location.pathname.split('/').splice(1).map((segment)=> { return `/${segment}`; });
        const activeRoute = recursedRoutes.find((route)=> (route.path === locationSegments.join('')));
        
        if (JSON.stringify(this.state.activeRoute) !== JSON.stringify(activeRoute)) {
            this.setState({ activeRoute });
        }
    }

    render() {
        const { isOpen, routes, closeSidebar } = this.props;

        const user = JSON.parse(localStorage.getItem('usr'));

        const renderCategory = (category, index)=> {
            const user = JSON.parse(localStorage.getItem('usr'));
            if (!category.hidden && (category.roles.indexOf(user.role.type) !== -1)) {
                return (
                    <div className='Sidebar-Category' key={index}>
                        <div className='Sidebar-Category-Label'>{category.label}</div>
                        {category.views.map(renderRoute)}
                    </div>
                );
            }
        };

        const renderRoute = (route, index)=> {
            if (!route.hidden && (route.roles.indexOf(user.role.type) !== -1)) {
                return (
                    <NavLink className={clsx({ 'Sidebar-Link': true, 'selected': (JSON.stringify(this.state.activeRoute)===JSON.stringify(route)) })}
                        key={index}
                        to={route.path}
                        onClick={closeSidebar}>
                        <div>{route.icon}</div>
                        <div>{route.name}</div>
                    </NavLink>
                );
            }
            return null;
        };

        return (
            (user) &&
            <div className={clsx({ 'Sidebar-Container': true, 'hidden': !isOpen })}>
                <div className='Sidebar-Wrapper'>
                    {routes.map(renderCategory)}
                </div>
            </div>
        );
    }
}

export default Sidebar;