
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from 'react-autocomplete';

export const AutoComplete = ({ 
    options, 
    value, 
    getItemValue, 
    renderItem, 
    onChange, 
    onSelect, 
    backgroundColor = 'rgba(36, 54, 181, 0.64)' 
}) => {

    const [inputValue, setInputValue] = useState('');

    useEffect(()=> {
        setInputValue(value);
    }, [value]);

    const inputStyle = {
        background: backgroundColor,
        border: '1px solid #fff',
        borderRadius: '4px',
        boxShadow: '0 3px 4px 0px #000',
        color: '#fff',
        margin: '0 0 0 -5px',
        padding: '8px',
        width: '200px'
    };

    const menuStyle = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        maxHeight: '200px',
        padding: '2px 0',
        fontSize: '90%',
        position: 'fixed',
        overflow: 'auto',
        zIndex: '100'
    };

    return (
        <Autocomplete
            getItemValue={getItemValue}
            items={options}
            renderItem={renderItem}
            value={value}
            onChange={onChange}
            onSelect={onSelect}
            renderInput={(props)=> (
                <input {...props}
                    style={inputStyle}
                    value={inputValue}
                    onFocus={()=> { setInputValue(''); }}
                    onBlur={()=> { setInputValue(value); }} />
            )}
            renderMenu={(items, value, style) => (
                <div key={value} style={{ ...style, ...menuStyle }} children={items} />
            )}
        />
    );
};

AutoComplete.propTypes = {
    innerRef: PropTypes.node,
    options: PropTypes.array,
    style: PropTypes.object,
    value: PropTypes.string,
    getOptionLabel: PropTypes.func,
    getItemValue: PropTypes.func,
    renderItem: PropTypes.func,
    onChange: PropTypes.func,
    onSelect: PropTypes.func
};