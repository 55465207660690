
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { createUseStyles } from 'react-jss';

import { BottomDrawer, CenterDrawer, LeftDrawer, RightDrawer, TopDrawer } from './components';

export const drawerOrientation = { BOTTOM: 'bottom', CENTER: 'center', LEFT: 'left', RIGHT: 'right', TOP: 'top' };
export const backgroundClass = { CLEAR: 'clear', OPAQUE: 'opaque', TRANSPARENT: 'transparent',  };

export const Drawer = ({ background=backgroundClass.TRANSPARENT, children, isOpen=false, orientation, style={} })=> {
    const classes = useStyles();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(()=> {
        if (isOpen) {
            setIsVisible(true);
        }
    }, [isOpen]);
    
    if (isVisible && (orientation===drawerOrientation.CENTER)) {
        return (
            <div className={classes.Container} style={style}>
                <CenterDrawer
                    background={background}
                    isOpen={isOpen}>
                    {children}
                </CenterDrawer>
            </div>
        );
    } else if (isVisible && (orientation===drawerOrientation.BOTTOM)) {
        return (
            <div className={classes.Container} style={style}>
                <BottomDrawer
                    background={background}
                    isOpen={isOpen}>
                    {children}
                </BottomDrawer>
            </div>
        );
    } else if (isVisible && (orientation===drawerOrientation.LEFT)) {
        return (
            <div className={classes.Container} style={style}>
                <LeftDrawer
                    background={background}
                    isOpen={isOpen}>
                    {children}
                </LeftDrawer>
            </div>
        );
    } else if (isVisible && (orientation===drawerOrientation.RIGHT)) {
        return (
            <div className={classes.Container}>
                <RightDrawer
                    background={background}
                    isOpen={isOpen}>
                    {children}
                </RightDrawer>
            </div>
        );
    } else if (isVisible && (orientation===drawerOrientation.TOP)) {
        return (
            <div className={classes.Container} style={style}>
                <TopDrawer
                    background={background}
                    isOpen={isOpen}>
                    {children}
                </TopDrawer>
            </div>
        );
    } else {
        return null;
    }
};

const useStyles = createUseStyles({
    Container: {
        height: '100%',
        width: '100%'
    }
}, { name: 'Drawer' });

Drawer.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    orientation: PropTypes.string
};