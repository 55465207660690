
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

import { Events, NowOverlay, Programs, SeekButton, seekDirection, Thumb, Timeline } from './components';

import './style.scss';

export const ScrubberPanel = ({ 
    currentTime, epg=[], events=[], media, 
    selectedChannel, onMediaTimeChanged, onSeekBack, onSeekForward 
})=> {
    let showNowOverlay = false;
    const now = dayjs();
    if ((media.readyTimestamp && media.readyTimestamp.unix()) >= now.subtract(30, 'minute').unix()) {
        showNowOverlay = true;
    }
    
    return (
        <div className='Scrubber-Scrubber-Panel-Container'>
            <div className='Scrubber-Scrubber-Panel-Wrapper'>
                <SeekButton direction={seekDirection.BACK} onSeek={onSeekBack} />
                <Timeline currentTime={currentTime} media={media} selectedChannel={selectedChannel} />
                <Programs currentTime={currentTime} epg={epg} media={media} selectedChannel={selectedChannel}/>
                <Events currentTime={currentTime} events={events} media={media} selectedChannel={selectedChannel} />
                <Thumb currentTime={currentTime} media={media} onMediaTimeChanged={onMediaTimeChanged} />
                {(showNowOverlay) &&
                    <NowOverlay currentTime={currentTime} />
                }
                <SeekButton direction={seekDirection.FORWARD} onSeek={onSeekForward} />
            </div>
        </div>
    );
};

ScrubberPanel.propTypes = {
    currentTime: PropTypes.object,
    epg: PropTypes.array,
    events: PropTypes.array,
    media: PropTypes.object,
    selectedChannel: PropTypes.object,
    readyTimestamp: PropTypes.object,
    onMediaTimeChanged: PropTypes.func,
    onSeekBack: PropTypes.func,
    onSeekForward: PropTypes.func
};