
import { observable, action, reaction, toJS } from 'mobx';

import agentAJAX from '../services/agentAJAX';
import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

import config from '../constants/config';

import { ChannelEntity, ChannelGroupEntity } from '../entities';

import commonStore from './commonStore';
import authStore from './authStore';

import DataStore from './base';
import utils from './utils';

const defaultData = {
    channels: [],
    selectedChannel: null,

    loadingStates: {
        channels: false
    }
};

const dataKeys = { CHANNELS: 'channels' };
const loadingStates = { CHANNELS: 'channels' };

class ReChannelStore extends DataStore {
    @observable channels = [];
    @observable selectedChannel = null;

    @observable loadingState = {
        channels: false
    }

    constructor() {
        super();

        this.initializeSessionCache(dataKeys.CHANNELS, this.channels);
    }

    /**
     * Retrieves channels based on the current-user.
     * @param {boolean?} refresh - Should data forcefully be retrieved from the DB. 
     * @param {boolean?} noLoad - Should a Loading-Overlay be visible. 
     * @returns {Promise<Array<object>>}
     */
    @action async fetchChannels(refresh=true, noLoad=false) {
        if (!refresh) {
            this.fetchCachedData(dataKeys.CHANNELS);
        } else {
            const callee = '[ReChannelStore::fetchChannels]';
            const isAdmin = await authStore.isAdmin();
            const getAdminChannels = async ()=> {
                const response = await this.ajaxCall(agentAJAX.Channels.getAll, [noLoad], callee);
                if (response && !!response.length) {
                    this.setChannels(response);
                }
                return this.getChannels(noLoad);
            };
            const getChannelGroupChannels = async ()=> {
                const channels = [];
                const imageURL = `${config.serverURL}${config.apiRoute}`;
                const userId = (authStore.user._id || authStore.user.id);
                const response = await this.ajaxCall(agentAJAX.ChannelGroups.getByUserId, [userId, noLoad], callee);
                if (response && !!response.length) {
                    let channelIds = [];
                    response.forEach((channelGroup)=> {
                        channelIds = channelIds.concat(channelGroup.channels);
                    });
                    channelIds = [...new Set(channelIds)];  // Remove duplicates.
                    for (const channelId of channelIds) {
                        const channel = agentAJAX.Channels.get(channelId);
                        if (channel) {
                            channels.push(channel);
                        }
                    }
                    channels.forEach((channel)=> { // Fix Images - serve from API.
                        if (channel.image && channel.image.length) {
                            channel.image = `${imageURL}${channel.image}`;
                        }
                        channel.order = (!channel.order || channel.order <= 0) ? 9999 : channel.order;
                    });
                    channels.sort(utils.orderChannels);
                }
                this.setChannels(channels);
                return this.channels;
            };

            this.setLoadingState(loadingStates.CHANNELS, true);
            let channels = [];
            if (isAdmin) {  // Get ALL Channels
                channels = await getAdminChannels();
            } else {    // Return a specific-subset of Channels based on the users' ChannelGroup.
                channels = await getChannelGroupChannels();
            }
            this.setLoadingState(loadingStates.CHANNELS, false, true);
            return channels;
        }
    }

    /**
     * Retrieves the selected channel for this store.
     * If no selected channel exists, sets the value equal to the first
     * channel in the list of channels.
     * @returns Promise<object>
     */
     @action async getSelectedChannel() {
        if (this.selectedChannel && (this.selectedChannel._id || this.selectedChannel.id)) {
            return this.selectedChannel;
        }
        if (this.channels && this.channels.length) {
            this.setSelectedChannel(this.channels[0])
        } else {
            const channels = await this.getChannels(false);
            this.setSelectedChannel(channels[0]);
        }
        return this.selectedChannel;
    }

    /**
     * Sets the selected channel for this store.
     * @param {object} newChannel - The channel which is now selected.
     */
    @action setSelectedChannel(newChannel) {
        if (JSON.stringify(newChannel) !== JSON.stringify(this.selectedChannel)) {
            this.selectedChannel = newChannel;
        }
    }

    /**
     * Retrieves the channels for this store.
     * If no channels exists, tries to retrieve them from the backend.
     * @returns {array<object>|Promise<array<object>>}
     */
    @action async getChannels(noLoad=true) {
        return ((this.channels && this.channels.length) ? this.channels : await this.fetchChannels(true, noLoad));
    }

    /**
     * Sets the channels for this store.
     * @param {array<object>} channels - The channels-array to set.
     */
    @action setChannels(channels) {
        if (JSON.stringify(channels) !== JSON.stringify(this.channels)) {
            this.setSessionData(dataKeys.CHANNELS, channels);
            this.channels = channels;
        }
    }
}

// export default SIOStore(ReChannelStore, 'CHANNEL');
export default new ReChannelStore();