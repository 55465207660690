
import React from 'react';

import queryString from 'query-string';
import config from '../../../../constants/config';

class Player extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            source: ''
        }
    }

    componentDidMount() {
        const parsedQueryString = queryString.parse(window.location.search);

        // Assuming the content-id will be passed in.
        if (parsedQueryString && parsedQueryString.id) {
            // The source needs to be set somehow - not sure yet what that would look like...
            this.setState({ source: `${config.serverURL}/detect/${parsedQueryString.id}.mp4` });
        }
    }

    render() {

        return (
            <div className='Detection-Player-Container'>
                <div className='Detection-Player'>
                    <video controls
                        controlsList='nodownload'>
                        <source src={this.state.source} type='video/mp4' />
                    </video>
                </div>
                <div className='Detection-Controls'>
                    <p>
                        Playback indicative of content and not individual airing event.
                    </p>
                    <a href={this.state.source} 
                        rel="noopener noreferrer" 
                        target="_blank">Download</a>
                </div>
            </div>
        );
    }
};

export default Player;