import { createTheme } from '@material-ui/core/styles';
import { colors } from './colors';

/** Theme for Material-UI ^1.0.0 */
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors.primary1Color
    },
    secondary: {
      main: colors.secondary
    },
    background: {
      default: colors.headerBgd
    }
  },
  typography: {
    useNextVariants: true,
  },
  // Super Overrides
  overrides: {
    MuiPaper:{
      root:{
        background: colors.transparent
      }
    },
    MuiCard:{
      root:{
        overflow: 'unset'
      }
    },
    MUIDataTableViewCol:{
      label:{
        color: 'white',
      }
    },
    MuiCardActions:{
      root:{
        alignItems: 'flex-end'
      }
    },
    MUIDataTableToolbarSelect:{
      root:{
        backgroundColor: colors.fourthBackground,
      },
      deleteIcon:{
        color: colors.secondary,
      },
      title:{
        color: colors.secondary,
      }
    },
    MUIDataTableBodyCell:{
      root:{
        padding: '4px 5px 4px 5px',
        //verticalAlign: 'top'
      }
    },
    MUIDataTableFilter:{
      checkboxFormControlLabel:{
        color: 'white',
      }
    },
    MuiButton: {
      // raisedPrimary:{
      //   background: 'linear-gradient(45deg, #009cbd 25%, #00c9f4 80%)',
      //   color: 'white',
      //   border: 0,
      //   boxShadow: '0 2px 5px 1px rgba(0, 201, 244, .30)'
      // },
      // raisedSecondary:{
      //   background: 'linear-gradient(45deg, #ffa300 25%, #ffcc00 80%)',
      //   border: 0,
      //   boxShadow: '0 2px 5px 1px rgba(255, 204, 0, .30)'
      // },
      root: {
        borderRadius: 3,
        height: 45,
        padding: '0 30px',
        margin: '5px'
      }
    }
  }
});


export {
  theme
};
