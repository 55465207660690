
import React from 'react';

// Pages
import Dashboard from '../views/Dashboard';
import EPG from '../views/EPG';

import Scrubber from '../views/Scrubber';

import ChannelBuilder from '../views/ChannelBuilder';
import ChannelGroups from '../views/ChannelGroups';
import UserManager from '../views/UserManager';

import EventTagsManager from '../views/EventTagsManager';
import ExportsManager from '../views/Exports';
import TagsManager from '../views/TagsManager';

import Breakout from '../views/Breakout';
import Live from '../views/Live';

import { MdBuild, MdDashboard, MdGroupWork, MdPlayArrow, MdSettings, MdViewList } from 'react-icons/md';
import { FaFileExport, FaTags } from 'react-icons/fa';

import config from '../constants/config';

const _routes = [
    {
        identifier: 'general',
        hidden: false,
        label: 'Home',
        path: '/home',
        roles: ['root', 'manager', 'authenticated'],
        views: [
        {
            component: EPG,
            hidden: false,
            icon: <MdViewList size={24} />,
            layout: '/home',
            name: 'EPG',
            path: '/home/epg',
            roles: ['root', 'manager', 'authenticated']
        },
        {
            component: Scrubber,
            hidden: false,
            icon: <MdPlayArrow size={24} />,
            layout: '/home',
            name: 'Scrubber',
            path: '/home/scrubber',
            roles: ['root', 'manager', 'authenticated'],
        },
        {
            component: Live,
            hidden: true,
            layout: '/home',
            name: 'Live',
            path: '/home/live',
            redirect: true,
            roles: ['root', 'manager', 'authenticated']
        },
        {
            component: Breakout,
            hidden: true,
            layout: '/home',
            name: 'Breakout',
            path: '/home/breakout',
            redirect: false,
            roles: ['root', 'manager', 'authenticated']
        }]
    }, {
        identifier: 'channel-management',
        hidden: false,
        label: 'Channel Management',
        path: '/home',
        roles: ['root', 'manager'],
        views: [{
            component: Dashboard,
            hidden: false,
            icon: <MdDashboard size={24} />,
            layout: '/home',
            name: 'Dashboard',
            path: '/home/dashboard',
            roles: ['root', 'manager']
        }, {
            component: ChannelBuilder,
            hidden: false,
            icon: <MdBuild size={24} />,
            layout: '/home',
            name: 'Channel Manager',
            path: '/home/channel',
            roles: ['root']
        },
        {
            component: ChannelGroups,
            hidden: false,
            icon: <MdGroupWork size={24} />,
            layout: '/home',
            name: 'Channel Groups',
            path: '/home/channel-groups',
            roles: ['root'],
        }],
    }, {
        identifier: 'my-data',
        hidden: false,
        label: 'My Data',
        path: '/home',
        roles: ['root', 'manager', 'authenticated'],
        views: [{
            component: TagsManager,
            hidden: config.hideTags,
            icon: <FaTags size={24} />,
            layout: '/home',
            name: 'Tags Management',
            path: '/home/tags-manager',
            roles: ['root', 'manager', 'authenticated']
        }, {
            component: ExportsManager,
            hidden: false,
            icon: <FaFileExport size={24} />,
            layout: '/home',
            name: 'Exports Management',
            path: '/home/exports',
            roles: ['root', 'manager', 'authenticated'],
        }, {
            component: EventTagsManager,
            hidden: false,
            icon: <FaFileExport size={24} />,
            layout: '/home',
            name: 'Event Tags',
            path: '/home/event-tags',
            roles: ['root', 'manager', 'authenticated'],
        }]
    }, {
        identifier: 'admin',
        hidden: false,
        label: 'root',
        path: '/home',
        roles: ['root'],
        views: [{
            component: UserManager,
            hidden: false,
            icon: <MdSettings size={24} />,
            layout: '/home',
            name: 'User Management',
            path: '/home/user-manager',
            roles: ['root']
        }]
    }
];

const getRoutes = ()=> {
    return _routes;
};

export default getRoutes;
