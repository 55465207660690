
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';

import { CgRedo } from 'react-icons/cg';
import { MdPlayArrow } from 'react-icons/md';

import no_image from '../../../../assets/img/no_image.png';

import img_active from '../../../../assets/img/card-success.png';
import img_restarting from '../../../../assets/img/card-warning.png';
import img_stopped from '../../../../assets/img/card-danger.png';
import audioOnlyImg from '../../../../assets/img/audio-only.gif';

import config from '../../../../constants/config';

import styles from './style.scss';

class Channel extends React.Component {
    constructor(props) {
        super(props);

        this.descriptionContainer = React.createRef();

        this.state = {
            imageData: {
                timestamp: null,
                data: null
            }
        };
    }

    componentDidMount() {
        this.refreshImageData();

        setInterval(()=> {
            this.refreshImageData();
        }, 10000);
    }

    refreshImageData() {
        const { id, requestImage } = this.props;

        const timestamp = new Date().getTime();
        this.setState({ timestamp });
    }

    processClasses(failed) {
        return {
            channelContainer: clsx({ 'Channel-Container': true, 'failed': failed }),
            channelDetailsContainer: clsx({ 'Channel-Details-Container': true, 'failed': failed })
        };
    }

    render() {
        const { id, audioOnly, feedurl, image, name, status, onOpenConfirmation, onOpenModalPlayer } = this.props;

        const classes = this.processClasses(status !== 'PROCESSING');

        // let imageAddress = `${config.serverURL}/vod/${id}/thumbnail.jpg?v=${this.state.timestamp}`;
        // if (audioOnly) {
        //     // console.log(image);
        //     imageAddress = `${config.serverURL}${image}`;
        // }

        let imageAddress = img_restarting;
        let statusText = 'RESTARTING';
        switch(status) {
            case 'PROCESSING':
                imageAddress = img_active;
                statusText = 'RUNNING';
                break;
            case 'RESTARTING':
                imageAddress = img_restarting;
                break;
            case 'STOPPED':
                imageAddress = img_stopped;
                statusText = 'STOPPED';
                break;
            default:
                break
        }

        return (
            <Grid item>
                <div className={classes.channelContainer}>
                    <div className='Channel-Wrapper'>
                        <div className='channelheader'>
                            {statusText}
                        </div>
                        <img className='Channel-Image' alt='' src={imageAddress} onError={(event)=> { event.target.src = no_image; }} />
                        {status === 'PROCESSING' && audioOnly ? 
                            <img className='Channel-Image-Audio-Only' alt='' src={audioOnlyImg} /> : null
                            }
                        <div className={classes.channelDetailsContainer}>
                            <div className='Channel-Details-Description-Container' ref={this.descriptionContainer}
                                onMouseEnter={this.handleIdentityHover} onMouseLeave={this.handleIdentityMouseLeft}>
                                <p className='Channel-Details-Description-Identity'>
                                    {name}
                                </p>
                                <p className='Channel-Details-Description-Feed'>{feedurl}</p>
                            </div>
                            <div className='Channel-Details-Actions-Container'>
                                <div className='Channel-Details-Action-Container' onClick={onOpenConfirmation}>
                                    <div className='Channel-Details-Action restart'>
                                        <CgRedo size={24} />
                                    </div>
                                </div>
                                <div className='Channel-Details-Action-Container'>
                                    <div className='Channel-Details-Action play' onClick={onOpenModalPlayer}>
                                        <MdPlayArrow size={24} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        );
    }
}

export default Channel;

Channel.propTypes = {
    id: PropTypes.string,
    audioOnly: PropTypes.bool,
    feedurl: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    status: PropTypes.string,
    transcoder: PropTypes.string,
    onOpenConfirmation: PropTypes.func,
    onOpenModalPlayer: PropTypes.func
};