
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { createUseStyles } from 'react-jss';

import { backgroundClass } from '../../index';

export const LeftDrawer = ({ background, children, isOpen })=> {
    const classes = useStyles();
    
    return (
        <div className={clsx({
            [classes.Container]: true,
            [classes.SlideInLeft]: (isOpen),
            [classes.SlideOutLeft]: (!isOpen)})}>
            <div className={clsx({
                [classes.Wrapper]: true,
                [classes.Clear]: (background===backgroundClass.CLEAR),
                [classes.Opaque]: (background===backgroundClass.OPAQUE),
                [classes.Transparent]: (background===backgroundClass.TRANSPARENT)})}>
                {children}
            </div>
        </div>
    );
};

const useStyles = createUseStyles({
    '@keyframes slideInLeft': {
        from: { display: 'none', left: '-100%', opacity: '0' },
        to: { display: 'block', left: '0', opacity: '1' }
    },
    '@keyframes slideOutLeft': {
        from: { display: 'block', left: '0', opacity: '1' },
        to: { display: 'none', left: '-100%', opacity: '0' }
    },
    Container: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        zIndex: '99',
    },
    Wrapper: {
        height: '100%',
        left: '0',
        overflow: 'hidden',
        position: 'absolute',
        width: '100%'
    },
    Clear: {
        background: 0
    },
    Opaque: {
        background: '#000'
    },
    Transparent: {
        background: '#00000060'
    },
    SlideInLeft: {

        animationName: '$slideInLeft',
        animationDuration: '1s',
        animationTimingFunction: 'ease-out'
    },
    SlideOutLeft: {
        left: '-100%',
        
        animationName: '$slideOutLeft',
        animationDuration: '1s',
        animationTimingFunction: 'ease-in'
    }
}, { name: 'LeftDrawer' });

LeftDrawer.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool,
};