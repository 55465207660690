/*
{ 
  id: '1', 
  firstName: 'John', 
  lastName: 'Dow', 
  email: 'john@dow.com', 
  username: 'john', 
  avatar: 'https://ui-avatars.com/api/?name=John+Dow',
  password: '$2b$05$TrB1So568cW3c6isDS8j0erb4HbjQsK3BYBi0mgJpjhyeUsodQnoe', 
  roles: [], 
  attributes: {}},
*/

export default {
    id: 'user',
    label: 'User List',
    name: 'user',
    namePlural: 'users',
    hideTitle: true, // Hides Title From Edit & View
    titleField: 'name',
    cardOptions: {
        labelField: 'name',
        descField: 'email',
        imgField: 'avatar',
        masonryOptions: { // masonryCardOptions Overrides
            transitionDuration: 100,
            gutter: 10,
            originTop: true,
            resize: true,
            fitWidth: true
        }
    },
    gridOptions: {
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 20, 50, 100, 200]
        // download: false,
        // print: false,
        // filter: false,
        // columns: []
    },
    fields: [{
        id: '_id',
        identity: true,
        type: 'text',
        width: 0,
        listColumnOptions: { display: false }, // mui-Datatable column options... display:false, width etc.
        required: false
    }, {
        id: 'channelgroups',
        label: 'Channel Group',
        type: 'list',
        list: [], // populate list in Store
        width: 100,
        inMany: true,
        listColumnOptions: { display: false },
    }, {
        id: 'confirmed',
        type: 'boolean',
        listColumnOptions: { display: false },
        label: 'Confirmed (Future Email Confirm)',
        labelFalse: 'unconfirmed',
        labelTrue: 'Confirmed'
    }, {
        id: 'blocked',
        type: 'boolean',
        listColumnOptions: { display: false },
        defaultValue: false,
        label: 'Blocked (Lock or Unlock User)',
        labelFalse: 'Active',
        labelTrue: 'Blocked'
    }, {
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        required: true
    }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        required: true
    }, {
        id: 'email',
        type: 'email',
        label: 'Email',
        required: true
    }, {
        id: 'username',
        type: 'text',
        label: 'User Name',
        required: true
    }, {
        id: 'password',
        type: 'password',
        label: 'Password',
        listColumnOptions: { display: false },
    }, {
        id: 'role',
        type: 'lov',
        list: [], // populate list in Store
        label: 'Role',
        required: true
    }],
    groups: [{
        id: 'p1', type: 'panel',
        label: 'Details', width: 45,
        fields: ['firstName', 'lastName', 'username', 'email', 'role']
    }, {
        id: 'p2', type: 'panel',
        label: 'Details', width: 45,
        fields: ['password', 'channelgroups', 'description', 'confirmed', 'blocked']
    }]
}