
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'reactstrap';

import { Modal } from '../components';

import './style.scss';

class ModalConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        const { onClose } = this.props;

        onClose();
    }

    render() {
        const { acceptText='Confirm', children, declineText='Cancel', isOpen, onAccept, onDecline, showButtons=true } = this.props;

        return (
            <Modal clickAway isOpen={isOpen} onClose={this.handleClose}>
                <div className='Modal-Confirmation-Container'>
                    <div className='Modal-Confirmation-Wrapper'>
                        <div className='Modal-Confirmation-Content'>
                            {children}
                        </div>
                        <div className='Modal-Confirmation-Controls-Container'>
                            <div className='Modal-Confirmation-Controls-Wrapper'>
                                {showButtons ? (
                                    <>
                                        <Button onClick={onDecline}>
                                            {declineText}
                                        </Button>
                                        <Button onClick={onAccept}>
                                            {acceptText}
                                        </Button>
                                    </>
                                ) : (
                                    <>Loading...</>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ModalConfirmation;

ModalConfirmation.propTypes = {
    acceptText: PropTypes.string,
    children: PropTypes.node,
    declineText: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func
}