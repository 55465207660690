
import React from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';

import { MdArrowBack, MdArrowForward } from 'react-icons/md';

import './style.scss';

export const seekDirection = { BACK: 'back', FORWARD: 'forward' };
export const SeekButton = ({ direction, onSeek })=> {

    return (
        <div className='Scrubber-Panel-Seek-Button-Container'>
            <button className={clsx({
                'Scrubber-Panel-Seek-Button': true,
                'Scrubber-Panel-Seek-Button-Back': (direction===seekDirection.BACK),
                'Scrubber-Panel-Seek-Button-Forward': (direction===seekDirection.FORWARD) })} onClick={onSeek}>
                {(direction===seekDirection.BACK) ?
                    <MdArrowBack size={24} /> :
                    <MdArrowForward size={24} />
                }
            </button>
        </div>
    );
};

SeekButton.propTypes = {
    direction: propTypes.string.isRequired,
    onSeek: propTypes.func
};