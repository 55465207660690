
import { observable, action } from 'mobx';

import Hls from 'hls.js';

import config from '../constants/config';

import SIOStore from '../serviceWrapper/SocketIO/IOConnectStore';

const defaultData = {
    canPlay: false,
    currentTime: 0,
    duration: 0,
    isFullscreen: false,
    isPlaying: false,
    isReady: false,
    mediaAttached: false,
    playbackRate: 1,
    player: null,
    readyTimestamp: null,
    segmentDuration: 30,
    segmentInterval: 5,
    source: null,
};

class RePlayerStore {
    @observable canPlay = false;
    @observable currentTime = 0;
    @observable duration = 0;
    @observable isFullscreen = false;
    @observable isPlaying = false;
    @observable isReady = false;
    @observable mediaAttached = false;
    @observable playbackRate = 1;
    @observable player = null;
    @observable readyTimestamp = null;
    @observable segmentDuration = 30;
    @observable segmentInterval = 5;
    @observable source = null;

    @observable hls = null;

    @action setupHLS(callback=()=>{}) {
        if (this.player) {
            if (this.source) {
                if (!this.hls) {
                    this.hls = new Hls({ startPosition: 0 });
    
                    this.hlsFeedback('Attaching New Media');
                    if (!this.mediaAttached) {
                        this.hls.attachMedia(this.player);
                    }
    
                    this.hls.on(Hls.Events.MEDIA_ATTACHED, ()=> {
                        this.hlsFeedback('Media Attached');
                        if (this.hls) {
                            this.setPlayer(this.hls.media);
                            this.setIsReady(false);
                            
                            this.hls.loadSource(this.source);
                            if (this.player) {
                                this.player.addEventListener('loadedmetadata', ()=> {
                                    this.setIsReady(true);
                                    this.setCanPlay(true);
                                    this.setMediaAttached(true);
            
                                    // HACK: This needs to be addressed...
                                    setTimeout(()=> {
                                        callback();
                                    }, 200);
                                });
                            }
                        }
                    });
    
                    this.hls.on(Hls.Events.MANIFEST_LOADED, ()=> {
                        this.hlsFeedback('Loaded New Source');
                        this.setIsReady(false);
                    });
    
                    this.hls.on(Hls.Events.MANIFEST_PARSED, (_, data)=> {
                        this.hlsFeedback(`Manifest Loaded, Found ${data.levels.length} Quality Level(s)`);
                    });
    
                    this.hls.on(Hls.Events.MEDIA_DETACHED, ()=> {
                        this.resetPlayer();
                        this.setSource(null);
                        this.setPlayer(null);
                        this.setMediaAttached(false);
                    });
    
                    this.hls.on(Hls.Events.ERROR, (_, data)=> {
                        if (data.fatal) {
                            this.pause();
                        }
                        console.error('[HLS ERROR]', data);
                    });
                }
            } else {
                this.debugLog('No Source Provided');
            }
        } else {
            this.debugLog('HTML Player Is Not Available Yet');
        }
    }

    @action setPlayer(player) {
        this.debugLog('Setting new Player');
        this.player = player;
    }

    @action setSource(source, resetPlayer=true) {
        this.debugLog(`Updating Player Source: ${source}`);
        this.source = source;
        if (resetPlayer) {
            this.resetPlayer();
        }
    }

    @action setPlaybackRate(playbackRate) {
        if (this.playbackRate !== playbackRate) {
            this.debugLog(`Setting Playback Rate: ${playbackRate}`);
            this.playbackRate = playbackRate;
            if (this.player) {
                this.player.playbackRate = playbackRate;
            }
        }
    }

    @action setReadyTimestamp(readyTimestamp) {
        if (readyTimestamp) {
            if (!this.readyTimestamp || (this.readyTimestamp.unix() !== readyTimestamp.unix())) {
                this.debugLog(`Setting Ready Timestamp: ${readyTimestamp}`);
                this.readyTimestamp = readyTimestamp;
            }
        }
    }

    @action setIsFullscreen(isFullscreen) {
        if (this.isFullscreen !== isFullscreen) {
            this.debugLog(`Is Fullscreen: ${isFullscreen}`);
            this.isFullscreen = isFullscreen;

            (isFullscreen) ? this.enterFullscreen() : this.exitFullscreen();
        }
    }

    @action setIsPlaying(isPlaying) {
        if (this.isPlaying !== isPlaying) {
            this.debugLog(`Is Playing: ${isPlaying}`);
            this.isPlaying = isPlaying;
        }
    }

    @action play() {
        if (!this.isPlaying) {
            if (this.player) {
                this.player.play();
            }
            this.setIsPlaying(true);
        }
    }

    @action pause() {
        if (this.isPlaying) {
            if (this.player) {
                this.player.pause();
            }
            this.setIsPlaying(false);
        }
    }

    @action setCanPlay(canPlay) {
        if (this.canPlay !== canPlay) {
            this.debugLog(`Can Play: ${canPlay}`);
            this.canPlay = canPlay;
        }
    }

    @action setCurrentTime(currentTime) {
        if (currentTime) {
            this.currentTime = currentTime;
    
            this.player.currentTime = currentTime;
        }
    }

    @action setDuration(duration) {
        if (this.duration !== duration) {
            this.debugLog(`Duration Updated: ${duration}`);
            this.duration = duration;
        }
    }

    @action setMediaAttached(mediaAttached) {
        if (this.mediaAttached !== mediaAttached) {
            this.debugLog(`Media Attached Updated: ${mediaAttached}`);
            this.mediaAttached = mediaAttached;
        }
    }

    @action setSegmentDuration(segmentDuration) {
        if (this.segmentDuration !== segmentDuration) {
            this.debugLog(`Segment Duration: ${segmentDuration}`);
            this.segmentDuration = segmentDuration;
        }
    }

    @action setSegmentInterval(segmentInterval) {
        if (this.segmentInterval !== segmentInterval) {
            this.debugLog(`Segment Interval: ${segmentInterval}`);
            this.segmentInterval = segmentInterval;
        }
    }

    @action setIsReady(isReady) {
        if (this.isReady !== isReady) {
            this.debugLog(`Is Ready: ${isReady}`);
            this.isReady = isReady;
        }
    }

    @action clearStore() {
        Object.keys(defaultData).forEach(key => {
            this[key] = defaultData[key];
        });
    }

    resetPlayer() {
        this.debugLog('Clearing Player');
        this.pause();
        this.setCanPlay(false);
        this.setIsReady(false);
        this.setMediaAttached(false);
        this.setCurrentTime(0, false, true);
        this.setDuration(0);
        this.setPlaybackRate(1);
    }

    hlsFeedback(message) {
        this.debugLog(`[HLS] ${message}`);
    }

    enterFullscreen() {
        if (this.player) {
            if (this.player.requestFullscreen) {
                return this.player.requestFullscreen();
            } else if (this.player.mozRequestFullScreen) {
                return this.player.mozRequestFullScreen();
            } else if (this.player.webkitRequestFullscreen) {
                return this.player.webkitRequestFullscreen();
            } else if (this.player.msRequestFullscreen) {
                return this.player.msRequestFullscreen();
            }
        }
    }

    exitFullscreen() {
        if (this.player) {
            if (this.player.exitFullscreen) {
                return this.player.exitFullscreen();
            }
        }
    }

    debugLog(message) {
        if (config.debug) {
            console.log(`|RePlayerStore| ${message}`);
        }
    }
}

// const replayerStore = SIOStore(RePlayerStore, 'ALL');
const replayerStore = new RePlayerStore();
export default replayerStore;